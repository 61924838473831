<section 
    *ngIf="telemonitorings?.length > 0; else noTelemonitoringBlock"
    class="flex flex-col gap-2 h-[72vh] overflow-y-auto overflow-x-hidden"
    scroll-tracker
    [threshold]="{top: 700, bottom: 300}"
    [topGuard]="isPreviousPageLoading"
    [bottomGuard]="isNextPageLoading"
    (topThreshold)="this.loadPrevious.emit()"
    (bottomThreshold)="this.loadNext.emit()"
    [isDisabled]="disableScrollTracker"
>
    <pxc-telemonitoring-preview
        *ngFor="let telemonitoring of telemonitorings; trackBy: trackByTelemonitoringId"
        [displayPatient]="displayPatient"
        [telemonitoring]='telemonitoring'
        class="cursor-pointer animate-in fade-in slide-in-from-top-1"
        [routerLink]="['/dashboard/telemonitorings', telemonitoring.telemonitoring.id]"
        [queryParams]="{'cc': telemonitoring.telemonitoring.clientCode}"
        queryParamsHandling="merge"
        [attr.data-testid]="telemonitoring.telemonitoring.id"
        [actions]="actions"
    ></pxc-telemonitoring-preview>
    <div class="flex items-center justify-center" *ngIf="isNextPageLoading">
        <pxc-spinner [isVisible]="isNextPageLoading"></pxc-spinner>
    </div>
</section>

<ng-template #noTelemonitoringBlock>
    <div class="mx-auto my-3 flex flex-col items-center justify-center gap-3">
        <pxc-lottie-label
        animation="sequenceEmpty"
        message="Il n'y a aucun télésuivi"
        data-testid="empty-state"
        ></pxc-lottie-label>
        <ng-content select="[emptyState]"></ng-content>
    </div>
</ng-template> 