<div 
  class="flex justify-center items-center relative"
>
  <button
    tuiIconButton
    appearance="flat"
    *ngIf="max > itemsCount"
    class="!hidden xl:!block !absolute -translate-y-1/2 top-1/2 z-10 -left-9"
    [ngClass]="{
      'invisible': index === 0, 
      '!hidden': hideControlsOnMobile
    }"
    (click)="previous()"
    data-testid="previous"
    icon="tuiIconChevronLeftLarge"
    size="s"
  ></button>

  <ng-content></ng-content>

  <button
    tuiIconButton
    appearance="flat"
    *ngIf="max > itemsCount"
    class="!hidden xl:!block !absolute -translate-y-1/2 top-1/2 z-10 -right-9"
    [ngClass]="{
      'invisible': index + itemsCount > max - 1, 
      '!hidden': hideControlsOnMobile
    }"
    icon="tuiIconChevronRightLarge"
    (click)="next()"
    data-testid="next"
    size="s"
  ></button>
</div>