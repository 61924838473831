<form [formGroup]="sequenceForm">  
  <tui-radio-labeled
    *ngFor="let sequence of sequences; let idx=index; trackBy: trackBySequenceId"
    formControlName="selectedSequenceId"
    [item]="sequence.sequenceInstance.id"
    size="l"
    [attr.data-testid]="'radio-' + sequence.sequenceInstance.id"
    class="w-full py-2"
  >
    <pxc-sequence-preview 
      class="w-full" 
      id="seq-{{idx}}" 
      [sequence]="sequence" 
      [compactMode]="false"
      [mobileMode]="true"
      [patientRedirect]="false"
      [attr.data-testid]="'sequence-'+sequence.sequenceInstance.id"
    ></pxc-sequence-preview>
  </tui-radio-labeled>
</form>