/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, inject } from '@angular/core';
import { AuthenticationService } from '../../../services/authentication.service';
import { Department } from 'src/app/shared/models/base/department';
import { Observable, combineLatest, map, of, switchMap } from 'rxjs';
import { MenuEntry } from 'src/app/shared/models/base/menu-entry';
import { Store } from '@ngrx/store';
import { selectUserActiveLicenseState } from 'src/app/shared/store/licenses/licenses.selectors';
import { AvatarStyleService } from 'src/app/services/avatar-style.service';
import { SidebarService } from './sidebar.service';
import { ChatService } from '../../chat/chat.service';
import { LicenseState, supportAccessLicenseStates } from '@pixacare/pxc-ts-core';

const clientCodeReplacementToken = '#{clientCode}#';
const departmentIdReplacementToken = '#{departmentId}#';

@Injectable()
export class MenuBuilderService {

  private readonly chatService = inject(ChatService);

  clientMenuEntries$: Observable<MenuEntry[]>;
  departmentsEntries$: Observable<MenuEntry[]>;

  // Conditions

  private isLicenseSupport = ({ licenseState }: {
    licenseState: LicenseState;
  }) => licenseState === LicenseState.SUPPORT;

  private isDepartmentOpen = ({ department }: {
    department: Department;
  }) => department.isOpen;

  private isUserAdmin = ({ department }: {
    department: Department;
  }) => department.memberships[this.authService.currentUser.id]?.isAdmin;

  // Entries

  userEntries: MenuEntry[] = [
    {
      title: 'Messagerie',
      icon: 'tuiIconMessageSquareLarge',
      link: '/dashboard/chat',
      count: this.chatService.unreadMessagesCounter$,
    },
    {
      title: 'Mes télésuivis',
      icon: 'telemonitoring',
      link: '/dashboard/telemonitorings',
    },
    {
      title: 'Mes mots clés',
      icon: 'tuiIconTagLarge',
      link: '/dashboard/labels',
    },
    {
      title: 'Partagé avec moi',
      icon: 'tuiIconShare2Large',
      link: '/dashboard/sharedtome',
    },
  ];

  private clientEntries: MenuEntry[] = [
    {
      title: 'Administration',
      icon: 'tuiIconSettingsLarge',
      link: '/dashboard/admin',
      queryParams: { cc: '#{clientCode}#' },
      condition: ({ licenseState }) =>
        supportAccessLicenseStates.includes(licenseState),
    },
    {
      title: 'Mes photos',
      icon: 'tuiIconImageLarge',
      link: '/dashboard/sequences',
      queryParams: { cc: '#{clientCode}#' },
      condition: ({ licenseState }) =>
        !this.isLicenseSupport({ licenseState }),
    },
    {
      title: 'Mes patients',
      icon: 'tuiIconUsersLarge',
      link: '/dashboard/patients',
      queryParams: {
        cc: '#{clientCode}#',
        is_archived: '0',
      },
      condition: ({ licenseState }) =>
        !this.isLicenseSupport({ licenseState }),
    },
  ];

  private departmentEntries: MenuEntry[] = [
    {
      title: 'Administration',
      icon: 'tuiIconSettingsLarge',
      link: '/dashboard/department/#{departmentId}#/admin',
      queryParams: { cc: '#{clientCode}#' },
      condition: ({ licenseState, department }) =>
        this.isUserAdmin({ department }) || this.isLicenseSupport({ licenseState }),
    },
    {
      title: 'Photos du groupe',
      icon: 'tuiIconImageLarge',
      link: '/dashboard/department/#{departmentId}#/sequences',
      queryParams: {
        cc: '#{clientCode}#',
      },
      condition: ({ licenseState, department }) =>
        (this.isDepartmentOpen({ department }) || this.isUserAdmin({ department }))
        && !this.isLicenseSupport({ licenseState }),
    },
    {
      title: 'Patients du groupe',
      icon: 'tuiIconUsersLarge',
      link: '/dashboard/department/#{departmentId}#/patients',
      queryParams: {
        cc: '#{clientCode}#',
        is_archived: '0',
      },
      condition: ({ licenseState, department }) =>
        (this.isDepartmentOpen({ department }) || this.isUserAdmin({ department }))
        && !this.isLicenseSupport({ licenseState }),
    },
  ];

  constructor(
    private authService: AuthenticationService,
    private readonly avatarStyleService: AvatarStyleService,
    private readonly store: Store,
    private readonly sidebarService: SidebarService,
  ) {

    this.clientMenuEntries$ = this.computeMenuEntries(this.clientEntries);

    this.departmentsEntries$ = this.sidebarService.departments$.pipe(
      // Get entries for each departments
      switchMap((departments) => departments?.length > 0
        ? combineLatest(
          departments.map((department) =>
            this.computeMenuEntries(this.departmentEntries, department).pipe(
              map((entries) => ({ department, entries })))))
        : of([]),
      ),
      // Wrap them in their folder
      map((departmentsWithEntries) => departmentsWithEntries
        .filter(({ entries }) => entries.length > 0)
        .map(({ department, entries }) => ({
          id: department.id,
          title: department.name,
          style: this.avatarStyleService.getAvatarColors(department.name),
          children: entries,
        })),
      ),
    );
  }

  private computeMenuEntries(entries: MenuEntry[], department?: Department): Observable<MenuEntry[]> {
    return combineLatest([
      this.sidebarService.activeClientCode$,
      this.store.select(selectUserActiveLicenseState),
    ]).pipe(
      map(([clientCode, licenseState]) => ({
        validEntries: entries.filter((entry) =>
          !entry.condition || entry.condition({ licenseState, department })),
        clientCode,
      })),
      map(({ validEntries, clientCode }) =>
        this.resolveTokens(validEntries, { clientCode, departmentId: department?.id })),
    );
  }

  private resolveTokens(entries: MenuEntry[], values: {
    clientCode: string;
    departmentId?: number;
  }): MenuEntry[] {
    return entries.map((entry) => ({
      ...entry,
      link: this.resolveString(entry.link, values),
      queryParams: entry.queryParams && Object.entries(entry.queryParams).reduce((queryParams, [key, queryParam]) => ({
        ...queryParams,
        [key]: this.resolveString(queryParam, values),
      }),  {}),
    }));
  }

  private resolveString(value: string, { clientCode, departmentId }: {
    clientCode: string;
    departmentId?: number;
  }): string {

    const result = value.replace(clientCodeReplacementToken, clientCode);

    if (departmentId) {
      return result.replace(departmentIdReplacementToken, departmentId.toString());
    }

    return result;
  }

}
