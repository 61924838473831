import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';
import { Subscription } from 'rxjs';
import { SequenceContext } from 'src/app/shared/models/enums/sequence-context.enum';
import { NameBySequenceId } from 'src/app/shared/models/name-by-sequence-id';
import { trackBySequenceId } from 'src/app/shared/utils/trackBySequenceId';

type SequencesForm = { [sequenceId: string]: FormControl<boolean> };

@UntilDestroy()
@Component({
  selector: 'pxc-sequence-select-checkbox-list',
  templateUrl: './sequence-select-checkbox-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceSelectCheckboxListComponent implements OnChanges {

  @Input() sequences: FlattenedSequence[];
  @Input() selectedSequenceIds: number[];
  @Input() sadmEntityNames: NameBySequenceId;
  @Input() disabledSequenceIds: number[];
  @Input() context: SequenceContext = SequenceContext.SEQUENCE;
  @Input() compactMode = true;
  @Output() selectSequences: EventEmitter<number[]> = new EventEmitter();
  @Output() selectSadmSequences: EventEmitter<number> = new EventEmitter();

  SequenceContext = SequenceContext;
  sequencesForm: FormGroup;
  isAllSequencesSelected = false;

  sequenceFormValueChanges: Subscription;

  trackBySequenceId = trackBySequenceId;

  ngOnChanges({ sequences }: SimpleChanges): void {
    if (!sequences?.currentValue) {
      return;
    }

    this.sequencesForm = new FormGroup<SequencesForm>(
      this.sequences.reduce((acc, sequence) => {
        acc[sequence.sequenceInstance.id] = new FormControl<boolean>({
          value: this.selectedSequenceIds.includes(sequence.sequenceInstance.id),
          disabled: this.disabledSequenceIds.includes(sequence.sequenceInstance.id),
        });
        return acc;
      }, {}),
    );

    this.updateIsAllSequencesSelected(this.sequencesForm.getRawValue());

    this.sequenceFormValueChanges?.unsubscribe();
    this.sequenceFormValueChanges = this.sequencesForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: SequencesForm) => {
        this.selectSequences.emit(this.getSelectedSequenceIds(value));
        this.updateIsAllSequencesSelected(value);
      });
  }

  checkUncheckAll(checked: { currentTarget: HTMLInputElement }): void {
    if (checked) {
      this.isAllSequencesSelected = true;
      this.sequencesForm.patchValue(
        this.updateSequenceFormValue(this.getAllSelectableSequenceIds(), true),
      );
    } else {
      this.isAllSequencesSelected = false;
      this.sequencesForm.patchValue(
        this.updateSequenceFormValue(this.getAllSelectableSequenceIds(), false),
      );
    }
  }

  emitSelectSadmSequences(sadmEntityId: number): void {
    this.selectSadmSequences.emit(sadmEntityId);
  }

  private getSelectedSequenceIds(formValue: SequencesForm): number[] {
    return Object.keys(formValue).reduce((acc, key) => {
      if (formValue[key]) {
        acc.push(+key);
      }
      return acc;
    }, []);
  }

  private updateSequenceFormValue(sequenceIds: number[], checked: boolean): SequencesForm {
    return sequenceIds.reduce((acc, sequenceId) => {
      acc[sequenceId] = checked;
      return acc;
    }, {});
  }

  private updateIsAllSequencesSelected(formValue: SequencesForm): void {
    this.isAllSequencesSelected = Object.values(formValue).every((isSelected) => isSelected);
  }

  private getAllSelectableSequenceIds(): number[] {
    return this.sequences.reduce((acc, sequence) => {
      if (!this.disabledSequenceIds.includes(sequence.sequenceInstance.id)) {
        return [...acc, sequence.sequenceInstance.id];
      }
      return acc;
    }, []);
  }

}
