import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { InputValidationConfiguration } from '@pixacare/pxc-ts-core';
import { AppConfigHttpService } from 'src/app/services/http/app-config.http.service';

@Component({
  selector: 'app-input-validation-error-display',
  templateUrl: './input-validation-error-display.component.html',
})
export class InputValidationErrorDisplayComponent {

  @Input() control: UntypedFormControl;
  @Input() customConfig: InputValidationConfiguration;

  constructor(private readonly configurationService: AppConfigHttpService) {}

  get configuration(): InputValidationConfiguration {
    return {
      ...this.configurationService.configuration.inputValidation,
      ...this.customConfig,
    };
  }

}
