import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { first, switchMap } from 'rxjs';
import { SaturatedStorageComponent } from '../saturated-storage/saturated-storage.component';
import { AppConfigHttpService } from 'src/app/services/http/app-config.http.service';
import { selectClientDataConsumptionInGb } from 'src/app/shared/store/clients/clients.selectors';
import { selectClientCode } from 'src/app/shared/store/router/router.selectors';

@Directive({
  selector: '[showIfStorageSpaceAvailable]',
})
export class ShowIfStorageSpaceAvailableDirective {

  constructor(
    private readonly store: Store,
    private readonly configurationService: AppConfigHttpService,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly container: ViewContainerRef,
  ) { }

  @Input()
  set showIfStorageSpaceAvailable(display: boolean) {
    this.store.select(selectClientCode).pipe(
      first(),
      switchMap((clientCode) => this.store.select(selectClientDataConsumptionInGb(clientCode))),
    ).subscribe((dataConsumption) => {
      const lockQuotient = this.configurationService.configuration.paywall.lockQuotient;
      this.createContent((dataConsumption.used * 100) / dataConsumption.total < lockQuotient);
    });
  }

  createContent(display: boolean): void {
    this.container.clear();
    if (display) {
      this.container.createEmbeddedView(this.templateRef);
    } else {
      this.container.createComponent(SaturatedStorageComponent);
    }
  }

}
