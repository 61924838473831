<div class="flex flex-col gap-6 items-center justify-center h-screen bg-blue-50 px-4">
  <pxc-loader [status]="(isStatusChecking$ | async) ? Status.LOADING : Status.ERROR" [useLogo]="true"></pxc-loader>

  <h1 class="text-center">Service indisponible</h1>
  <p class="text-center text-balance">
    Veuillez nous excuser pour la gêne occasionnée. 
    <br> 
    Merci de réessayer dans quelques instants.
  </p>
  <button
    tuiButton
    appearance="primary"
    size="l"
    (click)="retryConnection()"
    [showLoader]="isStatusChecking$ | async"
  >
    Réessayer
  </button>
</div>