<ng-container *tuiLet="((displayChannelPane$ | async) === false) as displayChannelPane">
  <div 
    *ngIf="(isMobile$ | async) === false; else mobileTemplate" 
    class="flex items-stretch border-t border-slate-200 animate-in fade-in h-full"
  >
    <pxc-channel-list 
      class="h-full w-96 z-20"
      data-testid="channel-list"
    ></pxc-channel-list>
    
    <ng-container 
      [ngTemplateOutlet]="content"
    ></ng-container>

  </div>

  <ng-template #mobileTemplate>
    
    <pxc-channel-list 
      *ngIf="displayChannelPane; else content"
      class="w-full"
      data-testid="channel-list"
    ></pxc-channel-list>

  </ng-template>

  <ng-template #content>
    <div class="flex-1 h-full" data-testid="channel-pane">
      <router-outlet></router-outlet>
    </div>
  </ng-template>
</ng-container>