import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Patient, PaginationQuery, FilterOperator, PagedResponse } from '@pixacare/pxc-ts-core';
import { PatientHttpService } from '@services/http/patient.http.service';
import { Observable, mergeMap, switchMap, catchError, concatMap, concat, of, merge } from 'rxjs';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { notificationsActions } from '../notifications/notifications.actions';
import { telemonitoringsActions } from '../telemonitorings/telemonitorings.actions';
import { patientsActions } from './patients.actions';


@Injectable()
export class PatientsEffects {

  createPatient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(patientsActions.createPatient),
      mergeMap(({ clientCode, patient }) =>
        this.patientService.createPatient(clientCode, patient)
          .pipe(
            switchMap((newPatient: Patient) => [
              patientsActions.createPatientSuccess({ clientCode, patient: newPatient }),
              notificationsActions.success({
                message: 'Le patient a bien été créé.',
                title: 'Patient créé',
              }),
            ]),
            catchError(({ error, status }) => {
              // The patient already exists, so we redirect to the patient page
              if (status === HttpStatusCode.Conflict) {
                return [
                  lifeCycleActions.redirect({ url: `/dashboard/patients/${error.patientId}` }),
                ];
              }
              return [lifeCycleActions.loadError({ error })];
            }),
          ),
      ),
    ),
  );

  createPatientSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(patientsActions.createPatientSuccess),
      switchMap(({ patient }) => ([
        lifeCycleActions.redirect({ url: `/dashboard/patients/${patient.id}` }),
      ])),
    ),
  );

  getPatient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(patientsActions.getPatient),
      mergeMap(({ clientCode, patientId, departmentId }) =>
        this.patientService.getPatients(clientCode, {
          query: new PaginationQuery({
            filter: [
              {
                prop: 'id',
                op: FilterOperator.EQUAL,
                val: patientId.toString(),
              },
              ...(departmentId ? [{
                prop: 'department_id',
                op: FilterOperator.EQUAL,
                val: departmentId.toString(),
              }] : []),
            ],
          }),
        })
          .pipe(
            switchMap((collection: PagedResponse<Patient>) => [
              patientsActions.getPatientSuccess({ clientCode,  collection }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  getNextPatients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(patientsActions.getNextPatients),
      mergeMap(({ clientCode, options: { query, reset } }) =>
        this.patientService.getPatients(clientCode, { query })
          .pipe(
            switchMap((collection: PagedResponse<Patient>) => [
              patientsActions.getNextPatientsSuccess({ clientCode, reset, collection }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  updatePatient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(patientsActions.updatePatient),
      concatMap(({ clientCode, patientId, patient }) => concat(
        of(notificationsActions.info({ title: 'Le patient est en cours de mise à jour.' })),
        this.patientService.updatePatient(clientCode, patientId, patient)
          .pipe(
            switchMap((updatedPatient: Patient) => [
              patientsActions.updatePatientSuccess({ clientCode, patient: updatedPatient }),
              notificationsActions.success({
                title: 'Le patient a bien été modifié.',
                message: 'Patient modifié',
              }),
            ]),
            catchError((error: Error) => [ lifeCycleActions.loadError({ error }) ]),
          ),
      ),
      ),
    ),
  );

  mergePatients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(patientsActions.mergePatients),
      mergeMap(({ clientCode, patientId, targetPatientId }) =>
        this.patientService.mergePatients(clientCode, patientId, targetPatientId)
          .pipe(
            switchMap(() => [
              patientsActions.removePatient({ clientCode, patientId }),
              telemonitoringsActions.updateTelemonitoringPatient({ patientId, targetPatientId }),
              notificationsActions.success({
                title: 'La fusion des patients a été effectuée',
                message: 'Le patient a été supprimé',
              }),
            ]),
            catchError((error: Error) => [ lifeCycleActions.loadError({ error }) ]),
          ),
      ),
    ),
  );

  archivePatient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(patientsActions.archivePatient),
      mergeMap(({ clientCode, patientId }) => merge(
        of(notificationsActions.info({ title: 'Le patient est en cours d\'archivage.' })),
        this.patientService.archive(clientCode, patientId)
          .pipe(
            switchMap((archivedPatient: Patient) => [
              patientsActions.archivePatientSuccess({
                clientCode,
                patient: archivedPatient,
              }),
              notificationsActions.success({
                title: 'Le patient a bien été archivé.',
                message: 'Patient archivé',
              }),
            ]),
            catchError((error: Error) => [ lifeCycleActions.loadError({ error }) ]),
          ),
      ),
      ),
    ),
  );

  unarchivePatient$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(patientsActions.unarchivePatient),
      mergeMap(({ clientCode, patientId }) => merge(
        of(notificationsActions.info({ title: 'Le patient est en cours de désarchivage.' })),
        this.patientService.unarchive(clientCode, patientId)
          .pipe(
            switchMap((unarchivedPatient: Patient) => [
              patientsActions.unarchivePatientSuccess({
                clientCode,
                patient: unarchivedPatient,
              }),
              notificationsActions.success({
                title: 'Le patient a bien été désarchivé.',
                message: 'Patient désarchivé',
              }),
            ]),
            catchError((error: Error) => [ lifeCycleActions.loadError({ error }) ]),
          ),
      ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly patientService: PatientHttpService,
  ) {}

}
