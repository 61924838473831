import { Component, ChangeDetectionStrategy, OnChanges, SimpleChanges, Input } from '@angular/core';
import { FlattenedTelemonitoring, Telemonitoring } from '@pixacare/pxc-ts-core';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { sequencesCountPluralMapping } from '@shared/utils/plural-mappings';
import { TelemonitoringService } from '../telemonitoring.service';

@Component({
  selector: 'pxc-telemonitoring-preview',
  templateUrl: './telemonitoring-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringPreviewComponent implements OnChanges {

  @Input() telemonitoring: FlattenedTelemonitoring;
  @Input() actions: MenuAction<Telemonitoring>[];
  @Input() displayPatient = true;

  name: string;

  sequencesCountPluralMapping = sequencesCountPluralMapping;

  constructor(
    private readonly telemonitoringService: TelemonitoringService,
  ) {  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.telemonitoring) {
      this.name = this.telemonitoringService.getTelemonitoringName(this.telemonitoring);
    }
  }

  openQrCodeInGallery(): void {
    this.telemonitoringService.openQrCodeInGallery(this.telemonitoring.telemonitoring.id);
  }

}
