import { createReducer, on } from '@ngrx/store';
import { licensesActions }  from './licenses.actions';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { initialLicensesState, LicensesState } from './licenses.state';
import { License } from '@pixacare/pxc-ts-core';

export const licensesReducer = createReducer(
  initialLicensesState,

  on(lifeCycleActions.resetState, () => initialLicensesState),

  on(licensesActions.loadLicensesSuccess, (state: LicensesState, { flattenedLicenses }) => ({
    ...state,
    licenses: flattenedLicenses.reduce((acc, flattenedLicense) => ({
      ...acc,
      [flattenedLicense.license.id]: flattenedLicense.license,
    }), {} as { [licenseId: number]: License }),
  })),

  on(licensesActions.answerInvitationSuccess,
    (state: LicensesState, { license }) => ({
      ...state,
      licenses: {
        ...state.licenses,
        [license.id]: license,
      },
    })),
);
