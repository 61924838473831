<main *ngIf="isAppInitialized$ | async; else loading" class="overflow-x-hidden grid min-h-screen transition-all animate-in fade-in duration-300" [ngClass]="{
  'md:grid-cols-sidebar-collapsed': sidebarService.collapsed,
  'md:grid-cols-sidebar': !sidebarService.collapsed
}">

  <pxc-sidebar
    class="fixed max-md:w-3/4 md:static z-40 transition-all border-r border-slate-200"
    [ngClass]="{
      'max-md:-translate-x-full': !sidebarService.shown,
      'max-md:translate-x-0': sidebarService.shown
    }"
    [collapsed]="sidebarService.collapsed"
    data-testid="sidebar"
  ></pxc-sidebar>

  <div class="relative h-screen overflow-y-auto overflow-x-hidden">

    <pxc-header 
      class="sticky top-0 z-30 pointer-events-none"
      data-testid="navbar"
    ></pxc-header>

    <pxc-paywall-alert></pxc-paywall-alert>

    <router-outlet data-testid="content"></router-outlet>

  </div>

  <!-- This is the background overlay when sidebar is open on mobile -->
  <button 
    type="button"
    class="md:hidden fixed top-0 left-0 h-screen w-screen z-30 bg-slate-900/20 transition-all"
    (click)="sidebarService.shown = false"
    [ngClass]="{
      'hidden opacity-0': !sidebarService.shown,
      'opacity-100': sidebarService.shown
    }"
  ></button>

</main>

<ng-template #loading>
  <pxc-loader [fullscreen]="true" [useLogo]="true" data-testid="loading" class="animate-in fade-in zoom-in-90 duration-500"></pxc-loader>
</ng-template>
