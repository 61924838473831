<div class="h-24 p-3
  bg-action-dark
  text-white
  flex justify-center items-center"
>
  <div class="flex justify-center items-center h-full px-4 gap-4 divide-x divide-slate-600/40">
    <pxc-date-preview
      [date]="date"
      [displayTime]="false"
    ></pxc-date-preview>
    <pxc-patient-data 
      *ngIf="isOpen"
      customClass="text-white"
      [patient]="patient" 
      data-testid="patient-data"
      class="px-3"
    ></pxc-patient-data>
  </div>
  <pxc-handle 
    class="absolute right-0 translate-x-1/2"
    [handleState]="false"
    [direction]="HandleDirection.HORIZONTAL"
    (handleClicked)="toggle()"
    data-testid="card-handle"
  ></pxc-handle>
</div>