import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, concatMap, map, merge, mergeMap, Observable, of  } from 'rxjs';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { sadmActions } from './sadm.actions';
import { notificationsActions } from '../notifications/notifications.actions';
import { sequencesActions } from '../sequences/sequences.actions';
import { SadmHttpService } from 'src/app/services/http/sadm.http.service';
import { TuiAlertService } from '@taiga-ui/core';
import { SadmClient, SadmEntity } from '@pixacare/pxc-ts-core';

@Injectable()
export class SadmEffects {

  getSadmClients$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.getSadmClients),
      mergeMap(() => this.sadmService.getSadmClients({ forms: true }).pipe(
        map((sadmClients: SadmClient[]) => (
          sadmActions.getSadmClientsSuccess({ sadmClients, override: true })
        )),
        catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
      )),
    ),
  );

  getSadmEntities$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.getSadmEntities),
      mergeMap(({ clientCode, patientId, isArchived }) =>
        this.sadmService.getSadmEntities({ clientCode, patientId, isArchived }).pipe(
          map((sadmEntities) => sadmActions.getSadmEntitiesSuccess({ clientCode, patientId, sadmEntities })),
          catchError((error: Error) => [lifeCycleActions.loadError({ error })])),
      ),
    ),
  );

  getSadmEntitiesSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.getSadmEntitiesSuccess),
      mergeMap(({ sadmEntities }) => sadmEntities
        .filter((sadmEntity) => !!sadmEntity.sequences?.length)
        .map((sadmEntity) => sequencesActions.loadSequences({
          clientCode: sadmEntity.clientCode,
          data: sadmEntity.sequences,
        })),
      ),
    ),
  );

  getSadmEntity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.getSadmEntity),
      mergeMap(({ sadmEntityId }) => this.sadmService.getSadmEntity(sadmEntityId, {
        includeSadmClient: true,
      })
        .pipe(
          concatMap((sadmEntity) => [
            sadmActions.getSadmClientsSuccess({
              sadmClients: sadmEntity.sadmClient ? [sadmEntity.sadmClient] : [],
              override: false,
            }),
            sadmActions.getSadmEntitySuccess({ sadmEntity }),
          ]),
          catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
        )),
    ),
  );

  getSadmDashboard$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.getSadmDashboard),
      mergeMap(({ sadmEntityId }) => this.sadmService.getSadmDashboard(sadmEntityId)
        .pipe(
          mergeMap((sadmDashboard) => [
            sadmActions.getSadmClientsSuccess({ sadmClients: [sadmDashboard.sadmEntity.sadmClient], override: false }),
            sadmActions.getSadmEntitySuccess({ sadmEntity: sadmDashboard.sadmEntity }),
            sadmActions.getSadmDashboardSuccess({
              sadmEntityId,
              sadmDashboard,
            }),
          ]),
          catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
        )),
    ),
  );

  mergeSadmEntity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.mergeSadmEntity),
      mergeMap(({ fromSadmEntityId, toSadmEntityId }) =>
        this.sadmService.mergeSadmEntity(fromSadmEntityId, toSadmEntityId)
          .pipe(
            mergeMap(() => [
              sadmActions.unloadSadmEntity({ sadmEntityId: fromSadmEntityId }),
              notificationsActions.success({
                title: 'La fusion des lésions a été effectuée',
                message: 'La lésion a été supprimée',
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  deleteSadmEntity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.deleteSadmEntity),
      mergeMap(({ sadmEntityId }) => this.sadmService.deleteSadmEntity(sadmEntityId).pipe(
        mergeMap(() => [
          sadmActions.unloadSadmEntity({ sadmEntityId }),
          sadmActions.unloadSadmDashboard({ sadmEntityId }),
          sequencesActions.deleteSadmEntityFromSequences({
            sadmEntityId,
          }),
          notificationsActions.success({
            title: 'La lésion a été supprimée',
            message: 'La lésion a été supprimée',
          }),
        ]),
        catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
      )),
    ),
  );

  createSadmEntity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.createSadmEntity),
      mergeMap(({ sadmEntityPayload }) => {

        const loadingToastr = this.alertService.open('Veuillez patienter', {
          label: 'Création en cours',
          status: 'info',
          autoClose: 10000,
          hasCloseButton: false,
        }).subscribe();

        return this.sadmService.createSadmEntity(sadmEntityPayload)
          .pipe(
            mergeMap((sadmEntity) => {
              loadingToastr.unsubscribe();
              return [
                sadmActions.getSadmEntitySuccess({ sadmEntity }),
                lifeCycleActions.redirect({ url: `dashboard/sadms/${sadmEntity.id}` }),
              ];
            }),
            catchError((error) => {
              this.alertService.open('Une erreur est survenue lors de la création', {
                status: 'error',
              }).subscribe();
              console.error('Error while creating sadmEntity', error);
              loadingToastr.unsubscribe();
              return [lifeCycleActions.loadError({ error })];
            }),
          );
      },
      ),
    ),
  );

  updateSadmEntity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.updateSadmEntity),
      mergeMap(({ sadmEntity }) => merge(
        of(notificationsActions.info({ title: 'La plaie est en cours de mise à jour.' })),
        this.sadmService.udpateSadmEntity(sadmEntity)
          .pipe(
            mergeMap((updatedSadmEntity: SadmEntity) => [
              sadmActions.updateSadmEntitySuccess( { sadmEntity: updatedSadmEntity } ),
              notificationsActions.success({
                title: 'La plaie a bien été modifiée.',
                message: 'Plaie modifiée',
              }),
            ]),
            catchError((error: Error) => [ lifeCycleActions.loadError({ error }) ]),
          ),
      )),
    ),
  );

  archiveSadmEntity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.archiveSadmEntity),
      mergeMap(({ sadmEntityId }) => merge(
        of(notificationsActions.info({ title: 'La plaie est en cours d\'archivage.' })),
        this.sadmService.archive(sadmEntityId)
          .pipe(
            mergeMap((archivedSadmEntity: SadmEntity) => [
              sadmActions.archiveSadmEntitySuccess( { sadmEntity: archivedSadmEntity }),
              notificationsActions.success({
                title: 'La plaie a bien été archivée.',
                message: 'Plaie archivée',
              }),
            ]),
            catchError((error: Error) => [ lifeCycleActions.loadError({ error }) ]),
          ),
      ),
      ),
    ),
  );

  unarchiveSadmEntity$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(sadmActions.unarchiveSadmEntity),
      mergeMap(({ sadmEntityId }) => merge(
        of(notificationsActions.info({ title: 'La plaie est en cours de désarchivage.' })),
        this.sadmService.unarchive(sadmEntityId)
          .pipe(
            mergeMap((unarchivedSadmEntity: SadmEntity) => [
              sadmActions.unarchiveSadmEntitySuccess( { sadmEntity: unarchivedSadmEntity }),
              notificationsActions.success({
                title: 'La plaie a bien été désarchivée.',
                message: 'Plaie désarchivée',
              }),
            ]),
            catchError((error: Error) => [ lifeCycleActions.loadError({ error }) ]),
          ),
      ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly sadmService: SadmHttpService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
  ) {}

}
