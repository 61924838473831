import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectIsStoreInitialized } from 'src/app/shared/store/life-cycle/life-cycle.selectors';
import { SidebarService } from '../sidebar/sidebar.service';
import { lifeCycleActions } from 'src/app/shared/store/life-cycle/life-cycle.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientCodeService } from '../client-code.service';

@UntilDestroy()
@Component({
  templateUrl: './main-layout.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SidebarService],
})
export class MainLayoutComponent implements OnInit {

  isAppInitialized$: Observable<boolean> = this.store.select(selectIsStoreInitialized);

  readonly sidebarService = inject(SidebarService);

  constructor(
    private readonly store: Store,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly clientCodeService: ClientCodeService,
  ) {}

  ngOnInit(): void {

    this.store.dispatch(lifeCycleActions.initialize());

    this.clientCodeService.ensureClientCode$.pipe(
      untilDestroyed(this),
    ).subscribe();

    this.breakpointObserver.observe('(min-width: 768px) and (max-width: 1023px)')
      .subscribe((result) => {
        this.sidebarService.collapsed = result.matches;
        this.changeDetectorRef.detectChanges();
      });
  }

}
