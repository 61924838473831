<div *ngIf="collection$ | async as collection; else telemonitoringLoadingBlock" class="flex flex-col gap-3">
  <pxc-title title="Mes télésuivis" class="animate-in fade-in"></pxc-title>
  <pxc-filter-tabs class="animate-in fade-in"></pxc-filter-tabs>
  <pxc-filter-bar 
    searchPlaceholder="Rechercher un télésuivi par nom, date ou patient ..."
    data-testid="filter-bar"
     class="animate-in fade-in"
  ></pxc-filter-bar>
  
  <ng-container *ngIf="(isDataLoaded$ | async) === true; then telemonitoringListBlock else telemonitoringLoadingBlock"></ng-container>
    
  <ng-template #telemonitoringListBlock>
    <pxc-telemonitoring-list
      [telemonitorings]="collection.data"
      [actions]="actions"
      [isPreviousPageLoading]="collection.context.isPreviousPageLoading"
      [isNextPageLoading]="collection.context?.isNextPageLoading"
      (loadPrevious)="loadPreviousPage()"
      (loadNext)="loadNextPage()"
      data-testid="tlm-list"
    ></pxc-telemonitoring-list>
  </ng-template>
</div>

<ng-template #telemonitoringLoadingBlock>
  <pxc-loader data-testid="loading" class="my-5">
    Chargement des télésuivis ...
  </pxc-loader>
</ng-template>