import { Injectable } from '@angular/core';
import { MenuAction } from '../shared/models/menu-actions/menu-action';
import { ActionButton } from '../shared/models/menu-actions/action-buttons';
import { Status } from '../shared/models/enums/status.enum';

@Injectable()
export class MenuActionAdapterService {

  menuActionsToActionsButtons(actions: MenuAction[]): ActionButton[] {

    if (!actions) {
      return [];
    }

    return actions.map((action: MenuAction): ActionButton => ({
      appearance: action.status === Status.ERROR ? 'flat-destructive' : 'flat',
      hint: action.hint,
      actionIds: [action.id],
      icon: action.icon,
      title: action.label,
    }));
  }

}
