import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Output } from '@angular/core';
import { TuiElasticContainerModule, TuiStepperModule, TuiTextareaModule } from '@taiga-ui/kit';
import { ReceiversPickerComponent } from '../receivers-picker/receivers-picker.component';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiButtonModule, TuiDialogContext, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { SharedModule } from '../../shared/shared.module';
import { Size } from 'src/app/shared/models/enums/size.enum';
import { ReceiverListComponent } from '../receiver-list/receiver-list.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StatusComponent } from '../../shared/status/status.component';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { Message } from 'src/app/shared/models/chat/message';
import { MessageWithReceivers } from 'src/app/shared/models/chat/message-with-receivers';
import { Channel, SearchedUser } from '@pixacare/pxc-ts-core';
import { TuiLetModule } from '@taiga-ui/cdk';

enum SharePickerPage {
  SELECT_RECEIVERS,
  MESSAGE,
}

@Component({
  selector: 'pxc-share',
  standalone: true,
  imports: [
    CommonModule,
    ReceiversPickerComponent,
    TuiStepperModule,
    TuiButtonModule,
    SharedModule, // title component
    ReceiverListComponent,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    FormsModule,
    ReactiveFormsModule,
    StatusComponent,
    TuiLetModule,
    TuiElasticContainerModule,
  ],
  templateUrl: './share.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareComponent {

  @Output() cancel = new EventEmitter<void>();
  @Output() confirm = new EventEmitter<MessageWithReceivers>();

  channels: Channel[] = [];
  users: SearchedUser[] = [];
  mailAddresses: string[] = [];

  shareText =  new FormControl('');

  currentPage = SharePickerPage.SELECT_RECEIVERS;
  SharePickerPage = SharePickerPage;
  Size = Size;
  Status = Status;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<Message, void>,
  ) {}

  close(): void {
    this.context.completeWith(null);
    this.cancel.emit();
  }

  previous(): void {
    if (this.currentPage === SharePickerPage.MESSAGE) {
      this.currentPage = SharePickerPage.SELECT_RECEIVERS;
    }
  }

  next(): void {
    if (this.currentPage === SharePickerPage.SELECT_RECEIVERS) {
      this.currentPage = SharePickerPage.MESSAGE;
      return;
    }

    const newMessage: MessageWithReceivers = {
      text: this.shareText.value || '',
      receivers: {
        toChannelIds: this.channels.map((channel) => channel.id),
        toUserIds: this.users.map((user) => user.id),
        toMailAddresses: this.mailAddresses,
      },
    };

    this.confirm.emit(newMessage);
  }

}

