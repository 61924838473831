<div class="flex flex-wrap gap-3 min-h-20">
    <pxc-cross-badge 
        *ngFor="let channel of channels" 
        (click)="removeChannel.emit(channel)" 
        [isActive]="removable"
    >
        <pxc-channel-preview
            [channel]="channel"
            [showDetails]="false"
            [vertical]="true"
            size="small"
        ></pxc-channel-preview>
    </pxc-cross-badge>
    <pxc-cross-badge 
        *ngFor="let user of users" 
        (click)="removeUser.emit(user)" 
        [isActive]="removable"
    >
        <pxc-user-card
            [user]="user"
            [vertical]="true"
            [showSpecialty]="false"
            size="small"
        ></pxc-user-card>
    </pxc-cross-badge>
    <pxc-cross-badge 
        *ngFor="let mailAddress of mailAddresses" 
        (click)="removeMailAddress.emit(mailAddress)" 
        [isActive]="removable"
    >
        <pxc-user-card
            [mailAddress]="mailAddress"
            [vertical]="true"
            showSpecialty="false"
            size="small"
        ></pxc-user-card>
    </pxc-cross-badge>
</div>