<div
  *ngIf="
    signUpConfiguration.enabled || !!signUpInvitationToken;
    else signUpDisabledBlock
  "
>
  <ng-container *ngIf="(invitationTokenStatus | async) === InvitationTokenStatus.VALID; else invitationTokenError">
    <div class="flex flex-col gap-2 py-2">
      <h2>Inscription</h2>
      <p class="max-w-prose text-balance">Rejoignez l'environnement sécurisé de photographies médicales.</p>
    </div>

    <form [formGroup]="registerForm" (ngSubmit)="register()" data-testid="register-form" class="flex flex-col gap-6 mt-6">

      <div class="grid lg:grid-cols-2 gap-6">
        <div>
          <tui-input
            formControlName="firstName"
            [disabled]="(actionOnGoing$ | async) ? '' : null"
            tuiTextfieldIconLeft="tuiIconUserLarge"
            [required]="true"
            ngDefaultControl
          >
            Prénom
          </tui-input>
          <app-input-validation-error-display [control]="inputFirstName">
          </app-input-validation-error-display>
        </div>
        <div>
          <tui-input
            formControlName="lastName"
            [disabled]="(actionOnGoing$ | async) ? '' : null"
            tuiTextfieldIconLeft="tuiIconUserLarge"
            [required]="true"
            ngDefaultControl
          >
            Nom
          </tui-input>
          <app-input-validation-error-display [control]="inputLastName">
          </app-input-validation-error-display>
        </div>
      </div>

        <div>
          <tui-input
            formControlName="mailAddress"
            [disabled]="(actionOnGoing$ | async) ? '' : null"
            tuiTextfieldIconLeft="tuiIconMailLarge"
            [readOnly]="(emailFromInvitationToken | async)"
            ngDefaultControl
          >
            Adresse E-Mail
            <input tuiTextfield type="email" data-testid="email-input">
          </tui-input>
          <app-input-validation-error-display
            [control]="inputMailAddress"
          ></app-input-validation-error-display>
        </div>

        <div class="grid lg:grid-cols-2 gap-6">
          <div>
            <tui-input-password
              formControlName="password"
              tuiTextfieldIconLeft="tuiIconLockLarge"
              [disabled]="(actionOnGoing$ | async) ? '' : null"
              ngDefaultControl
            >
              Mot de passe
            </tui-input-password>
            <app-input-validation-error-display [control]="inputPassword">
            </app-input-validation-error-display>
          </div>
          <div>
            <tui-input-password
              formControlName="passwordConfirm"
              tuiTextfieldIconLeft="tuiIconLockLarge"
              [disabled]="(actionOnGoing$ | async) ? '' : null"
              ngDefaultControl
            >
              Confirmation du mot de passe
            </tui-input-password>
            <app-input-validation-error-display [control]="inputPasswordConfirm">
            </app-input-validation-error-display>
          </div>
        </div>

        <div class="grid lg:grid-cols-2 gap-6">
          <div>
            <tui-input
              formControlName="phoneNumber"
              [disabled]="(actionOnGoing$ | async) ? '' : null"
              tuiTextfieldIconLeft="tuiIconPhoneLarge"
              ngDefaultControl
            >
              Numéro de téléphone
              <input tuiTextfield type="tel" data-testid="phone-input">
            </tui-input>
            <app-input-validation-error-display [control]="inputPhoneNumber">
            </app-input-validation-error-display>
          </div>

          <div>
            <pxc-medical-specialty-input
              formControlName="medicalSpecialtyId"
              ngDefaultControl
            ></pxc-medical-specialty-input>
          </div>
        </div>

        <div class="my-2">
          <tui-checkbox-labeled
            formControlName="CGVU" 
            [disabled]="(actionOnGoing$ | async) ? '' : null"
            ngDefaultControl
          >
            J'accepte les
            <a 
              tuiLink 
              data-appearance="primary" 
              href="https://pixacare.com/cgu" 
              target="_blank" 
              rel="noopener noreferrer" 
              class="inline-block"
            >
              CGVU
              <tui-svg src="tuiIconExternalLink"></tui-svg>
            </a>
          </tui-checkbox-labeled>
          <app-input-validation-error-display [control]="inputCGVU">
          </app-input-validation-error-display>
        </div> 

      <div class="flex justify-between flex-wrap gap-3 mt-2">

        <button
          tuiButton
          appearance="outline"
          routerLink="../login"
          [disabled]="actionOnGoing$ | async"
          class="max-sm:w-full"
        >
          J'ai déjà un compte
        </button>

        <button
          tuiButton
          type="submit"
          appearance="primary"
          [showLoader]="actionOnGoing$ | async"
          class="max-sm:order-first max-sm:w-full"
        >
          Inscription
        </button>

      </div>

    </form>
    
  </ng-container>
</div>

<ng-template #invitationTokenError>
  <ng-container [ngSwitch]="(invitationTokenStatus | async)">
    <ng-container *ngSwitchCase="InvitationTokenStatus.INVALID"
      [ngTemplateOutlet]="signUpErrorBlock"
      [ngTemplateOutletContext]="{
        title: 'Invitation invalide',
        message: 'Ce lien d\'invitation est expiré ou erroné, merci de vous rapprocher de la '
        + 'personne qui vous a invitée.' 
      }"
    ></ng-container>

    <ng-container *ngSwitchCase="InvitationTokenStatus.CONSUMED"
      [ngTemplateOutlet]="signUpErrorBlock"
      [ngTemplateOutletContext]="{
        title: 'Vous avez déjà un compte',
        message: 'Vous pouvez vous connecter en cliquant sur le bouton ci-dessous. ' 
          + 'En cas d\'oubli de votre mot de passe, vous pourrez le régénérer.' 
      }"
    ></ng-container>
  </ng-container>
</ng-template>

<ng-template #signUpDisabledBlock>
  <ng-container
    [ngTemplateOutlet]="signUpErrorBlock"
    [ngTemplateOutletContext]="{
      title: 'Inscriptions désactivées',
      message: signUpConfiguration.disabledMessage
    }"
  ></ng-container>
</ng-template>

<ng-template #signUpErrorBlock let-title="title" let-message="message">
  <div class="flex flex-col items-center gap-2 text-center" data-testid="message">
    <h3>{{title}}</h3>
    <p class="max-w-prose text-center">{{message}}</p>
  </div>
  <button
    tuiButton
    appearance="primary"
    class="w-full"
    routerLink="../login"
  >
    Revenir à la connexion
  </button>
</ng-template>
