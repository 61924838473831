<div class="flex gap-2 items-center max-md:justify-between w-full">

  <ng-container *ngIf="parsedActionButtons$ | async as parsedActionButtons">
    <div *ngIf="parsedActionButtons?.length > 0" class="flex gap-2 max-sm:flex-1 items-center" data-testid="action-buttons">

      <ng-container *ngFor="let actionButton of parsedActionButtons">
  
        <tui-hosted-dropdown
          *ngIf="actionButton.actions.length > 1; else button"
          tuiDropdownAlign="right"
          [content]="content"
          (openChange)="actionButton.isOpen = $event"
          [open]="actionButton.isOpen"
        >
          <ng-container *ngTemplateOutlet="button"></ng-container>
        </tui-hosted-dropdown>
  
        <ng-template #button>
          <ng-container *tuiLet="actionButton.actions.length > 1 as hasDropdown">
            <ng-container *ngIf="actionButton.title ?? actionButton.actions[0].label as title; else iconButton">
              <button 
                type="button" 
                tuiButton
                [icon]="actionButton.icon" 
                [iconRight]="hasDropdown ? rightIcon : null"
                [appearance]="actionButton.appearance"
                [tuiHint]="actionButton.hint"
                [tuiHintAppearance]="actionButton.appearance === 'flat-destructive' ? 'error': ''"
                [pseudoActive]="hasDropdown && actionButton.isOpen"
                (click)="hasDropdown ? null : actionButton.actions[0].execute(entity)"
                [attr.data-testid]="'button-' + actionButton.title"
              >
                {{ title }}
              </button>
            </ng-container>
          
            <ng-template #iconButton>
              <button 
                type="button" 
                tuiIconButton
                [icon]="actionButton.icon" 
                [appearance]="actionButton.appearance"
                [tuiHint]="actionButton.hint"
                [tuiHintAppearance]="actionButton.appearance === 'flat-destructive' ? 'error': ''"
                [pseudoActive]="hasDropdown && actionButton.isOpen"
                (click)="hasDropdown ? null : actionButton.actions[0].execute(entity)"
                [attr.data-testid]="'button-' + actionButton.title"
              ></button>
            </ng-template>
          </ng-container>
        </ng-template>
        
        <ng-template #rightIcon>
          <tui-svg src="tuiIconChevronDown" class="transition" [ngClass]="actionButton.isOpen ? 'rotate-180' : ''"></tui-svg>
        </ng-template>

        <ng-template #content>
          <pxc-menu-actions [entity]="entity" [actions]="actionButton.actions"></pxc-menu-actions>
        </ng-template>
  
      </ng-container>
    
    </div>
  </ng-container>

  <ng-container *ngIf="moreActions$ | async as moreActions">
    <tui-hosted-dropdown 
      data-testid="more-button"
      *ngIf="moreActions?.length > 0" 
      tuiDropdownAlign="right" 
      tuiDropdownDirection="bottom"
      [content]="moreActionsMenu" 
      [(open)]="isActionsDropdownOpen"
    >

      <button
        *ngIf="!optionButtonLabel; else moreActionsButtonText"
        type="button"
        tuiIconButton
        [appearance]="optionButtonAppearance"
        [pseudoActive]="isActionsDropdownOpen || null"
        icon="tuiIconMoreVertical"
      ></button>

      <ng-template #moreActionsButtonText>
        <a tuiLink data-appearance="muted">
          {{ optionButtonLabel }}
          <tui-svg src="tuiIconMoreVertical"></tui-svg>
        </a>
      </ng-template>

      <ng-template #moreActionsMenu>
        <pxc-menu-actions [entity]="entity" [actions]="moreActions"></pxc-menu-actions>
      </ng-template>

    </tui-hosted-dropdown>
  </ng-container>

</div>