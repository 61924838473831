<div class="flex flex-col gap-3 h-full w-full" *tuiLet="formStyle === FormDisplayStyle.FullscreenRows as isFullScreenMode">
  <ng-container *ngIf="reports?.length ; then summaryBlock else noSummaryBlock"></ng-container>
  <ng-template #summaryBlock>

    <pxc-sadm-entity-thumbnails-carousel
      [reports]="reports"
      [sadmEntityId]="sadmEntityId"
      [clientCode]="clientCode"
      [selectedIndex]="currentIndex"
      (selectedIndexChange)="setCurrentIndex($event)"
      data-testid="thumbnails-preview"
      [itemsCount]="mathMin(isFullScreenMode ? 4 : 3, reports.length)"
      class="mx-auto max-w-full"
    ></pxc-sadm-entity-thumbnails-carousel>

    <div class="flex-1 overflow-y-auto" [ngClass]="{'2xl:max-h-[37vh]': !isFullScreenMode}">
      <pxc-form-display
        [sections]="reports[currentIndex].sections"
        [formDisplayStyle]="formStyle"
        data-testid="form-answers"
      ></pxc-form-display>
    </div>
        
    <div
      class="flex gap-3 justify-end"
    >
      <ng-content></ng-content>
      <button
        *ngIf="!isFullScreenMode"
        tuiButton
        appearance="secondary"
        iconRight="tuiIconExternalLink"
        (click)="openFullscreen()"
        size="s"
        data-testid="fullscreen-form"
      >
        Ouvrir
      </button>
    </div>

  </ng-template>
  <ng-template #noSummaryBlock>
    <div data-testid="no-summary">
      <h6 class="max-w-prose text-center">Il n'y a aucun rapport d'analyse disponible.</h6>
    </div>
  </ng-template>
</div>
