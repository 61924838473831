<button class="flex gap-6 p-6 ring-1 ring-slate-200 transition bg-white hover:shadow-slate-600/10 focus:shadow-slate-600/10 hover:shadow-xl hover:ring-0 focus:shadow-xl focus:ring-0 rounded-3xl text-left w-full h-full">
  <tui-svg
    [src]="icon"
    class="flex-shrink-0"
    data-testid="card-button-icon"
  ></tui-svg>
  <div>
    <h5>{{title}}</h5>
    <p class="text-slate-600">{{description}}</p>
  </div>
  <tui-svg
    src="tuiIconChevronRightLarge"
    class="flex-shrink-0 text-slate-300 ml-auto self-center"
  ></tui-svg>
</button>
