<pxc-tile>
    <ng-container title>
        {{ participant.firstName }} {{ participant.lastName }} 
    </ng-container>
    <ng-container subtitle>
        {{ participant.medicalSpecialtyLabel }} 
    </ng-container>
    <pxc-avatar 
        leading
        [name]="participant.firstName + ' ' + participant.lastName"
        [attr.data-testid]="'avatar-' + participant.id"
    ></pxc-avatar>
</pxc-tile>