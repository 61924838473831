import {
  ChartType,
  LegendOptions,
  PluginOptionsByType,
  TooltipOptions,
} from 'chart.js';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { Chart } from '../models/charts/chart';
import { ChartLabel } from '../models/charts/chart-label.enum';
import { ChartConfiguration } from './config/chart.config';
import { eventLinePlugin } from './plugins/event-line.plugin';
import { getISODate } from '../utils/utils';
import { ChartDataset } from '@pixacare/pxc-ts-core';

interface Builder<T> {
  reset(charts?: ChartConfiguration[]): void;
  build(): T;
}

export class ChartBuilder implements Builder<Chart> {

  chart: Chart;
  private initialChartConfig: ChartConfiguration;

  constructor(chartConfig: ChartConfiguration) {
    this.initialChartConfig = chartConfig;
    this.reset();
  }

  reset(): void {
    this.chart = new Chart(JSON.parse(JSON.stringify((this.initialChartConfig))));
  }

  setTimeAxis(axis: string): ChartBuilder {
    this.chart.config.options.scales[axis] = {
      ...this.chart.config.options.scales[axis],
      type: 'time',
      time: {
        unit: 'day',
        displayFormats: {
          day: 'dd/MM',
        },
      },
      adapters: { date: { locale: fr } },
    };
    return this;
  }

  setGradientColors(): ChartBuilder {
    this.chart.config.data.datasets.forEach((dataset: ChartDataset) => {
      dataset.backgroundColor = (context) => {
        const ctxChart = context.chart;
        const { ctx, chartArea } = ctxChart;

        if (!chartArea) {
          return;
        }

        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (!dataset.gradient || dataset.width !== chartWidth || dataset.height !== chartHeight) {
          dataset.width = chartWidth;
          dataset.height = chartHeight;
          if (dataset.startGradientColor && dataset.endGradientColor) {
            dataset.gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
            dataset.gradient.addColorStop(0, dataset.startGradientColor);
            dataset.gradient.addColorStop(1, dataset.endGradientColor);
          }
        }
        return dataset.gradient;
      };
    });
    return this;
  }

  formatAxisTicks(axis: string, {
    callback = (value) => format(new Date(value), 'dd/LL', { locale: fr }),
  }: { callback?: (value: any, index: any, ticks: any) => string } = {
    callback: (value) => format(new Date(value), 'dd/LL', { locale: fr }),
  }): ChartBuilder {
    if (!Array.isArray(this.chart.config.data.labels)) {
      this.chart.config.data.labels = [this.chart.config.data.labels];
    }

    const targetAxis = this.chart.config.options.scales[axis];
    targetAxis.ticks = {
      ...targetAxis.ticks,
      callback,
    };

    return this;
  }

  formatDatesWithoutTime(): ChartBuilder {
    this.chart.config.data.datasets.forEach((dataset) => {
      dataset.data.forEach((data: any) => {
        const date = new Date(data.x);
        data.x = getISODate(date);
      });
    });
    return this;
  }

  setPluginsOptions(plugins: PluginOptionsByType<ChartType>): ChartBuilder {
    this.chart.config.options.plugins = plugins;
    return this;
  }

  setLegendPluginOptions(legend: Partial<LegendOptions<ChartType>> = {
    align: 'start' }, labels: unknown = { boxHeight: 0, boxWidth: 30, filter: (legendItem, data) => {

    if (data.datasets[legendItem.datasetIndex].label === ChartLabel.EVENTS) {
      return false;
    }

    return true;
  } }): ChartBuilder {
    this.chart.config.options.plugins.legend = legend;
    this.chart.config.options.plugins.legend.labels = labels;

    return this;
  }

  setTooltipPluginOptions(tooltip: Partial<TooltipOptions<ChartType>> = {
    callbacks: {
      title: (item): string => format(new Date(item[0].raw.x), 'dd LLL y', { locale: fr }),
      label: (item): string[] => {
        if (item.dataset.label === ChartLabel.EVENTS) {
          return ['Ajustement du protocole de soin'];
        } else {
          return [`${item.dataset.label} : ${item.parsed.y}`];
        }
      },
    } as any,
  }): ChartBuilder {
    this.chart.config.options.plugins.tooltip = tooltip;
    return this;
  }

  setBasePlugins(): ChartBuilder {
    this.chart.config.plugins = [
      ...this.chart.config.plugins,
      {
        id: 'multilineDatasetLabelHeightAdapter',
        beforeInit(chart: any) {
          chart.config.data.datasets.forEach((dataset) => {
            if (Array.isArray(dataset.label)) {
              const fitValue = chart.legend.fit;
              chart.legend.fit = function fit() {
                fitValue.bind(chart.legend)();
                this.height += 10;
              };
            }
          });
        },
      },
    ];
    return this;
  }

  registerEventLinePlugin(): ChartBuilder {
    this.chart.config.options.plugins[eventLinePlugin.id] = true;
    this.chart.config.plugins.push(eventLinePlugin);
    return this;
  }

  build(): Chart {
    const result = this.chart;
    this.reset();
    return result;
  }

}
