import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ResetPasswordHttpService } from '../../services/reset-password.http.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
  AbstractControl,
} from '@angular/forms';
import { passwordValidator } from 'src/app/shared/validators/validators';
import { AppConfigHttpService } from 'src/app/services/http/app-config.http.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TuiAlertService } from '@taiga-ui/core';
import { User } from '@pixacare/pxc-ts-core';

@Component({
  templateUrl: './reset-password.component.html',
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;

  tokenCheck = true;
  invalidToken: boolean;
  tokenUser: User;
  actionOnGoing$: BehaviorSubject<boolean>;
  resetPasswordToken: string;

  constructor(
    private readonly formBuilder: UntypedFormBuilder,
    private readonly route: ActivatedRoute,
    private readonly resetPasswordService: ResetPasswordHttpService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    private readonly router: Router,
    private readonly configurationService: AppConfigHttpService,
    private readonly authenticationService: AuthenticationService,
  ) {
    this.actionOnGoing$ = new BehaviorSubject(false);
  }

  get inputPassword(): AbstractControl {
    return this.resetPasswordForm.get('inputPassword');
  }

  get inputPasswordConfirm(): AbstractControl {
    return this.resetPasswordForm.get('inputPasswordConfirm');
  }

  ngOnInit(): void {

    this.resetPasswordForm = this.formBuilder.group({
      inputPassword: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
        passwordValidator(
          this.configurationService.configuration.inputValidation.password.regex,
        ),
      ]),
      inputPasswordConfirm: new UntypedFormControl(null, [
        Validators.required,
        Validators.maxLength(256),
        passwordValidator(
          this.configurationService.configuration.inputValidation.password.regex,
        ),
      ]),
    });
    this.route.queryParams.subscribe((params) => {
      this.resetPasswordToken = params.token;
      if (this.resetPasswordToken) {
        this.actionOnGoing$.next(true);
        this.resetPasswordService.checkToken(this.resetPasswordToken).subscribe({
          next: (user) => {
            this.tokenUser = user;
            this.actionOnGoing$.next(false);
          },
          error: () => {
            this.actionOnGoing$.next(false);
          },
        });
      }
    });
  }

  resetPassword(): void {
    if (!this.resetPasswordForm.valid) {
      this.alertService.open('Merci de résoudre les erreurs de saisie.', {
        label: 'Erreur de validation',
        status: 'error',
      }).subscribe();

      return;
    }

    const password = this.inputPassword.value;
    const passwordConfirm = this.inputPasswordConfirm.value;
    if (password !== passwordConfirm) {
      this.alertService.open('Les mots de passe ne sont pas identiques.', {
        label: 'Erreur de validation',
        status: 'error',
      }).subscribe();
      return;
    }

    this.actionOnGoing$.next(true);
    this.authenticationService.resetPassword({
      token: this.resetPasswordToken,
      password,
    }).subscribe({
      next: () => {
        this.actionOnGoing$.next(false);
        this.router.navigate(['/']);
      },
      error: (err: HttpErrorResponse) => {
        this.actionOnGoing$.next(false);

        let title = '';
        let message = '';
        if (err.status === 400) {
          title = 'Lien expiré';
          message = 'Ce lien est expiré, merci de refaire la demande.';
        } else {
          console.error(err);
          title = 'Erreur Système';
          message = "Une erreur s'est produite, merci de réessayer plus tard.";
        }
        this.alertService.open(message, {
          label: title,
          status: 'error',
        }).subscribe();
      },
    });
  }

}
