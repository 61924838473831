import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'pxc-last-update-badge',
  templateUrl: './last-update-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LastUpdateBadgeComponent {

  @Input() lastUpdate: Date;

}
