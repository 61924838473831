<tui-combo-box
  *tuiLet="items$ | async as items"
  [stringify]="stringify"
  [ngModel]="getFromId(items, value)"
  (ngModelChange)="onValueChange($event)"
  [tuiTextfieldCleaner]="true"
  data-testid="input"
  [disabled]="disabled"
  ngDefaultControl
>
  Spécialité médicale
  <tui-data-list-wrapper
    *tuiDataList
    [items]="items | tuiFilterByInputWith : stringify"
    [itemContent]="stringify | tuiStringifyContent"
    emptyContent="Aucun résultat"
  ></tui-data-list-wrapper>
</tui-combo-box>