<div class="flex flex-col gap-4">

  <ng-container *ngIf="!isLoading; else loadingTemplate">
    <tui-stepper 
      [(activeItemIndex)]="activePageIndex" 
      class="justify-between mb-2 pointer-events-none"
    >
      <button tuiStep>Détails</button>
      <button tuiStep>Emplacement</button>
      <button tuiStep>Séquences</button>
      <button tuiStep>Mode</button>
    </tui-stepper>
  
    <tui-elastic-container>

      <div *ngIf="activePageIndex === TelemonitoringRequestStep.DETAILS" class="flex flex-col gap-4">
      
        <pxc-patient-card 
          [patient]="patient" 
          data-testid="patient">
        </pxc-patient-card>
        
        <label tuiLabel="Nom du télésuivi" data-testid="label-name">
          <tui-input [(ngModel)]="name" data-testid="input-name" [tuiTextfieldLabelOutside]="true">{{defaultName}}</tui-input>
        </label>

        <label tuiLabel="Message personnalisé" data-testid="label-message">
          <tui-textarea [(ngModel)]="message" data-testid="input-message" [tuiTextfieldLabelOutside]="true">Saisir un message personnalisé</tui-textarea>
        </label>
      
        <tui-checkbox-labeled [(ngModel)]="notifyOnNewData" data-testid="input-notify">Me notifier à chaque nouvelle séquence</tui-checkbox-labeled>

        <ng-template [ngTemplateOutlet]="navigationButtons"></ng-template>
      </div>

      <div *ngIf="activePageIndex === TelemonitoringRequestStep.LOCATION" class="flex flex-col gap-3">
        <pxc-department-picker
          [(clientCode)]="clientCode"
          [(departmentIds)]="departmentIds"
        ></pxc-department-picker>

        <ng-template [ngTemplateOutlet]="navigationButtons"></ng-template>

      </div>
    
      <div *ngIf="activePageIndex === TelemonitoringRequestStep.SEQUENCES" class="flex flex-col gap-4">
  
        <pxc-sequence-picker-preview
          title="Sélectionner les séquences à ajouter au télésuivi"
          emptyState="Cette étape est facultative, vous pourrez ajouter des séquences à ce télésuivi plus tard."
          [(sequences)]="sequences"
          (mediaClicked)="mediaClicked($event)"
          [patient]="patient"
          [clientCode]="clientCode"
          [departmentIds]="departmentIds"
        ></pxc-sequence-picker-preview>
        
        <ng-template [ngTemplateOutlet]="navigationButtons"></ng-template>
    
      </div>
    
      <div *ngIf="activePageIndex === TelemonitoringRequestStep.MODE" class="flex flex-col gap-3">
        <ng-container *ngIf="selectedMode === TelemonitoringMode.PROTECTED; then typePassword; else selectMode"></ng-container>
    
        <ng-template #selectMode>
          <pxc-choice-picker 
            title="Choisir le mode de télésuivi" 
            icon="tuiIconShieldLarge"
          >
            <pxc-card-button
              title="Ouvert"
              icon="tuiIconUnlockLarge"
              description="Le télésuivi est accessible sans mot de passe."
              (click)="pickMonitoringMode(TelemonitoringMode.OPEN)"
              data-testid="select-open"
            ></pxc-card-button>
            <pxc-card-button
              title="Protégé"
              icon="tuiIconLockLarge"
              description="L'accès au télésuivi est protégé par un mot de passe."
              (click)="pickMonitoringMode(TelemonitoringMode.PROTECTED)"
              data-testid="select-protected"
            ></pxc-card-button>
          </pxc-choice-picker>
          <div class="mt-4">
            <ng-template [ngTemplateOutlet]="navigationButtons"></ng-template>
          </div>
        </ng-template>
    
        <ng-template #typePassword>
  
          <h4 class="text-center my-2">Définir le mot de passe</h4>
  
          <code-input
            data-testid="password-section"
            [code]="password"
            [codeLength]="telemonitoringCodeLength"
            (codeChanged)="onPasswordOptChange($event)"
          ></code-input>
  
          <div class="flex gap-2 justify-between flex-wrap mt-4">
            <button tuiButton appearance="outline" (click)="resetPickModeStep()" class="max-md:w-full">
              Retour
            </button>
            <button tuiButton [disabled]="!isPasswordValid" (click)="createMonitoring()" class="max-md:w-full max-md:order-first">
              Confirmer
            </button>
          </div>
  
        </ng-template>
    
      </div>
    </tui-elastic-container>

  </ng-container>

  <ng-template #loadingTemplate>
    <ng-container [ngSwitch]="telemonitoringOperationState$ | async">

      <pxc-status 
        *ngSwitchCase="'ONGOING'"
        [state]="Status.LOADING"
        title="Création en cours"
        description="Le télésuivi est en cours de création."
        data-testid="status-loading">
        <ng-container>
          <div class="flex gap-2 justify-end mt-2 w-full">
            <button tuiButton appearance="outline" (click)="closeModal()">Fermer</button>
          </div>
        </ng-container>
      </pxc-status>

      <pxc-status
        *ngSwitchCase="'FINISHED'"
        [state]="Status.SUCCESS"
        title="Succès"
        description="Le télésuivi a été créé avec succès."
        data-testid="status-success">
        <ng-container>
         <div class="flex gap-2 justify-end mt-2 w-full">
          <button
            tuiButton
            appearance="secondary"
            (click)="sendTutorial()"
            data-testid="send-tutorial"
            icon="tuiIconMail"
          >
            Envoyer le tutoriel
          </button>
          <button tuiButton (click)="closeModal()" data-testid="close-modal">
            Fermer
          </button>
         </div>
        </ng-container>
      </pxc-status>

      <pxc-status
        *ngSwitchCase="'FAILED'"
        [state]="Status.ERROR"
        title="Échec"
        description="Un problème est survenu lors de la création du télésuivi."
        data-testid="status-fail">
        <ng-container>
          <div class="flex gap-2 justify-end mt-2 w-full">
            <button tuiButton (click)="closeModal()">Fermer</button>
          </div>
        </ng-container>
      </pxc-status>
    
    </ng-container>

  </ng-template>

  <ng-template #navigationButtons>
    <div class="flex items-center gap-2 md:gap-4 flex-wrap mt-2">
      <button 
        tuiButton 
        appearance="outline" 
        (click)="previousPage()"
        class="mr-auto max-md:order-last max-md:w-full"
      >
        {{ activePageIndex === TelemonitoringRequestStep.DETAILS ? 'Annuler' : 'Précédent' }}
      </button>

      <ng-container *ngIf="activePageIndex === TelemonitoringRequestStep.LOCATION">
        <p 
          *ngIf="
            departmentIds.length === 0 
            && clientCode !== 'common';
            else groupCount
          " 
          class="text-red-500 max-md:w-full text-center"
          data-testid="no-department-selected-message"
        >
          Veuillez sélectionner au moins un groupe
        </p>
        <ng-template #groupCount>
          <p data-testid="selected-departments-counter" class="max-md:w-full">
            {{ departmentIds.length | i18nPlural: departmentsPluralMapping }}
          </p>
        </ng-template>
      </ng-container>

      <button 
        tuiButton 
        data-testid="next-button"
        (click)="nextPage()" 
        *ngIf="activePageIndex < TelemonitoringRequestStep.MODE"
        [disabled]="activePageIndex === TelemonitoringRequestStep.LOCATION && departmentIds.length === 0 && clientCode !== 'common'"
        class="max-md:w-full"
      >
        Suivant
      </button>
    </div>
  </ng-template>
  
</div>