import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Size } from 'src/app/shared/models/enums/size.enum';
import { AvatarComponent } from '../avatar/avatar.component';
import { FilteredJoinPipe } from 'src/app/shared/pipes/filtered-join.pipe';
import { TileComponent } from '../tile/tile.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { TuiLineClampModule } from '@taiga-ui/kit';
import { SearchedUser } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-user-card',
  templateUrl: './user-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    FilteredJoinPipe,
    TileComponent,
    TuiLetModule,
    TuiLineClampModule,
  ],
})
export class UserCardComponent {

  @Input() user: SearchedUser;
  @Input() mailAddress: string;
  @Input() size = Size.MEDIUM;
  @Input() vertical = false;
  @Input() showSpecialty = true;

  Size = Size;

}
