import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ChannelPreviewComponent } from '../channel-preview/channel-preview.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { RouterModule } from '@angular/router';
import { TuiButtonModule, TuiScrollbarModule } from '@taiga-ui/core';
import { ChatService } from '../chat.service';
import { ChannelCreateService } from '../channel-create/channel-create.service';
import { FILTER_TEMPLATES, FilterBarService } from '../../filters/filter-bar.service';
import { map, Observable, of, switchMap } from 'rxjs';
import { Channel } from '@pixacare/pxc-ts-core';
import { StatusComponent } from '../../shared/status/status.component';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { FilterBarComponent } from '../../filters/filter-bar/filter-bar.component';

@Component({
  selector: 'pxc-channel-list',
  standalone: true,
  imports: [
    CommonModule,
    ChannelPreviewComponent,
    TuiLetModule,
    RouterModule,
    TuiScrollbarModule,
    TuiButtonModule,
    StatusComponent,
    FilterBarComponent,
  ],
  templateUrl: './channel-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ChannelCreateService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: of([]),
    },
  ],
})
export class ChannelListComponent implements OnInit {

  channels$: Observable<Channel[]>;
  search$: Observable<string>;

  Status = Status;

  readonly chatService = inject(ChatService);
  readonly channelCreateService = inject(ChannelCreateService);
  private readonly filterBarService = inject(FilterBarService);

  ngOnInit(): void {

    this.search$ = this.filterBarService.search$;

    this.channels$ = this.search$.pipe(
      switchMap((search) => this.chatService.searchChannels$(search)),
      map((channels: Channel[]) => channels.filter((channel) => channel?.lastMessage?.channelId)),
    );

  }

  openCreateChannelDialog(): void {
    this.channelCreateService.openCreateChannelDialog();
  }

}
