import { Inject, Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { FlattenedSequence, Media, Patient } from '@pixacare/pxc-ts-core';
import { downloadBase64AsImage } from 'src/app/shared/utils/utils';
import { GalleryService } from '../../gallery/gallery.service';
import { StudioComponent } from '../../studio/studio/studio.component';
import { SequencePreviewDrawerComponent } from '../sequence-preview-drawer/sequence-preview-drawer.component';
import { TuiDialogService } from '@taiga-ui/core';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { GalleryItem } from 'src/app/shared/models/gallery-item';

interface GalleryMenuConfig {
  disableEditing?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class SequenceGalleryService {

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    @Inject(TuiDialogService) private readonly dialogService: TuiDialogService,
    private readonly galleryService: GalleryService,
  ) {}

  open(
    sequence$: Observable<FlattenedSequence>,
    openIdx: number,
    updateSequenceMedia: (mediaIdx: number) => void,
    { disableEditing }: GalleryMenuConfig = { disableEditing: false },
  ): void {

    const items$: Observable<GalleryItem[]> = sequence$.pipe(
      map((seq): GalleryItem[] => (
        seq.medias.map((media): GalleryItem => ({
          pictureSrc: media.uri,
          thumbnailSrc: media.thumbnailUri,
        }))
      )),
    );

    sequence$.pipe(first()).subscribe((sequence) => {
      this.galleryService.open({
        items$,
        openIdx,
        onImageLoadingError: (mediaIdx: number) => {
          updateSequenceMedia(mediaIdx);
        },
        actions: [
          {
            icon: 'tuiIconDownloadLarge',
            label: 'Télécharger',
            execute: () => {
              sequence$.pipe(first()).subscribe((updatedSequence) => {
                this.download(
                  updatedSequence.medias[this.galleryService.getDisplayIdx],
                  this.galleryService.getDisplayIdx,
                  updatedSequence.patientInstance,
                  updatedSequence.sequenceInstance.createdOn,
                );
              });
            },
          },
          {
            icon: 'tuiIconEdit2Large',
            label: 'Modifier',
            condition: () =>
              sequence.patientInstance
              && !sequence.patientInstance.isArchived
              && !disableEditing,
            execute: () => {
              sequence$.pipe(first()).subscribe((updatedSequence) => {
                this.edit(
                  updatedSequence.medias[this.galleryService.getDisplayIdx],
                  updatedSequence.clientCode,
                );
              });
            },
          },
        ],
        fixedWidget: {
          component: SequencePreviewDrawerComponent,
          config: {
            context: {
              patient: sequence.patientInstance,
              date: sequence.sequenceInstance.createdOn,
            },
            dialogClass: 'dialog--gallery-info',
          },
        },
      }).subscribe();
    });
  }

  download(media: Media, mediaIdx: number, patient: Patient, createdOn: Date): void {
    const patientName = patient ? `${patient?.lastName.toUpperCase()}-${patient?.firstName}` : 'anonyme';
    const filename = `${patientName}_${createdOn.toLocaleDateString()}_${mediaIdx}`;

    downloadBase64AsImage(
      media.uri,
      filename,
      'jpg',
    );
  }

  edit(media: Media, clientCode: string): void {
    this.galleryService.setConfig({
      keyboardShortcuts: false,
    });

    this.dialogService.open<string>(
      new PolymorpheusComponent(StudioComponent, this.injector),
      {
        data: {
          ...media,
          clientCode,
        },
        size: 'page',
        closeable: false,
        dismissible: false,
      },
    ).subscribe((studioOutput: string) => {

      this.galleryService.setConfig({
        keyboardShortcuts: true,
      });

      if (studioOutput) {
        this.galleryService.navLastItem();
      }

    });

  }

}
