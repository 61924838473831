<img *ngIf="!!src && !mediaRetryStrategy.hasReachedMaxRetries; else errorBlock"
  class="w-[inherit] rounded aspect-square"
  [hidden]="isMediaLoading"
  (load)="mediaLoaded()"
  (error)="loadingError()"
  [src]="src"
  data-testid="image"
/>

<pxc-image-loading
  *ngIf="src && isMediaLoading && !mediaRetryStrategy.hasReachedMaxRetries"
  data-testid="spinner"
  class="w-[inherit] h-[inherit] rounded aspect-square"
></pxc-image-loading>

<ng-template #errorBlock>
  <pxc-image-broken
    class="w-[inherit] h-[inherit] rounded aspect-square"
  ></pxc-image-broken>
</ng-template>