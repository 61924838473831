<pxc-client-title-header
  *ngIf="(client$ | async) as client"
  class="mb-3"
  [title]="client.fullName"
  subtitle="Administration"
  [clientCdnImagePath]="client.cdnImagePath"
></pxc-client-title-header>

<nav tuiTabs class="my-2">
  <button 
    *ngFor="let tab of tabs"
    tuiTab
    [routerLink]="tab.link"
    [routerLinkActive]="['active']"
    queryParamsHandling="merge"
    [attr.data-testid]="tab.link"
  >
    <tui-svg [src]="tab.icon" class="mr-1"></tui-svg>
    {{tab.title}}
  </button>
</nav>

<router-outlet></router-outlet>  
