<tui-island>
  <div class="flex gap-4">
    <tui-svg src="tuiIconClipboardLarge"></tui-svg>
    <div class="flex-1">
      <p class="font-medium">Protocole de soin</p>
      <p class="text-slate-600 text-sm">{{ description }}</p>
    </div>
    <button 
      *ngIf="!!protocol; else createProtocolButton"
      tuiButton
      appearance="secondary"
      iconRight="tuiIconExternalLink"
      size="s"
      class="self-center"
      (click)="openClick.emit()"
      data-testid="open-protocol"
    >
      Ouvrir
    </button>
    <ng-template #createProtocolButton>
      <button 
        tuiButton
        appearance="secondary"
        iconRight="tuiIconPlus"
        size="s"
        class="self-center"
        (click)="create.emit()"
        data-testid="create-protocol"
        [disabled]="sadmEntity.isArchived || (sadmEntity.patient && sadmEntity.patient.isArchived)"
      >
        Ajouter
      </button>
    </ng-template>
  </div>
</tui-island>
