import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { first, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { selectUserStatistics } from 'src/app/shared/store/statistics/statistics.selectors';
import { statisticsActions } from 'src/app/shared/store/statistics/statistics.actions';
import { Router } from '@angular/router';
import { homeStatistics } from 'src/app/shared/models/home-statistics/home-statistics.config';
import { HelpActionsService } from 'src/app/services/help-actions.service';
import { selectClientCode } from 'src/app/shared/store/router/router.selectors';
import { User, UserStatistics } from '@pixacare/pxc-ts-core';

@Component({
  templateUrl: './home.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    HelpActionsService,
  ],
})
export class HomeComponent implements OnInit {

  userStatistics$: Observable<UserStatistics>;
  currentUser: User;
  clientCode$: Observable<string>;

  stats = homeStatistics;

  helpActions = this.helpActionsService.getActions();

  constructor(
    private readonly store: Store,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly helpActionsService: HelpActionsService,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(statisticsActions.load());
    this.userStatistics$ = this.store.select(selectUserStatistics);
    this.currentUser = this.authenticationService.currentUser;
    this.clientCode$ = this.store.select(selectClientCode);
  }

  createSequence(): void {
    this.clientCode$.pipe(first()).subscribe((clientCode) =>
      this.router.navigate(['/dashboard/sequences'], {
        queryParams: {
          cc: clientCode,
          create: true,
        },
        queryParamsHandling: 'merge',
      }),
    );
  }

}
