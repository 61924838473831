import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output,
} from '@angular/core';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';
import { SequenceContext } from 'src/app/shared/models/enums/sequence-context.enum';
import { NameBySequenceId } from 'src/app/shared/models/name-by-sequence-id';

@Component({
  selector: 'pxc-sequence-select-list',
  templateUrl: './sequence-select-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceSelectListComponent {

  @Input() sequences: FlattenedSequence[];
  @Input() telemonitoringNames: NameBySequenceId;
  @Input() sadmEntityNames: NameBySequenceId;
  @Input() selectedSequenceIds: number[] = [];
  @Input() disabledSequenceIds: number[] = [];
  @Input() singleSelectMode = false;
  @Input() context: SequenceContext = SequenceContext.SEQUENCE;
  @Input() compactMode = true;

  @Output() selectSequences: EventEmitter<number[]> = new EventEmitter();
  @Output() unselectSequences: EventEmitter<number[]> = new EventEmitter();
  @Output() loadNext: EventEmitter<void> = new EventEmitter();

  select(sequenceIds: number[]): void {
    this.selectSequences.emit(sequenceIds);
  }

  selectTelemonitoringSequences(telemonitoringId: number): void {
    const sequenceIds = this.sequences
      .filter((sequence) => sequence.sequenceInstance.telemonitoringId === telemonitoringId)
      .map((sequence) => sequence.sequenceInstance.id);
    this.selectSequences.emit(sequenceIds);
  }

  selectSadmSequences(sadmEntityId: number): void {
    const sequenceIds = this.sequences
      .filter((sequence) => sequence.sequenceInstance.sadmEntityId === sadmEntityId)
      .map((sequence) => sequence.sequenceInstance.id);
    this.selectSequences.emit(sequenceIds);
  }

}
