import { NgModule } from '@angular/core';
import { PatientsStoreModule } from './patients/patients.store.module';
import { ClientsStoreModule } from './clients/clients.store.module';
import { LifeCycleStoreModule } from './life-cycle/life-cycle.store.module';
import { LicensesStoreModule } from './licenses/licenses.store.module';
import { UsersStoreModule } from './users/users.store.module';
import { DepartmentStoreModule } from './departments/departments.store.module';
import { LabelStoreModule } from './labels/labels.store.module';
import { SadmStoreModule } from './sadm/sadm.store.module';
import { TelemonitoringsStoreModule } from './telemonitorings/telemonitorings.store.module';
import { NotificationsStoreModule } from './notifications/notifications.store.module';
import { SequencesStoreModule } from './sequences/sequences.store.module';
import { SharesStoreModule } from './shares/shares.store.module';
import { StatisticsStoreModule } from './statistics/statistics.store.module';
import { DrawerStoreModule } from './drawer/drawer-store.module';
import { ExportStoreModule } from './exports/exports.store.module';
import { RouterStoreModule } from './router/router.store.module';

@NgModule({
  imports: [
    LifeCycleStoreModule,
    ClientsStoreModule,
    DepartmentStoreModule,
    DrawerStoreModule,
    LabelStoreModule,
    LicensesStoreModule,
    NotificationsStoreModule,
    PatientsStoreModule,
    SadmStoreModule,
    SequencesStoreModule,
    SharesStoreModule,
    StatisticsStoreModule,
    TelemonitoringsStoreModule,
    UsersStoreModule,
    ExportStoreModule,
    RouterStoreModule,
  ],
  declarations: [],
})
export class FeaturesStoreModule { }
