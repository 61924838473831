<pxc-filter-dropdown-button
  [isActive]="template.filters[0]?.val.length"
  buttonText='{{template.name}} {{ template.filters[0]?.val.length > 0 ? "(" + template.filters[0]?.val.length + ")" : "" }}'
>

<div class="flex p-3 items-stretch flex-col gap-2 w-80 h-96 overflow-hidden" data-testid="dropdown-wrapper">

  <tui-input
    #input
    tuiTextfieldIconLeft="tuiIconSearch"
    tuiTextfieldLabelOutside="true" 
    tuiTextfieldCleaner="true" 
    [ngModel]="search$ | async"
    (ngModelChange)="search$.next($event)"
    data-testid="search"
    tuiTextfieldSize="s"
    (keydown.arrowDown.prevent)="onArrowDown($event)"
    ngDefaultControl
  >
    <input tuiTextfield />
    {{ template.placeholder || 'Rechercher ...' }}
  </tui-input>

    <tui-scrollbar 
      class="flex-1" 
      scroll-tracker 
      [threshold]="{top: 100, bottom: 100}"
      (bottomThreshold)="loadNextEntities()" 
      (topThreshold)="loadPreviousEntities()"
      data-testid="scroll-wrapper"
    >

      <tui-data-list *tuiLet="displayedItems$| async as filtered" emptyContent="Aucun résultat" size="s" (keydown)="onKeyDown($event.key, input.nativeFocusableElement)">

        <tui-opt-group tuiMultiSelectGroup [ngModel]="this.template.filters[0]?.val || []" (ngModelChange)="updateSelection($event)" data-testid="opt-group" ngDefaultControl>

          <tui-opt-group>

              <button 
                *ngFor="let item of filtered" 
                tuiOption 
                size="m" 
                [value]="item.value"
              >
                <div>
                  <p class="font-bold">{{item.display}}</p>
                  <p class="text-slate-600">{{item.subtitle}}</p>
                  <p class="text-slate-600 text-sm">{{item.description}}</p>
                </div>
              </button>
  
          </tui-opt-group>

        </tui-opt-group>

      </tui-data-list>
    </tui-scrollbar>

    <button [disabled]="!template.filters[0]?.val.length" tuiButton (click)="clear()" size="s" data-testid="reset-button" appearance="secondary-destructive">
      Réinitialiser
    </button>

  </div>
</pxc-filter-dropdown-button>