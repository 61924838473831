import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SadmFormService } from '../sadm-form.service';
import { Observable, first } from 'rxjs';
import { BaseSadmEntity, FormAnswers } from '@pixacare/pxc-ts-core';
import { SadmEntityTypePipe } from '@shared/pipes/sadm-entity-type.pipe';
import { SadmEntityNamePipe } from '@shared/pipes/sadm-entity-name.pipe';

@Component({
  selector: 'pxc-sadm-form-input',
  templateUrl: './sadm-form-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SadmFormService,
    SadmEntityNamePipe,
    SadmEntityTypePipe,
  ],
})
export class SadmFormInputComponent implements OnChanges {

  @Input() clientCode: string;
  @Input() patientId: number;

  @Input() sadmEntityId: number;
  @Output() sadmEntityIdChange = new EventEmitter<number>();

  @Input() analysisFormAnswers: FormAnswers;
  @Output() analysisFormAnswersChange = new EventEmitter<FormAnswers>();

  @Input() sadmEntity: BaseSadmEntity;
  @Output() sadmEntityChange = new EventEmitter<BaseSadmEntity>();

  @Input() protocolFormAnswers: FormAnswers;
  @Output() protocolFormAnswersChange = new EventEmitter<FormAnswers>();

  @Input() preventSadmEntityIdChange = false;

  sadmEntityName$: Observable<string>;

  constructor(
    private readonly sadmFormService: SadmFormService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sadmEntityId || changes.sadmEntity) {
      this.updateSadmEntityName();
    }
  }

  updateSadmEntityName(): void {
    this.sadmEntityName$ = this.sadmFormService.getSadmEntityName({
      sadmEntityId: this.sadmEntityId,
      sadmEntity: this.sadmEntity,
    });
  }

  addAnalysis(): void {
    this.sadmFormService.createAnalysis({
      clientCode: this.clientCode,
      patientId: this.patientId,
      selectedSadmEntityId: this.sadmEntityId,
    }).pipe(first())
      .subscribe(this.emit.bind(this));
  }

  editAnalysis(): void {
    this.sadmFormService.editAnalysis({
      sadmEntityId: this.sadmEntityId,
      sadmEntity: this.sadmEntity,
      analysisFormAnswers: this.analysisFormAnswers,
    }).pipe(first())
      .subscribe((v) => {
        if (v) {
          this.analysisFormAnswersChange.emit(v);
        }
      });
  }

  deleteAnalysis(): void {
    if (this.analysisFormAnswers) {
      this.analysisFormAnswersChange.emit(null);
    }
    if (this.sadmEntityId && !this.preventSadmEntityIdChange) {
      this.sadmEntityIdChange.emit(null);
    }
    if (this.sadmEntity) {
      this.sadmEntityChange.emit(null);
    }
  }

  changeSadmEntityId(): void {
    if (!this.preventSadmEntityIdChange) {
      this.sadmFormService.openEntityPicker({
        clientCode: this.clientCode,
        patientId: this.patientId,
      }).subscribe(this.emit.bind(this));
    }
  }

  emit(data: {
    sadmEntityId?: number;
    analysisFormAnswers?: FormAnswers;
    sadmEntity?: BaseSadmEntity;
    protocolFormAnswers?: FormAnswers;
  }): void {

    if (!data) {
      return;
    }

    if (data.sadmEntityId && !this.preventSadmEntityIdChange) {
      this.sadmEntityIdChange.emit(data.sadmEntityId);
    }

    if (data.analysisFormAnswers) {
      this.analysisFormAnswersChange.emit(data.analysisFormAnswers);
    }

    if (data.sadmEntity) {
      this.sadmEntityChange.emit(data.sadmEntity);
    }

    if (data.protocolFormAnswers) {
      this.protocolFormAnswersChange.emit(data.protocolFormAnswers);
    }

  }

}
