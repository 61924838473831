import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TuiBadgeModule } from '@taiga-ui/kit';

@Component({
  selector: 'pxc-archived-badge',
  standalone: true,
  imports: [
    CommonModule,
    TuiBadgeModule,
  ],
  templateUrl: './archived-badge.component.html',
})
export class ArchivedBadgeComponent {

  @Input() size = 'm';
  @Input() value = 'Archivé';

}
