import { Component, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { FilterBarService, FILTER_TEMPLATES } from '@modules/filters/filter-bar.service';
import {
  CloseConfirmableDialogComponent,
} from '@modules/shared/close-confirmable-dialog/close-confirmable-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FlattenedSequence, FilterOperator, PaginationQuery } from '@pixacare/pxc-ts-core';
import { SequenceContext } from '@shared/models/enums/sequence-context.enum';
import { sequenceFilterTemplates } from '@shared/models/filters/filter-template.config';
import { FilteredSequenceSearch } from '@shared/models/filters/filtered-sequence-search';
import { defaultPaginationContext } from '@shared/models/pagination/pagination-context.config';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Observable, Subject, combineLatest, first } from 'rxjs';
import { SequencePaginationService } from '../services/sequence-pagination.service';
import { PagedCollection } from '@shared/models/pagination/paged-collection';

@UntilDestroy()
@Component({
  templateUrl: './sequence-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: sequenceFilterTemplates,
    },
  ],
})
export class SequencePickerComponent extends CloseConfirmableDialogComponent<FilteredSequenceSearch> implements OnInit {

  patientId: number = this.context.data.patientId;
  clientCode: string = this.context.data.clientCode;
  alreadySelectedSequenceIds: number[] = this.context.data.alreadySelectedSequenceIds || [];
  disableSelectedSequences = this.context.data.disableSelectedSequences || false;
  departmentIds: number[] = this.context.data.departmentIds ?? [];
  sequenceContext: SequenceContext = this.context.data.sequenceContext || SequenceContext.SEQUENCE;
  singleSelectMode: boolean = this.context.data.singleSelectMode || false;

  selectedSequenceIds: number[];
  collection$: Observable<PagedCollection<FlattenedSequence[]>>;
  isCollectionLoaded$: Observable<boolean>;

  constructor(
    private readonly filterBarService: FilterBarService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<FilteredSequenceSearch, {
      patientId?: number;
      clientCode: string;
      alreadySelectedSequenceIds?: number[];
      disableSelectedSequences?: boolean;
      departmentIds?: number[];
      sequenceContext?: SequenceContext;
      shouldAskConfirmation$?: Subject<boolean>;
      singleSelectMode?: boolean;

    }>,
    private readonly sequencePaginationService: SequencePaginationService,
  ) {
    super(context);
  }

  ngOnInit() {
    this.collection$ = this.sequencePaginationService.select();
    this.isCollectionLoaded$ = this.sequencePaginationService.isCollectionLoaded$;

    this.selectedSequenceIds = [...this.alreadySelectedSequenceIds];

    if (this.patientId) {
      this.filterBarService.setPersistentFilters([{
        prop: 'patient_id',
        op: FilterOperator.EQUAL,
        val: this.patientId.toString(),
      }]);
    }

    this.filterBarService.filteredSearch$
      .pipe(untilDestroyed(this))
      .subscribe(({ search, filters }) => {
        this.sequencePaginationService.load(this.clientCode, {
          query: new PaginationQuery({
            orderBy: ['created_on|desc'],
            filter: [
              ...filters,
              ...(this.departmentIds?.length
                ? [{ prop: 'department_id', op: FilterOperator.IN, val: this.departmentIds.join(', ') }]
                : []
              ),
            ],
            search,
            size: defaultPaginationContext.countPerPage,
          }),
          reset: true,
        });
      });
  }

  loadNextPage(): void {
    this.sequencePaginationService.loadNextPage(this.clientCode);
  }

  selectSequences(sequenceIds: number[]): void {
    this.selectedSequenceIds = sequenceIds;
    this.shouldCloseConfirmChange(true);
  }

  confirmSelection(): void {
    combineLatest([
      this.filterBarService.filteredSearch$,
      this.collection$,
    ]).pipe(first()).subscribe(([{ search, filters }, sequences]) => {

      let filteredSequenceSearch: FilteredSequenceSearch = {
        search: null,
        filters: [{
          prop: 'id',
          op: FilterOperator.IN,
          val: this.selectedSequenceIds.join(','),
        }],
        sequenceIds: this.selectedSequenceIds,
        sequences: sequences.data?.filter(
          (sequence) => this.selectedSequenceIds.includes(sequence.sequenceInstance.id),
        ),
      };


      if (this.selectedSequenceIds.length === sequences?.data.length) {

        filteredSequenceSearch = {
          ...filteredSequenceSearch,
          search,
          filters: this.departmentIds?.length ? filters : [...filteredSequenceSearch.filters, ...filters],
        };

      }
      this.context.completeWith(filteredSequenceSearch);
    });
  }

  close(): void {
    this.context.completeWith(null);
  }


}
