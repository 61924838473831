import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Patient } from '@pixacare/pxc-ts-core';
import { PatientsState } from './patients.state';
import { PagedCollection } from '../../models/pagination/paged-collection';

export const PATIENTS_FEATURE_KEY = 'Patients';

export const selectPatientsState = createFeatureSelector<PatientsState>(
  PATIENTS_FEATURE_KEY,
);

export const selectPatientState = createSelector(
  selectPatientsState,
  (state) => state,
);

export const selectPatients = (clientCode: string): MemoizedSelector<
PatientsState,
PagedCollection<Patient[]>
> => createSelector(
  selectPatientsState,
  (state) => ({
    ...state.patients[clientCode],
    data: state.patients[clientCode]?.sorted?.map(
      (patientId) => state.patients[clientCode]?.data?.[patientId],
    ),
  }),
);

export const selectIsPatientStateLoaded = (
  clientCode: string = null,
): MemoizedSelector<PatientsState, boolean> => createSelector(
  selectPatientsState,
  (state) => {
    if (clientCode) {
      return state.loadState[clientCode] || false;
    }
    return Object.keys(state.loadState).every((code) => state.loadState[code] === true);
  },
);

export const selectClientPatient = (
  clientCode: string,
  patientId: number,
): MemoizedSelector<PatientsState, Patient> => createSelector(
  selectPatientsState,
  (state) => {
    const patient = (state.patients[clientCode]?.data || {})[patientId];
    return patient && {
      ...patient,
      clientCode,
    };
  },
);

export const selectIsPatientActive = (
  clientCode: string,
  patientId: number,
): MemoizedSelector<PatientsState, boolean> => createSelector(
  selectPatientsState,
  (state) => {
    const patient = (state.patients[clientCode]?.data || {})[patientId];
    return patient && !patient.isArchived;
  },
);

export const selectIsPatientArchived = (
  clientCode: string,
  patientId: number,
): MemoizedSelector<PatientsState, boolean> => createSelector(
  selectPatientsState,
  (state) => {
    const patient = (state.patients[clientCode]?.data || {})[patientId];
    return patient && patient.isArchived;
  },
);

export const selectIsUserPatientOwner =
  (clientCode: string, patientId: number, userId: number): MemoizedSelector<PatientsState, boolean> => createSelector(
    selectPatientsState,
    (state) => state.patients[clientCode]?.data[patientId]?.createdBy === userId,
  );

export const selectPatientOrder = createSelector(
  selectPatientsState,
  (state) => state.order,
);
