import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { lastValueFrom, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Configuration } from '@pixacare/pxc-ts-core';

@Injectable({
  providedIn: 'root',
})
export class AppConfigHttpService {

  configuration: Configuration;

  constructor(private readonly http: HttpClient) { }

  async getAppConfig(): Promise<void> {
    await lastValueFrom(
      this.http.get<Configuration>(`${environment.apiBaseUrl}/Configuration`)
        .pipe(
          tap((configuration) => this.configuration = configuration),
          catchError(() => of(null)),
        ),
    );
  }

}
