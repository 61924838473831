import { AbstractTuiValueTransformer, TuiDay } from '@taiga-ui/cdk';

type From = TuiDay | null;

type To = Date | null;

export class DateTransformer implements AbstractTuiValueTransformer<From, To> {

  fromControlValue(controlValue: To): From {
    return controlValue && TuiDay.fromLocalNativeDate(controlValue);
  }

  toControlValue(componentValue: From): To {
    return componentValue?.toLocalNativeDate() || null;
  }

}
