<tui-checkbox-labeled
  class="mb-3"
  [ngModel]="isAllSequencesSelected"
  (ngModelChange)="checkUncheckAll($event)"
  data-testid="checkbox-all"
  contentAlign="left"
>
  Tout sélectionner
</tui-checkbox-labeled>
<form [formGroup]="sequencesForm">
  <tui-checkbox-labeled
    *ngFor="let sequence of sequences; let idx=index; trackBy: trackBySequenceId"
    [formControlName]="sequence.sequenceInstance.id"
    [attr.data-testid]="'checkbox-' + sequence.sequenceInstance.id"
    class="w-full mb-2"
  >

    <ng-container *ngIf="context === SequenceContext.TELEMONITORING && !!this.sequence.sequenceInstance.telemonitoringId; else sequencePreview">
      <tui-badged-content
        [contentTop]="warningIcon"
        size="l"
        class="w-full"
      >
        <ng-container *ngTemplateOutlet="sequencePreview"></ng-container>
      </tui-badged-content>
      
      <ng-template #warningIcon>
        <div class="bg-orange-500">
          <tui-svg 
            src="tuiIconAlertTriangle"              
            tuiHint="Cette séquence est déjà attribuée à un télésuivi. Si vous la réattribuez, cette dernière sera retirée de l'autre télésuivi."
            tuiHintAppearance="warning"
            tuiHintDirection="left"
            class="-mt-1"
          ></tui-svg>
        </div>
      </ng-template>
    </ng-container>
    
    <ng-template #sequencePreview>
      <tui-island class="w-full box-border">
        <div class="flex flex-col w-full divide-y divide-slate-100">
          
          <pxc-sequence-preview 
            class="w-full" 
            id="seq-{{idx}}" 
            [sequence]="sequence"
            [compactMode]="compactMode"
            [mobileMode]="true"
            [patientRedirect]="false"
            [attr.data-testid]="'sequence-'+sequence.sequenceInstance.id"
          >
          </pxc-sequence-preview>
          <!-- Telemonitoring condition to display the section here -->
          <div 
            *ngIf="(sadmEntityNames && !!sequence.sequenceInstance.sadmEntityId)" 
            class="flex flex-col gap-2 pt-3 mt-3 pl-2"
          >
            <!-- <ng-container
              *ngIf="telemonitoringNames && telemonitoringNames[sequence.sequenceInstance.telemonitoringId]"
              [ngTemplateOutlet]="selectWholeEntity"
              [ngTemplateOutletContext]="{
                icon: 'telemonitoring',
                entityName: telemonitoringNames[sequence.sequenceInstance.telemonitoringId],
                linkTitle: 'Sélectionner toutes les photos liées au télésuivi',
                action: selectTelemonitoringSequences.bind(this, sequence.sequenceInstance.telemonitoringId)
              }"
            ></ng-container> -->
            <ng-container
              *ngIf="sadmEntityNames && sadmEntityNames[sequence.sequenceInstance.sadmEntityId]"
              [ngTemplateOutlet]="selectWholeEntity"
              [ngTemplateOutletContext]="{
                icon: 'analysis',
                entityName: sadmEntityNames[sequence.sequenceInstance.sadmEntityId],
                linkTitle: 'Sélectionner toutes les photos liées à la plaie',
                action: emitSelectSadmSequences.bind(this, sequence.sequenceInstance.sadmEntityId)
              }"
            ></ng-container>
          </div>
        </div>
      </tui-island>
    </ng-template>

  </tui-checkbox-labeled>
    
</form>

<ng-template #selectWholeEntity 
  let-entityName="entityName" 
  let-linkTitle="linkTitle" 
  let-action="action"
  let-icon="icon"
>
  <div class="flex gap-4 items-center">
    <tui-svg [src]="icon"></tui-svg>
    <p>
      Liée à 
      <span class="font-bold">
        {{entityName}}
      </span>.<br>
      <a 
        stopPropagation
        class="text-sm text-blue-600 cursor-pointer no-underline"
        (click)="action()"
      >{{linkTitle}}</a>
    </p>
  </div>
</ng-template>