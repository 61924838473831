<div class="flex flex-col gap-2 items-start w-full">

  <pxc-back-navigation-button 
    class="block mt-2" 
    label="Retour à la liste" 
    defaultUrl="/dashboard/telemonitorings"
    data-testid="back-button"
  ></pxc-back-navigation-button>
  
  <ng-container *ngIf="isTelemonitoringStateLoaded$ | async; else loadingBlock">

    <div *ngIf="telemonitoring$ | async as telemonitoring; else emptyState" class="flex max-lg:flex-col gap-8 w-full animate-in fade-in">
      <ng-container *tuiLet="(isArchived$ | async) as isArchived">

        <div class="flex flex-col mt-2 gap-6 flex-1">
    
          <div class="flex max-lg:flex-col gap-6 lg:items-center">

            <div>
              <ng-container [ngTemplateOutlet]="qrCodeTemplate"></ng-container>
            </div>
            
            <div class="flex flex-col gap-2.5">

              <div class="flex items-center">
                <h4 data-testid="name" class="leading-none">{{telemonitoring.telemonitoring.name}}</h4>

                <div *ngIf="isArchived">
                  <pxc-archived-badge></pxc-archived-badge>
                </div>

              </div>
      
              <pxc-last-update-badge 
                *ngIf="telemonitoring.telemonitoring.lastActivity as lastActivity" 
                [lastUpdate]="lastActivity"
              ></pxc-last-update-badge>
        
              <p class="text-slate-600 text-sm leading-snug">
                Créé le <span class="font-medium" data-testid="creation-date">{{telemonitoring.telemonitoring.createdOn | date:'shortDate'}}</span>
                <ng-container *ngIf="telemonitoring.createdByInstance">
                  par <pxc-user-preview [user]="telemonitoring.createdByInstance" data-testid="author" [colored]="false"></pxc-user-preview>
                </ng-container>
              </p>
        
            </div>
    
            <pxc-action-buttons 
              [entity]="telemonitoring.telemonitoring" 
              [actions]="tmActions" 
              [actionButtons]="actionButtonsGroups"
              data-testid="actions"
              class="lg:ml-auto"
              optionButtonAppearance="outline"
            ></pxc-action-buttons>
    
          </div>
          
          <div *ngIf="telemonitoring.patient && telemonitoring.patient.isArchived">
            <pxc-archived-notification
              title="Le patient de ce télésuivi est archivé"
              description="Vous ne pouvez plus modifier ou ajouter de données à ce patient.">
            </pxc-archived-notification>
          </div>

          <div *ngIf="telemonitoring.telemonitoring.message" data-testid="description">
            <pxc-title title="Message" icon="tuiIconMessageCircleLarge" titleSize="small"></pxc-title>
            <p>{{telemonitoring.telemonitoring.message}}</p> 
          </div>
    
          <div class="flex flex-col justify-start gap-2">
            <div class="flex gap-2 max-lg:flex-wrap items-center">
              <pxc-filter-bar
                searchPlaceholder="Rechercher une séquence par mot clé, date ou patient ..."
                data-testid="filter-bar"
              ></pxc-filter-bar>

              <pxc-create-button *ngIf="telemonitoring.patient"
                (click)="addSequence()"
                data-testid="add-sequence"
                class="mt flex-none max-lg:w-full"
                label="Ajouter une séquence"
                [disabled]="telemonitoring.patient.isArchived"
              ></pxc-create-button>
            </div>

            <div *ngIf="telemonitoring.sequences; else loadingBlock" class="max-h-[70vh]">
              <pxc-sequence-list 
                [sequences]="sequences$ | async" 
                [context]="context" 
                [actions]="sequenceActions" 
                (sequenceMediaClicked)="openGallery($event)" data-testid="sequence-list"
              ></pxc-sequence-list>
            </div>
    
          </div> 
    
        </div>
    
        <ng-template #qrCodeTemplate>
          <div 
            *tuiLet="qrCode$ | async as qrCode" 
            class="cursor-pointer rounded-lg bg-white shadow-lg w-36 h-36 overflow-hidden" 
            data-testid="qr-code"
            (click)="openQrCode(telemonitoring.telemonitoring.id)"
          >
            <tui-loader [showLoader]="!qrCode" class="h-full w-full">
              <div *ngIf="qrCode" [innerHTML]="qrCode"></div>
            </tui-loader>
          </div>
        </ng-template>
      
      </ng-container>
    </div>

  </ng-container>
  
  <ng-template #loadingBlock>
    <pxc-loader data-testid="loader" class="mx-auto my-5">
      Chargement de votre télésuivi ...
    </pxc-loader>
  </ng-template>

  <ng-template #emptyState>
    <div class="flex flex-col items-center justify-center gap-4 mx-auto my-5">
      <pxc-lottie-label animation="patientEmpty" message="Oups !"></pxc-lottie-label>
      <p>Désolé, nous n'avons pas trouvé le télésuivi que vous cherchiez ...</p>
      <a tuiButton routerLink="/dashboard/telemonitorings" queryParamsHandling="merge" >Retour à la liste des télésuivis</a>
    </div>
  </ng-template>
  

</div>