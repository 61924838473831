import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TuiLoaderModule } from '@taiga-ui/core';

@Component({
  selector: 'pxc-spinner',
  templateUrl: './spinner.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TuiLoaderModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {

  @Input() isVisible = true;

}
