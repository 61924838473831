import { Component, ChangeDetectionStrategy, Output, Input, EventEmitter } from '@angular/core';
import { FlattenedTelemonitoring, Telemonitoring } from '@pixacare/pxc-ts-core';
import { MenuAction } from '@shared/models/menu-actions/menu-action';

@Component({
  selector: 'pxc-telemonitoring-list',
  templateUrl: './telemonitoring-list.component.html',
  styleUrls: ['./telemonitoring-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemonitoringListComponent {

  @Input() telemonitorings: FlattenedTelemonitoring[];
  @Input() actions: MenuAction<Telemonitoring>[];
  @Input() displayPatient = true;
  @Input() disableScrollTracker = false;
  @Input() isNextPageLoading = false;
  @Input() isPreviousPageLoading = false;

  @Output() loadPrevious: EventEmitter<null> = new EventEmitter();
  @Output() loadNext: EventEmitter<null> = new EventEmitter();

  trackByTelemonitoringId(index: number, telemonitoring: FlattenedTelemonitoring): number {
    return telemonitoring.telemonitoring.id * telemonitoring.telemonitoring.lastActivity?.getTime();
  }

}
