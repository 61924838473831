import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SADM_FEATURE_KEY } from './sadm.selectors';
import { SadmDashboard } from '../../models/sadms/sadm-dashboard';
import { BaseSadmEntity, SadmClient, SadmEntity } from '@pixacare/pxc-ts-core';

export const sadmActions = createActionGroup({
  source: SADM_FEATURE_KEY,
  events: {
    'Get sadm clients': emptyProps(),
    'Get sadm clients success': props<{ sadmClients: SadmClient[]; override: boolean }>(),

    'Get sadm entities': props<{ patientId: number; clientCode: string; isArchived?: boolean }>(),
    'Get sadm entities success': props<{ patientId: number; clientCode: string; sadmEntities: SadmEntity[] }>(),

    'Create sadm entity': props<{ sadmEntityPayload: BaseSadmEntity & {
      clientCode: string;
      patientId: number;
    }; }>(),
    'Get sadm entity': props<{ sadmEntityId: number }>(),
    'Get sadm entity success': props<{ sadmEntity: SadmEntity }>(),
    'Merge sadm entity': props<{ fromSadmEntityId: number; toSadmEntityId: number }>(),
    'Delete sadm entity': props<{ sadmEntityId: number }>(),
    'Unload sadm entity': props<{ sadmEntityId: number }>(),

    'Get sadm dashboard': props<{ sadmEntityId: number }>(),
    'Get sadm dashboard success': props<{ sadmEntityId: number; sadmDashboard: SadmDashboard }>(),
    'Unload sadm dashboard': props<{ sadmEntityId: number }>(),

    'Update sadm entity': props<{ sadmEntity: SadmEntity }>(),
    'Update sadm entity success': props<{ sadmEntity: SadmEntity }>(),

    'Archive sadm entity': props<{ sadmEntityId: number }>(),
    'Archive sadm entity success': props<{ sadmEntity: SadmEntity }>(),

    'Unarchive sadm entity': props<{ sadmEntityId: number }>(),
    'Unarchive sadm entity success': props<{ sadmEntity: SadmEntity }>(),
  },
});
