import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TuiFileLike, TuiInputFilesModule, TuiMarkerIconModule } from '@taiga-ui/kit';
import { FileImagePreviewComponent } from '../file-image-preview/file-image-preview.component';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiLetModule } from '@taiga-ui/cdk';
import { ImageOverlayComponent } from '../image-overlay/image-overlay.component';
import { ImageBrokenComponent } from '../image-broken/image-broken.component';
import { SequenceMediaComponent } from '../../sequence/sequence-media/sequence-media.component';
import { Media } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-image-input',
  standalone: true,
  imports: [
    CommonModule,
    TuiInputFilesModule,
    FormsModule,
    ReactiveFormsModule,
    TuiMarkerIconModule,
    FileImagePreviewComponent,
    TuiButtonModule,
    TuiSvgModule,
    TuiLetModule,
    ImageOverlayComponent,
    ImageBrokenComponent,
    SequenceMediaComponent,
  ],
  templateUrl: './image-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ImageInputComponent,
    },
  ],
})
export class ImageInputComponent implements ControlValueAccessor {

  @Input() label = 'Ajoutez vos images ici';
  @Input() accept = 'image/*';

  @Input() showDelete = true;

  @Input() medias: Media[] = [];
  @Output() mediaDelete = new EventEmitter<number>();
  @Output() mediaClickedIdx = new EventEmitter<number>();

  disabled = false;
  touched = false;
  value: File[] = [];

  rejectedFiles: readonly TuiFileLike[] = [];

  onChange: (value: File[]) => void = () => {};
  onTouched: () => void = () => {};

  removeMedia(media: Media): void {
    this.medias = this.medias.filter((m) => m.id !== media.id);
    this.mediaDelete.emit(media.id);
  }

  onReject(files: TuiFileLike | readonly TuiFileLike[]): void {
    this.rejectedFiles = [...this.rejectedFiles, ...(files as TuiFileLike[])];
  }

  onFileChange(files: File[]): void {
    this.value = files;
    this.onChange(this.value);
  }

  removeRejected({ name }: TuiFileLike): void {
    this.rejectedFiles = this.rejectedFiles.filter(
      (rejected) => rejected.name !== name,
    );
  }

  removeFile({ name }: File): void {
    this.value = this.value.filter((file) => file.name !== name);
    this.onChange(this.value);
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(obj: File[]): void {
    this.value = obj;
  }

  registerOnChange(fn: (value: File[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
