<div class="flex flex-wrap">
  <ng-container *ngIf="sadmsEntities.length > 0; else emptyState">
    <div *ngFor="let sadmEntity of sadmsEntities" class="w-full sm:w-1/1 md:w-1/2 lg:w-1/3 xl:w-1/4 p-2" >
      <pxc-sadm-entity-preview
        [sadmEntity]="sadmEntity"
        [sadmType]="sadmType"
        class="cursor-pointer"
        [routerLink]="['/dashboard/sadms/', sadmEntity.id]"
        queryParamsHandling="merge"
        [attr.data-testid]="sadmEntity.id"
      ></pxc-sadm-entity-preview>
    </div>
  </ng-container>
</div>

<ng-template #emptyState>
  <p class="text-slate-500 text-center my-2" data-testid="no-sadm">
    Vous n'avez aucune {{SadmTypeLabel[sadmType]}} pour ce patient.
  </p>
</ng-template>