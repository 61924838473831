import Konva from 'konva';
import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { EventBus } from '../event-bus/event-bus';
import { EventTopic } from '../event-bus/event-bus.interfaces';
import { PictureAnnotations } from '@pixacare/pxc-ts-core';

export class ImportAnnotationsCommand implements Command {

  receiver: StudioCanvas;
  eventBus: EventBus;
  pictureAnnotations: PictureAnnotations;
  constructor(receiver: StudioCanvas, eventBus: EventBus, pictureAnnotations: PictureAnnotations) {
    this.receiver = receiver;
    this.eventBus = eventBus;
    this.pictureAnnotations = pictureAnnotations;
  }

  execute(): void {
    this.receiver.stage.rotation(this.pictureAnnotations.transformations.rotation);
    this.eventBus.publish(EventTopic.UPDATE_CURRENT_CROPCONFIG, {
      cropConfig: this.pictureAnnotations.transformations.cropConfig,
    });
    this.eventBus.publish(EventTopic.REVERT_CROP, { cropConfig: this.pictureAnnotations.transformations.cropConfig });

    this.pictureAnnotations.shapes.forEach((shape) => {
      switch (shape.type) {

        case 'Rect': {
          const rect = new Konva.Rect({
            ...shape,
          });
          this.receiver.addShape(rect);
          break;
        }

        case 'Ellipse': {
          const ellipse = new Konva.Ellipse({
            ...shape,
            radiusX: shape.radiusX,
            radiusY: shape.radiusY,
            width: 0,
            height: 0,
          });
          this.receiver.addShape(ellipse);
          break;
        }

        case 'Line': {
          const line = new Konva.Line({
            ...shape,
            lineCap: shape.lineCap || 'round',
            lineJoin: shape.lineJoin || 'round',
          });
          this.receiver.addShape(line);
          break;
        }

        default: {
          break;
        }
      }
    });

    this.eventBus.publish(EventTopic.IMPORT_FINISHED);
  }

}
