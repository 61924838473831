<ng-container *ngIf="(storageSpace$ | async) as storageSpace">
  <div *ngIf="!closed" class="container mx-auto px-4 lg:px-8">
    <tui-notification 
      *ngIf="storageSpace === StorageSpace.LOW && showStorageWarningNotification === true" 
      icon="tuiIconAlertTriangle" 
      status="warning" 
      (close)="close()"
      (closeEvent)="showStorageWarningNotification = false"
      class="mb-3"
      data-testid="low-storage-alert"
  >
      Votre espace de stockage est bientôt saturé. 
      <ng-container *ngTemplateOutlet="callToActionPaywallMail"></ng-container>
    </tui-notification>

    <tui-notification 
      *ngIf="storageSpace === StorageSpace.FULL" 
      icon="tuiIconAlertTriangle" 
      status="error" 
      class="mb-3"
      data-testid="no-storage-alert">
      Votre espace de stockage est saturé. 
      <ng-container *ngTemplateOutlet="callToActionPaywallMail"></ng-container>
    </tui-notification>

    <ng-template #callToActionPaywallMail>
      <a (click)="manageStorage()" tuiLink>Gérer le stockage.</a>
    </ng-template>
  </div>
</ng-container>