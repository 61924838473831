<ng-container *ngIf="patients; else loadingBlock">
  <tui-scrollbar class="max-h-96 relative" *ngIf="patients.length > 0; else noPatientList">
    <div class="flex flex-col divide-y divide-slate-100">

      <ng-container *ngIf="(isGamEnabled$ | async) && gamLinkedPatients.length > 0">
        <ng-container [ngTemplateOutlet]="sectionTitle" [ngTemplateOutletContext]="{title: 'Patients existants dans votre établissement'}"></ng-container>
        <ng-container *ngFor="let patient of gamLinkedPatients" [ngTemplateOutlet]="patientCard" [ngTemplateOutletContext]="{patient: patient}"></ng-container>
      </ng-container>

      <ng-container *ngIf="pixacarePatients.length > 0">
        <ng-container [ngTemplateOutlet]="sectionTitle" [ngTemplateOutletContext]="{title: 'Patients existants dans Pixacare'}"></ng-container>
        <ng-container *ngFor="let patient of pixacarePatients" [ngTemplateOutlet]="patientCard" [ngTemplateOutletContext]="{patient: patient}"></ng-container>
      </ng-container>

    </div>
  </tui-scrollbar>
</ng-container>  

<ng-template #loadingBlock>
  <pxc-loader size="tiny" data-testid="loading" class="my-auto"></pxc-loader>
</ng-template>

<ng-template #noPatientList>
  <div class="flex h-full w-full justify-center items-center">
    <p class="text-center text-slate-400" data-testid="no-identified-patient">Aucun patient n'a été identifié.</p>
  </div>
</ng-template>
    
<ng-template #sectionTitle let-title="title">
  <p class="sticky top-0 font-medium text-sm text-slate-500 bg-white lg:bg-slate-50 p-2 z-10 border-b border-slate-200">{{title}}</p>
</ng-template>

<ng-template #patientCard let-patient="patient">
  <button 
    class="w-full flex gap-3 p-3 hover:bg-slate-100" 
    (click)="onPatientClick(patient)"
    [attr.data-testid]="'select-patient-'+patient.id"
  >
    <pxc-patient-avatar
      size="small"
      [patient]="patient"
    ></pxc-patient-avatar>
    <pxc-patient-data
      [patient]="patient"
      size="small"
    ></pxc-patient-data>
  </button>
</ng-template>