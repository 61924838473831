<ng-container *tuiLet="{ resolvedShare: resolvedShare$ | async, error: resolvedShareError$ | async } as data">

    <ng-container *ngIf="!data.error; else errorBlock">
        <ng-container *ngIf="data.resolvedShare as resolvedShare; else loadingBlock">

            <ng-container [ngSwitch]="resolvedShare.objectType">
                <pxc-sequence-preview
                    *ngSwitchCase="ObjectType.SEQUENCE"
                    class="cursor-auto"
                    [sequence]="resolvedShare.object"
                    [mobileMode]="true"
                    [patientRedirect]="false"
                    (mediaClicked)="mediaClicked($event, resolvedShare.object)"
                    (click)="$event.stopPropagation()"
                ></pxc-sequence-preview>
            </ng-container>

        </ng-container>
    </ng-container>

</ng-container>

<ng-template #loadingBlock>
    <pxc-spinner></pxc-spinner>
</ng-template>

<ng-template #errorBlock>
    Une erreur est survenue lors de la récupération du partage
</ng-template>