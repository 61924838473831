<div class="flex gap-2 items-center max-w-full">

  <button tuiIconButton 
    appearance="secondary" 
    (click)="previous()" 
    [disabled]="selectedIndex <= 0" 
    data-testid="previous" 
    icon="tuiIconChevronLeftLarge"
  ></button>

  <tui-carousel
    class="sadm-carousel"
    [itemsCount]="itemsCount$ | async"
    [(index)]="displayIndex"
  >

    <ng-container *ngFor="let report of reports; let idx = index">
      <div 
        *tuiItem 
        [attr.data-testid]="report.title"
        class="flex flex-col gap-2 items-center cursor-pointer rounded-xl p-2 max-w-[16vh] mx-auto"
        [ngClass]="{
          'bg-pixablue-1 transition-all text-white': selectedIndex === idx
        }"
        (click)="moveTo(idx)"
      >

        <img
          class="rounded-xl w-full h-full" 
          (click)="openGallery(idx)"
          [src]="report.media.thumbnailUri"
          data-testid="thumbnail"
          alt="thumbnail"
        />

        <pxc-date-preview
          [date]="report.date" 
          [displayTime]="false"
          class="m-1"
        ></pxc-date-preview>

      </div>
    </ng-container>

  </tui-carousel>

  <button tuiIconButton 
    appearance="secondary" 
    (click)="next()" 
    [disabled]="selectedIndex >= reports.length - 1" 
    data-testid="next" 
    icon="tuiIconChevronRightLarge"
  ></button>

</div>