<div
  class="flex gap-4"
  [ngClass]="{
    'justify-between content-start mt-3 md:items-center lg:grid lg:grid-cols-2 lg:gap-4': formDisplayStyle === FormDisplayStyles.FullscreenRows,
    'flex-col': formDisplayStyle !== FormDisplayStyles.FullscreenRows
  }"
>
  <ng-container *ngFor='let section of sections'>
    <div 
      class="flex flex-col gap-2 w-full"
      *ngIf="section.questions.length > 0" 
      [attr.data-testid]="'section-'+section.id"
    >
      <p class="font-medium" [ngClass]="{
        'text-lg': formDisplayStyle === FormDisplayStyles.Columns
      }">{{section.title | titlecase}}</p>
      <div 
        class="flex"
        [ngClass]="{
          'flex-wrap gap-2': formDisplayStyle !== FormDisplayStyles.Columns, 
          'flex-col gap-4': formDisplayStyle === FormDisplayStyles.Columns
        }"
      >
        <ng-container *ngFor="let question of section.questions">
          <div 
            class="flex flex-col"
            [ngClass]="{
              'border border-slate-100 bg-slate-50 items-center justify-center flex-1 rounded-xl p-2 text-center': formDisplayStyle !== FormDisplayStyles.Columns,
              'p-3': formDisplayStyle === FormDisplayStyles.FullscreenRows
            }"
            *ngIf="question.value || question.value === 0"
          >
            <span class="text-sm">{{question.title}}</span>
            <div class="flex items-baseline gap-1">
              <span class="font-medium whitespace-pre-line">{{question.value | normalize }}</span>
              <span class="text-sm">{{question.unit}}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>