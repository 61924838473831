import { CommonModule } from '@angular/common';
import {
  Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit,
} from '@angular/core';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { TuiSvgModule, TuiDropdownModule, TuiHostedDropdownModule, TuiWrapperModule } from '@taiga-ui/core';
import { ClientData } from 'src/app/shared/models/base/client-data';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../../shared/shared.module';
import { AnimateFadeDirective } from '../../shared/directives/animate-fade.directive';
import { Size } from 'src/app/shared/models/enums/size.enum';

@Component({
  selector: 'pxc-client-picker',
  templateUrl: './client-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiSvgModule,
    TuiDropdownModule,
    TuiActiveZoneModule,
    SharedModule,
    TuiHostedDropdownModule,
    AnimateFadeDirective,
    TuiWrapperModule,
  ],
})
export class ClientPickerComponent implements OnInit {

  @Input() clients: { [clientCode: string]: ClientData };
  @Input() selectedClient: string;
  @Input() collapsed = false;
  @Input() size = Size.MEDIUM;

  @Output() selectedClientChange = new EventEmitter<string>();

  cdnClientBaseUrl: string = environment.cdn.clientBaseUrl;
  defaultClientImagePath = 'default.png';
  clientCodes: string[] = [];
  open = false;
  Size = Size;

  ngOnInit(): void {
    this.clientCodes = Object.keys(this.clients ?? {});
  }

  selectClient(clientCode: string): void {
    this.selectedClientChange.emit(clientCode);
    this.open = false;
  }

}
