import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChannelMessage, ChannelMessageGroup, ChannelMessageGroupsEngine, InsertionType } from '@pixacare/pxc-ts-core';

@Injectable()
export class MessageGroupService {

  messageGroups$: Observable<ChannelMessageGroup[]>;

  private messageGroups: BehaviorSubject<ChannelMessageGroup[]> = new BehaviorSubject<ChannelMessageGroup[]>([]);
  private engine = new ChannelMessageGroupsEngine();

  constructor() {
    this.messageGroups$ = this.messageGroups.asObservable();
  }

  addMessages(
    messages: ChannelMessage[],
    insertionType: InsertionType = 'append',
    lastDisplayedMessage?: ChannelMessage,
  ): void {
    this.messageGroups.next(this.engine.addMessages(messages, insertionType, lastDisplayedMessage));
  }

  reset(): void {
    this.engine.reset();
    this.messageGroups.next([]);
  }

}
