<div *ngIf="(passwordResetRequestSent$ | async) === false; else passwordResetRequestSentBlock">
  <div class="flex flex-col gap-2 py-2">
    <h2>Demande de réinitialisation</h2>
    <p class="max-w-prose">
      Merci de renseigner votre adresse e-mail, nous vous enverrons un message
      contenant les instructions pour réinitialiser votre mot de passe.
    </p>
  </div>

  <form [formGroup]="passwordResetRequestForm" (ngSubmit)="requestPasswordReset()"
    data-testid="reset-password-request-form" class="flex flex-col gap-6 mt-6">
    <div>
      <tui-input
        autofocus
        formControlName="emailInput"
        [disabled]="(actionOnGoing$ | async) ? '' : null"
        tuiTextfieldIconLeft="tuiIconMailLarge"
      >
        Adresse E-Mail
      </tui-input>

      <app-input-validation-error-display [control]="emailInput"></app-input-validation-error-display>
    </div>

    <div class="flex flex-col gap-2">
      <button 
        tuiButton 
        class="w-full" 
        type="submit" 
        [disabled]="actionOnGoing$ | async" 
        appearance="primary"
      >
        Envoyer un lien
      </button>
  
      <button 
        tuiButton 
        appearance="outline" 
        class="w-full" 
        routerLink="../login"
      >
        Revenir à la connexion
      </button>
    </div>

  </form>

</div>
<ng-template #passwordResetRequestSentBlock>
  <p class="max-w-prose text-center mx-auto">
    Si cette adresse e-mail est associée à un de nos utilisateurs, nous enverrons
    une procédure de réinitialisation du mot de passe.
  </p>
  <button 
    tuiButton 
    appearance="primary" 
    class="w-full mt-3" 
    routerLink="../login"
  >
    Revenir à la connexion
  </button>
</ng-template>