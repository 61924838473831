import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { selectSequences } from '../sequences/sequences.selectors';
import { SharesState } from './shares.state';
import { Share } from '@pixacare/pxc-ts-core';

export const SHARES_FEATURE_KEY = 'Shares';

export const selectSharesState = createFeatureSelector<SharesState>(
  SHARES_FEATURE_KEY,
);

export const selectShares = createSelector(
  selectSharesState,
  (state) => Object.values(state.shares),
);

export const selectUsersShares = (
  userIds: number[],
): MemoizedSelector<SharesState, Share[]> => createSelector(
  selectSharesState,
  (state) => Object.values(state.shares)?.filter((share) => userIds.includes(share.createdBy)),
);

export const selectAreSharedSequencesFetched = createSelector(
  selectSharesState,
  selectSequences,
  (state, sharedSequences) => {
    const loadedSequences = sharedSequences.data
      ? sharedSequences.data.map((sequence) => sequence.sequenceInstance.id)
      : [];

    return Object.values(state.shares).every((share) => loadedSequences?.includes(share.objectId));
  },

);
