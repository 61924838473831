<div class="relative ml-4 h-full group"  [attr.tabindex]="isFocusable ? '0': null">
  <pxc-card-details class="block h-full">
    <div class="flex flex-col !ml-2 gap-1">
      <pxc-patient-data [patient]="patient" data-testid="patient-details" size="small">
        <ng-content></ng-content>
        <ng-container slot="additional-data">
          <ng-content select="[slot=details]"></ng-content>
        </ng-container>
        <ng-content></ng-content>
        <ng-container additional-data>
          <ng-content select="[details]"></ng-content>
        </ng-container>
      </pxc-patient-data>
      <pxc-last-update-badge
        *ngIf="displayLastActivity"
        [lastUpdate]="patient.lastActivity | localDate"
      ></pxc-last-update-badge>
    </div>
    <ng-container dropdown-menu *ngIf="actions">
      <pxc-action-buttons 
        *showIfUserAuthorizedForPatient="patient"
        [entity]="patient" 
        [actions]="actions" 
        data-testid="menu-actions" 
      ></pxc-action-buttons>
    </ng-container>
  </pxc-card-details>

  <pxc-patient-avatar 
    class="absolute top-4 -left-4"
    [patient]="patient"
    size="small"
    data-testid="patient-avatar"
  ></pxc-patient-avatar>
</div>
