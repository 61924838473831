import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { SadmActionsService } from '../../sadm/sadm-actions.service';
import 'chartjs-adapter-date-fns';
import { SequenceActionsService } from '../../sequence/services/sequence-actions.service';
import { SadmService } from './sadm.service';
import { DialogService } from 'src/app/services/dialog.service';
import { SadmEntityProtocolsComponent } from '../../sadm/sadm-entity-protocols/sadm-entity-protocols.component';
import { FormComponent } from '../../forms/form/form.component';
import { SadmProtocolsService } from '../../sadm/sadm-protocols.service';
import { ChartService } from 'src/app/shared/charts/chart.service';
import { SadmFormService } from '../../sadm/sadm-form.service';
import { SadmEntityCreationService } from '../../sadm/sadm-entity-creation.service';
import { Store } from '@ngrx/store';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartEvent } from 'chart.js';
import { SequenceCreateStep } from 'src/app/shared/models/enums/sequence-create-step.enum';
import { FormAnswers, Patient, SadmDisplay, SadmEntity, SadmEventType, SadmTypeLabel } from '@pixacare/pxc-ts-core';
import { ActivatedRoute, Params } from '@angular/router';
import { SadmDashboard } from 'src/app/shared/models/sadms/sadm-dashboard';
import { SadmEntityNamePipe } from '@shared/pipes/sadm-entity-name.pipe';
import { SadmEntityTypePipe } from '@shared/pipes/sadm-entity-type.pipe';

@UntilDestroy()
@Component({
  templateUrl: './sadm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ChartService,
    SadmService,
    SadmProtocolsService,
    SadmFormService,
    SadmEntityCreationService,
    SadmEntityNamePipe,
    SadmEntityTypePipe,
  ],
})
export class SadmComponent {

  sadmEntity$: Observable<SadmEntity>;
  dashboard$: Observable<SadmDashboard>;
  isArchived$: Observable<boolean>;

  SadmTypeLabel = SadmTypeLabel;
  Display = SadmDisplay;

  currentFormSummaryIndex = 0;
  editAnalysisButtonLoading$ = new BehaviorSubject<boolean>(false);

  private readonly sadmActionsService = inject(SadmActionsService);
  private readonly sequenceActionsService = inject(SequenceActionsService);
  private readonly dialogService = inject(DialogService);
  private readonly protocolsService = inject(SadmProtocolsService);
  private readonly sadmFormService = inject(SadmFormService);
  private readonly store = inject(Store);
  private readonly route = inject(ActivatedRoute);
  private readonly sadmService = inject(SadmService);

  constructor() {
    this.sadmEntity$ = this.sadmService.sadmEntity$;
    this.dashboard$ = this.sadmService.dashboard$;
    this.isArchived$ = this.sadmEntity$.pipe(
      untilDestroyed(this),
      map((sadmEntity: SadmEntity) =>
        sadmEntity.isArchived || (sadmEntity.patient && sadmEntity.patient.isArchived),
      ),
    );
    this.route.queryParams.pipe(first()).subscribe({
      next: ({ sequenceId }: Params) => {
        if (sequenceId) {
          this.displaySelectedFormSummary(+sequenceId);
        }
      },
    });
  }

  get actions(): MenuAction<SadmEntity>[] {
    return this.sadmActionsService.getActions();
  }

  createSequence(): void {
    this.sadmEntity$.pipe(
      first((sadmEntity) => !!sadmEntity.patient),
    ).subscribe((sadmEntity) => {
      this.addSequence(sadmEntity.clientCode, sadmEntity.patient, sadmEntity.id);
    });
  }


  displaySelectedFormSummary(sequenceId: number): void {
    this.sadmService.getFormSummaryIndex(sequenceId).pipe(first()).subscribe({
      next: (index: number) => {
        this.currentFormSummaryIndex = index;
      },
    });
  }

  openProtocols(index: number = 0): void {

    combineLatest([
      this.sadmEntity$,
      this.dashboard$,
    ]).pipe(first())
      .subscribe(([sadmEntity, dashboard]) => {
        this.dialogService.openComponent(
          SadmEntityProtocolsComponent, {
            size: 'l',
            data: {
              sadmEntity,
              sadmClient: sadmEntity.sadmClient,
              protocolsReports: dashboard.protocolReports,
              index,
            },
          },
        ).subscribe();
      });

  }

  createProtocol(): void {

    this.sadmEntity$
      .pipe(first())
      .subscribe((sadmEntity) => {

        this.dialogService.openComponentWithCloseConfirmation<FormComponent, FormAnswers>(
          FormComponent, {
            label: 'Créer un protocole',
            data: {
              form: sadmEntity.sadmClient.protocolCreationForm,
            },
            dismissible: false,
            size: 'l',
          },
        ).subscribe((answers: FormAnswers) => {
          if (answers) {
            this.protocolsService.createProtocol({
              sadmEntityId: sadmEntity.id,
              sadmClientId: sadmEntity.sadmClientId,
              answers,
            });
          }
        });

      });

  }

  editReport(): void {

    this.editAnalysisButtonLoading$.next(true);

    combineLatest([
      this.sadmService.getFormAnswerByIndex(this.currentFormSummaryIndex),
      this.sadmEntity$,
    ]).pipe(
      first(),
      tap(() => this.editAnalysisButtonLoading$.next(false)),
      switchMap(([{ formAnswer, sequenceId }, { id, clientCode }]) =>
        this.sadmFormService.editAnalysis({
          sadmEntityId: id,
          analysisFormAnswers: formAnswer.answers,
        }).pipe(
          map((answers) => ({ answers, clientCode, sequenceId })),
          catchError(() => []),
        ),
      ),
      first(),
      untilDestroyed(this),
    ).subscribe(({ answers, clientCode, sequenceId }) => {
      if (answers) {

        this.store.dispatch(sequencesActions.editSequenceAnalysis({
          clientCode,
          sequenceId,
          answers,
        }));

      }
    });
  }

  addReport(): void {
    this.sadmEntity$.pipe(
      first(),
      map(({ patient, clientCode, id }) => ({
        patientId: patient.id,
        selectedSadmEntityId: id,
        clientCode,
        patient,
      })),
      switchMap((data) =>
        this.sadmFormService.createAnalysis(data)
          .pipe(
            first(),
            map(({ analysisFormAnswers }) => ({
              ...data,
              analysisFormAnswers,
            })),
            catchError(() => []),
          )),
      untilDestroyed(this),
    ).subscribe(({ patient, analysisFormAnswers, clientCode, selectedSadmEntityId }) => {
      if (analysisFormAnswers) {
        this.addSequence(clientCode, patient, selectedSadmEntityId, analysisFormAnswers);
      }
    });
  }

  onChartClick({ active }: { active?: any[] }): void {
    const element = this.getProtocolAdjustmentElement(active);
    if (element.length > 0) {
      this.sadmEntity$.pipe(first()).subscribe((sadmEntity) => {
        const lastElement = element[element.length - 1];
        this.openProtocols(sadmEntity.protocolsHistory.length - lastElement.index - 1);
      });
    }
  }

  onChartHover({ event, active }: { event?: ChartEvent; active?: any }): void {
    const element = this.getProtocolAdjustmentElement(active);
    if (element.length > 0) {
      (event.native.target as any).style.cursor = 'pointer';
    } else {
      (event.native.target as any).style.cursor = 'default';
    }
  }

  private getProtocolAdjustmentElement(active: any[]): any[] {
    return active.filter((target) =>
      target.element.$context.raw.entities?.some((entity) => entity.type === SadmEventType.PROTOCOL_ADJUSTMENT),
    );
  }

  private addSequence(
    clientCode: string, patient: Patient, sadmEntityId: number, analysisFormAnswers?: FormAnswers,
  ): void {
    this.sequenceActionsService.addSequence(clientCode, {
      patient,
      sadmEntityId,
      analysisFormAnswers,
      step: SequenceCreateStep.PICTURES,
    });
  }

}
