import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, Output,
} from '@angular/core';
import { Label } from '@pixacare/pxc-ts-core';
import { TuiTagModule } from '@taiga-ui/kit';

@Component({
  selector: 'pxc-label',
  templateUrl: './label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TuiTagModule,
  ],
})
export class LabelComponent {

  @Input() label: Label;
  @Input() clickable = false;
  @Input() isDeletable = false;
  @Output() closeEvent = new EventEmitter<void>();

}
