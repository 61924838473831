import { createActionGroup, props } from '@ngrx/store';
import { DEPARTMENTS_FEATURE_KEY } from './departments.selectors';
import { DepartmentMembershipData, DepartmentResponse } from '@pixacare/pxc-ts-core';

export const departmentsActions = createActionGroup({
  source: DEPARTMENTS_FEATURE_KEY,
  events: {
    'Get departments': props<{ clientCode: string }>(),
    'Get departments success': props<{ clientCode: string; departments: DepartmentResponse[] }>(),

    'Update collaboration': props<{ clientCode: string; departmentId: number; isOpen: boolean }>(),
    'Update collaboration success': props<{ clientCode: string; departmentId: number; isOpen: boolean }>(),

    'Create department': props<{ clientCode: string; name: string }>(),
    'Create department success': props<{ clientCode: string; department: DepartmentResponse }>(),

    'Delete department': props<{ clientCode: string; departmentId: number }>(),

    'Update department user': props<{
      clientCode: string;
      departmentId: number;
      departmentUserId: number;
      membership: DepartmentMembershipData;
    }>(),
    'Update department user success': props<{
      clientCode: string;
      departmentId: number;
      departmentUserId: number;
      membership: DepartmentMembershipData;
    }>(),

    'Remove department user': props<{
      userId: number;
      clientCode: string;
      departmentId: number;
    }>(),
    'Remove department user success': props<{
      userId: number;
      clientCode: string;
      departmentId: number;
    }>(),
  },
});
