<div class="flex flex-col items-center my-5 gap-6 px-4">
  <div class="flex justify-center items-center gap-3">

  <tui-svg
    [src]="icon || defaultStatusInformations[state].icon"
    class="!text-xl"
    data-testid="icon"
    [ngClass]="defaultStatusInformations[state].color"
  ></tui-svg>
  <h5
    *ngIf="showTitle"
    [ngClass]="defaultStatusInformations[state].color"
  >
      {{title || defaultStatusInformations[state].title }}
  </h5>
</div>
  <p class="text-lg max-w-prose text-slate-400 text-center text-balance">
    {{ description || defaultStatusInformations[state].description }}
  </p>
  <ng-content></ng-content>

</div>
