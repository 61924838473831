import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ToastrConfig } from '../shared/models/helpers/toastr-config';
import { downloadBlob } from '../shared/utils/utils';
import { TuiAlertService } from '@taiga-ui/core';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {

  constructor(
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
  ) { }

  downloadDocument({
    name,
    document$,
    notifications = {
      loading: {
        message: 'Nous nous occupons de tout, votre fichier arrivera dans quelques instants...',
        title: 'Création en cours',
      },
      success: {
        message: 'Le document a bien été téléchargé sur votre appareil',
        title: 'Export généré !',
      },
      error: {
        message: 'Une erreur est survenue lors du téléchargement, merci de réessayer plus tard.',
        title: 'Echec du téléchargement',
      },
    },
  }: {
    name: string;
    document$: Observable<HttpResponse<Blob>>;
    notifications?: {
      loading: ToastrConfig;
      success: ToastrConfig;
      error: ToastrConfig;
    }; }): void {

    const loadingToastr = this.alertService.open(notifications.loading.message, {
      label: notifications.loading.title,
      status: 'info',
      hasCloseButton: false,
      autoClose: 10000,
    }).subscribe();

    document$.subscribe({
      next: (blobResponse) => {
        const blob = blobResponse.body;
        downloadBlob(blob, name, 'pdf');

        loadingToastr.unsubscribe();
        this.alertService.open(notifications.success.message, {
          label: notifications.success.title,
          status: 'success',
        }).subscribe();
      },
      error: () => {
        loadingToastr.unsubscribe();
        this.alertService.open(notifications.error.message, {
          label: notifications.error.title,
          status: 'error',
        }).subscribe();
      },
    });
  }

}
