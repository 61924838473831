<pxc-back-navigation-button 
  class="block max-lg:pt-2 pb-4" 
  label="Retour à la liste" 
  defaultUrl="/dashboard/patients"
  data-testid="back-button"
></pxc-back-navigation-button>

<ng-container *ngIf="isPatientStateLoaded$ | async; else loader">
  <ng-container *ngIf="patient$ | async as selectedPatient; else emptyState">

    <div class="flex max-md:flex-col md:items-center md:justify-between gap-5 mb-2 animate-in fade-in">
      <div class="flex items-center gap-5" data-testid="patient-data">
        <pxc-patient-avatar
          [patient]="selectedPatient"
          [size]="Size.LARGE"
        ></pxc-patient-avatar>
        <pxc-patient-data
          [patient]="selectedPatient"
          data-testid="patient-details"
          size="large"
        >
          <div *ngIf="selectedPatient.isArchived">
            <pxc-archived-badge></pxc-archived-badge>
          </div>
         
          <pxc-patient-link-quick-action *showIfClientIsGamEnabled="selectedPatient"></pxc-patient-link-quick-action>
        </pxc-patient-data>
      </div>
      <pxc-action-buttons 
        [entity]="selectedPatient" 
        [actions]="patientActions" 
        [actionButtons]="actionButtonsGroups" 
        data-testid="actions"
        optionButtonAppearance="outline"
      ></pxc-action-buttons>
    </div>
    
    <div *ngIf="selectedPatient.isArchived">
      <pxc-archived-notification 
        title="Ce patient est archivé"
        description="Vous ne pouvez plus modifier ou ajouter de données à ce patient.">
      </pxc-archived-notification>
    </div>

    <nav tuiTabs class="mb-2 animate-in fade-in" data-testid="tabs">
    
      <button 
        *ngFor="let tab of tabs"
        tuiTab
        [routerLink]="tab.link"
        [routerLinkActive]="['active']"
        [queryParams]="tab.queryParams"
        queryParamsHandling="merge"
        [attr.data-testid]="tab.link"
      >
        <tui-svg [src]="tab.icon" class="mr-1.5"></tui-svg>
        {{tab.title}}
      </button>
    
    </nav>
    
    <div class="animate-in fade-in">
      <router-outlet></router-outlet>  
    </div>
  
  </ng-container>
</ng-container>

<ng-template #emptyState>
  <div class="flex flex-col items-center justify-center gap-4">
    <pxc-lottie-label animation="patientEmpty" message="Oups !"></pxc-lottie-label>
    <p>Désolé, nous n'avons pas trouvé le patient que vous cherchiez ...</p>
    <a tuiButton routerLink="/dashboard/patients" queryParamsHandling="merge" >Retour à la liste des patients</a>
  </div>
</ng-template>

<ng-template #loader>
  <pxc-loader data-testid="loading">Chargement du patient ...</pxc-loader>
</ng-template>