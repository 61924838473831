<div class="flex max-md:flex-col max-md max-md:divide-y md:divide-x divide-slate-200">

  <div class="flex flex-col gap-2 flex-1 max-md:pb-3" [class.pr-3]="displayPatientsList">

    <ng-container *ngIf="patientEditForm?.errors | keyvalue as errors; else gamDetails">
      <tui-notification 
        icon="tuiIconAlertTriangleLarge" 
        status="error" 
        data-testid="error-alert"
      >
        {{errors[0].value}}
      </tui-notification >
    </ng-container>
  
    <ng-template #gamDetails>
      <ng-container *ngIf="(selectIsGamEnabled$ | async) === true">
          <ng-container *ngIf="patientEditForm.value.isGamLinked; then linkedPatientIcon; else unlinkedPatientIcon"></ng-container>
          <ng-template #linkedPatientIcon>
            <tui-notification icon="tuiIconLinkLarge" status="success" data-testid="gam-linked-alert">Le patient est rattaché à votre établissement.</tui-notification>
          </ng-template>
          <ng-template #unlinkedPatientIcon>
            <tui-notification *ngIf="!isPatientAnonymous" icon="tuiIconUnlinkLarge" status="warning" data-testid="gam-unlinked-alert">Le patient n'est pas rattaché à votre établissement.</tui-notification>
          </ng-template>
      </ng-container>
    </ng-template>
  
    <pxc-patient-edit-form [form]="patientEditForm" data-testid="patient-edit-form"></pxc-patient-edit-form>
  
  </div>

  <pxc-patient-search-list
    class="md:w-80"
    *ngIf="displayPatientsList"
    [patients]="searchedPatients$ | async"
    (selectPatient)="selectPatient($event)"
    data-testid="patient-searched-list"
  ></pxc-patient-search-list>
</div>

<div class="flex gap-2 justify-between mt-4 flex-wrap" *ngIf="displayActionButtons">
  <button tuiButton appearance="outline" type="button" (click)="closeEdition()" data-testid="patient-cancel" class="max-sm:w-full">Annuler</button>
  <button tuiButton type="button"
    *tuiLet="isCheckingFormValidity$ | async as isCheckingFormValidity"
    [disabled]='!patientEditForm.valid || isCheckingFormValidity'
    [showLoader]="isCheckingFormValidity"
    (click)="save()" data-testid="patient-confirm" class="max-sm:w-full max-sm:order-first"
  >Enregistrer</button>
</div>