import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SequencePickerComponent } from '@modules/sequence/sequence-picker/sequence-picker.component';
import { SequenceReportComponent } from '@modules/sequence/sequence-report/sequence-report.component';
import { Store } from '@ngrx/store';
import { Telemonitoring, Patient } from '@pixacare/pxc-ts-core';
import { AuthorizationsService } from '@services/authorizations.service';
import { DialogService } from '@services/dialog.service';
import { DocumentService } from '@services/document.service';
import { TelemonitoringHttpService } from '@services/http/telemonitoring.http.service';
import { PatientTab } from '@shared/models/enums/patient-tabs.enum';
import { SequenceContext } from '@shared/models/enums/sequence-context.enum';
import { Status } from '@shared/models/enums/status.enum';
import { FilteredSequenceSearch } from '@shared/models/filters/filtered-sequence-search';
import { ActionsService } from '@shared/models/helpers/actions-service';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { MenuActionId } from '@shared/models/menu-actions/menu-action-id.enum';
import { FilteredSequenceReportConfig } from '@shared/models/report-config';
import { selectIsPatientActive } from '@shared/store/patients/patients.selectors';
import { telemonitoringsActions } from '@shared/store/telemonitorings/telemonitorings.actions';
import { selectTelemonitoring } from '@shared/store/telemonitorings/telemonitorings.selectors';
import { Observable, first } from 'rxjs';
import {
  TelemonitoringSendTutorialComponent,
} from './telemonitoring-send-tutorial/telemonitoring-send-tutorial.component';
import { isEverythingTrue$ } from '@shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class TelemonitoringActionsService extends ActionsService {

  actions: MenuAction<Telemonitoring>[] = [
    {
      id: MenuActionId.TELEMONITORING_TUTORIAL,
      label: 'Tutoriel',
      icon: 'tuiIconFile',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => this.isPatientActive$(telemonitoring),
      execute: (telemonitoring: Telemonitoring): void => {
        this.documentService.downloadDocument({
          name: telemonitoring.name || 'Tutoriel de télésuivi',
          document$: this.telemonitoringService.getTutorial(+telemonitoring.id),
        });
      },
    },
    {
      id: MenuActionId.TELEMONITORING_SEND_TUTORIAL,
      label: 'Envoyer Tutoriel',
      icon: 'tuiIconMail',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive$(telemonitoring),
        this.isUserOwner(telemonitoring),
      ),
      execute: (telemonitoring: Telemonitoring): void => {
        this.dialogService.openComponent(TelemonitoringSendTutorialComponent, {
          label: 'Envoyer le tutoriel',
          data: {
            telemonitoring,
          },
        }).subscribe();
      },
    },
    {
      id: MenuActionId.TELEMONITORING_REPORT,
      label: 'Rapport',
      icon: 'tuiIconFileText',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => this.isPatientActive$(telemonitoring),
      execute: (telemonitoring: Telemonitoring): void => {

        this.dialogService.openComponentWithCloseConfirmation
        <SequenceReportComponent, FilteredSequenceReportConfig>(SequenceReportComponent, {
          label: 'Sélectionner les séquences à exporter',
          data: {
            patientId: telemonitoring.patientId,
            clientCode: telemonitoring.clientCode,
            telemonitoringId: telemonitoring.id,
          },
          size: 'l',
        }).subscribe((reportConfig) => {

          if (reportConfig) {
            this.documentService.downloadDocument({
              name: telemonitoring.name || 'Rapport de télésuivi',
              document$:
                this.telemonitoringService.exportTelemonitoring(+telemonitoring.id, reportConfig.sequenceIds, {
                  includeCharts: reportConfig.includeCharts,
                  includeReports: reportConfig.includeReports,
                }),
            });
          }

        });
      },
    },
    {
      id: MenuActionId.TELEMONITORING_LINK_SEQUENCES,
      label: 'Lier des séquences',
      icon: 'tuiIconLink',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive$(telemonitoring),
        this.isTelemonitoringActive(telemonitoring),
      ),
      execute: (telemonitoring: Telemonitoring): void => {

        this.store.select(selectTelemonitoring(telemonitoring.id))
          .pipe(first((tm) => !!tm.sequences))
          .subscribe((tm) => {

            const alreadySelectedSequenceIds = tm.sequences.map((sequence) => sequence.sequenceInstance.id);

            this.dialogService.openComponentWithCloseConfirmation
            <SequencePickerComponent, FilteredSequenceSearch>(SequencePickerComponent, {
              data: {
                patientId: telemonitoring.patientId,
                clientCode: telemonitoring.clientCode,
                alreadySelectedSequenceIds,
                disableSelectedSequences: true,
                sequenceContext: SequenceContext.TELEMONITORING,
              },
              label: 'Sélectionnez les séquences à lier au télésuivi',
              size: 'l',
            }).subscribe((filteredSequenceSearch) => {

              if (filteredSequenceSearch) {
                const filteredSequenceIds = filteredSequenceSearch.sequenceIds?.filter(
                  (id) => !alreadySelectedSequenceIds.includes(id),
                );

                if (filteredSequenceIds.length > 0) {
                  this.store.dispatch(telemonitoringsActions.linkSequences({
                    telemonitoringId: +telemonitoring.id,
                    clientCode: telemonitoring.clientCode,
                    sequenceIds: filteredSequenceIds,
                  }));
                }
              }

            });

          });

      },
    },
    {
      id: MenuActionId.TELEMONITORING_ARCHIVE,
      label: 'Archiver',
      icon: 'tuiIconArchive',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive$(telemonitoring),
        this.isTelemonitoringActive(telemonitoring),
        this.isUserOwner(telemonitoring),
      ),
      execute: (telemonitoring: Telemonitoring): void => {
        this.store.dispatch(telemonitoringsActions.updateTelemonitoringActiveState({
          telemonitoringId: telemonitoring.id,
          isActive: false,
        }));
      },
    },
    {
      id: MenuActionId.TELEMONITORING_ENABLE,
      label: 'Activer',
      icon: 'tuiIconArchive',
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive$(telemonitoring),
        this.isTelemonitoringArchived(telemonitoring),
        this.isUserOwner(telemonitoring),
      ),
      execute: (telemonitoring: Telemonitoring): void => {
        this.store.dispatch(telemonitoringsActions.updateTelemonitoringActiveState({
          telemonitoringId: telemonitoring.id,
          isActive: true,
        }));
      },
    },
    {
      id: MenuActionId.TELEMONITORING_DELETE,
      label: 'Supprimer',
      icon: 'tuiIconTrash',
      status: Status.ERROR,
      condition$: (telemonitoring: Telemonitoring): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive$(telemonitoring),
        this.isTelemonitoringArchived(telemonitoring),
        this.isUserOwner(telemonitoring),
      ),
      execute: (telemonitoring: Telemonitoring): void => {
        this.dialogService.openConfirm('Êtes-vous certain de vouloir supprimer ce télésuivi ?',
          {
            content: 'Cette action est irréversible.',
            yes: 'Supprimer',
          }).subscribe((confirm) => {
          if (confirm) {
            this.store.dispatch(
              telemonitoringsActions.deleteTelemonitoring({
                telemonitoringId: +telemonitoring.id,
              }),
            );
            this.router.navigate(['/dashboard/patients', telemonitoring.patientId], {
              queryParamsHandling: 'merge',
              queryParams: { tab: PatientTab.TELEMONITORINGS },
            });
          }
        });
      },
    },
  ];

  constructor(
    public readonly store: Store,
    private readonly router: Router,
    private readonly telemonitoringService: TelemonitoringHttpService,
    private readonly documentService: DocumentService,
    private readonly authorizationsService: AuthorizationsService,
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  isPatientActive$(telemonitoring: Telemonitoring): Observable<boolean> {
    return this.store.select(selectIsPatientActive(telemonitoring.clientCode, telemonitoring.patientId));
  }

  isUserOwner(telemonitoring: Telemonitoring): boolean {
    return this.authorizationsService.getUserId() === telemonitoring.createdBy;
  }

  isTelemonitoringActive(telemonitoring: Telemonitoring): boolean {
    return telemonitoring.isActive;
  }

  isTelemonitoringArchived(telemonitoring: Telemonitoring): boolean {
    return !telemonitoring.isActive;
  }

}
