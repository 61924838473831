<div class="my-auto w-18 flex flex-col">
  <p class="flex items-center justify-center gap-1 text-current">
    <span class="text-3xl">{{day}}</span>
    <span class="text-sm leading-3">
      {{month}}<br>
      {{year}}
    </span>
  </p>
  <p *ngIf="displayTime" class="text-current text-slate-600" data-testid="time">
    {{time}}
  </p>
</div>
