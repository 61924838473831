import { HomeStatistics } from './home-statistics';

export const homeStatistics: HomeStatistics[] = [
  {
    label: 'Mes séquences créées',
    icon: 'tuiIconCameraLarge',
    value: 'sequenceCount',
    color: 'pixablue',
    link: '/dashboard/sequences',
  },
  {
    label: 'Mes photographies médicales',
    icon: 'tuiIconImageLarge',
    value: 'mediaCount',
    color: 'pixapurple',
    link: '/dashboard/sequences',
  },
  {
    label: 'Mes patients traités',
    icon: 'tuiIconUserLarge',
    value:'treatedPatientCount',
    color: 'pixared',
    link: '/dashboard/patients',
  },
];
