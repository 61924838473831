<div 
    class="grid grid-cols-5 gap-4" 
    *tuiLet="(value?.length || rejectedFiles?.length || medias?.length) as hasFiles"
>

    <pxc-image-overlay
        [showDelete]="showDelete && !disabled"
        *ngFor="let media of medias; let idx = index"
        (delete)="removeMedia(media)"
        [attr.data-testid]="'media-'+media.id"
    >
        <pxc-sequence-media
            [src]="media.thumbnailUri"
            [sequenceId]="media.sequenceId"
            [clientCode]="media.clientCode"
            [mediaId]="media.id"
            (click)="mediaClickedIdx.emit(idx)"
            class="cursor-zoom-in"
            [attr.data-testid]="'seq-media-'+media.id"
        ></pxc-sequence-media>
        <img [src]="media.thumbnailUri" class="w-full aspect-square rounded-xl">
    </pxc-image-overlay>

    <pxc-file-image-preview
        *ngFor="let file of value"
        [file]="file"
        class="w-full"
        [showDelete]="showDelete && !disabled"
        (delete)="removeFile(file)"
        [attr.data-testid]="'file-'+file.name"
    ></pxc-file-image-preview>

    <pxc-image-overlay 
        *ngFor="let file of rejectedFiles"
        (delete)="removeRejected(file)"
        [showDelete]="showDelete && !disabled"
    >
        <div
            class="w-full aspect-square rounded-xl bg-red-50 flex flex-col justify-center items-center p-2 gap-2 select-none"
        >
            <tui-svg src="noimage" class="text-red-600"></tui-svg>
            <p class="text-slate-600 text-sm text-center line-clamp-2 max-w-full break-all">
                {{ file.name }}
            </p>
            <p class="text-xs text-slate-500 text-center">
                Ce fichier n'est pas pris en charge
            </p>
        </div>
    </pxc-image-overlay>
    
    <tui-input-files
        [accept]="accept"
        [multiple]="true"
        (ngModelChange)="onFileChange($event)"
        [ngModel]="value"
        [disabled]="disabled"
        (reject)="onReject($event)"
        [ngClass]="{
            'col-span-5': !hasFiles,
            'aspect-square w-full': hasFiles
        }"
        data-testid="image-input"
        ngDefaultControl
    >
        <ng-template let-dragged>

            <div class="flex flex-col gap-3 items-center my-2 text-sm">
                <ng-container *ngIf="dragged; else base">
                    <tui-svg
                        class="text-pixablue-1"
                        src="tuiIconSmileLarge"
                    ></tui-svg>
                    Déposez l'image ici !
                </ng-container>
            </div>

            <ng-template #base>
                <tui-svg
                    class="text-slate-600"
                    src="tuiIconPlusLarge"
                ></tui-svg>
                {{label}}
            </ng-template>

        </ng-template>
    </tui-input-files>
    
</div>