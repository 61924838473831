import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject }
  from '@angular/core';
import { Store } from '@ngrx/store';
import { Patient } from '@pixacare/pxc-ts-core';
import { Observable, switchMap } from 'rxjs';
import { selectIsGamEnabled } from 'src/app/shared/store/clients/clients.selectors';
import { selectClientCode } from 'src/app/shared/store/router/router.selectors';

@Component({
  selector: 'pxc-patient-search-list',
  templateUrl: './patient-search-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientSearchListComponent implements OnChanges {

  @Input() patients: Patient[] = null;
  @Output() selectPatient = new EventEmitter<Patient>();

  isGamEnabled$: Observable<boolean>;
  gamLinkedPatients: Patient[] = [];
  pixacarePatients: Patient[] = [];

  private readonly store = inject(Store);

  constructor() {
    this.isGamEnabled$ = this.store.select(selectClientCode).pipe(
      switchMap((clientCode) => this.store.select(selectIsGamEnabled(clientCode))),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (!changes.patients) {
      return;
    }

    if (this.patients) {
      this.gamLinkedPatients = [];
      this.pixacarePatients = [];

      for (const patient of this.patients) {
        if (patient.isGamLinked) {
          this.gamLinkedPatients.push(patient);
        } else {
          this.pixacarePatients.push(patient);
        }
      }
    }
  }

  onPatientClick(patient: Patient): void {
    this.selectPatient.emit(patient);
  }

}
