import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs';
import { FormEngineValidators } from './form-engine-validators';
import { FormEngineComputations } from './form-engine-computations';
import {
  ComputationType,
  ComputedFormSection,
  Form,
  FormAnswerValue,
  FormAnswers,
  FormComputation,
  FormQuestion,
  QuestionType,
  ValidatorType,
} from '@pixacare/pxc-ts-core';

const FORM_ENGINE_FLOAT_DECIMALS = 1;

@UntilDestroy()
@Injectable()
export class FormEngineService {

  static EXTRACT_QUESTIONS_IDS_REGEX = /#{([^}]*)}/g;
  static DYNAMIC_FLAG_REPLACE_REGEX = /#{|}/g;

  static wrapKey(key: string): string {
    return `#{${key}}`;
  }

  static wrapChoiceKey(key: string): string {
    return FormEngineService.wrapKey(`choices.${key}`);
  }

  static getChoiceKey(val: FormAnswerValue): string {
    const [target, targetKey] = val.toString().replace(FormEngineService.DYNAMIC_FLAG_REPLACE_REGEX, '').split('.');
    return targetKey;
  }

  static replaceKey(content: string, key: string, value: string): string {
    return content.replace(FormEngineService.wrapKey(key), value);
  }

  static computeValidatorValue(
    form: UntypedFormGroup,
    formComputation: FormComputation,
    subType?: ValidatorType,
  ): string {
    let computation = formComputation.computation;
    formComputation.dependencies
      .filter((depQuestion) => depQuestion.type === ComputationType.VALIDATOR && depQuestion.subType === subType)
      .forEach((depQuestion) => {
        const formValue = form.getRawValue()[depQuestion.questionId] || 0;
        computation = FormEngineService.replaceKey(computation, depQuestion.questionId, formValue);
      });

    // eslint-disable-next-line no-eval
    return eval(computation);
  }

  static computeFormValue(
    form: UntypedFormGroup,
    formComputation: FormComputation,
  ): string {
    let computation = formComputation.computation;
    let isDependencyUnfilled = false;
    formComputation.dependencies
      .filter((depQuestion) => depQuestion.type === ComputationType.VALUE)
      .forEach((depQuestion) => {
        const formValue = form.getRawValue()[depQuestion.questionId] || 0;
        if (!formValue) {
          isDependencyUnfilled = true;
          return;
        }
        computation = FormEngineService.replaceKey(computation, depQuestion.questionId, formValue);
      });

    return isDependencyUnfilled
      ? null
      // eslint-disable-next-line no-eval
      : Number(Number(eval(computation)).toFixed(FORM_ENGINE_FLOAT_DECIMALS)).toString();
  }

  computeForm(form: Form): ComputedFormSection[] {
    const sections = [];

    form.pages.forEach((page) => {
      page.sections.forEach((section) => {
        sections.push({
          ...section,
          questions: section.questions.map((questionId) => form.questions[questionId]),
        });
      });
    });

    return sections;
  }

  generateAngularForm(computedSections: ComputedFormSection[], formAnswers?: FormAnswers): UntypedFormGroup {

    const questions = computedSections.flatMap((section) => section.questions);

    const formGroup = new UntypedFormGroup(
      questions.reduce((acc, question) => ({
        ...acc,
        [question.id]: this.getQuestionFormControl(question, formAnswers?.[question.id]),
      }), {} as { [key: string]: UntypedFormControl }),
    );

    const computationEngine = new FormEngineComputations(questions);
    const formComputations = computationEngine.getFormComputations();

    Object.keys(formComputations)
      .filter((questionId) => formComputations[questionId].hooks.length > 0)
      .forEach((questionId) => {
        formGroup.get(questionId).valueChanges.pipe(
          debounceTime(300), untilDestroyed(this),
        ).subscribe(() => {
          formComputations[questionId].hooks.forEach((hook) => {
            if (hook.type === ComputationType.VALUE) {

              formGroup.get(hook.questionId).setValue(
                FormEngineService.computeFormValue(formGroup, formComputations[hook.questionId]),
              );
            } else if (hook.type === ComputationType.VALIDATOR) {

              const question = questions.find((targetQuestion) => targetQuestion.id === hook.questionId);
              if (question) {
                const validators = question.validators?.map((validator) => {
                  let value = validator.value.toString();
                  if (FormEngineService.EXTRACT_QUESTIONS_IDS_REGEX.test(value)) {
                    value = FormEngineService.computeValidatorValue(
                      formGroup,
                      formComputations[hook.questionId],
                      validator.type,
                    );
                  }
                  return FormEngineValidators.VALIDATORS_MAP[validator.type]({
                    ...validator,
                    value: value || validator.value,
                  });
                });
                if (validators.length > 0) {
                  formGroup.get(hook.questionId).setValidators(validators);
                }
              }
            }
          });
        });
      });

    return formGroup;
  }

  generateFormAnswers(formGroup: UntypedFormGroup, questions: FormQuestion[]): FormAnswers {
    return questions.reduce((acc, question) => {

      const value = this.getQuestionAnswer(formGroup, question);

      if (value == null || (Array.isArray(value) && !value.length)) {
        return acc;
      }

      return {
        ...acc,
        [question.id]: value,
      };

    }, {} as { [key: string]: FormAnswerValue });
  }

  private getQuestionAnswer(form: UntypedFormGroup, question: FormQuestion): FormAnswerValue {
    const value = form.get(question.id).value;

    if (!value && value !== 0 && !(question.type === QuestionType.BOOL && form.get(question.id).dirty)) {
      return null;
    }

    if (question.type === QuestionType.CHECKBOX) {
      return Object.keys(value)
        .filter((key) => value[key])
        .map((key) => FormEngineService.wrapChoiceKey(key));
    }

    if (question.type === QuestionType.RADIO) {
      return FormEngineService.wrapChoiceKey(value);
    }

    if (question.type === QuestionType.NUMBER) {
      return +(+value).toFixed(FORM_ENGINE_FLOAT_DECIMALS);
    }

    return value;
  }

  private getQuestionFormControl(
    question: FormQuestion, formValue: FormAnswerValue,
  ): UntypedFormControl | UntypedFormGroup {

    const validators = FormEngineValidators.getQuestionValidators(question);
    const state = {
      value: formValue ?? question.parameters.default,
      disabled: question.parameters?.readonly,
    };

    if (question.type === QuestionType.CHECKBOX) {
      return new UntypedFormGroup(
        question.choices.reduce((acc, choice) => {

          const active = (formValue as string[])?.includes(FormEngineService.wrapChoiceKey(choice.key));

          return {
            ...acc,
            [choice.key]: new UntypedFormControl({ ...state, value: active ?? false }, validators),
          };

        }, {} as { [key: string]: UntypedFormControl }),
      );
    } else if (question.type === QuestionType.RADIO) {
      state.value = formValue ? FormEngineService.getChoiceKey(formValue) : null;
    }

    return new UntypedFormControl(state, validators);
  }

}
