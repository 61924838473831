<pxc-title title="Partages" class="mb-3"></pxc-title>

<div *ngIf="areSharedSequencesFetched$ | async; else loadingBlock" class="flex flex-col gap-2">
  <pxc-filter-bar data-testid="search"></pxc-filter-bar>
  <pxc-sequence-list
    *ngIf="sharedSequences$ | async as sharedSequences"
    [sequences]="sharedSequences"
    [patientRedirect]="false"
    (loadNext)='loadNextEntities()'
    (sequenceMediaClicked)="openGallery($event)"
    data-testid="sequence-list"
  ></pxc-sequence-list>
</div>

<ng-template #loadingBlock>
  <pxc-loader data-testid="loading">Récupération de vos données en cours ...</pxc-loader>
</ng-template>