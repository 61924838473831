import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { STATISTICS_FEATURE_KEY } from './statistics.selectors';
import { UserStatistics } from '@pixacare/pxc-ts-core';

export const statisticsActions = createActionGroup({
  source: STATISTICS_FEATURE_KEY,
  events: {
    Load: emptyProps(),
    'Load success': props<{ userStatistics: UserStatistics }>(),
  },
});
