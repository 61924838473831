<tui-elastic-container>
  <pxc-patient-edit
    [(patient)]="patient"
    [clientCode]="clientCode"
    [displayActionButtons]="false"
    (formValidityUpdate)="isPatientCreationFormValid$.next($event)"
    data-testid="patient-edit"
  ></pxc-patient-edit>

  <div class="flex justify-between gap-2 mt-4">
    <button tuiButton appearance="outline" data-testid="patient-cancel" (click)="cancel()">Annuler</button>
    <button tuiButton [disabled]="(isPatientCreationFormValid$ | async) === false" data-testid="patient-confirm" (click)="createPatient()">
      Ajouter
    </button>
  </div>
</tui-elastic-container>