<ng-container *ngIf="(!!sadmEntityId || !!sadmEntityChange) && !!analysisFormAnswers; else addSequenceAnalysisSection">
  <tui-island>
    <div class="flex max-md:flex-col md:justify-between md:items-center gap-4">
      <div class="flex gap-4 items-center">
        <tui-svg src="tuiIconCheckLarge" class="text-emerald-600"></tui-svg>
        <div class="flex flex-1 flex-col">
          <p class="text-emerald-600 font-bold">Questionnaire complété</p>
          <p class="text-sm text-slate-500">
            {{ sadmEntityName$ | async | titlecase }}
          </p>
        </div>
      </div>

      <div class="flex gap-2 max-md:flex-col max-md:justify-stretch">
        <button 
          tuiButton 
          appearance="secondary" 
          iconRight="tuiIconEdit" 
          size="s"
          data-testid="analysis-edit-button"
          (click)="editAnalysis()"
        >
          Éditer
        </button>
        <button 
          tuiButton 
          appearance="secondary-destructive" 
          iconRight="tuiIconTrash" 
          size="s"
          (click)="deleteAnalysis()" 
          data-testid="analysis-delete-button"
        >
          Supprimer
        </button>
      </div>
    </div>
  </tui-island>
  
</ng-container>

<ng-template #addSequenceAnalysisSection>
  <div class="flex flex-col gap-2" *tuiLet="sadmEntityName$ | async as sadmEntityName">

    <div *ngIf="!!sadmEntityId && sadmEntityName" class="flex gap-4 md:items-center md:justify-between max-md:flex-col">
      <p class="text-sm text-slate-600">Lésion sélectionnée : 
        <a 
          class="font-bold text-blue-800 transition" 
          (click)="changeSadmEntityId()"
          [ngClass]="{'cursor-pointer hover:text-blue-600': !preventSadmEntityIdChange}"
          data-testid="selected-sadm-entity"
        >
          {{sadmEntityName | titlecase}}
          <tui-svg src="tuiIconEdit3" *ngIf="!preventSadmEntityIdChange"></tui-svg>
        </a>
      </p>
    </div>

    <pxc-create-button 
      label="Ajouter un questionnaire" 
      icon="addForm" 
      (click)="addAnalysis()"
      data-testid="analysis-create-button"
      appearance="secondary"
    ></pxc-create-button>
  </div>
</ng-template>