import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '@pixacare/pxc-ts-core';
import { LogoutStrategyService } from 'src/app/services/logout-strategy.service';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { ActionsService } from 'src/app/shared/models/helpers/actions-service';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { MenuActionId } from 'src/app/shared/models/menu-actions/menu-action-id.enum';

@Injectable()
export class UserActionsService extends ActionsService {

  actions: MenuAction<User>[] = [
    {
      id: MenuActionId.USER_PROFILE,
      label: 'Mon profil',
      icon: 'tuiIconUser',
      execute: (): void => {
        this.router.navigate(['/dashboard/profile/'], {
          queryParamsHandling: 'merge',
        });
      },
    },
    {
      id: MenuActionId.USER_LOGOUT,
      label: 'Déconnexion',
      status: Status.ERROR,
      icon: 'tuiIconLogOut',
      execute: (): void => {
        this.logoutStrategyService.logout();
      },
    },
  ];

  constructor(
    private readonly router: Router,
    private readonly logoutStrategyService: LogoutStrategyService,
  ) {
    super();
  }

}
