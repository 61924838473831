<div class="flex flex-col gap-4">
  <div>
    <pxc-select-label 
      [(selectedLabels)]="labelsToCreate" 
      [autocomplete]="false"
      data-testid="input"
    ></pxc-select-label>
    <p class="text-sm text-slate-600 mt-2">Appuyer sur entrée pour valider l'ajout du mot clé.</p>
  </div>
  <div class="flex justify-between gap-2">
    <button tuiButton appearance="outline" (click)="cancel()" data-testid="cancel-button">Annuler</button>
    <button tuiButton (click)="confirm()" [disabled]="labelsToCreate.length === 0" data-testid="add-button">Ajouter</button>
  </div>
</div>