import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { base64toBlob } from 'src/app/shared/utils/utils';
import { MediaHttpService } from 'src/app/services/http/media.http.service';
import { Media, PictureAnnotations } from '@pixacare/pxc-ts-core';

@Injectable({
  providedIn: 'root',
})
export class StudioService {

  constructor(
    private readonly mediaService: MediaHttpService,
    private readonly store: Store,
  ) {}

  savePicture(
    imageData: Media,
    pictureAnnotations: PictureAnnotations,
    picture: string,
    duplicate?: boolean,
  ): Promise<Media> {
    return new Promise<Media>((resolve, reject) => {

      this.mediaService.annotateMedia(
        imageData.clientCode,
        imageData.id,
        imageData.filename,
        pictureAnnotations,
        base64toBlob(picture),
        duplicate,
      )
        .subscribe({
          next:  (response) => {
            this.store.dispatch(
              sequencesActions.addSequenceMedia({
                clientCode: imageData.clientCode,
                sequenceId: response.sequenceId,
                mediaId: response.id,
              }));
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  getPicture(imageData: Media): Promise<Media> {
    return new Promise((resolve, reject) => {
      this.mediaService.getMedia(imageData.clientCode, imageData.id, {
        original: true,
        fillFullSizeUri: true,
        annotations: true,
      })
        .subscribe({
          next: (response) => {

            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
        });
    });

  }

  getLineSnappedPositions(x1: number, y1: number, x2: number, y2: number): number[] {
    let points: number[];

    const xLength = x2 - x1;
    const yLength = y2 - y1;
    const angle = (Math.atan2(xLength, yLength) * 180) / Math.PI;

    if ((angle < 30 && angle > -30) || ((angle > 150 && angle < 180) || (angle < -150 && angle > -180))) {
      // BOTTOM || TOP
      points = [x1, y1, x1, y1 + yLength];
    } else if ((angle > -120 && angle < -60) || (angle > 60 && angle < 120)) {
      // LEFT || RIGHT
      points = [x1, y1, x1 + xLength, y1];
    } else {

      const distance = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);

      const xPositionOffset = distance * Math.cos(Math.PI / 4);
      const yPositionOffset = distance * Math.sin(Math.PI / 4);

      if (angle > 30 && angle < 60) {
      // BOTTOM RIGHT
        points = [x1, y1, x1 + xPositionOffset, y1 + yPositionOffset];
      } else if (angle > 120 && angle < 150) {
      // TOP RIGHT
        points = [x1, y1, x1 + xPositionOffset, y1 - yPositionOffset];
      } else if (angle < -120 && angle > -150) {
      // TOP LEFT
        points = [x1, y1, x1 - xPositionOffset, y1 - yPositionOffset];
      } else if (angle < -30 && angle > -60) {
      // BOTTOM LEFT
        points = [x1, y1, x1 - xPositionOffset, y1 + yPositionOffset];
      }

    }

    return points;
  }

}
