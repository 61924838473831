<div class="mb-3">
    <pxc-filter-bar 
        searchPlaceholder="Rechercher un utilisateur par nom, prénom ou email ..."
        data-testid="filter-bar"
    ></pxc-filter-bar>
</div>
<pxc-users-administration-table
    *ngIf="(searchedAdministrationUsers$ | async) as searchedAdministrationUsers; else loadingBlock"
    [administrationUsers]="searchedAdministrationUsers"
    [actionsByUserState]="departmentUsersActions"
    (adminChanged)="adminChange($event)"
>
    <pxc-administration-table-header
        *ngIf="(department$ | async) as department"
        slot="header"
        title="Membres de {{department.name}}"
        counterLabel="{{(administrationUsers$ | async).length}} membres"
        [userCountByAdminState]="(administrationUsers$ | async) | CountByKey: 'state'"
        [actions]="tableHeaderActions"
        [actionButtonsGroups]="tableHeaderActionButtons"
    ></pxc-administration-table-header>
</pxc-users-administration-table>

<ng-template #loadingBlock>
    <pxc-loader data-testid="loading" class="my-5">
        Chargement des utilisateurs ...
    </pxc-loader>
</ng-template>