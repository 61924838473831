import {
  ChangeDetectionStrategy,
  Component, Inject, OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { TuiButtonModule, TuiDialogContext, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  first,
} from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DocumentService } from 'src/app/services/document.service';
import { ClientUser, FlattenedSequence, Patient, Telemonitoring, TelemonitoringUser } from '@pixacare/pxc-ts-core';
import { OperationState } from 'src/app/shared/models/enums/operation.enum';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { TelemonitoringMode } from 'src/app/shared/models/enums/telemonitoring.enum';
import { telemonitoringsActions } from 'src/app/shared/store/telemonitorings/telemonitorings.actions';
import { getRandomValue } from 'src/app/shared/utils/utils';
import { TelemonitoringService } from '../telemonitoring.service';
import { TelemonitoringHttpService } from 'src/app/services/http/telemonitoring.http.service';
import { TelemonitoringSendTutorialComponent }
  from '../telemonitoring-send-tutorial/telemonitoring-send-tutorial.component';
import { departmentsPluralMapping } from 'src/app/shared/utils/plural-mappings';
import { UserSequenceGalleryService } from '../../sequence/services/user-sequence-gallery.service';
import { MediaClickedOutput } from 'src/app/shared/models/media-clicked-output';
import { TelemonitoringRequestStep } from 'src/app/shared/models/enums/telemonitoring-request-step.enum';
import { CommonModule } from '@angular/common';
import {
  TuiCheckboxLabeledModule,
  TuiElasticContainerModule,
  TuiInputModule,
  TuiStepperModule,
  TuiTextareaModule,
} from '@taiga-ui/kit';
import { PatientModule } from '../../patient/patient.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SequencePickerPreviewComponent }
  from '../../sequence/sequence-picker-preview/sequence-picker-preview.component';
import { SharedModule } from '../../shared/shared.module';
import { CodeInputModule } from 'angular-code-input';
import { SequenceContext } from 'src/app/shared/models/enums/sequence-context.enum';
import { FilteredSequenceSearch } from 'src/app/shared/models/filters/filtered-sequence-search';
import { SequencePickerComponent } from '../../sequence/sequence-picker/sequence-picker.component';
import { StatusComponent } from '../../shared/status/status.component';
import { DepartmentPickerComponent } from '../../department/department-picker/department-picker.component';

@Component({
  templateUrl: './telemonitoring-request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    UserSequenceGalleryService,
  ],
  standalone: true,
  imports: [
    CommonModule,
    TuiStepperModule,
    TuiElasticContainerModule,
    PatientModule,
    TuiInputModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    FormsModule,
    ReactiveFormsModule,
    TuiCheckboxLabeledModule,
    SequencePickerPreviewComponent,
    SharedModule,
    CodeInputModule,
    TuiButtonModule,
    StatusComponent,
    DepartmentPickerComponent,
  ],
})
export class TelemonitoringRequestComponent implements OnInit {

  activePageIndex = TelemonitoringRequestStep.DETAILS;
  isLoading = false;

  patient: Patient = this.context.data.patient;
  clientCode: string = this.context.data.clientCode;
  departmentIds: number[] = this.context.data.departmentIds ?? [];
  sequences: FlattenedSequence[] = this.context.data.sequences || [];

  TelemonitoringMode = TelemonitoringMode;
  selectedMode: TelemonitoringMode = null;
  createdTelemonitoring: Telemonitoring = null;

  password: string = null;
  name: string = null;
  defaultName: string = null;
  message: string = null;
  notifyOnNewData = false;

  isPasswordValid = false;

  telemonitoringOperationState$ = new BehaviorSubject<OperationState>(OperationState.ONGOING);

  selectSequencesEventSubscription$: Subscription = null;

  telemonitoringCodeLength = 6;

  departmentsMemberships$: Observable<ClientUser[]> = null;
  telemonitoringUsers: TelemonitoringUser[] = null;

  Status = Status;
  TelemonitoringRequestStep = TelemonitoringRequestStep;
  departmentsPluralMapping = departmentsPluralMapping;

  constructor(
    private readonly telemonitoringService: TelemonitoringHttpService,
    private readonly telemonitoringHelperService: TelemonitoringService,
    private readonly authenticationService: AuthenticationService,
    private readonly store: Store,
    private readonly documentService: DocumentService,
    private readonly dialogService: DialogService,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<void, Partial<TelemonitoringRequestComponent>>,
    private readonly galleryService: UserSequenceGalleryService,
  ) { }

  ngOnInit(): void {
    this.defaultName = this.telemonitoringHelperService.getTelemonitoringDefaultName(this.patient);
  }

  pickMonitoringMode(mode: TelemonitoringMode): void {
    this.selectedMode = mode;
    if (this.selectedMode === TelemonitoringMode.PROTECTED) {
      this.password = Math.floor(100000 + getRandomValue() * 900000).toString();
      this.isPasswordValid = true;
    } else {
      this.createMonitoring();
    }
  }

  onPasswordOptChange(password: string): void {
    this.password = password;
    this.isPasswordValid = this.password.length === this.telemonitoringCodeLength;
  }

  resetPickModeStep(): void {
    this.selectedMode = null;
    this.password = null;
  }

  createMonitoring(): void {

    if (this.notifyOnNewData) {
      this.telemonitoringUsers = [{
        id: this.authenticationService.currentUser.id,
        isNotified: true,
      }];
    }

    const sequencesIds = this.getSequenceIds();

    this.telemonitoringService.createTelemonitoring(
      this.patient.id,
      this.clientCode,
      this.name,
      this.password,
      this.message,
      sequencesIds,
      this.telemonitoringUsers,
      this.departmentIds,
    ).subscribe({
      next: (tm) => {
        this.telemonitoringOperationState$.next(OperationState.FINISHED);
        this.createdTelemonitoring = tm.body;
        this.store.dispatch(telemonitoringsActions.addTelemonitoring({
          telemonitoring: this.createdTelemonitoring,
          sequencesIds,
        }));
        this.downloadReport();
      },
      error: (error) => {
        console.error('An error occured while creating a telemonitoring', error);
        this.telemonitoringOperationState$.next(OperationState.FAILED);
      },
    });

    this.isLoading = true;
  }

  previousPage(): void {
    if (this.activePageIndex === 0) {
      this.context.completeWith();
    } else {
      this.activePageIndex--;
    }
  }

  nextPage(): void {
    this.activePageIndex++;
  }

  downloadReport(): void {

    this.documentService.downloadDocument({
      name: 'Tutoriel',
      document$: this.telemonitoringService.getTutorial(+this.createdTelemonitoring.id),
      notifications: {
        loading: {
          message: 'Le tutoriel sera automatiquement téléchargé.',
          title: 'Téléchargement du tutoriel en cours',
        },
        success: {
          message:'Le tutoriel a bien été téléchargé.',
          title: 'Téléchargement du tutoriel réussi',
        },
        error: {
          message: 'Le tutoriel n\'a pas été téléchargé.',
          title: 'Echec du téléchargement du tutoriel, merci de réessayer plus tard.',
        },
      },
    });

  }

  sendTutorial(): void {
    this.context.completeWith();
    this.dialogService.openComponent(TelemonitoringSendTutorialComponent, {
      label: 'Envoyer le tutoriel',
      data: {
        telemonitoring: this.createdTelemonitoring,
      },
    }).subscribe();
  }

  closeModal(): void {
    this.context.completeWith();
  }

  getSequenceIds(): number[] {
    return this.sequences.map((s) => s.sequenceInstance.id);
  }

  openSequencePicker(): void {
    this.dialogService.openComponentWithCloseConfirmation
    <SequencePickerComponent, FilteredSequenceSearch>(SequencePickerComponent, {
      data: {
        patientId: this.patient.id,
        clientCode: this.clientCode,
        departmentIds: this.departmentIds,
        alreadySelectedSequenceIds: this.getSequenceIds(),
        sequenceContext: SequenceContext.TELEMONITORING,
      },
      label: 'Sélectionnez les séquences à exporter',
      size: 'l',
    }).pipe(first()).subscribe((filteredSequenceSearch) => {
      if (filteredSequenceSearch) {
        this.sequences = filteredSequenceSearch.sequences;
      }
    });
  }

  mediaClicked({ clientCode, mediaIdx, sequenceId }: MediaClickedOutput): void {
    this.galleryService.openGallery(clientCode, sequenceId, mediaIdx);
  }

}
