import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { combineLatest, debounceTime, distinctUntilChanged, first, map, Observable }
  from 'rxjs';
import { FilterType } from 'src/app/shared/models/filters/filter-type.enum';
import { FlattenedFilterTemplate } from 'src/app/shared/models/filters/flattened-filter-template';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { FilterBarService } from '../filter-bar.service';
import { TuiSizeL, TuiSizeS } from '@taiga-ui/core';
import { CommonModule } from '@angular/common';
import { TuiButtonModule, TuiHostedDropdownModule, TuiSvgModule } from '@taiga-ui/core';
import { TuiIslandModule } from '@taiga-ui/kit';
import { FilterDateRangeComponent } from '../filter-types/filter-date-range/filter-date-range.component';
import { FilterSelectorComponent } from '../filter-types/filter-selector/filter-selector.component';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TuiLetModule } from '@taiga-ui/cdk';

@UntilDestroy()
@Component({
  selector: 'pxc-filter-bar',
  templateUrl: './filter-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiSvgModule,
    TuiIslandModule,
    FilterDateRangeComponent,
    FilterSelectorComponent,
    TuiButtonModule,
    TuiHostedDropdownModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TuiLetModule,
  ],
  styles: [`
    :host {
      display: flex;
      width: 100%;
    }
  `],
})
export class FilterBarComponent implements OnInit {

  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  @Input() actions: MenuAction<void>[] = [];
  @Input() searchPlaceholder = 'Rechercher ...';
  @Input() size: TuiSizeS | TuiSizeL = 'l';

  FilterType = FilterType;

  templates$: Observable<FlattenedFilterTemplate[]>;
  search$: Observable<string>;
  isClearable$: Observable<boolean>;

  constructor(
    private readonly filterBarService: FilterBarService,
  ) {}

  ngOnInit() {

    this.templates$ = this.filterBarService.templates$.pipe(
      map((templates) => templates.filter((template) => template.type !== FilterType.TABS)),
    );
    this.search$ = this.filterBarService.search$;

    this.isClearable$ = combineLatest([
      this.templates$,
      this.search$,
    ]).pipe(
      map(([templates, search]) =>
        (search !== '' && search !== null)
        || templates.some((template) =>
          template.type !== FilterType.TABS
            && template.filters.length > 0,
        ),
      ),
    );
  }

  clear(): void {
    this.filterBarService.clear();
  }

  updateTemplate(template: FlattenedFilterTemplate): void {
    this.filterBarService.updateTemplate(template);
  }

  updateSearch(value: string): void {
    this.search$.pipe(
      first(),
      debounceTime(400),
      map((search) => search?.trim()),
      distinctUntilChanged(),
    ).subscribe(() => this.filterBarService.updateSearch(value));
  }

  trackByTemplate(index: number, template: FlattenedFilterTemplate): string {
    return `${template.type}-${template.property}`;
  }

  focusSearch(): void {
    this.searchInput.nativeElement.focus();
  }

}
