import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {

  private history: string[] = [];

  constructor(
    private readonly router: Router,
    private readonly location: Location,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history[0] = this.history[1];
        this.history[1] = event.urlAfterRedirects;
      }
    });
  }

  back(defaultRoute = '/'): void {
    this.history.pop();
    if (this.hasPreviousPage()) {
      this.location.back();
    } else {
      this.router.navigate([defaultRoute], {
        queryParamsHandling: 'merge',
      });
    }
  }

  hasPreviousPage(): boolean {
    return this.history[0] !== undefined;
  }

}
