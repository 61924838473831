<pxc-receiver-list
    *ngIf="selectedChannels.length > 0 || selectedUsers.length > 0 || selectedMailAddresses.length > 0"
    [channels]="selectedChannels"
    [users]="selectedUsers"
    [mailAddresses]="selectedMailAddresses"
    (removeChannel)="removeChannel($event)"
    (removeUser)="removeUser($event)"
    (removeMailAddress)="removeMailAddress($event)"
></pxc-receiver-list>

<tui-input class="mt-3" [formControl]="searchInput" (keydown.enter)="submit()" tuiTextfieldIconLeft="tuiIconSearch">
    Cherchez par nom, conversation ou ajoutez un email
</tui-input>

<div 
    class="w-full flex flex-col min-h-52 max-h-[50vh] my-3"
    *tuiLet="({ channels: (channels$ | async), users: (users$ | async) } ) as data"
>
        <tui-scrollbar *ngIf="data.channels || data.users; else loadingBlock" class="flex-1 max-h-[53vh]">
            <div *ngIf="data.channels?.length > 0 || data.users?.length > 0; else emptySearchBlock" class="flex flex-col gap-3 relative">
                <ng-container *ngIf="data.channels?.length > 0">
                    <pxc-title
                        class="sticky top-0 bg-white lg:bg-slate-50 z-10"
                        title="Conversations"
                        titleSize="small"
                    ></pxc-title>
                    <div class="flex flex-col">
                        <pxc-channel-preview 
                            *ngFor="let channel of data.channels"
                            class="cursor-pointer transition hover:bg-slate-100 px-4 py-2 z-0"
                            [channel]="channel"
                            [showDetails]="false"
                            (click)="selectChannel(channel)"
                        ></pxc-channel-preview>
                    </div>
                </ng-container>
    
                <ng-container *ngIf="data.users?.length > 0">
                    <pxc-title
                        class="sticky top-0 bg-white lg:bg-slate-50 z-10"
                        title="Utilisateurs"
                        titleSize="small"
                    ></pxc-title>
                    <div class="flex flex-col">
                        <pxc-user-card 
                            *ngFor="let user of data.users"
                            class="cursor-pointer transition hover:bg-slate-100 px-4 py-2"
                            [user]="user"
                            (click)="selectUser(user)"
                        ></pxc-user-card>
                    </div>
                </ng-container>
            </div>
        </tui-scrollbar>
        
        <ng-template #emptySearchBlock>
            <pxc-status
                [state]="Status.BASIC"
                [icon]="searchInput.value.includes('@') ? 'tuiIconAtSign' : searchInput.value ? 'tuiIconSearch' : 'tuiIconMehLarge'"
                [showTitle]="false"
                [description]="
                    searchInput.value.includes('@') ? 'Entrez un email et appuyez sur Entrée pour ajouter un utilisateur.' :
                    searchInput.value 
                    ? 'Aucune conversation ou utilisateur ne correspond à votre recherche.' 
                    : 'Vous n\'avez pas encore de conversations ! Cherchez un utilisateur ou entrez un email pour démarrer.'
                "
            ></pxc-status>
        </ng-template>
</div>

<ng-template #loadingBlock>
    <pxc-loader size="tiny"></pxc-loader>
</ng-template>
