import { DataConsumptionState } from './data-consumption-state';
import { StorageSpace } from './storage-space.enum';

export const dataConsumptionStateConfig: { [key in StorageSpace]: DataConsumptionState } = {
  [StorageSpace.AVAILABLE]: {
    icon: 'tuiIconUploadCloudLarge',
    styles: {
      hintAppearance: 'onDark',
      progressClass: 'bg-blue-200',
      containerClass: 'bg-blue-100/60 shadow-blue-200/20',
    },
    label: 'Espace utilisé',
    title: (remainingStorage) => 'Il vous reste ' + remainingStorage + ' de stockage',
  },
  [StorageSpace.LOW]: {
    icon: 'tuiIconAlertTriangleLarge',
    styles: {
      hintAppearance: 'onDark',
      progressClass: 'bg-orange-200',
      containerClass: 'bg-orange-100/60 shadow-orange-200/20',
    },
    label: 'Espace utilisé',
    title: () => 'Vous arrivez bientôt à court d\'espace !',
  },
  [StorageSpace.FULL]: {
    icon: 'tuiIconAlertTriangleLarge',
    styles: {
      hintAppearance: 'error',
      progressClass: 'bg-red-200',
      containerClass: 'bg-red-100/60 shadow-red-200/20',
    },
    label: 'Espace saturé',
    title: () => 'Espace de stockage saturé !',
  },
};
