import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormSection } from '@pixacare/pxc-ts-core';
import { FormDisplayStyle } from 'src/app/shared/models/forms/form-display-style.enum';

@Component({
  selector: 'pxc-form-display',
  templateUrl: './form-display.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormDisplayComponent {

  @Input() sections: FormSection[];

  @Input() formDisplayStyle = FormDisplayStyle.Rows;

  FormDisplayStyles = FormDisplayStyle;

}
