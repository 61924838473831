<div class="flex gap-1 items-center" *tuiLet="'text-slate-500 text-sm' as subtextStyle">
  <ng-container *ngIf="patient; else anonymousPatient">
    <div class="flex flex-row items-center gap-3" data-testid="patient-data">
      <div class="flex flex-col overflow-hidden text-left">
        <div [ngClass]="customClass || 'text-pixablue-1'" [ngSwitch]="size">
          <h6 *ngSwitchCase="Size.MEDIUM" class="flex gap-2 leading-snug">
            <ng-container *ngTemplateOutlet="patientTitle"></ng-container>
          </h6>
          <h4 *ngSwitchCase="Size.LARGE" class="flex gap-3 leading-snug">
            <ng-container *ngTemplateOutlet="patientTitle"></ng-container>
          </h4>
          <p *ngSwitchDefault class="font-medium leading-snug">
            <ng-container *ngTemplateOutlet="patientTitle"></ng-container>
          </p>
        </div>
        <div>
          <p *ngIf="patient.birthName; else noBirthName" [ngClass]="customClass || subtextStyle">
            Né(e) <span [class.text-slate-600]="!customClass">{{ patient.birthName }}</span> le <ng-container *ngTemplateOutlet="birthDate"></ng-container>
          </p>
          <p *ngIf="patient.businessIdentifier" [ngClass]="customClass || subtextStyle">
            NIP : <span [class.text-slate-600]="!customClass">{{ patient.businessIdentifier }}</span>
          </p>
        </div>
        <ng-content select="[additional-data]"></ng-content>
      </div>
    </div>
  </ng-container> 

  <ng-template #noBirthName>
    <p [ngClass]="customClass || subtextStyle">
      Né(e) le <ng-container *ngTemplateOutlet="birthDate"></ng-container>
    </p>
  </ng-template>

  <ng-template #birthDate>
    <span [class.text-slate-600]="!customClass">{{ patient.birthDate | date: 'shortDate' }}</span>
  </ng-template>
  
  <ng-template #anonymousPatient>
    <h5 class="w-full inline-block"
      [ngClass]="customClass || 'text-pixablue-1'"
      data-testid="patient-data-anonymous"
    >Anonyme</h5>
  </ng-template>

  <ng-template #patientTitle>
    {{patient | patientName}}
    <ng-content></ng-content>
  </ng-template>

</div>