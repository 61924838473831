<div class="flex flex-col gap-2">

  <p>Entrez les adresses email auxquelles vous souhaitez envoyer le tutoriel de télésurveillance.</p>

  <pxc-tag-input 
    [validators]="validators" 
    [(tags)]="mailAddresses" 
    data-testid="input"
    [(search)]="search"
    disableAutocomplete="true"
  ></pxc-tag-input>

  <div class="flex gap-2 justify-between max-md:flex-col-reverse mt-2">
    <button tuiButton appearance="outline" (click)="close()" data-testid="cancel-button">
      Annuler
    </button>
    <button tuiButton (click)="sendTutorial()" data-testid="send-button" [disabled]="mailAddresses.length === 0">
      Envoyer
    </button>  
  </div>
</div>