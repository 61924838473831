import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  SequencePickerPreviewComponent,
} from '@modules/sequence/sequence-picker-preview/sequence-picker-preview.component';
import { SequenceModule } from '@modules/sequence/sequence.module';
import { UserSequenceGalleryService } from '@modules/sequence/services/user-sequence-gallery.service';
import { SharedModule } from '@modules/shared/shared.module';
import { Patient, FlattenedSequence, PaginationQuery, FilterOperator } from '@pixacare/pxc-ts-core';
import { PatientHttpService } from '@services/http/patient.http.service';
import { PatientTeleexpertiseStep } from '@shared/models/enums/patient-teleexpertise-step.enum';
import { SequenceContext } from '@shared/models/enums/sequence-context.enum';
import { MediaClickedOutput } from '@shared/models/media-clicked-output';
import {
  TuiButtonModule, TuiLabelModule, TuiTextfieldControllerModule, TuiDialogContext, TuiAlertService,
} from '@taiga-ui/core';
import { TuiStepperModule, TuiInputModule, TuiTextareaModule } from '@taiga-ui/kit';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { BehaviorSubject } from 'rxjs';
import { PatientModule } from '../patient.module';


@Component({
  selector: 'pxc-patient-teleexpertise-request',
  standalone: true,
  imports: [
    CommonModule,
    TuiStepperModule,
    TuiButtonModule,
    PatientModule,
    SequenceModule,
    SequencePickerPreviewComponent,
    TuiLabelModule,
    TuiInputModule,
    TuiTextareaModule,
    TuiTextfieldControllerModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers: [
    UserSequenceGalleryService,
  ],
  templateUrl: './patient-teleexpertise-request.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientTeleexpertiseRequestComponent {

  @Input() patient: Patient = this.context.data.patient;
  @Input() sequences: FlattenedSequence[] = this.context.data.sequences || [];
  @Input() clientCode: string = this.context.data.clientCode;

  departmentIds: number[] = this.context.data.departmentIds ?? [];
  message: string = null;
  displayStepperContent$ = new BehaviorSubject(true);

  step: PatientTeleexpertiseStep = PatientTeleexpertiseStep.INFORMATIONS;

  PatientTeleexpertiseStep = PatientTeleexpertiseStep;
  SequenceContext = SequenceContext;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<any, Partial<PatientTeleexpertiseRequestComponent>>,
    private readonly galleryService: UserSequenceGalleryService,
    private readonly patientHttpService: PatientHttpService,
    private readonly alertService: TuiAlertService,
  ) { }

  next(): void {
    if (this.step === PatientTeleexpertiseStep.INFORMATIONS) {
      this.step = PatientTeleexpertiseStep.SEQUENCES;
      return;
    }

    const query = {
      query: new PaginationQuery({
        orderBy: ['created_on|desc'],
        filter: this.sequences.length ? [{
          prop: 'id',
          op: FilterOperator.IN,
          val: this.sequences.map((sequence) => sequence.sequenceInstance.id).join(','),
        }] : [],
      }),
    };
    this.displayStepperContent$.next(false);
    this.patientHttpService.teleexpertise(this.clientCode, this.patient.id, this.message ?? '', query).subscribe({
      next: (redirectUrl: string) => {
        window.open(redirectUrl, '_blank');
        this.displayStepperContent$.next(true);
        this.context.completeWith(true);
      },
      error: (error) => {
        console.error('patient-teleexpertise-request error:', error);
        this.displayStepperContent$.next(true);
        this.alertService.open('Echec de la téléexpertise avec Omnidoc', {
          label: 'Une erreur est survenue',
          status: 'error',
        }).subscribe();
      },
    });
  }

  previous(): void {
    if (this.step === PatientTeleexpertiseStep.SEQUENCES) {
      this.step = PatientTeleexpertiseStep.INFORMATIONS;
      return;
    }
    this.context.completeWith(null);
  }

  mediaClicked({ clientCode, mediaIdx, sequenceId }: MediaClickedOutput): void {
    this.galleryService.openGallery(clientCode, sequenceId, mediaIdx);
  }

}
