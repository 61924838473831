import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { isEqual } from 'date-fns';
import { first } from 'rxjs';
import { BaseSadmEntity, FlattenedSequence, FormAnswers, Label, Patient } from '@pixacare/pxc-ts-core';
import { AutoCompleteLabelModel } from 'src/app/shared/models/helpers/auto-complete-label-model';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { selectSequence } from 'src/app/shared/store/sequences/sequences.selectors';
import { PatientEditService } from '../../patient/patient-edit/patient-edit/patient-edit.service';

interface SequenceEditContext {
  updatedPatient: Patient;
  updatedLabels: AutoCompleteLabelModel[];
  updatedDate: Date;
  updatedDescription: string;
  removedMediasId: number[];
  newMedias: File[];
  sequence?: FlattenedSequence;
  clientCode: string;
  analysisFormAnswers: FormAnswers;
  protocolCreationFormAnswers?: FormAnswers;
  sadmEntityId: number;
  sadmEntity: BaseSadmEntity;
}

@Injectable()
export class SequenceEditService {

  constructor(private readonly store: Store) {}

  isSequenceTouched(baseSequence: FlattenedSequence, {
    updatedPatient,
    updatedLabels,
    updatedDate,
    updatedDescription,
    removedMediasId,
    newMedias,
    analysisFormAnswers,
    sadmEntityId,
  }: SequenceEditContext): boolean {
    return !isEqual(updatedDate, baseSequence.sequenceInstance.createdOn)
    || this.hasLabelChanged(baseSequence, updatedLabels)
    || baseSequence.sequenceInstance.description !== updatedDescription
    || removedMediasId.length > 0
    || newMedias.length > 0
    || !PatientEditService.compare(baseSequence.patientInstance, updatedPatient)
    || baseSequence.patientInstance?.businessIdentifier !== updatedPatient?.businessIdentifier
    || baseSequence.sequenceInstance.formAnswer?.answers !== analysisFormAnswers
    || baseSequence.sequenceInstance.sadmEntityId !== sadmEntityId;
  }

  save(payload: SequenceEditContext): void {
    this.handlePatchSequence(payload);
    this.handlePatientEdition(payload);
    this.handleLabelsEdition(payload);
    this.handleMediasEdition(payload);
    this.handleAnalysisEdition(payload);
  }

  private handlePatientEdition({
    sequence, updatedPatient, clientCode,
  }: SequenceEditContext): void {
    if (!PatientEditService.compare(sequence.patientInstance, updatedPatient)
    || sequence.patientInstance?.businessIdentifier !== updatedPatient?.businessIdentifier) {
      this.store.dispatch(sequencesActions.updateSequencePatient({
        clientCode,
        sequenceId: sequence.sequenceInstance.id,
        patient: updatedPatient,
      }));
    }
  }

  private hasLabelChanged(sequence: FlattenedSequence, updatedLabels: AutoCompleteLabelModel[]): boolean {

    if (sequence.labels.length !== updatedLabels.length) {
      return true;
    }

    const labelWords: { [label: string]: boolean } = updatedLabels.reduce((acc, label) => {
      const word = typeof label.value === 'string'
        ? label.value.toUpperCase() :
        label.value.word?.toUpperCase();
      return { ...acc, [word]: true };
    }, {} as { [label: string]: boolean });

    return sequence.labels.some((label) => !labelWords[label.word]);

  }

  private handleLabelsEdition({
    sequence, updatedLabels, clientCode,
  }: SequenceEditContext): void {
    if (this.hasLabelChanged(sequence, updatedLabels)) {
      const createdLabels = updatedLabels
        .filter((updatedLabel) => !this.isLabel(updatedLabel.value))
        .map((updatedLabel) => updatedLabel.value.toString());

      const existingLabels = updatedLabels
        .filter((updatedLabel) => this.isLabel(updatedLabel.value))
        .map((updatedLabel) => (updatedLabel.value as Label).id);

      this.store.dispatch(sequencesActions.updateSequenceLabels({
        userId: sequence.sequenceInstance.createdBy,
        sequenceId: sequence.sequenceInstance.id,
        clientCode,
        existingLabels,
        createdLabels,
      }));
    }
  }

  private handleMediasEdition({
    sequence, removedMediasId, newMedias, clientCode,
  }: SequenceEditContext): void {

    // Delete medias update
    removedMediasId.forEach((mediaId) => {
      this.store.dispatch(sequencesActions.deleteSequenceMedia({
        sequenceId: sequence.sequenceInstance.id,
        mediaId,
        clientCode,
      }));
    });

    // Add medias update
    if (newMedias.length > 0) {
      this.store.dispatch(sequencesActions.attachSequenceMedias({
        clientCode,
        sequenceId: sequence.sequenceInstance.id,
        medias: newMedias,
      }));
    }
  }

  private handleAnalysisEdition({
    sequence,
    analysisFormAnswers,
    sadmEntity,
    sadmEntityId,
    protocolCreationFormAnswers,
    clientCode,
  }: SequenceEditContext): void {
    if (!!sequence.sequenceInstance.formAnswer?.answers && !analysisFormAnswers) {
      // if there was an analysis and now there is none, it was DELETED
      this.store.dispatch(sequencesActions.deleteSequenceAnalysis({
        clientCode,
        sequenceId: sequence.sequenceInstance.id,
      }));
    } else if (!sadmEntity && sequence.sequenceInstance.sadmEntityId === sadmEntityId
      && (analysisFormAnswers !== sequence.sequenceInstance.formAnswer?.answers)
    ) {
      // if the sadmEntityId didn't change but the analysis did, it was EDITED
      // the protocol can't be edited this way so we don't have to handle it
      this.store.dispatch(sequencesActions.editSequenceAnalysis({
        clientCode,
        sequenceId: sequence.sequenceInstance.id,
        answers: analysisFormAnswers,
      }));
    } else if (!sequence.sequenceInstance.formAnswer && !!analysisFormAnswers) {
      // if there was no analysis and now there is one, it was ADDED
      // protocol can't be added this way so we don't have to handle it
      this.store.dispatch(sequencesActions.addSequenceAnalysis({
        clientCode,
        sequenceId: sequence.sequenceInstance.id,
        answers: analysisFormAnswers,
        sadmEntity,
        sadmEntityId,
        ...(protocolCreationFormAnswers ? { protocolCreationFormAnswer: {
          answers: protocolCreationFormAnswers,
        } } : {}),
      }));
    } else if (!!sequence.sequenceInstance.formAnswer?.answers
      && (sequence.sequenceInstance.sadmEntityId !== sadmEntityId
      || sadmEntity)
      && analysisFormAnswers
    ) {
      // special case : if the user deleted the current analysis then added a new one
      this.store.dispatch(sequencesActions.deleteSequenceAnalysis({
        clientCode,
        sequenceId: sequence.sequenceInstance.id,
      }));

      // wait for deletion to be done before adding the new analysis
      this.store.select(selectSequence(sequence.sequenceInstance.id))
        .pipe(first((s) => !s.sequenceInstance.formAnswer))
        .subscribe(() => {
          this.store.dispatch(sequencesActions.addSequenceAnalysis({
            clientCode,
            sequenceId: sequence.sequenceInstance.id,
            answers: analysisFormAnswers,
            sadmEntity,
            sadmEntityId,
            ...(protocolCreationFormAnswers ? { protocolCreationFormAnswer: {
              answers: protocolCreationFormAnswers,
            } } : {}),
          }));
        });
    }
  }

  private handlePatchSequence({
    updatedDate, sequence, clientCode, updatedDescription,
  }: SequenceEditContext): void {
    if (!isEqual(updatedDate, sequence.sequenceInstance.createdOn)
    || updatedDescription !== sequence.sequenceInstance.description) {
      this.store.dispatch(sequencesActions.patchSequence({
        sequence: {
          ...sequence.sequenceInstance,
          createdOn: updatedDate,
          description: updatedDescription,
        },
        clientCode,
      }));
    }
  }

  private isLabel(object: Label | string): object is Label {
    return !!(object as Label).word;
  }

}
