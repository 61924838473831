<tui-hosted-dropdown
    tuiDropdownAlign="right"
    [content]="dropdownContent"
    [(open)]="open"
>
    <button tuiButton [appearance]="appearance" data-testid="button" [icon]="icon">
        <div class="flex gap-3">
            {{ actions[selectedKey].title }}
            <tui-svg
                src="tuiIconChevronDown"
                class="transition-all duration-300"
                [ngClass]="{ 'rotate-180': open }"
            ></tui-svg>
        </div>
    </button>
</tui-hosted-dropdown>

<ng-template #dropdownContent>
    <div class="p-2 flex flex-col" data-testid="dropdown">
        <div *ngFor="let actionsKeyValue of (actions | keyvalue)"
            class="flex items-center p-2 rounded-xl hover:bg-slate-100 transition-colors duration-300 cursor-pointer"
        >
            <div *tuiLet="actionsKeyValue.value as action" (click)="onClick(action)" class="flex gap-2 items-center justify-between w-full">
                <div class="flex items-center gap-3">
                    <tui-svg *ngIf="action.icon" [src]="action.icon" class="text-slate-700"></tui-svg>
                    <div class="flex flex-col">
                        <p class="whitespace-normal font-semibold">
                            {{ action.title }}
                        </p>
                        <p *ngIf="action.label" class="whitespace-normal text-slate-500">
                            {{ action.label }}
                        </p>
                    </div>
                </div>
                <tui-svg *ngIf="actionsKeyValue.key === selectedKey"
                    class="text-slate-500"
                    src="tuiIconCheck"
                ></tui-svg>
            </div>
        </div>
    </div>
</ng-template>
