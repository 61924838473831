import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Auth } from '@angular/fire/auth';
import { BehaviorSubject, Observable, lastValueFrom, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ValidationCodeActionType } from '../shared/models/enums/validation-code-action-type.enum';
import { TokenPayload } from '../shared/models/token-payload';
import { ValidationCode } from '../shared/models/validation-code';
import { decodeToken } from '../shared/utils/token-utils';
import { LocalStorageService } from './local-storage.service';
import { InvitedUser, User } from '@pixacare/pxc-ts-core';

const JWT_NAME = 'user-jwt';
const RTP_NAME = 'user-rtp';

const headerApiV2 = {
  headers: { 'X-version': '2.0' },
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  private jwt: string;
  private rtp: string;
  private user: User;

  private readonly httpClient = inject(HttpClient);
  private readonly auth = inject(Auth);
  private readonly localStorageService = inject(LocalStorageService);

  get currentUser(): User {
    return this.user;
  }

  setTokens(jwt: string, rtp: string): void {
    this.setToken(jwt);
    this.setRtpToken(rtp);
  }

  setToken(jwt: string): void {
    this.jwt = jwt;
    this.localStorageService.set(JWT_NAME, jwt ?? '');
  }

  setRtpToken(rtp: string): void {
    this.rtp = rtp;
    this.localStorageService.set(RTP_NAME, rtp ?? '');
  }

  getToken(): string {
    if (!this.jwt) {
      this.jwt = this.localStorageService.get(JWT_NAME);
    }
    return this.jwt;
  }

  getRtpToken(): string {
    if (!this.rtp) {
      this.rtp = this.localStorageService.get(RTP_NAME);
    }
    return this.rtp;
  }

  clearTokens(): void {
    this.jwt = null;
    this.rtp = null;
    this.localStorageService.remove(JWT_NAME);
    this.localStorageService.remove(RTP_NAME);
  }

  async getCurrentUser(): Promise<void> {

    const jwt = this.getToken();
    if (jwt) {
      await lastValueFrom(
        this.httpClient.get<User>(`${environment.apiBaseUrl}/user/current`, {
          headers: new HttpHeaders({ Authorization: `Bearer ${jwt}` }),
        })
          .pipe(
            tap((user) => this.user = user),
            catchError(() => of(null)),
          ),
      );
    }
  }

  sendValidationCode(validationCode: ValidationCode): Observable<ValidationCode> {
    return this.httpClient.post<ValidationCode>(`${environment.apiBaseUrl}/User/Auth/Otp`, validationCode);
  }

  logout(): void {
    this.auth.signOut();
    this.clearTokens();
    this.user = null;
  }

  resetPassword({ token, password }: {
    token: string;
    password: string;
  }): Observable<User> {
    return this.httpClient
      .post<User>(
      `${environment.apiBaseUrl}/User/ForgotPasswordValidate`,
      { token, password },
    )
      .pipe(
        map((user: User) => {
          this.setTokens(user.token, user.rtpToken);
          this.user = user;
          return this.user;
        }),
      );
  }

  login({ mailAddress, password }: {
    mailAddress: string;
    password: string;
  }): Observable<TokenPayload> {
    return this.httpClient
      .post<User>(`${environment.apiBaseUrl}/User/Authenticate`, { mailAddress, password }, headerApiV2)
      .pipe(
        map((user: User) => {
          this.setTokens(user.token, user.rtpToken);
          this.user = user;
          return decodeToken(user.token);
        }),
      );
  }

  register({ firstName, lastName, mailAddress, password, phoneNumber, medicalSpecialtyId, invitationToken }: {
    firstName: string;
    lastName: string;
    mailAddress: string;
    password: string;
    phoneNumber: string;
    medicalSpecialtyId: number;
    invitationToken: string;
  }): Observable<User> {
    return this.httpClient
      .post<User>(`${environment.apiBaseUrl}/User/SignUp`,
      {
        firstName,
        lastName,
        mailAddress,
        password,
        phoneNumber,
        medicalSpecialtyId,
        invitationToken,
      },
      headerApiV2)
      .pipe(
        map((user: User) => {
          this.setTokens(user.token, user.rtpToken);
          this.user = user;
          return this.user;
        }),
      );
  }

  validate({ actionId, actionType, validationCode }: {
    actionId: string;
    actionType: ValidationCodeActionType;
    validationCode: string; }): Observable<User> {
    return this.httpClient
      .post<User>(`${environment.apiBaseUrl}/User/Authenticate`, { actionId, actionType, validationCode }, headerApiV2)
      .pipe(
        map((user) => {
          if (user) {
            this.setTokens(user.token, user.rtpToken);
            this.user = user;
          }
          return this.user;
        }),
      );
  }


  changePassword({ oldPassword, newPassword }: {
    oldPassword: string;
    newPassword: string;
  }): Observable<User> {
    return this.httpClient
      .post<User>(`${environment.apiBaseUrl}/User/ChangePassword`, { oldPassword, newPassword })
      .pipe(
        map((user: User) => {
          this.setTokens(user.token, user.rtpToken);
          this.user = user;
          return this.user;
        }),
      );
  }

  updateCurrentUser({ firstName, lastName, mailAddress, phoneNumber, medicalSpecialtyId }: {
    firstName: string;
    lastName: string;
    mailAddress: string;
    phoneNumber: string;
    medicalSpecialtyId: number;
  }): Observable<User> {
    return this.httpClient
      .patch<User>(`${environment.apiBaseUrl}/User/Current`,
      {
        firstName,
        lastName,
        mailAddress,
        phoneNumber,
        medicalSpecialtyId,
      })
      .pipe(
        map((user: User) => {
          this.user = user;
          return this.user;
        }),
      );
  }

  getUserByInvitationToken(token: string): Observable<InvitedUser> {
    return this.httpClient.get<InvitedUser>(
      `${environment.apiBaseUrl}/User/GetUserByInvitationToken/${token}`,
    );
  }

}
