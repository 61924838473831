import { ChangeDetectionStrategy, Component, Inject, Optional } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Subject } from 'rxjs';

@UntilDestroy()
@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloseConfirmableDialogComponent<T> {

  constructor(
    @Optional() @Inject(POLYMORPHEUS_CONTEXT) private readonly dialogContext: TuiDialogContext<T, {
      shouldAskConfirmation$?: Subject<boolean>;
    }>,
  ) { }

  shouldCloseConfirmChange(shouldCloseConfirm: boolean): void {
    this.dialogContext?.data?.shouldAskConfirmation$?.next(shouldCloseConfirm);
  }

  bindShouldCloseConfirm(shouldCloseConfirm$: Subject<boolean>): void {
    shouldCloseConfirm$.pipe(untilDestroyed(this)).subscribe((shouldCloseConfirm) => {
      this.shouldCloseConfirmChange(shouldCloseConfirm);
    });
  }

}
