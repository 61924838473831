<div class="flex flex-col gap-6">

  <div class="flex flex-col gap-2 py-2">
    <h2>{{title}}</h2>
    <p class="max-w-prose">
      Afin d'accéder à l'environnement sécurisé Pixacare, merci de valider votre
      compte avec le code que nous allons envoyer par e-mail.
    </p>
  </div>
  
  <code-input
    [codeLength]="6"
    (codeCompleted)="onOtpComplete($event)"
    data-testid="code-input"
    [disabled]="!isCodeSent"
    #codeInputComponent
  ></code-input>
  
  <div class="flex justify-center items-center">
    <p 
      *ngIf="countdown$ | async as value; else resendButton"
      class="text-center max-w-prose text-slate-500"
    >
      <span class="font-bold">Vous n'avez pas reçu de code ?</span><br>
      Vous pourrez renvoyer un code par email dans  {{ value }} secondes.
    </p>
    <ng-template #resendButton>
      <button
        tuiButton
        appearance="secondary"
        (click)="sendCode()"
        data-testid="resend-button"
      >
        Renvoyer un code par email
      </button>
    </ng-template>
  </div>

</div>