import { Injectable } from '@angular/core';
import { Chart } from '../models/charts/chart';
import { ChartBuilder } from './chart-builder';
import { ChartConfiguration } from './config/chart.config';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

@Injectable()
export class ChartService {

  createChartConfig(config: ChartConfiguration): Chart {

    const chartBuilder = new ChartBuilder(config);
    chartBuilder
      .setBasePlugins()
      .setLegendPluginOptions()
      .setGradientColors();

    if (config.type === 'line') {

      chartBuilder
        .setTooltipPluginOptions()
        .setTimeAxis('x')
        .formatAxisTicks('y', {
          callback: (value) => Math.round(value).toString(),
        })
        .formatDatesWithoutTime();
    } else if (config.type === 'bar') {
      chartBuilder
        .setTooltipPluginOptions({
          callbacks: {
            title: (item): string => format(new Date(item[0].raw.x), 'dd LLL y', { locale: fr }),
            label: (item): string[] => [
              `${item.dataset.label} : ${config.data.yTicksLabels[item.parsed.y] || item.parsed.y}`,
            ],
          } as any,
        })
        .formatAxisTicks('y', {
          callback: (value) => config.data.yTicksLabels[value],
        })
        .formatAxisTicks('x', {
          callback(value) {
            return format(new Date(this.getLabelForValue(value)), 'dd/LL', { locale: fr });
          },
        });

    }

    if (config.type !== 'bar' && config.main) {
      chartBuilder.registerEventLinePlugin();
    }

    return chartBuilder.build();
  }

}
