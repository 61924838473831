import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { HomeComponent } from './home/home.component';
import { LottieModule } from 'ngx-lottie';
import { SequencesComponent } from './sequences/sequences.component';
import { SequenceModule } from '../sequence/sequence.module';
import { SharedModule } from '../shared/shared.module';
import { PatientComponent } from './patient/patient.component';
import { PatientModule } from '../patient/patient.module';
import { ClientAddUserComponent } from './client-add-user/client-add-user.component';
import { SharedtomeComponent } from './sharedtome/sharedtome.component';
import { CalendarPipe } from 'src/app/shared/pipes/calendar.pipe';
import { DrawerModule } from '../drawer/drawer.module';
import { TelemonitoringComponent } from './telemonitoring/telemonitoring.component';
import { TelemonitoringModule } from '../telemonitoring/telemonitoring.module';
import { GalleryModule } from '../gallery/gallery.module';
import { PatientsComponent } from './patients/patients.component';
import { TelemonitoringsComponent } from './telemonitorings/telemonitorings.component';
import { LargeLayoutComponent } from './large-layout/large-layout.component';
import { NgChartsModule } from 'ng2-charts';
import { MediumLayoutComponent } from './medium-layout/medium-layout.component';
import { SadmComponent } from './sadm/sadm.component';
import { SadmModule } from '../sadm/sadm.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import {
  TuiButtonModule,
  TuiDropdownModule,
  TuiExpandModule,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiLinkModule,
  TuiLoaderModule,
  TuiNotificationModule,
  TuiScrollbarModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import {
  TuiBadgeModule,
  TuiCheckboxLabeledModule,
  TuiElasticContainerModule,
  TuiIslandModule,
  TuiMarkerIconModule,
  TuiTabsModule,
} from '@taiga-ui/kit';
import { PatientSequencesComponent } from './patient-sequences/patient-sequences.component';
import { PatientTelemonitoringsComponent } from './patient-telemonitorings/patient-telemonitorings.component';
import { FormsModule as AngularFormModule, ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '../forms/forms.module';
import { PaywallAlertComponent } from '../paywall/paywall-alert/paywall-alert.component';
import { UserCardComponent } from 'src/app/shared/components/user-card/user-card.component';
import { TagInputComponent } from 'src/app/shared/components/tag-input/tag-input.component';
import { StatusComponent } from '../shared/status/status.component';
import { PatientAnalysesComponent } from './patient-analyses/patient-analyses.component';
import { LabelTableComponent } from '../label/label-table/label-table.component';
import { LabelComponent } from '../label/label/label.component';
import { FilterBarComponent } from '../filters/filter-bar/filter-bar.component';
import { FilterTabsComponent } from '../filters/filter-tabs/filter-tabs.component';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { UserPreviewComponent } from '../user/user-preview/user-preview.component';
import { FilterButtonsComponent } from '../filters/filter-buttons/filter-buttons.component';
import { ArchivedBadgeComponent } from '@modules/shared/archived-badge/archived-badge.component';
import {
  ArchivedNotificationComponent,
} from '@modules/shared/archived-notification/archived-notification.component';
import { SadmEntityNamePipe } from '@shared/pipes/sadm-entity-name.pipe';

@NgModule({
  declarations: [
    MainLayoutComponent,
    MediumLayoutComponent,
    LargeLayoutComponent,
    HomeComponent,
    SequencesComponent,
    PatientComponent,
    PatientsComponent,
    ClientAddUserComponent,
    SharedtomeComponent,
    TelemonitoringComponent,
    TelemonitoringsComponent,
    SadmComponent,
    CalendarPipe,
    HeaderComponent,
    HomeLayoutComponent,
    PatientSequencesComponent,
    PatientTelemonitoringsComponent,
    PatientAnalysesComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    SequenceModule,
    PatientModule,
    LabelTableComponent,
    LabelComponent,
    TelemonitoringModule,
    PaywallAlertComponent,
    DashboardRoutingModule,
    LottieModule,
    AngularFormModule,
    ReactiveFormsModule,
    DrawerModule,
    GalleryModule,
    SadmModule,
    NgChartsModule,
    FilterBarComponent,
    FilterTabsComponent,
    FilterButtonsComponent,
    TuiLetModule,
    TuiLoaderModule,
    TuiButtonModule,
    TuiIslandModule,
    TuiSvgModule,
    TuiHostedDropdownModule,
    TuiDropdownModule,
    TuiBadgeModule,
    TuiTabsModule,
    TuiLinkModule,
    TuiExpandModule,
    TuiNotificationModule,
    TuiHintModule,
    SidebarComponent,
    TuiTableModule,
    TuiElasticContainerModule,
    FormsModule,
    TuiMarkerIconModule,
    UserCardComponent,
    TagInputComponent,
    StatusComponent,
    TuiScrollbarModule,
    TuiCheckboxLabeledModule,
    UserPreviewComponent,
    ArchivedBadgeComponent,
    ArchivedNotificationComponent,
    SadmEntityNamePipe,
  ],
})
export class DashboardModule {}
