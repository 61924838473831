<ng-container *ngIf="sequences.length > 0; else noSequenceBlock">
  <tui-scrollbar 
    class="max-h-[56vh] px-3"
    scroll-tracker
    [threshold]="{bottom: 300}"
    (bottomThreshold)="loadNext?.emit()"
  >
    <pxc-sequence-select-checkbox-list *ngIf="!singleSelectMode; else radioButtonBlock"
      [sequences]="sequences"
      [compactMode]="compactMode"
      [selectedSequenceIds]="selectedSequenceIds"
      [sadmEntityNames]="sadmEntityNames"
      [disabledSequenceIds]="disabledSequenceIds"
      [context]="context"
      (selectSequences)="select($event)"
      (selectSadmSequences)="selectSadmSequences($event)"
      class="w-full mb-2"
      data-testid="sequences-checkbox"
    ></pxc-sequence-select-checkbox-list>

    <ng-template #radioButtonBlock>
      <pxc-sequence-select-radio-list
        [sequences]="sequences"
        [compactMode]="compactMode"
        (selectSequence)="select([$event])"
        data-testid="sequences-radio"
        class="w-full mb-2"
        data-testid="sequences-radio"
      ></pxc-sequence-select-radio-list>
    </ng-template>
  
  </tui-scrollbar>

</ng-container>

<ng-template #noSequenceBlock>
  <div class="pb-6" data-testid="empty-state">
    <pxc-lottie-label
      animation="sequenceEmpty"
      message="Vous n'avez aucune photo"
    ></pxc-lottie-label>
  </div>
</ng-template>