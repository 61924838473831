<div class="relative cursor-pointer group">
    <ng-content></ng-content>
    <div *ngIf="isActive" class="absolute top-0 right-0 z-30 md:opacity-0 md:group-hover:opacity-100 transition-opacity" data-testid="active">
        <tui-badge
            status="custom"
            class="bg-pixablue-3 !text-pixablue-1 tui-space_right-2"
        >
            <tui-svg
                src="tuiIconClose"
            ></tui-svg>
        </tui-badge>
    </div>
</div>