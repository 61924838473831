export enum MenuActionId {
  PATIENT_EDIT,
  PATIENT_MERGE,
  PATIENT_TELEMONITOR,
  PATIENT_REPORT,
  PATIENT_TELEEXPERTISE_WITH_OMNIDOC,
  PATIENT_ARCHIVE,
  PATIENT_UNARCHIVE,
  SEQUENCE_EDIT,
  SEQUENCE_SHARE,
  SEQUENCE_MOVE,
  SEQUENCE_TELEMONITORING,
  SEQUENCE_UNLINK,
  SEQUENCE_DELETE,
  TELEMONITORING_TUTORIAL,
  TELEMONITORING_SEND_TUTORIAL,
  TELEMONITORING_REPORT,
  TELEMONITORING_LINK_SEQUENCES,
  TELEMONITORING_ARCHIVE,
  TELEMONITORING_ENABLE,
  TELEMONITORING_DELETE,
  SADM_ENTITY_EDIT,
  SADM_MERGE,
  SADM_ENTITY_ARCHIVE,
  SADM_ENTITY_UNARCHIVE,
  SADM_ENTITY_DELETE,
  USER_PROFILE,
  USER_LOGOUT,
  HELP_MANUAL,
  HELP_CONTACT,
  HELP_TUTORIALS,
  CLIENT_ADMIN_USER_ENABLE,
  CLIENT_ADMIN_USER_DISABLE,
  CLIENT_ADMIN_USER_REINVITE,
  CLIENT_ADMIN_USER_INVITE,
  DEPARTMENT_ADMIN_USER_ENABLE,
  DEPARTMENT_ADMIN_USER_DISABLE,
  DEPARTMENT_ADMIN_USER_REMOVE,
  DEPARTMENT_ADMIN_USER_INVITE,
}
