import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataExport } from '@pixacare/pxc-ts-core';

@Injectable({
  providedIn: 'root',
})
export class ExportDataHttpService {

  constructor(private readonly http: HttpClient) {}

  getDataExports(): Observable<DataExport[]> {
    return this.http.get<DataExport[]>(`${environment.apiBaseUrl}/Export`);
  }

  createNewExport(): Observable<DataExport> {
    return this.http
      .get<DataExport>(`${environment.apiBaseUrl}/Export/RequestArchive`);
  }

  getExportAccessToken(archiveId: number): Observable<string> {
    return this.http.get(
      `${environment.apiBaseUrl}/Export/AccessToken/${archiveId}`,
      { responseType: 'text' },
    );
  }

  delete(exportId: number): Observable<unknown> {
    return this.http
      .delete(`${environment.apiBaseUrl}/Export/${exportId}`);
  }

  getExportLink(accessToken: string): Observable<string> {
    return this.http.get(`${environment.apiBaseUrl}/Export/${accessToken}`, { responseType: 'text' });
  }

}
