import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { MediaClickedOutput } from 'src/app/shared/models/media-clicked-output';
import { SequencePickerComponent } from '../sequence-picker/sequence-picker.component';
import { FilteredSequenceSearch } from 'src/app/shared/models/filters/filtered-sequence-search';
import { SequenceContext } from 'src/app/shared/models/enums/sequence-context.enum';
import { SequenceModule } from '../sequence.module';
import { sequenceCountPluralMapping } from 'src/app/shared/utils/plural-mappings';
import { TuiButtonModule } from '@taiga-ui/core';
import { FlattenedSequence, Patient } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-sequence-picker-preview',
  standalone: true,
  imports: [
    CommonModule,
    SequenceModule,
    TuiButtonModule,
  ],
  templateUrl: './sequence-picker-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequencePickerPreviewComponent {

  @Input() title = 'Sélectionnez des séquences';
  @Input() emptyState = 'Cette étape est facultative';

  @Input() patient: Patient;
  @Input() clientCode: string;
  @Input() departmentIds: number[] = [];
  @Input() sequences: FlattenedSequence[] = [];
  @Input() sequenceContext: SequenceContext = SequenceContext.TELEMONITORING;

  @Output() sequencesChange = new EventEmitter<FlattenedSequence[]>();

  @Output() mediaClicked = new EventEmitter<MediaClickedOutput>();

  sequenceCountPluralMapping = sequenceCountPluralMapping;

  private readonly dialogService = inject(DialogService);

  getSequenceIds(): number[] {
    return this.sequences.map((s) => s.sequenceInstance.id);
  }

  openSequencePicker(): void {
    this.dialogService.openComponentWithCloseConfirmation
    <SequencePickerComponent, FilteredSequenceSearch>(SequencePickerComponent, {
      data: {
        patientId: this.patient.id,
        clientCode: this.clientCode,
        departmentIds: this.departmentIds,
        alreadySelectedSequenceIds: this.getSequenceIds(),
        sequenceContext: this.sequenceContext,
      },
      label: this.title,
      size: 'l',
    }).subscribe((filteredSequenceSearch) => {
      if (filteredSequenceSearch) {
        this.sequencesChange.emit(filteredSequenceSearch.sequences);
      }
    });
  }

}
