<div *ngIf="(collection$ | async) as collection; else loadingBlock" class="flex flex-col gap-4">

  <div class="flex flex-col md:flex-row md:items-center justify-between animate-in fade-in">
    <pxc-title 
      [title]="mode === ContextualComponentMode.USER ? 'Mes photos' : (departmentName$ | async)" 
      [subtitle]="mode === ContextualComponentMode.DEPARTMENT && 'Photos du groupe'"
    ></pxc-title>
    <pxc-create-button
      *ngIf="(hasLicenseWriteAccess$ | async)"
      class="flex-none"
      (click)="addSequence()"
      data-testid="add-sequence"
      label="Ajouter une séquence"
   ></pxc-create-button>
  </div>

  <pxc-filter-bar
    searchPlaceholder="Rechercher une séquence par mot clé, date ou patient ..."
    data-testid="filter-bar"
    class="animate-in fade-in"
  ></pxc-filter-bar>
  
  <pxc-sequence-list
    *ngIf="(isCollectionLoaded$ | async); else loadingBlock"
    [sequences]="collection.data"
    [actions]="actions"
    [isNextPageLoading]="collection.context.isNextPageLoading"
    [isPreviousPageLoading]="collection.context.isPreviousPageLoading"
    (loadPrevious)="loadPreviousPage()"
    (loadNext)="loadNextPage()"
    (sequenceMediaClicked)="openSequenceMediaGallery($event)"
    data-testid="sequence-list"
  ></pxc-sequence-list>

</div>

<ng-template #loadingBlock>
  <pxc-loader data-testid="loading" class="my-5">
    Récupération de vos séquences en cours ...
  </pxc-loader>
</ng-template>