<tui-multi-select
  [pseudoInvalid]="errors.status === 'INVALID'"
  (blur)="addUnvalidatedTag()"
  (keydown.enter)="addUnvalidatedTag()"
  [search]="search"
  (searchChange)="searchChange.emit($event)"
  [ngModel]="tags"
  (ngModelChange)="tagsChange.emit($event)"
  [stringify]="stringify"
  expandable="true"
  [tuiTextfieldLabelOutside]="true"
  [tuiTextfieldCleaner]="true"
  data-testid="input"
  class="hide-right-icon"
>
  {{placeholder}}
  <ng-container *ngIf="!disableAutocomplete">
    <tui-data-list-wrapper
      *tuiDataList
      [items]="autocompleteModels"
      [itemContent]="autocompleteUsers ? userTemplate : stringify"
      [emptyContent]="emptyMessage"
      [class.!hidden]="!(search$ | async)?.length"
    ></tui-data-list-wrapper>
  </ng-container>
</tui-multi-select>

<app-input-validation-error-display 
  *ngIf="inputSubmitted" 
  [control]="errors" 
  data-testid="validation-error-message"
></app-input-validation-error-display>

<p class="text-slate-600 mt-2">{{helpText}}</p>

<ng-template #userTemplate let-data>
  <pxc-user-card 
    *ngIf="autocompleteUsers?.[data.value] as user"
    [user]="user"
  ></pxc-user-card>
</ng-template>