import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiButtonModule, TuiDialogContext } from '@taiga-ui/core';
import { TuiElasticContainerModule } from '@taiga-ui/kit';
import { UserInputComponent } from 'src/app/shared/components/user-input/user-input.component';
import { ChannelUser, SearchedUser } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-channel-create',
  standalone: true,
  imports: [
    CommonModule,
    TuiButtonModule,
    TuiElasticContainerModule,
    UserInputComponent,
  ],
  templateUrl: './channel-create.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelCreateComponent {

  users: ChannelUser[] = [];

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<ChannelUser[], void>,
  ) {}

  setUsers(users: SearchedUser[]): void {
    this.users = users.map(({ firstName, lastName, id }) => ({
      firstName,
      lastName,
      id,
    }));
  }

  close(): void {
    this.context.completeWith(null);
  }

  create(): void {
    if (!this.users.length) {
      return;
    }
    this.context.completeWith(this.users);
  }


}
