
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ROUTER_FEATURE_KEY } from './router.selectors';
import { NavigationActionTiming, StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { CustomRouterStateSerializer } from './router-state.serializer';

@NgModule({
  imports: [
    StoreRouterConnectingModule.forRoot({
      serializer: CustomRouterStateSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    StoreModule.forFeature(ROUTER_FEATURE_KEY, routerReducer),
  ],
  declarations: [],
})
export class RouterStoreModule { }
