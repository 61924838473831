<div class="flex flex-col p-1.5">
  <button
    *ngFor='let action of actions'
    class="max-w-48 rounded-xl p-2.5 flex items-center gap-3 text-slate-600"
    [ngClass]="action.status === Status.ERROR ? 'hover:bg-red-100 hover:text-red-600' : 'hover:bg-slate-100'"
    (click)="action.execute(entity)"
  >
    <tui-svg data-testid="icon" [src]="action.icon"></tui-svg>
    <p class="text-current line-clamp-2 text-left mr-2">{{action.label}}</p>
</button>
</div>
