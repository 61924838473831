<ng-container *ngIf="(patientExport$ | async) as patientExport; else loading">
  
  <pxc-pdf-previewer [source]="patientExport.preview"></pxc-pdf-previewer>

  <div class="flex flex-col md:flex-row md:items-center m-3 gap-3">
    <button 
      tuiButton 
      appearance="outline"
      (click)="close()"
    >
      Fermer
    </button>

    <button 
      tuiButton
      type="button"
      class="md:ml-auto"
      appearance="secondary"
      (click)="download(patientExport.content)"
      icon="tuiIconDownload"
    >
      Télécharger
    </button>
    <button 
      tuiButton 
      type="button"
      (click)="export()"
      icon="tuiIconExternalLink"
    >
      Exporter dans le DPI
    </button>
  </div>

</ng-container>

<ng-template #loading>
  <div class="flex flex-col my-5 justify-center gap-2 items-center">
    <pxc-loader>
      Chargement de l'aperçu du rapport de suivi ...
    </pxc-loader>
  
    <button tuiButton appearance="flat" type="button" (click)="close()">
      Annuler
    </button>
  </div>
</ng-template>
