<div class="flex flex-col gap-4">
  <pxc-title
    *ngIf="title"
    [title]="title"
    [icon]="icon"
    titleSize="small"
    data-testid="title"
  ></pxc-title>
  <div class="grid md:grid-cols-2 gap-3 mx-1">
    <ng-content></ng-content>
  </div>
</div>
