<div class="flex flex-col gap-2">
  <div class="flex flex-col lg:flex-row items-center justify-between gap-2 my-2">
    <pxc-title title="Mes mots clés"></pxc-title>
    <button tuiButton (click)="openCreateLabelDialog()" appearance="primary" data-testid="add-button" icon="tuiIconPlusLarge">
      Ajouter
    </button>
  </div>
  
  <pxc-label-table
    [labels]="labels$ | async"
    [displayStats]="true"
    [actions]="actions"
  ></pxc-label-table>
</div>

<ng-template #removeDialog let-observer>

  <div class="flex flex-col gap-4">

    <p>
      Le mot clé 
      <span class="px-2 py-1 rounded-full uppercase bg-pixablue-3">{{currentLabel.labelInstance.word}}</span>
      ne vous sera plus proposé lors de la sélection de mots clés.
    </p>

    <tui-checkbox-labeled [(ngModel)]="removeFromSequences">
      Supprimer également ce mot clé de mes séquences déjà créées
    </tui-checkbox-labeled>

    <div class="flex gap-2 justify-between mt-2">
      <button tuiButton appearance="outline" (click)="observer.complete()">
        Annuler
      </button>
      <button tuiButton (click)="removeLabel(observer)" appearance="secondary-destructive" data-testid="confirm-delete-button">
        Ne plus utiliser
      </button>
    </div>

  </div>

</ng-template>
