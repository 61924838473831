import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { isDateFromFuture, isDateFromPast } from '../../utils/utils';
import { selectDepartmentMemberships } from '../departments/departments.selectors';
import { UsersState } from './users.state';
import { ClientUser, DepartmentMembership, LicenseState } from '@pixacare/pxc-ts-core';

export const USERS_FEATURE_KEY = 'Users';

export const selectUsersState = createFeatureSelector<UsersState>(
  USERS_FEATURE_KEY,
);

export const selectUsers = createSelector(
  selectUsersState,
  (state) => state.users,
);

export const selectAllUsers = createSelector(
  selectUsersState,
  (state) => Object.values(state.users).reduce((acc, current) => ({
    ...acc,
    ...current,
  }), {}),
);

export const selectClientUsers =
(clientCode: string): MemoizedSelector<UsersState, ClientUser[]> => createSelector(
  selectUsersState,
  (state: UsersState) => Object.values(state.users?.[clientCode] || []),
);

export const selectDepartmentUsers =
(clientCode: string, departmentId: number): MemoizedSelector<UsersState, ClientUser[]> => createSelector(
  selectUsersState,
  selectDepartmentMemberships(clientCode, departmentId),
  (state, memberships) => Object.values(memberships).map((membership) => state.users[clientCode][membership.userId])
    .filter((user) => user !== undefined),
);

export const selectIndexedDepartmentUsers =
(clientCode: string, departmentId: number):
MemoizedSelector<UsersState, { [userId: number]: ClientUser }> => createSelector(
  selectUsersState,
  selectDepartmentMemberships(clientCode, departmentId),
  (state, memberships) => Object.values(memberships).reduce((acc, membership) => ({
    ...acc,
    [membership.userId]: state.users[clientCode][membership.userId],
  }), {}),
);


export const selectEnabledDepartmentUsers =
(clientCode: string, departmentId: number): MemoizedSelector<UsersState, ClientUser[]> => createSelector(
  selectUsersState,
  selectDepartmentMemberships(clientCode, departmentId),
  selectDepartmentUsers(clientCode, departmentId),
  (state: UsersState, memberships: DepartmentMembership[], departmentUsers: ClientUser[]) =>
    departmentUsers.filter((user) => isDateFromFuture(new Date(memberships[user?.id]?.disabledOn))),
);

export const selectDisabledDepartmentUsers =
(clientCode: string, departmentId: number): MemoizedSelector<UsersState, ClientUser[]> => createSelector(
  selectUsersState,
  selectDepartmentMemberships(clientCode, departmentId),
  selectDepartmentUsers(clientCode, departmentId),
  (state: UsersState, memberships: DepartmentMembership[], departmentUsers: ClientUser[]) =>
    departmentUsers.filter((user) => isDateFromPast(new Date(memberships[user?.id]?.disabledOn))),
);

export const selectInvitedDepartmentUsers =
(clientCode: string, departmentId: number): MemoizedSelector<UsersState, ClientUser[]> => createSelector(
  selectDepartmentUsers(clientCode, departmentId),
  (departmentUsers: ClientUser[]) =>
    departmentUsers.filter((user) => user?.licenseState === LicenseState.INVITED)
      .sort((firstUser, secondUser) => (firstUser.mailAddress > secondUser.mailAddress) ? 1 : -1),
);
