import {
  ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild,
} from '@angular/core';

@Component({
  selector: 'pxc-zoom-container',
  templateUrl: './zoom-container.component.html',
  styles: [`
    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
   }
  `, `
    :host {
      height: 100%;
      }
  `],


  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomContainerComponent {

  @ViewChild('zoomContainer') zoomContainer: ElementRef;
  @ViewChild('slider') slider: ElementRef;

  @Input() maxScale = 10;
  @Input() zoomButtons = true;
  @Input() zoomWheel = true;
  @Input() zoomWheelCTRLBinding = false;
  @Input() enableOverflow = false;

  scale = 1;
  zoomTarget = { x: 0, y: 0 };
  pos = { x: 0, y: 0 };
  isUserClicking = false;

  getTransformStyle(): string {
    return `matrix(${this.scale}, 0, 0, ${this.scale}, ${this.pos.x}, ${this.pos.y})`;
  }

  zoomIn(): void {
    this.zoomAndSlide(1);
  }

  zoomOut(): void {
    this.zoomAndSlide(-1);
  }

  wheelEvent(event: WheelEvent): void {
    event.preventDefault();

    if (!this.zoomWheel) {
      return;
    }

    if (this.zoomWheelCTRLBinding && !event.ctrlKey) {
      return;
    }

    let delta = -event.deltaY;
    delta = Math.max(-1, Math.min(1, delta));

    this.zoomAndSlide(
      delta,
      0.3,
      event.pageX - this.zoomContainer.nativeElement.offsetLeft,
      event.pageY - this.zoomContainer.nativeElement.offsetTop,
    );
  }

  zoomAndSlide(
    zoomDelta: number,
    zoomFactor = 0.5,
    zoomPointX = this.slider.nativeElement.clientWidth / 2,
    zoomPointY = this.slider.nativeElement.clientHeight / 2,
  ): void {
    // Determine the point on where the slider in zoomed in
    this.zoomTarget.x = (zoomPointX - this.pos.x) / this.scale;
    this.zoomTarget.y = (zoomPointY - this.pos.y) / this.scale;

    // Apply zoom
    this.scale += zoomDelta * zoomFactor * this.scale;
    this.scale = Math.max(1, Math.min(this.maxScale, this.scale));

    // Calculate x and y base on zoom
    this.pos.x = -this.zoomTarget.x * this.scale + zoomPointX;
    this.pos.y = -this.zoomTarget.y * this.scale + zoomPointY;

    this.keepContentInBoundaries();
  }

  keepContentInBoundaries(): void {
    const sliderWidth = this.slider.nativeElement.clientWidth;
    const sliderHeight = this.slider.nativeElement.clientHeight;

    // Make sure the slider stays in its zoomContainer area when zooming out
    if (this.pos.x > 0) {
      this.pos.x = 0;
    }
    if (this.pos.x + sliderWidth * this.scale < sliderWidth) {
      this.pos.x = -sliderWidth * (this.scale - 1);
    }
    if (this.pos.y > 0) {
      this.pos.y = 0;
    }
    if (this.pos.y + sliderHeight * this.scale < sliderHeight) {
      this.pos.y = -sliderHeight * (this.scale - 1);
    }
  }

  enableMoving(): void {
    this.isUserClicking = true;
  }

  mouseMove(event: MouseEvent): void {
    if (!this.isUserClicking) {
      return;
    }

    this.pos.x += event.movementX;
    this.pos.y += event.movementY;
    this.keepContentInBoundaries();
  }

  disableMoving(): void {
    this.isUserClicking = false;
  }

  isContainerZoomMax(): boolean {
    return this.scale === this.maxScale;
  }

  isContainerZoomMin(): boolean {
    return this.scale === 1;
  }

  isMovingAllowed(): boolean {
    return this.scale > 1 && !this.isUserClicking;
  }

  isUserMoving(): boolean {
    return this.scale > 1 && this.isUserClicking;
  }

}
