<pxc-tile *ngIf="!!channel" (click)="toggle()" class="transition hover:bg-slate-100 cursor-pointer px-8 py-2">

    <tui-svg leading src="tuiIconBellLarge"></tui-svg>

    <p title>Notifications</p>

    <tui-toggle 
        trailing
        [ngModel]="notificationsEnabled"
        [readOnly]="true"
        [disabled]="loading$ | async"
    ></tui-toggle>

</pxc-tile>
