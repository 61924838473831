import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `:host {
      display: block;
      height: 100%;
    }`,
  ],
})
export class AppComponent implements OnInit {

  title = 'pxc-webapp';

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  isBrowserIE = window.document.documentMode;

  constructor(
    private readonly appInsights: ApplicationInsights,
  ) { }

  ngOnInit() {
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }

}
