<div 
    class="w-full flex flex-col border-r border-slate-200 h-full gap-2" 
    *tuiLet="(channels$ | async) as channels"
  >
    <div class="flex gap-2 items-center justify-between px-4 pt-3">
        <h5 class="leading-loose">Messagerie</h5>
        <button 
            type="button"
            tuiIconButton
            appearance="secondary"
            icon="tuiIconPlusLarge"
            shape="rounded"
            size="s"
            aria-label="Créer une conversation"
            (click)="openCreateChannelDialog()"
        ></button>
    </div>
    <pxc-filter-bar
        class="px-4 py-1"
        searchPlaceholder="Rechercher une conversation ..."
        size="m"
    ></pxc-filter-bar>
    <tui-scrollbar 
        class="flex-1" 
        *ngIf="channels?.length > 0; else emptyState"
    >
        <div class="flex flex-col">
            <pxc-channel-preview
                *ngFor="let channel of channels" 
                class="cursor-pointer transition hover:bg-slate-100 px-4 py-2"
                [channel]="channel"
                [routerLink]="['./', channel.id]"
                [routerLinkActive]="['bg-slate-100']"
                queryParamsHandling="merge"
            ></pxc-channel-preview>
        </div>
    </tui-scrollbar>
</div>

<ng-template #emptyState>
    <pxc-status
        *tuiLet="(search$ | async) as search"
        [state]="Status.BASIC"
        [icon]="!search ? 'tuiIconMessageCircleLarge' : 'tuiIconMehLarge'"
        [showTitle]="false"
        [description]="search ? 'Aucune conversation ne correspond à votre recherche.' : 'Vous n\'avez pas encore de conversations !'"
    >
    </pxc-status>
</ng-template>