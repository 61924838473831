import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output }
  from '@angular/core';
import { AbstractControl, FormsModule, ReactiveFormsModule, UntypedFormControl, ValidationErrors }
  from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiDataListWrapperModule, TuiMultiSelectModule } from '@taiga-ui/kit';
import { AutoCompleteModel } from 'src/app/shared/models/helpers/auto-complete-model';
import { TuiDataListModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { UserCardComponent } from 'src/app/shared/components/user-card/user-card.component';
import { startWith } from 'rxjs';
import { SearchedUser } from '@pixacare/pxc-ts-core';

@UntilDestroy()
@Component({
  selector: 'pxc-tag-input',
  templateUrl: './tag-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TuiMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    TuiDataListWrapperModule,
    TuiDataListModule,
    UserCardComponent,
    TuiTextfieldControllerModule,
    SharedModule,
  ],
})
export class TagInputComponent implements OnInit {

  @Input() placeholder = 'exemple@pixacare.com';
  @Input() helpText = 'Appuyer sur la touche entrée pour valider.';
  @Input() validators: ((control: AbstractControl) => ValidationErrors)[] = [];
  @Input() prefixIcon: string;
  @Input() emptyMessage = 'Aucun résultat.';

  @Input() autocompleteModels: AutoCompleteModel[];
  @Input() autocompleteUsers: { [id: string]: SearchedUser };
  @Input() allowOnlyAutoComplete = false;
  @Input() disableAutocomplete = false;

  @Input() search = '';
  @Output() searchChange = new EventEmitter<string>();

  @Input() tags: AutoCompleteModel[] = [];
  @Output() tagsChange = new EventEmitter<AutoCompleteModel[]>();

  errors = new UntypedFormControl();
  inputSubmitted = false;

  search$ = this.searchChange.pipe(startWith(''));

  readonly stringify = (item: AutoCompleteModel): string => item.display;

  ngOnInit(): void {

    this.errors.markAsDirty(); // required to trigger validators

    this.searchChange.pipe(untilDestroyed(this)).subscribe((search) => {
      // check validators when input change
      const control = new UntypedFormControl(search);
      this.validators.forEach((validator) => {
        const result = validator(control);
        this.errors.setErrors(result);
        // don"t check others validators if one is invalid
        if (result) {
          return;
        }
      });
    });

  }

  addUnvalidatedTag(): void {

    if (this.allowOnlyAutoComplete) {
      return;
    }

    try {

      if (!this.search) {
        throw new Error('No search value');
      }

      if (this.errors.invalid) {
        throw new Error('Invalid tag');
      }

      if (this.tags.map((tag) => tag.display).includes(this.search)) {
        throw new Error('Tag already exists');
      }

      this.tagsChange.emit([...this.tags, { display: this.search, value: this.search }]);
      this.searchChange.emit('');
      this.inputSubmitted = false;

    } catch (error) {
      console.warn(error);
      this.inputSubmitted = true;
    }

  }

}
