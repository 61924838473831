import { Pipe, PipeTransform } from '@angular/core';
import { getLocalDate, getLocalISODate } from '../utils/utils';

@Pipe({
  name: 'localDate',
})
export class LocalDatePipe implements PipeTransform {

  transform(value: Date | string): string {
    return (value instanceof Date) ? getLocalDate(value).toISOString() : getLocalISODate(value);
  }

}
