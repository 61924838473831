<div class="w-full h-full">
    <div class="flex overflow-auto flex-col-reverse h-full p-4 gap-3 scroll-smooth"
      #scrollBar
      scroll-tracker
      [threshold]="{top: topThreshold, bottom: bottomThreshold}"
      (bottomThreshold)="bottomReached()"
      (topThreshold)="topReached()"
    >
      <div
        *ngFor="let messageGroup of messageGroups; index as groupIndex; trackBy: trackByMessageGroup"
        class="flex flex-col-reverse gap-1"
      >
        <pxc-message-bubble
          *ngFor="let messageId of messageGroup.messageIds; ; index as messageIndex; trackBy: trackByMessageId"
          [message]="messages[messageId]"
          [showHeader]="messageGroup.options?.[messageId]?.showSender ?? false"
          [readers]="(groupIndex === 0 && messageIndex === 0) ? lastMessageReaders : null"
          [channelUsersCount]="channel.users.length"
          (messageClicked)="openReadersList(messages[messageId])"
        ></pxc-message-bubble>

        <div class="sticky top-4 left-1/2 -translate-x-1/2 px-3 py-1 mt-2 bg-slate-100 rounded-xl w-fit">
          <p class="text-slate-800 text-sm font-medium text-center">
            {{messageGroup.date | date:'longDate'}}
          </p>
        </div>

      </div>

  </div>

  <button 
    tuiButton 
    appearance="primary" 
    class="fixed left-1/2 -translate-x-1/2 shadow-xl transition-all duration-300"
    [ngClass]="{
      'opacity-0 pointer-events-none bottom-0': !hasUnseenMessages,
      'opacity-100 bottom-14': hasUnseenMessages
    }"
    iconRight="tuiIconArrowDown"
    (click)="scrollToBottom()"
  >
    Nouveaux messages
  </button>  

</div>