import { Router, type ActivatedRouteSnapshot, type CanActivate } from '@angular/router';
import { Observable, combineLatest, concat, map, of } from 'rxjs';
import { AuthorizationsService } from '../services/authorizations.service';
import { Injectable, inject } from '@angular/core';
import { GuardInitializerService } from '../services/guard-initializer.service';

@Injectable({
  providedIn: 'root',
})
export class IsUserDepartmentAdminGuard implements CanActivate {

  private readonly authorizationService = inject(AuthorizationsService);
  private readonly guardInitializerService = inject(GuardInitializerService);
  private readonly router = inject(Router);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {

    const clientCode = route.queryParams.cc;

    if (!clientCode) {
      console.error('Client code is missing');
      this.redirect();
      return of(false);
    }

    return this.guardInitializerService.executeOrWaitForStoreInitialization$(
      combineLatest([
        this.authorizationService.isDepartmentAdminParams$(clientCode, +route.params.departmentId),
        this.authorizationService.hasSupportAccess(),
      ]).pipe(
        map(([isDepartmentAdmin, hasSupportAccess]) => {
          if (!isDepartmentAdmin && !hasSupportAccess) {
            this.redirect();
            return false;
          }
          return true;
        }),
      ),
    );
  }

  redirect(): void {
    this.router.navigate(['/dashboard'], { queryParamsHandling: 'merge' });
  }

}
