import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { PatientIdentity, Patient, PagedResponse, PaginationOptions } from '@pixacare/pxc-ts-core';
import { SortOrderStrategy } from '@shared/models/enums/sort-order-strategy.enum';
import { PATIENTS_FEATURE_KEY } from './patients.selectors';

export const patientsActions = createActionGroup({
  source: PATIENTS_FEATURE_KEY,
  events: {
    'Reset patients state': emptyProps(),

    'Create patient': props<{ clientCode: string; patient: PatientIdentity }>(),
    'Create patient success': props<{ clientCode: string; patient: Patient }>(),

    'Get patient': props<{ clientCode: string; patientId: number; departmentId?: number }>(),
    'Get patient success': props<{ clientCode: string; collection: PagedResponse<Patient> }>(),

    'Get next patients': props<{ clientCode: string; options?: PaginationOptions }>(),
    'Get next patients success': props<{
      clientCode: string;
      reset?: boolean;
      collection: PagedResponse<Patient>;
    }>(),

    'Update patient': props<{ clientCode: string; patientId: number; patient: Patient }>(),
    'Update patient success': props<{ clientCode: string; patient: Patient }>(),
    'Update patients success': props<{ clientCode: string; patients: Patient[] }>(),

    'Merge patients': props<{ clientCode: string; patientId: number; targetPatientId: number }>(),

    'Remove patient': props<{ clientCode: string; patientId: number }>(),

    'Set order': props<{ order: SortOrderStrategy }>(),

    'Archive patient': props<{ clientCode: string; patientId: number }>(),
    'Archive patient success': props<{ clientCode: string; patient: Patient }>(),

    'Unarchive patient': props<{ clientCode: string; patientId: number }>(),
    'Unarchive patient success': props<{ clientCode: string; patient: Patient }>(),
  },
});
