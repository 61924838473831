<tui-scrollbar
  *ngIf="patients?.length > 0; else noPatientBlock"
  class="h-[60vh] w-full"
  scroll-tracker
  [threshold]="{bottom: 300}"
  (bottomThreshold)="loadNext.emit()"
  [bottomGuard]="isNextPageLoading"
  data-testid="scroll-viewport"
>
  <div class="grid lg:grid-cols-2 2xl:grid-cols-3 gap-x-5 gap-y-4">
    <pxc-patient-card
      *ngFor="let patient of patients"
      class="cursor-pointer px-[2px] animate-in fade-in slide-in-from-top-1"
      [patientLink]="patient.id"
      [patient]="patient"
      [actions]="actions"
      [displayLastActivity]="true"
      data-testid="patient-card"
    >
      <pxc-patient-link-quick-action *showIfClientIsGamEnabled="patient"></pxc-patient-link-quick-action>
      <pxc-archived-badge *ngIf="patient.isArchived"></pxc-archived-badge>
    </pxc-patient-card>

    <div class="flex items-center justify-center col-span-full" data-testid="loading-bottom">
      <pxc-spinner [isVisible]="isNextPageLoading"></pxc-spinner>
    </div>
  </div>
</tui-scrollbar>

<ng-template #noPatientBlock>
  <pxc-lottie-label
    animation="patientEmpty"
    message="Vous n'avez aucun patient"
    data-testid="no-patient-message"
  ></pxc-lottie-label>
</ng-template>