<div *ngIf="collection$ | async as collection; else patientLoadingBlock" class="flex flex-col gap-3">
  <div class="flex flex-col md:flex-row md:items-center justify-between animate-in fade-in">
    <pxc-title 
      [title]="mode === ContextualComponentMode.USER ? 'Mes patients' : (departmentName$ | async)" 
      [subtitle]="mode === ContextualComponentMode.DEPARTMENT && 'Patients du groupe'"
    ></pxc-title>
    <pxc-create-button
      *ngIf="(hasLicenseWriteAccess$ | async)"
      class="flex-none"
      label="Ajouter un patient"
      icon="tuiIconUserPlus"
      (click)="createPatient()"
    ></pxc-create-button>
  </div>

  <pxc-filter-tabs class="animate-in fade-in"></pxc-filter-tabs>

  <div class="flex gap-2 max-lg:flex-wrap max-lg:justify-end items-center animate-in fade-in">
    <pxc-filter-bar 
      searchPlaceholder="Rechercher un patient par nom, prénom, nip ou date de naissance ..."
      data-testid="filter-bar"
    ></pxc-filter-bar>
    <pxc-dropdown-button
      class="shrink-0"
      icon="filterLines"
      appearance="flat"
      [actions]="orderService.orderMenuActions"
      [selectedKey]="(order$ | async)"
    ></pxc-dropdown-button>
  </div>

  <pxc-filter-buttons></pxc-filter-buttons>
  
  <pxc-patient-list
    *ngIf="(isDataLoaded$ | async) === true; else patientLoadingBlock"
    [patients]="collection.data"
    [isNextPageLoading]="collection.context?.isNextPageLoading"
    (loadNext)='loadNextPage()'
    data-testid="patient-list"
  ></pxc-patient-list>
  
</div>

<ng-template #patientLoadingBlock>
  <pxc-loader data-testid="loading" class="my-5">
    Chargement des patients ...
  </pxc-loader>
</ng-template>