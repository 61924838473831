<div *ngIf="(labels$ | async) as labels">
    <pxc-label-table
        [labels]="labels"
        [actions]="actions"
    >
        <div slot="header" class="flex justify-between items-center p-3 border-b border-slate-300" *ngIf="(department$ | async) as department">
            <div class="flex items-center gap-3">
                <pxc-title title="Mots clés de {{department.name}}" titleSize="small"></pxc-title>
                <tui-badge
                    status="custom"
                    class="bg-pixablue-3 !text-blue-900"
                    value="{{labels.length}} mots clés"
                ></tui-badge>
            </div>
            <button
                slot="actions"
                tuiButton
                icon="tuiIconPlusLarge"
                (click)="addLabels()"
            >
                Ajouter
            </button>
        </div>
    </pxc-label-table>
</div>