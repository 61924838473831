<ng-container *ngIf="(channel$ | async) as channel">
  <div class="w-full h-full flex overflow-hidden">
    <div class="w-full h-full flex flex-col relative">
      <pxc-channel-header 
        [channel]="channel" 
        class="w-full z-10"
        (openChannelInfo)="openSettings()"
      ></pxc-channel-header>
  
      <div 
        class="flex flex-1 items-center justify-center bg-slate-100 overflow-hidden" 
        [ngClass]="{
          '2xl:mr-96': showSettings
        }"
        *tuiLet="(messages$ | async) as messages"
      >
        <pxc-message-list 
          *ngIf="(isEmpty$ | async) === false; else emptyState"
          class="h-full w-full block z-0"
          [channel]="channel"
          [messages]="messages" 
          [messageGroups]="(messageGroups$ | async)"
          [hasUnseenMessages]="(hasUnseenMessages$ | async)"
          (loadNext)="loadNext()"
          (markMessagesAsSeen)="markMessagesAsSeen()"
        ></pxc-message-list>
      </div>
      
      <pxc-message-input 
        (sendMessage)="sendMessage($event)" 
        class="w-full z-10"
      ></pxc-message-input>
    </div>
  
    <pxc-channel-settings
      [channel]="channel"
      (closeClick)="closeSettings()"
      class="transition-all h-full lg:max-2xl:shadow-xl lg:border-l lg:border-slate-200 duration-300 shrink-0 absolute top-0 max-lg:left-0 right-0 bg-slate-50 lg:w-96"
      [ngClass]="{
        'opacity-100 z-20 translate-x-0': showSettings,
        'opacity-0 translate-x-full': !showSettings
      }"
    ></pxc-channel-settings>
  
  </div>
</ng-container>

<ng-template #emptyState>
  <p class="text-slate-500">Aucun message.</p>
</ng-template>

<ng-template #loading>
  <pxc-spinner></pxc-spinner>
</ng-template>

