<tui-island class="py-1 px-3">

  <div class="flex flex-col divide-y divide-slate-100 flex-1">

    <div class="py-1.5 flex flex-wrap justify-between items-center gap-2">

      <p class="text-sm text-slate-600 text-balance" data-testid="date-author">
        <span class="font-medium">{{telemonitoring.telemonitoring.createdOn | date: 'mediumDate' | uppercase}}</span> à 
        {{telemonitoring.telemonitoring.createdOn | date: 'shortTime'}}
        <span class="mx-1 text-slate-300">•</span>
        <pxc-user-preview [user]="telemonitoring.createdByInstance" [colored]="false"></pxc-user-preview>
      </p>

      <div class="flex flex-wrap gap-3 !text-sm" stopPropagation>
        <button
          type="button"
          tuiLink
          data-appearance="primary"
          data-testid="qr-code"
          (click)="openQrCodeInGallery()">
          QR Code
        </button>
        <ng-container *ngIf="actions">
          <pxc-action-buttons 
            [entity]="telemonitoring.telemonitoring" 
            [actions]="actions"
            data-testid="actions"
            optionButtonLabel="Options"
          ></pxc-action-buttons>
        </ng-container>
      </div>
    </div>

    <div class="flex max-sm:flex-col gap-3 py-2.5" *tuiLet="telemonitoring.telemonitoring.sequenceIds !== null as hasSequenceIds">

      <div class="shrink-0 w-20 aspect-square">
        <div 
          *ngIf="!telemonitoring.thumbnailUri && !hasSequenceIds; else thumbnail"
          class="w-full h-full tui-skeleton"
        ></div>

        <ng-template #thumbnail>
          <pxc-sequence-media
            [src]="telemonitoring.thumbnailUri" 
            [clientCode]="telemonitoring.telemonitoring.clientCode" 
            data-testid="thumbnail"
          ></pxc-sequence-media>
        </ng-template>
      </div>
  
      <div class="grid lg:grid-cols-2 gap-3 flex-1">
      
        <div class="flex flex-col pt-1">
          <p class="line-clamp-2 leading-snug text-medium text-lg">
            {{name}}
          </p>
          <tui-badge *ngIf="!telemonitoring.telemonitoring.isActive || telemonitoring.patient.isArchived"
            value="Archivé" 
            data-testid="status">
          </tui-badge>
          <p class="text-slate-600 text-sm" data-testid="sequences-count" *ngIf="hasSequenceIds; else sequenceCountLoading">
            {{ telemonitoring.telemonitoring.sequenceIds?.length | i18nPlural : sequencesCountPluralMapping }}
          </p>
          <ng-template #sequenceCountLoading>
            <div class="tui-skeleton w-36 h-4"></div>
          </ng-template>
          <pxc-last-update-badge 
            *ngIf="telemonitoring.telemonitoring.lastActivity as lastActivity" 
            [lastUpdate]="lastActivity"
            class="mt-1"
          ></pxc-last-update-badge>
        </div>
      
        <div class="flex gap-3" *ngIf="displayPatient">
          <pxc-patient-avatar [patient]="telemonitoring.patient" data-testid="avatar" class="shrink-0" size="small"></pxc-patient-avatar>
          <pxc-patient-data [patient]="telemonitoring.patient" data-testid="patient" class="grow" size="small"></pxc-patient-data>
        </div>
      </div>
    
    </div>

  </div>
    
</tui-island>