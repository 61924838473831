import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TuiAlertService } from '@taiga-ui/core';
import { Observable, combineLatest, first, map, switchMap } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { ClientUserHttpService } from 'src/app/services/http/client-user.http.service';
import { AdministrationUser } from 'src/app/shared/models/administration-user';
import { UserAdminState } from 'src/app/shared/models/enums/user-admin-state.enum';
import { usersActions } from 'src/app/shared/store/users/users.actions';
import { msToTime } from 'src/app/shared/utils/utils';
import { ClientAddUserComponent } from '../../dashboard/client-add-user/client-add-user.component';
import { ClientAdminService } from '../client-admin/client-admin.service';
import { selectClientUsers } from 'src/app/shared/store/users/users.selectors';
import { selectClientCode } from 'src/app/shared/store/router/router.selectors';
import { ClientUser, HttpCustomErrorCode, LicenseState, writeAccessLicenseStates } from '@pixacare/pxc-ts-core';

@Injectable()
export class ClientUsersAdminService {

  private administrationUsers$: Observable<AdministrationUser[]>;

  private readonly clientAdminService = inject(ClientAdminService);
  private readonly store = inject(Store);
  private readonly dialogService = inject(DialogService);
  private readonly clientUserService = inject(ClientUserHttpService);
  private readonly alertService = inject(TuiAlertService);

  loadClientAdministrationUsers$(): Observable<AdministrationUser[]> {
    if (this.administrationUsers$) {
      return this.administrationUsers$;
    }

    this.administrationUsers$ = this.store.select(selectClientCode).pipe(
      switchMap((clientCode) => this.store.select(selectClientUsers(clientCode))),
      map((clientUsers) =>
        clientUsers.map((clientUser) => ({
          ...clientUser,
          state: this.getUserState(clientUser),
          isAdmin: clientUser.licenseState === LicenseState.ADMINISTRATOR,
        })),
      ),
    );

    return this.administrationUsers$;
  }

  enableUser(user: AdministrationUser): void {
    combineLatest([
      this.clientAdminService.clientCode$,
      this.dialogService.openConfirm( `Êtes-vous certain de vouloir réactiver ${user.firstName} ${user.lastName} ?`, {
        content: "Cet utilisateur pourra à nouveau accéder à l'application.",
        yes: "Réactiver l'utilisateur",
        no: 'Annuler',
      }),
    ]).pipe(first()).subscribe(([clientCode, confirm]) => {
      if (confirm) {
        this.store.dispatch(
          usersActions.updateUserLicenseState({
            clientCode,
            licenseState: LicenseState.BASIC,
            userId: user.id,
          }));
      }
    });
  }

  disableUser(user: AdministrationUser): void {
    combineLatest([
      this.clientAdminService.clientCode$,
      this.dialogService.openConfirm( `Êtes-vous certain de vouloir désactiver ${user.firstName} ${user.lastName} ?`, {
        content: "Cet utilisateur ne pourra plus accéder à l'application.",
        yes: "Désactiver l'utilisateur",
        no: 'Annuler',
      }),
    ]).pipe(first()).subscribe(([clientCode, confirm]) => {
      if (confirm) {
        this.store.dispatch(
          usersActions.updateUserLicenseState({
            clientCode,
            licenseState: LicenseState.DISABLED,
            userId: user.id,
          }));
      }
    });
  }

  resendInvitation(user: AdministrationUser): void {
    this.clientUserService.resendInvitation(user.id).subscribe({
      next: () => {
        this.alertService.open('L\'invitation a bien été ré-envoyée.', {
          label: 'Opération réussie',
          status: 'success',
        }).subscribe();
      },
      error: (err: HttpErrorResponse) => {
        const title = 'Opération échouée';
        let message = '';
        if (err.status === 429 && err.error.customErrorCode === HttpCustomErrorCode.INVITATION_LINK_COOLDOWN) {
          const time = msToTime(err.error.cooldown);
          message = "Il n'est pas possible d'envoyer plus d'un mail par jour, "
            + `veuillez réessayer dans ${time}.`;
        } else {
          message = "Une erreur s'est produite, "
            + 'merci de réessayer ou de contacter votre administrateur.';
        }
        this.alertService.open(message, {
          label: title,
          status: 'error',
        }).subscribe();
      },
    });
  }

  adminChange(user: AdministrationUser): void {
    this.clientAdminService.clientCode$
      .pipe(first()).subscribe((clientCode) => {
        this.store.dispatch(
          usersActions.updateUserLicenseState({
            clientCode,
            licenseState: user.isAdmin ? LicenseState.BASIC : LicenseState.ADMINISTRATOR,
            userId: user.id,
          }),
        );
      });
  }

  inviteUsers(): void {
    combineLatest([
      this.clientAdminService.clientCode$,
      this.dialogService.openComponent
      <ClientAddUserComponent, string[]>(ClientAddUserComponent, {
        label: 'Ajouter des utilisateurs',
      }),
    ]).pipe(first())
      .subscribe(([clientCode, value]) => {
        if (value && value.length > 0) {
          this.store.dispatch(
            usersActions.addUsers({
              clientCode,
              departmentId: null,
              mailAddresses: value,
            }),
          );
        }
      });
  }

  protected getUserState(clientUser: ClientUser): UserAdminState {
    if (clientUser?.licenseState === LicenseState.DISABLED) {
      return UserAdminState.DISABLED;
    }
    if (clientUser?.licenseState === LicenseState.INVITED) {
      return UserAdminState.INVITED;
    }
    if (writeAccessLicenseStates.includes(clientUser?.licenseState)) {
      return UserAdminState.ENABLED;
    }
  }

}
