<div class="flex justify-center items-center rounded-full select-none"
    [ngClass]="{
        'w-8 h-8': size === Size.TINY,
        'w-10 h-10': size === Size.SMALL,
        'w-12 h-12 ': size === Size.MEDIUM, 
        'w-16 h-16': size === Size.LARGE
    }"
    [ngStyle]="{
        'background-color': avatarColors.backgroundColor
    }"
    data-testid="avatar"
>
  <p class="font-bold"  
    [ngClass]="{
      'text-xs': size === Size.TINY,
      'text-lg': size === Size.MEDIUM,
      'text-xl': size === Size.LARGE
    }"
    [ngStyle]="{
      'color': avatarColors.textColor
    }">
    {{text}}
  </p>
</div>