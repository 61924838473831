import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { departmentsActions } from './departments.actions';
import { labelsActions } from '../labels/labels.actions';
import { notificationsActions } from '../notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, switchMap, catchError, concatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import { DepartmentHttpService } from 'src/app/services/http/department.http.service';
import { DepartmentResponse } from '@pixacare/pxc-ts-core';

@Injectable()
export class DepartmentsEffects {

  getDepartments$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.getDepartments),
      concatMap(({ clientCode }) =>
        this.departmentService.getDepartments(clientCode)
          .pipe(
            switchMap((departments: DepartmentResponse[]) =>
              [
                departmentsActions.getDepartmentsSuccess({
                  clientCode,
                  departments,
                }),
                labelsActions.addLabels({
                  labels: departments.reduce((deptAcc, dept) => [
                    ...deptAcc,
                    ...dept.labelPacks.reduce((labelsAcc, labelPack) => [...labelsAcc, ...labelPack.labels], []),
                  ], []),
                }),
                labelsActions.addLabelPack({
                  labelPacks: [].concat(...departments.map((department) => department.labelPacks)),
                }),
              ],
            ),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  updateCollaboration$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.updateCollaboration),
      mergeMap(({ clientCode, departmentId, isOpen }) =>
        this.departmentService.updateCollaboration(
          clientCode, departmentId, isOpen,
        )
          .pipe(
            switchMap(() => [
              departmentsActions.updateCollaborationSuccess({
                clientCode, departmentId, isOpen,
              }),
              notificationsActions.success({
                message: 'Le mode de collaboration a bien été changé.',
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  deleteDepartment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.deleteDepartment),
      mergeMap(({ clientCode, departmentId }) =>
        this.departmentService.delete(clientCode, departmentId)
          .pipe(
            mergeMap(() => [
              departmentsActions.getDepartments({
                clientCode,
              }),
              notificationsActions.success({
                title: 'Groupe supprimé',
                message: 'Le groupe a bien été supprimé.',
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  createDepartment$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.createDepartment),
      mergeMap(({ clientCode, name }) =>
        this.departmentService.create(clientCode, name)
          .pipe(
            mergeMap(() => [
              departmentsActions.getDepartments({
                clientCode,
              }),
              notificationsActions.success({
                title: 'Groupe créé',
                message: `Le groupe ${name} a bien été créé.`,
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  updateDepartmentUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.updateDepartmentUser),
      mergeMap(({
        clientCode, departmentId, departmentUserId, membership,
      }) =>
        this.departmentService.updateUser(
          clientCode, departmentId, departmentUserId, membership.isAdmin, membership.disabledOn,
        )
          .pipe(
            switchMap(() => [
              departmentsActions.updateDepartmentUserSuccess({
                clientCode, departmentId, departmentUserId, membership,
              }),
              notificationsActions.success({
                title: 'Utilisateur mis à jour',
                message: 'Les informations de l\'utilisateur ont bien été modifiées',
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  removeDepartmentUser$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(departmentsActions.removeDepartmentUser),
      mergeMap(({
        clientCode, departmentId, userId,
      }) =>
        this.departmentService.removeUser(
          userId, clientCode, departmentId,
        )
          .pipe(
            switchMap(() => [
              departmentsActions.removeDepartmentUserSuccess({
                clientCode, departmentId, userId,
              }),
              notificationsActions.success({
                title: 'Utilisateur mis à jour',
                message: 'Utilisateur retiré',
              }),
            ]),
            catchError((error: Error) => [
              lifeCycleActions.loadError({ error }),
              notificationsActions.error({
                title: "L'utilisateur n'a pas été retiré du groupe.",
                message: 'Une erreur s\'est produite.',
              }),
            ]),
          ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly departmentService: DepartmentHttpService,
  ) {}

}
