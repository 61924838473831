import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, debounceTime, distinctUntilChanged, first, map, mergeMap, tap } from 'rxjs';
import { ChatService } from '../chat.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Message } from 'src/app/shared/models/chat/message';

@UntilDestroy()
@Injectable()
export class ChannelPaneService {

  channelId$: Observable<string> = this.activatedRoute.params.pipe(
    untilDestroyed(this),
    map((params) => params.channelId),
    distinctUntilChanged(),
  );

  channel$ = this.channelId$.pipe(
    untilDestroyed(this),
    tap((channelId) => this.chatService.loadChannelMessages(channelId)),
    mergeMap((channelId) => this.chatService.channels$.pipe(
      map((channels) => channels.find((channel) => channel.id === channelId)),
      debounceTime(100),
    )),
  );

  messageGroups$ = this.chatService.getMessageGroups();
  messages$ = this.chatService.currentChannelMessages$;

  hasUnseenMessages$ = this.chatService.hasUnseenMessages$;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly chatService: ChatService,
  ) {}

  sendMessage(newMessage: Message): void {
    this.channelId$.pipe(first()).subscribe((channelId) => {
      this.chatService.sendMessage(channelId, newMessage);
    });
  }

  loadNext(): void {
    this.channelId$.pipe(first()).subscribe((channelId) => {
      this.chatService.loadChannelMessages(channelId, { reset: false });
    });
  }

  markMessagesAsSeen(): void {
    this.chatService.markMessagesAsSeen();
    this.channelId$.pipe(first()).subscribe((channelId) => {
      this.chatService.updateChannelUnreads(channelId);
    });
  }

}
