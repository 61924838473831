import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { TuiComparator, TuiTableModule } from '@taiga-ui/addon-table';
import { FILTER_TEMPLATES, FilterBarService } from '../../filters/filter-bar.service';
import { Observable, map, of } from 'rxjs';
import { SharedModule } from '../../shared/shared.module';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { TuiScrollbarModule } from '@taiga-ui/core';
import { match } from 'src/app/shared/utils/utils';
import { personCountPluralMapping, usagePluralMapping } from 'src/app/shared/utils/plural-mappings';
import { TuiLetModule } from '@taiga-ui/cdk';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { LabelComponent } from '../label/label.component';
import { ActionButton } from 'src/app/shared/models/menu-actions/action-buttons';
import { MenuActionAdapterService } from 'src/app/services/menu-action-adapter.service';
import { FilterBarComponent } from '../../filters/filter-bar/filter-bar.component';
import { StatusComponent } from '../../shared/status/status.component';
import { FlattenedLabel } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-label-table',
  standalone: true,
  imports: [
    CommonModule,
    ScrollingModule,
    TuiTableModule,
    LabelComponent,
    SharedModule,
    FilterBarComponent,
    TuiScrollbarModule,
    TuiLetModule,
    StatusComponent,
  ],
  templateUrl: './label-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FilterBarService,
    MenuActionAdapterService,
    {
      provide: FILTER_TEMPLATES,
      useValue: of([]),
    },
  ],
})
export class LabelTableComponent implements OnInit, OnChanges {

  @Input() labels: FlattenedLabel[];
  @Input() actions: MenuAction[];
  @Input() displayStats = false;

  Status = Status;
  personCountPluralMapping = personCountPluralMapping;
  usagePluralMapping = usagePluralMapping;

  search$: Observable<string>;
  labels$: Observable<FlattenedLabel[]>;

  columns: string[];
  actionButtons: ActionButton[];

  private readonly filterBarService = inject(FilterBarService);
  private readonly menuActionAdapterService = inject(MenuActionAdapterService);

  constructor() {
    this.search$ = this.filterBarService.search$;
  }

  ngOnInit(): void {

    this.actionButtons = this.menuActionAdapterService.menuActionsToActionsButtons(this.actions);

    this.columns = [
      'labelInstance.word',
      ...(this.displayStats ? ['data.subscribersCount', 'userLabelStatsInstance.count'] : []),
      'createdBy', 'actions',
    ];

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labels) {
      this.labels$ = this.search$.pipe(
        map((search) => this.labels.filter((fLabel) => match(search, [fLabel.labelInstance.word]))),
      );
    }
  }

  labelSorter: TuiComparator<FlattenedLabel> = (a, b) =>
    a.labelInstance?.word.localeCompare(b.labelInstance?.word);

  usersSorter: TuiComparator<FlattenedLabel> = (a, b) =>
    (a.data?.subscribersCount ?? -1) - (b.data?.subscribersCount ?? -1);

  usageSorter: TuiComparator<FlattenedLabel> = (a, b) =>
    (a.userLabelStatsInstance?.count ?? -1) - (b.userLabelStatsInstance?.count ?? -1);

  createdBySorter: TuiComparator<FlattenedLabel> = (a, b) =>
    (a.createdBy?.firstName ?? 'zz').localeCompare(b.createdBy?.firstName ?? 'zz');

  trackByLabelId(index: number, label: FlattenedLabel): number {
    return label.labelInstance.id;
  }

}
