<ng-container [ngSwitch]="state">
    <tui-badge
        *ngSwitchCase="UserState.ENABLED"
        status="success"
        value="Actif{{count ? 's : ' + count : ''}}"
    ></tui-badge>
    <tui-badge
        *ngSwitchCase="UserState.INVITED"
        status="neutral"
        value="Invitation envoyée{{count ? 's : ' + count : ''}}"
    ></tui-badge>
    <tui-badge
        *ngSwitchCase="UserState.DISABLED"
        status="warning"
        value="Désactivé{{count ? 's : ' + count : ''}}"
    ></tui-badge>
</ng-container>