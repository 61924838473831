import {
  Directive, Input, TemplateRef, ViewContainerRef,
} from '@angular/core';
import { Patient } from '@pixacare/pxc-ts-core';
import { CreateContentDirective } from './create-content.directive';
import { AuthorizationsService } from 'src/app/services/authorizations.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[showIfClientIsGamEnabled]',
})
export class ShowIfClientEnabledDirective extends CreateContentDirective {

  constructor(
    protected authorizationsService: AuthorizationsService,
    protected templateRef: TemplateRef<any>,
    protected container: ViewContainerRef,
  ) {
    super(templateRef, container);
  }

  @Input()
  set showIfClientIsGamEnabled(patient: Patient) {
    this.authorizationsService.isClientGamEnabled$()
      .pipe(untilDestroyed(this))
      .subscribe((isEnabled) => {
        if (patient) {
          this.createContent(isEnabled && patient.isGamLinked);
        } else {
          this.createContent(isEnabled);
        }
      });
  }

}
