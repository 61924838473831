import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, first, switchMap } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { MessageAttachment } from 'src/app/shared/models/chat/message-attachment';
import { SequencePickerComponent } from '../../sequence/sequence-picker/sequence-picker.component';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';
import { Store } from '@ngrx/store';
import { selectClientCode } from 'src/app/shared/store/router/router.selectors';

@Injectable()
export class MessageInputService {

  readonly attachment$: Observable<MessageAttachment>;
  readonly sendMessage$: Observable<void>;

  private readonly attachmentSubject$ = new BehaviorSubject<MessageAttachment>(null);
  private readonly sendMessageSubject$ = new BehaviorSubject<void>(null);

  private readonly dialogService = inject(DialogService);
  private readonly store = inject(Store);

  constructor() {
    this.attachment$ = this.attachmentSubject$.asObservable();
    this.sendMessage$ = this.sendMessageSubject$.asObservable();
  }

  updateAttachment(attachment: MessageAttachment): void {
    this.attachmentSubject$.next(attachment);
  }

  selectAttachment(): void {
    this.store.select(selectClientCode).pipe(
      first(),
      switchMap((clientCode) => this.dialogService.openComponent(SequencePickerComponent, {
        label: 'Sélectionnez la séquence à partager',
        size: 'l',
        data: {
          clientCode,
          singleSelectMode: true,
        },
      })),
    ).subscribe(({ sequences }: { sequences: FlattenedSequence[] }) => {
      if (sequences.length === 0) { return; }
      this.updateAttachment({ sequence: sequences[0] });
      this.sendMessageSubject$.next();
    });
  }

}
