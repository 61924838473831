import { createReducer, on } from '@ngrx/store';
import { patientsActions } from './patients.actions';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { initialPatientsState, PatientsState } from './patients.state';
import { getNextPagedCollection } from '../../models/helpers/pagination-helper';
import { patientPaginationContext } from '../../models/pagination/pagination-context.config';
import { Patient } from '@pixacare/pxc-ts-core';
import { SortOrderStrategy } from '../../models/enums/sort-order-strategy.enum';
import { toArchivable } from '../archivable.adapter';

const patientSortByLastActivityOrder = (p1: Patient, p2: Patient): number =>
  p2.lastActivity.getTime() - p1.lastActivity.getTime();

const patientSortByAlphabeticalOrder = (p1: Patient, p2: Patient): number => {
  const lastNameComparison = p1.lastName?.localeCompare(p2.lastName);
  if (lastNameComparison === 0) {
    return p1.firstName?.localeCompare(p2.firstName);
  }
  return lastNameComparison;
};

const patientSort = (patientId1, patientId2, patients, order: SortOrderStrategy) => {
  switch (order) {
    case SortOrderStrategy.LAST_ACTIVITY:
      return patientSortByLastActivityOrder(patients[patientId1], patients[patientId2]);
    case SortOrderStrategy.ALPHABETICAL:
      return patientSortByAlphabeticalOrder(patients[patientId1], patients[patientId2]);
    default:
      return patientSortByLastActivityOrder(patients[patientId1], patients[patientId2]);
  }
};

export const patientsReducer = createReducer(
  initialPatientsState,

  on(lifeCycleActions.resetState, patientsActions.resetPatientsState, () => initialPatientsState),

  on(patientsActions.createPatientSuccess, (state: PatientsState, { clientCode, patient }) => ({
    ...state,
    patients: {
      [clientCode]: {
        ...state.patients[clientCode],
        data: {
          ...state.patients[clientCode]?.data,
          [patient.id]: patient,
        },
        sorted: [
          patient.id,
          ...state.patients[clientCode]?.sorted,
        ],
      },
    },
  })),


  on(patientsActions.updatePatientSuccess, (state: PatientsState, { clientCode, patient }) => {
    if (patient) {
      return {
        ...state,
        patients: {
          ...state.patients,
          [clientCode]: {
            ...state.patients[clientCode],
            data: {
              ...state.patients[clientCode]?.data,
              [patient.id]: {
                ...(state.patients[clientCode]?.data || {})[patient.id],
                ...patient,
              },
            },
            sorted: [...state.patients[clientCode]?.sorted || []].sort((patientId1, patientId2) =>
              patientSort(patientId1, patientId2, state.patients[clientCode]?.data, state.order),
            ),
          },
        },
        loadState: {
          ...state.loadState,
          [clientCode]: true,
        },
      };
    } else {
      return state;
    }
  }),

  on(patientsActions.updatePatientsSuccess, (state: PatientsState, { clientCode, patients }) => ({
    ...state,
    patients: {
      [clientCode]: {
        ...state.patients[clientCode],
        data: {
          ...state.patients[clientCode]?.data,
          ...patients.reduce((acc, patient) => {
            if (patient) {
              const existingPatient = state.patients[clientCode]?.data?.[patient.id];
              acc[patient.id] = {
                ...existingPatient,
                ...patient,
              };
            }
            return acc;
          }, {} as { [patientId: number]: Patient }),
        },
        sorted: [...state.patients[clientCode]?.sorted || []].sort((patientId1, patientId2) =>
          patientSort(patientId1, patientId2, state.patients[clientCode]?.data, state.order),
        ),
      },
    },
    loadState: {
      [clientCode]: true,
    },
  })),

  on(patientsActions.getNextPatients, (state, { clientCode, options: { reset } }) => ({
    ...state,
    patients: {
      [clientCode]: {
        ...state.patients[clientCode],
        context: {
          ...state.patients[clientCode]?.context,
          isNextPageLoading: true,
        },
        ...(reset && { sorted: [], data: null }),
      },
    },
    loadState: {
      [clientCode]: reset ? false : state.loadState[clientCode],
    },
  }),
  ),

  on(patientsActions.getNextPatientsSuccess, (state, { clientCode, reset, collection }): PatientsState => ({
    ...state,
    patients: {
      [clientCode]: getNextPagedCollection({
        currentCollection: state.patients[clientCode],
        nextCollection: collection,
      }, {
        reset,
        paginationContext: patientPaginationContext,
        sortPredicate: (entityId1, entityId2, entities) => (
          patientSort(entityId1, entityId2, entities, state.order)
        ),
      }),
    },
    loadState: {
      [clientCode]: true,
    },
  })),

  on(patientsActions.getPatientSuccess, (state, { clientCode, collection }): PatientsState => ({
    ...state,
    patients: {
      [clientCode]: {
        ...state.patients[clientCode],
        data: {
          ...state.patients[clientCode]?.data,
          ...collection.data.reduce((acc, patient) => {
            if (patient) {
              acc[patient.id] = patient;
            }
            return acc;
          }
          , {}),
        },
      },
    },
    loadState: {
      [clientCode]: true,
    },
  })),

  on(patientsActions.removePatient, (state: PatientsState, { clientCode, patientId }) => {

    const { [patientId]: deletedPatient, ...data } = state.patients[clientCode].data || {};

    return {
      ...state,
      patients: {
        [clientCode]: {
          ...state.patients[clientCode],
          data,
          sorted: [
            ...(state.patients[clientCode]?.sorted || []).filter((id) => patientId !== id),
          ],
        },
      },
    };
  }),

  on(patientsActions.setOrder, (state: PatientsState, { order }) => ({
    ...state,
    order,
  })),

  on(
    patientsActions.archivePatientSuccess,
    patientsActions.unarchivePatientSuccess,
    (state: PatientsState, { clientCode, patient }) => ({
      ...state,
      patients: {
        ...state.patients,
        [clientCode]: {
          ...state.patients[clientCode],
          data: {
            ...state.patients[clientCode]?.data,
            [patient.id]: {
              ...state.patients[clientCode]?.data[patient.id],
              ...toArchivable(patient),
            },
          },
        },
      },
    }),
  ),

);
