<div class="flex flex-col gap-3 min-h-400">
  
  <pxc-filter-bar 
        searchPlaceholder="Rechercher un patient par nom, prénom, nip ou DDN ..."
        data-testid="filter-bar"
  ></pxc-filter-bar>

  <ng-container *ngIf="(collection$ | async) as collection; else loadingBlock">      
      <ng-container *ngIf="(isCollectionLoaded$ | async); else loadingBlock">
        <section
          *ngIf="collection.data.length > 0; else noPatientBlock"
          class="overflow-y-auto flex flex-col gap-3 max-h-[70vh] flex-1"
          scroll-tracker
          [threshold]="{bottom: 300}"
          (bottomThreshold)="loadNextPatients()"
          [bottomGuard]="collection.context.isNextPageLoading"
          data-testid="scroll-viewport"
        >
          <pxc-status *ngIf="collection.data.length === 0" [state]="Status.BASIC" title="Aucun résultat"
            description="Aucun patient ne correspond à votre recherche." icon="search-outline"
            data-testid="no-result-message">
          </pxc-status>

          <ng-container *ngFor="let patient of collection.data">
            <pxc-patient-card
              class="cursor-pointer"
              (click)="selectTargetPatient(patient)"
              [patient]="patient"
              [attr.data-testid]="'patient-'+patient.id"
              [isFocusable]="true"
              [displayLastActivity]="true"
            >
            <pxc-patient-link-quick-action *showIfClientIsGamEnabled="patient"></pxc-patient-link-quick-action>
          </pxc-patient-card>
          </ng-container>

          <div class="flex items-center justify-center">
            <pxc-spinner [isVisible]="collection.context.isNextPageLoading"></pxc-spinner>
          </div>
        </section>
      </ng-container>
    
  </ng-container>
  
  <ng-template #noPatientBlock>
    <tui-notification data-testid="no-patient-message">
      Il n'y a aucun patient disponible pour une fusion.
    </tui-notification>
  </ng-template>

  <ng-template #loadingBlock>
    <div class="flex items-center justify-center flex-1">
      <pxc-loader>
        Chargement des patients...
      </pxc-loader>
    </div>
  </ng-template>
  
  <div class="flex justify-between gap-2 mt-4 flex-wrap">
    <button tuiButton appearance="outline" (click)="cancel()" data-testid="cancel-button" class="max-md:w-full">
      Annuler
    </button>
    <button tuiButton [disabled]="targetPatient === null" (click)="mergePatients()" class="max-md:w-full max-md:order-first"
      data-testid="confirm-button">
      Fusionner
    </button>
  </div>
</div>