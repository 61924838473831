import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { ChannelCreateService } from '../modules/chat/channel-create/channel-create.service';

@Injectable()
export class RedirectToChannelGuard implements CanActivate {

  private channelCreateService = inject(ChannelCreateService);

  canActivate(route: ActivatedRouteSnapshot): boolean  {
    const { userId, firstName, lastName } = route.queryParams;
    if (userId && firstName && lastName) {
      this.channelCreateService.createChannel([{ id: +userId, firstName, lastName }]);
      return false;
    } else {
      return true;
    }
  }

}
