<tui-scrollbar class="max-h-[65vh]">

<pxc-filter-buttons></pxc-filter-buttons>

<ng-container *ngIf="sadms$ | async as sadms; else emptyState">
  <ng-container *ngIf="isDataLoaded$ | async; else loadingBlock">
    <div *ngIf="sadms.length; else emptyState" class="flex flex-col gap-12 py-4">
      <div *ngFor="let sadm of sadms" class="flex flex-col gap-2">
        <div class="flex justify-between gap-4 max-md:flex-col">
          <pxc-title
            title
            [title]="SadmTypeLabel[sadm.sadmType] | pluralize | titlecase"
            titleSize="small"
          ></pxc-title>
          <button 
            tuiButton 
            *tuiLet="patient$ | async as patient" 
            appearance="secondary" 
            size="s" 
            [disabled]="patient.isArchived" 
            (click)="createEntity(sadm)" 
            [attr.data-testid]="'add-'+sadm.id+'-button'" icon="tuiIconPlusLarge"
          >Ajouter</button>
        </div>
        <pxc-sadm-entity-list
          [sadmsEntities]="sadm.entities"
          [sadmType]="sadm.sadmType"
          [attr.data-testid]="sadm.name"
        ></pxc-sadm-entity-list>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loadingBlock>
  <div class="mx-auto my-4">
    <pxc-loader data-testid="loading">Récupération des questionnaires en cours ...</pxc-loader>
  </div>
</ng-template>

<ng-template #emptyState>
  <div class="flex flex-col gap-2 items-center justify-center mx-auto my-3" data-testid="empty-state">

    <pxc-lottie-label
      animation="sequenceEmpty"
      message="Vous n'avez pas de questionnaires"
    ></pxc-lottie-label>

    <p class="max-w-prose mx-auto text-center">Contactez Pixacare pour activer un module de plaie.</p>

  </div>
</ng-template>

</tui-scrollbar>
