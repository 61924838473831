import Konva from 'konva';
import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { EventTopic } from '../event-bus/event-bus.interfaces';
import { DrawConfig } from '../config/studio-interfaces';
import { EventBus } from '../event-bus/event-bus';

export class DrawEllipseCommand implements Command {

  receiver: StudioCanvas;
  eventBus: EventBus;
  drawConfig: DrawConfig;
  constructor(receiver: StudioCanvas, eventBus: EventBus, drawConfig: DrawConfig) {
    this.receiver = receiver;
    this.eventBus = eventBus;
    this.drawConfig = drawConfig;
  }

  execute(): void {

    let isUserDrawing = false;

    const drawingEllipse: Konva.Ellipse = new Konva.Ellipse({
      radiusX: 0,
      radiusY: 0,
      visible: false,
      strokeScaleEnabled: false,
    });
    this.receiver.addTempShape(drawingEllipse);

    let x1: number;
    let y1: number;
    let x2: number;
    let y2: number;

    this.receiver.stage.on('mousedown touchstart', (e) => {
      drawingEllipse.stroke(this.drawConfig.color.code);
      drawingEllipse.strokeWidth(this.drawConfig.width);
      if (e.currentTarget !== this.receiver.stage) {
        return;
      }
      x1 = this.receiver.layer.getRelativePointerPosition().x;
      y1 = this.receiver.layer.getRelativePointerPosition().y;

      isUserDrawing = true;
      drawingEllipse.width(0);
      drawingEllipse.height(0);
    });

    this.receiver.stage.on('mousemove touchmove', (e) => {
      if (!isUserDrawing) {
        return;
      }
      if (!drawingEllipse.isVisible()) {
        drawingEllipse.visible(true);
      }
      x2 = this.receiver.layer.getRelativePointerPosition().x;
      y2 = this.receiver.layer.getRelativePointerPosition().y;

      if (e.evt.shiftKey) {
        drawingEllipse.setAttrs({
          x: x1,
          y: y1,
          width: Math.max(Math.abs(y2 - y1), Math.abs(x2 - x1)),
          height: Math.max(Math.abs(y2 - y1), Math.abs(x2 - x1)),
        });

        const midOffsetX = drawingEllipse.getAttr('width') / 2;
        const midOffsetY = drawingEllipse.getAttr('height') / 2;
        drawingEllipse.offsetX((x2 < x1) ? midOffsetX : -midOffsetX);
        drawingEllipse.offsetY((y2 < y1) ? midOffsetY : -midOffsetY);
      } else {
        drawingEllipse.setAttrs({
          x: x1,
          y: y1,
          width: Math.abs(x2 - x1),
          height: Math.abs(y2 - y1),
        });

        drawingEllipse.offsetX(-(x2 - x1) / 2);
        drawingEllipse.offsetY(-(y2 - y1) / 2);
      }

      if (e.evt.altKey) {
        drawingEllipse.setAttrs({
          width: Math.abs(x2 - x1) * 2,
          height: Math.abs(y2 - y1) * 2,
        });
        drawingEllipse.offsetX(0);
        drawingEllipse.offsetY(0);
        if (e.evt.shiftKey) {
          drawingEllipse.setAttrs({
            width: Math.max(Math.abs(y2 - y1), Math.abs(x2 - x1) * 2),
            height: Math.max(Math.abs(y2 - y1), Math.abs(x2 - x1) * 2),
          });
        }
      }
    });

    this.receiver.stage.on('mouseup touchend', () => {
      isUserDrawing = false;
      if (!drawingEllipse.visible()) {
        return;
      }
      drawingEllipse.visible(false);

      // Create Ellipse
      const ellipse = new Konva.Ellipse({
        x: drawingEllipse.getAttr('x'),
        y: drawingEllipse.getAttr('y'),
        radiusX: drawingEllipse.getAttr('radiusX'),
        radiusY: drawingEllipse.getAttr('radiusY'),
        stroke: this.drawConfig.color.code,
        strokeWidth: this.drawConfig.width,
        offsetX: drawingEllipse.offsetX(),
        offsetY: drawingEllipse.offsetY(),
      });

      drawingEllipse.destroy();
      this.receiver.addShape(ellipse);
      drawingEllipse.destroy();
      this.eventBus.publish(EventTopic.AUTO_SELECT, { shapes: [ellipse] });
    });
  }

}
