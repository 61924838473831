import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { LABELS_FEATURE_KEY } from './labels.selectors';
import { labelsReducer } from './labels.reducer';
import { EffectsModule } from '@ngrx/effects';
import { LabelsEffect } from './labels.effects';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(LABELS_FEATURE_KEY, labelsReducer),
    EffectsModule.forFeature([LabelsEffect]),
  ],
  declarations: [],
})
export class LabelStoreModule { }
