import {
  Input, Component, OnInit, HostListener, Inject, ViewChild, ChangeDetectorRef,
} from '@angular/core';
import { StudioCanvas } from '../canvas/studio-canvas';
import { CommandInvoker } from '../commands/command-invoker';
import { DownloadCommand } from '../commands/download-command';
import { DrawEllipseCommand } from '../commands/draw-ellipse-command';
import { DrawRectangleCommand } from '../commands/draw-rectangle-command';
import { DrawFreeCommand } from '../commands/draw-free-command';
import { SetBackgroundCommand } from '../commands/set-background-command';
import { SelectCommand } from '../commands/select-command';
import { SaveCommand } from '../commands/save-command';
import { RotateCommand } from '../commands/rotate-command';
import { ValidateCropCommand } from '../commands/validate-crop-command';
import { PrepareCropCommand } from '../commands/prepare-crop-command';
import { ImportAnnotationsCommand } from '../commands/import-annotations-command';
import { GlobalKeystrokesHandler } from './global-keystrokes-handler';
import { FreeDrawMode, Tool } from '../config/studio-enums';
import {
  Receiver, EventSubject, EventTopic,
} from '../event-bus/event-bus.interfaces';
import { EventBus } from '../event-bus/event-bus';
import { studioTools } from '../config/studio-tools';
import {
  DrawConfig, ColorPickerConfig,
} from '../config/studio-interfaces';
import { studioColors } from '../config/studio-colors.config';
import { StudioService } from './studio.service';
import { DrawLineCommand } from '../commands/draw-line-command';
import {
  TuiAlertService,
  TuiButtonModule,
  TuiDialogContext,
  TuiDialogService,
  TuiHintModule,
  TuiHostedDropdownModule,
  TuiSvgModule,
} from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Subject } from 'rxjs';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ColorPickerComponent } from '../color-picker/color-picker.component';
import { CropConfig, Media, PictureAnnotations } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-studio',
  templateUrl: './studio.component.html',
  styles: [`
    :host {
      height: 100vh;
      display: block;
    }
  `],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TuiButtonModule,
    TuiHintModule,
    TuiSvgModule,
    ColorPickerComponent,
    TuiHostedDropdownModule,
  ],
})
export class StudioComponent implements OnInit, Receiver {

  @ViewChild('closeConfirm') closeConfirmTemplate;

  @Input() imageData: Media = this.context.data;
  backgroundImage = new Image();

  canvas: StudioCanvas;
  eventBus: EventBus = new EventBus();
  commandInvoker: CommandInvoker = new CommandInvoker();
  globalKeystrokeHandler: GlobalKeystrokesHandler;
  tool = Tool;
  studioTools = studioTools;
  selectedToolName = '';
  isEverythingSaved = true;
  isModificationDone = false;
  isSaveOngoing = false;

  cropConfig: CropConfig = {
    x: null, y: null, width: null, height: null,
  };

  currentCropConfig: CropConfig = {
    x: null, y: null, width: null, height: null,
  };

  drawConfig: DrawConfig = {
    color: studioColors[0],
    width: 3,
  };

  colorPickerConfig: ColorPickerConfig = {
    minWidth: 1,
    maxWidth: 40,
    displayWidth: 30,
  };

  savedAnnotations: PictureAnnotations;

  isPictureLoading = true;

  constructor(
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<string, Media>,
    private readonly dialogService: TuiDialogService,
    private readonly studioService: StudioService,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    if ((event.target as Element).nodeName !== 'INPUT') {
      this.eventBus.publish(EventTopic.KEY_PRESSED, { keyPressed: event.key, toolSwitchName: this.selectedToolName });
    }
  }

  async ngOnInit() {
    this.canvas = new StudioCanvas('canvas-tools', this.eventBus, this.studioService);
    this.globalKeystrokeHandler = new GlobalKeystrokesHandler(this.eventBus, this.canvas);

    this.loadEventBusSubscriptions();
    this.loadCommands();

    this.studioService.getPicture(this.imageData)
      .then(async (response) => {
        this.imageData.image = response.uri;
        await this.loadBackground();
        if (response.isAnnoted && response.annotations) {
          this.commandInvoker.set(Tool.IMPORT_ANNOTATIONS, new ImportAnnotationsCommand(
            this.canvas, this.eventBus, response.annotations));
          this.commandInvoker.execute(Tool.IMPORT_ANNOTATIONS);
        }
      })
      .then(() => {
        this.isPictureLoading = false;
        this.onToolClick(Tool.DEFAULT_TOOL);
        this.changeDetectorRef.detectChanges();
      })
      .catch((error) => {
        this.alertService.open(`Statut: ${error.status}`, {
          label: 'Erreur lors du chargement',
          status: 'error',
        }).subscribe();
      });

    // avoid focus on colorPicker
    if (document.activeElement instanceof HTMLElement) { document.activeElement.blur(); }
  }

  loadCommands(): void {
    this.commandInvoker.set(Tool.DRAW_RECTANGLE, new DrawRectangleCommand(this.canvas, this.eventBus, this.drawConfig));
    this.commandInvoker.set(Tool.DRAW_ELLIPSE, new DrawEllipseCommand(this.canvas, this.eventBus, this.drawConfig));
    this.commandInvoker.set(Tool.DRAW_LINE, new DrawLineCommand(
      this.canvas, this.eventBus, this.drawConfig, this.studioService));
    this.commandInvoker.set(Tool.SET_BACKGROUND, new SetBackgroundCommand(
      this.canvas, this.backgroundImage, this.cropConfig, this.eventBus));
    this.commandInvoker.set(Tool.FREE_DRAW, new DrawFreeCommand(this.canvas, {
      mode: FreeDrawMode.BRUSH,
      drawConfig: this.drawConfig,
    }));
    this.commandInvoker.set(Tool.ERASER, new DrawFreeCommand(this.canvas, {
      mode: FreeDrawMode.ERASER,
      drawConfig: this.drawConfig,
    }));
    this.commandInvoker.set(Tool.SHAPE_SELECTION, new SelectCommand(this.canvas));
    this.commandInvoker.set(Tool.ROTATE, new RotateCommand(this.canvas, this.eventBus));
    this.commandInvoker.set(Tool.PREPARE_CROP, new PrepareCropCommand(
      this.canvas, this.currentCropConfig, this.eventBus));
    this.commandInvoker.set(Tool.VALIDATE_CROP, new ValidateCropCommand(
      this.canvas, this.cropConfig, this.eventBus));
  }

  async loadBackground(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.backgroundImage.src = this.imageData.image;
      this.backgroundImage.crossOrigin = 'Anonymous';
      this.backgroundImage.onload = () => {
        this.commandInvoker.execute(Tool.SET_BACKGROUND);
        this.commandInvoker.set(Tool.DOWNLOAD, new DownloadCommand(
          this.canvas,
          this.backgroundImage.width / this.canvas.stage.width(),
        ));
        this.commandInvoker.set(Tool.SAVE_PICTURE, new SaveCommand(
          this.canvas,
          this.imageData,
          this.cropConfig,
          this.backgroundImage.width / this.canvas.stage.width(),
          this.eventBus,
          this.studioService,
        ));
        resolve();
      };
    });
  }

  loadEventBusSubscriptions(): void {
    this.eventBus.subscribe(EventTopic.AUTO_SELECT, this);
    this.eventBus.subscribe(EventTopic.SWITCH_TOOL, this);
    this.eventBus.subscribe(EventTopic.SAVE, this);
    this.eventBus.subscribe(EventTopic.MODIFY_ANNOTATIONS, this);
    this.eventBus.subscribe(EventTopic.REVERT_CROP, this);
    this.eventBus.subscribe(EventTopic.EXECUTE_CROP, this);
    this.eventBus.subscribe(EventTopic.CROP_FINISHED, this);
    this.eventBus.subscribe(EventTopic.ROTATION_FINISHED, this);
    this.eventBus.subscribe(EventTopic.UPDATE_CURRENT_CROPCONFIG, this);
    this.eventBus.subscribe(EventTopic.CLOSE, this);
  }

  onToolClick = (commandName: string): void => {
    if (this.isPictureLoading) {
      return;
    }

    this.selectedToolName = commandName;
    this.canvas.stage.off(); // remove all listener

    if (commandName !== Tool.SHAPE_SELECTION) {
      this.canvas.deselectShapes();
    }

    if (commandName !== Tool.PREPARE_CROP) {
      this.canvas.destroyCropSelection();
    }

    if (commandName) {
      this.commandInvoker.execute(commandName);
    }
  };

  getTooltipString(name: string, shortcut: string): string {
    return shortcut ? `${name} (${shortcut.toUpperCase()})` : name;
  }

  async savePicture(): Promise<void> {

    this.isSaveOngoing = true;

    this.changeDetectorRef.detectChanges();

    return new Promise(async (resolve, reject) => {

      try {
        await this.commandInvoker.execute(Tool.SAVE_PICTURE);
        this.alertService.open('Votre photographie a été correctement dupliquée', {
          label: 'Enregistrement réussi',
          status: 'success',
        }).subscribe();
        resolve();
      } catch {
        this.alertService.open('Une erreur est survenue lors de la duplication', {
          label: 'Erreur d\'enregistrement',
          status: 'error',
        }).subscribe();
        reject();
      } finally {
        this.isSaveOngoing = false;
      }
    });

  }

  closeWithoutSaving(): void {
    this.context.completeWith(null);
  }

  saveAndClose(confirmObserver: Subject<void>): void {
    this.savePicture()
      .then(() => {
        confirmObserver.complete();
        this.context.completeWith(this.getCanvasB64());
      });
  }

  closeStudio(): void {
    if (!this.isEverythingSaved) {

      this.dialogService.open(this.closeConfirmTemplate, {
        label: 'Enregistrer les modifications ?',
      }).subscribe();

    } else if (this.isModificationDone) {

      // User saved before and close
      this.context.completeWith(this.getCanvasB64());

    } else {

      // User havn't done annotation and close
      this.context.completeWith(null);

    }
  }

  downloadPicture(): void {
    this.commandInvoker.execute(Tool.DOWNLOAD);
  }

  getWidthDisplay(): number {
    const maxRoundSize = this.colorPickerConfig.displayWidth
    - (this.colorPickerConfig.maxWidth - this.colorPickerConfig.displayWidth) - 2;
    return Math.round((this.drawConfig.width * maxRoundSize) / this.colorPickerConfig.maxWidth + 5);
  }

  getCanvasB64(): string {
    return this.canvas.stage.toDataURL({
      mimeType: 'image/jpeg',
      pixelRatio: this.backgroundImage.width / this.canvas.stage.width(),
    });
  }

  executeCrop(): void {
    this.commandInvoker.execute(Tool.VALIDATE_CROP);
  }

  resetCrop(): void {
    Object.assign(this.currentCropConfig, {
      x: 0,
      y: 0,
      width: this.backgroundImage.width,
      height: this.backgroundImage.height,
    });
    this.onToolClick(Tool.PREPARE_CROP);
  }

  receive(topic: string, subject: EventSubject): void {
    switch (topic) {

      case EventTopic.AUTO_SELECT: {
        setTimeout(() => { // Avoid click event trigger
          this.onToolClick(Tool.SHAPE_SELECTION);
        });
        this.canvas.setSelectedShapes(subject.shapes);
        break;
      }

      case EventTopic.SWITCH_TOOL: {
        this.onToolClick(subject.toolSwitchName);
        break;
      }

      case EventTopic.REVERT_CROP: {
        Object.assign(this.cropConfig, subject.cropConfig);
        this.commandInvoker.execute(Tool.SET_BACKGROUND);
        break;
      }

      case EventTopic.EXECUTE_CROP: {
        this.executeCrop();
        break;
      }

      case EventTopic.CROP_FINISHED: {
        this.onToolClick(Tool.DEFAULT_TOOL);
        this.commandInvoker.execute(Tool.SET_BACKGROUND);
        this.eventBus.publish(EventTopic.MODIFY_ANNOTATIONS);
        break;
      }

      case EventTopic.ROTATION_FINISHED: {
        this.commandInvoker.execute(Tool.SET_BACKGROUND);
        this.eventBus.publish(EventTopic.MODIFY_ANNOTATIONS);
        break;
      }

      case EventTopic.UPDATE_CURRENT_CROPCONFIG: {
        Object.assign(this.currentCropConfig, subject.cropConfig);
        break;
      }

      case EventTopic.MODIFY_ANNOTATIONS: {
        this.isModificationDone = true;
        this.isEverythingSaved = false;
        break;
      }

      case EventTopic.SAVE: {
        this.isEverythingSaved = true;
        break;
      }

      case EventTopic.IMPORT_FINISHED: {
        this.isModificationDone = false;
        this.isEverythingSaved = true;
        break;
      }

      case EventTopic.CLOSE: {
        this.closeStudio();
        break;
      }

      default: {
        break;
      }
    }
  }

}
