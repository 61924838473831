<div *tuiLet="selectedPatient$ | async as selectedPatient" class="flex flex-col gap-2">

  <div class="flex gap-2 max-lg:flex-wrap items-center">
    <pxc-filter-bar
      searchPlaceholder="Rechercher une séquence par mot clé, date ou patient ..."
      data-testid="filter-bar"
    ></pxc-filter-bar>

    <ng-container *ngIf="selectedPatient">
      <pxc-create-button
        *ngIf="(hasLicenseWriteAccess$ | async)"
        [disabled]="selectedPatient.isArchived"
        (click)="addSequence(selectedPatient)"
        data-testid="add-sequence"
        class="mt flex-none max-lg:w-full"
        label="Ajouter une séquence"
      ></pxc-create-button>
    </ng-container>
  </div>

  <ng-container *ngIf="sequences$ | async as sequences;  else loadingBlock">
    <pxc-sequence-list
      *ngIf="isSequenceStateLoaded$ | async; else loadingBlock"
      [sequences]="sequences.data"
      [actions]="sequenceActions"
      [isNextPageLoading]="sequences.context.isNextPageLoading"
      [isPreviousPageLoading]="sequences.context.isPreviousPageLoading"
      (loadPrevious)="loadPreviousPage()"
      (loadNext)="loadNextPage()"
      (sequenceMediaClicked)="openSequenceMediaGallery($event)"
      data-testid="sequence-list"
    ></pxc-sequence-list>

  </ng-container>
</div>

<ng-template #loadingBlock>
  <div class="mx-auto my-4">
    <pxc-loader data-testid="loading">Récupération des photos en cours ...</pxc-loader>
  </div>
</ng-template>