<div class="flex justify-center items-center gap-2 w-fit">
  <tui-tooltip *ngIf="hint" [content]="hint" direction="top" data-testid="hint"></tui-tooltip>
  <span><ng-content></ng-content></span>
  <tui-toggle
    [ngModel]="toggle"
    (ngModelChange)="toggleChange.emit($event)"
    [disabled]="disabled"
    data-testid="toggle"
    size="l"
  ></tui-toggle>
</div>