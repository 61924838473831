import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Label, ClientUser, FlattenedLabel } from '@pixacare/pxc-ts-core';
import { StoreLabelPack } from '@shared/models/base/store-label-pack';
import { selectClientDepartment } from '../departments/departments.selectors';
import { selectAllUsers, selectIndexedDepartmentUsers } from '../users/users.selectors';
import { LabelsState } from './labels.state';

export const LABELS_FEATURE_KEY = 'Labels';

const labelSort = (a: Label, b: Label) => a.word.toLowerCase() > b.word.toLowerCase() ? 1 : -1;

const composeAndSortFlattenedLabels = (
  labels: Label[],
  users: { [userId: number]: ClientUser },
): FlattenedLabel[] => labels
  .sort(labelSort)
  .map((label) => ({
    labelInstance: label,
    data: {
      subscribersCount: label.statistics?.subscribersCount,
    },
    createdBy: users[label.createdBy],
    userLabelStatsInstance: {
      labelId: label.id,
      count: label.statistics?.count,
    },
  }));

export const selectLabelsState = createFeatureSelector<LabelsState>(
  LABELS_FEATURE_KEY,
);

export const selectLabels = createSelector(
  selectLabelsState,
  (state) => state.labels,
);

export const selectFlattenedLabels = createSelector(
  selectLabels,
  selectAllUsers,
  (labels, users): FlattenedLabel[] => composeAndSortFlattenedLabels(Object.values(labels), users),
);

export const selectUserFlattenedLabels = createSelector(
  selectLabelsState,
  selectAllUsers,
  (state, users) => composeAndSortFlattenedLabels(
    state.userLabels.map((labelId) => state.labels[labelId]) || [],
    users,
  ),
);

export const selectDepartmentFlattenedLabels = (
  departmentId: number,
  clientCode: string,
): MemoizedSelector<LabelsState, FlattenedLabel[]> => createSelector(
  selectClientDepartment(clientCode, departmentId),
  selectIndexedDepartmentUsers(clientCode, departmentId),
  selectLabelsState,
  (department, users, labelState) => {

    if (department.labelPackIds.length === 0) {
      return [];
    }

    const departmentLabelPacks = department.labelPackIds.map((id: number) => labelState.labelPacks[id]);
    const departmentLabels = departmentLabelPacks.flatMap(
      (labelPack: StoreLabelPack) => labelPack.labelIds.map((labelId) => labelState.labels[labelId]),
    );

    return composeAndSortFlattenedLabels(departmentLabels, users);
  },

);
