<tui-elastic-container>
  <pxc-share *ngIf="(isLoading$ | async) === false; else loadingBlock" (cancel)="close()" (confirm)="confirm($event)">
    <pxc-sequence-preview [sequence]="sequence"></pxc-sequence-preview>
  </pxc-share>
</tui-elastic-container>

<ng-template #loadingBlock>
  <pxc-loader size="tiny">
    Partage de votre séquence en cours ...
  </pxc-loader>
</ng-template>
