
const getFormDataValue = (value: string | number | Blob): string | Blob => {
  if (typeof value === 'string') {
    return value;
  }
  if (typeof value === 'number') {
    return value.toString();
  }
  return value;
};


export const appendToFormData = (formData: FormData, data: {
  [key: string]: string | string[] | number | number[] | Blob | Blob[] | File | File[];
}): FormData => {
  Object.entries(data).forEach(([key, value]) => {

    if (!value) {
      return;
    }

    if (Array.isArray(value)) {
      value.forEach((v: string | number | Blob) => {
        formData.append(key, getFormDataValue(v));
      });
    } else {
      formData.append(key, getFormDataValue(value));
    }
  });

  return formData;
};
