import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GalleryComponent } from './gallery/gallery.component';
import { SharedModule } from '../shared/shared.module';
import { TuiButtonModule, TuiModeModule, TuiSvgModule } from '@taiga-ui/core';

@NgModule({
  declarations: [
    GalleryComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TuiSvgModule,
    TuiButtonModule,
    TuiModeModule,
  ],
})
export class GalleryModule { }
