import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, OnInit, Output,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  scan,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { UserHttpService } from 'src/app/services/http/user.http.service';
import { AutoCompleteModel } from 'src/app/shared/models/helpers/auto-complete-model';
import { TagInputComponent } from '../tag-input/tag-input.component';
import { SearchedUser } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-user-input',
  templateUrl: './user-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TagInputComponent,
  ],
})
export class UserInputComponent implements OnInit {

  @Input() excludedIds: number[] = [];
  @Input() excludedEmails: string[] = [];
  @Input() validators: Validators = [Validators.email];
  @Input() placeholder = 'Entrez un nom ou une adresse email';
  @Input() allowOnlyAutoComplete = false;

  @Output() selectedEmailsChange = new EventEmitter<string[]>();
  @Output() selectedUsersChange = new EventEmitter<SearchedUser[]>();

  selectedValues: AutoCompleteModel[] = [];
  inputChange$ = new BehaviorSubject<string>('');

  autocompleteModels$: Observable<AutoCompleteModel[]>;
  autocompleteUsers$: Observable<{ [id: number]: SearchedUser }>;

  constructor(private readonly userService: UserHttpService) {}

  ngOnInit(): void {
    const users$: Observable<SearchedUser[]> = this.inputChange$.pipe(
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((value) => {
        if (value?.length) {
          return this.userService.searchUsers(value);
        }
        return of(null);
      }),
      map((users) => users?.filter((user) =>
        !this.getUserIds(this.selectedValues).includes(user.id) && !this.excludedIds.includes(user.id)),
      ),
      startWith([]),
    );

    this.autocompleteUsers$ = users$.pipe(
      filter((users) => users?.length > 0),
      map((users) => users.reduce((acc, user) => ({
        ...acc,
        [user.id]: user,
      }), {})),
      scan((acc, users) => (Object.assign({}, acc, users)), {}),
      shareReplay(1),
    );

    this.autocompleteModels$ = users$.pipe(
      map((users) => users?.map((user) => ({
        value: `${user.id}`,
        display: `${user.firstName} ${user.lastName}`,
      }))),
    );

  }

  emailFilter(value: string): boolean {
    return value.includes('@');
  }

  getUserIds(values: { value: string }[]): number[] {
    return values.map((v) => v.value)
      .filter((v) => !this.emailFilter(v))
      .map((v) => +v);
  }

  getUserMails(values: { value: string }[]): string[] {
    return values.map((v) => v.value)
      .filter(this.emailFilter);
  }

  setUsers(values: AutoCompleteModel[]): void {

    this.selectedValues = values.filter(({ value }) =>
      this.emailFilter(value) ? !this.excludedEmails.includes(value) : true );

    const emails = this.getUserMails(this.selectedValues);
    const ids = this.getUserIds(this.selectedValues);

    this.autocompleteUsers$.pipe(first()).subscribe((autocompleteUsers) => {
      this.selectedUsersChange.emit(
        ids.map((id) => autocompleteUsers[id]),
      );
    });

    this.selectedEmailsChange.emit(emails);

  }

}
