<pxc-carousel-navigation
  [max]="medias.length"
  [(index)]="currentThumbnailIndex"
  hideControlsOnMobile="true"
>
  <tui-carousel #carousel
    class="w-full carousel"
    [itemsCount]="3"
    [(index)]="currentThumbnailIndex"
  >
    <ng-container *ngFor="let media of medias; let idx = index;trackBy: trackByMediaId">
      <div 
        *tuiItem
        cdkDropList
        [cdkDropListConnectedTo]="['drawer-list']"
      >
        <div
          (cdkDragStarted)="dragStart()" 
          (cdkDragEnded)="dragEnd()" 
          [cdkDragData]="getDragMedia(media)"       
          cdkDrag
          [attr.data-testid]="'drag-'+idx"
          >
            <ng-container
              [ngTemplateOutlet]="sequenceMedia"
            ></ng-container>
      
          <ng-container *cdkDragPlaceholder>
            <div>
              <ng-container
                [ngTemplateOutlet]="sequenceMedia"
              ></ng-container>
            </div>
          </ng-container>

          <ng-template #sequenceMedia>
            <pxc-sequence-media
              class="flex w-full h-full cursor-pointer"
              (click)="emitMediaClicked(idx)"
              [src]="media.thumbnailUri"
              [clientCode]="clientCode"
              [sequenceId]="sequenceId"
              [mediaId]="media.id"
              data-testid="thumbnail"
            ></pxc-sequence-media>
          </ng-template>
        </div>
      </div>
    </ng-container>
  </tui-carousel>
</pxc-carousel-navigation>