  <div class="flex items-center gap-3 my-2">
    <tui-svg *ngIf="icon" [src]="icon" data-testid="icon"></tui-svg>
    <div [ngSwitch]="titleSize">
      <h1 *ngSwitchCase="Size.LARGE">{{ title }}</h1>
      <h2 *ngSwitchCase="Size.MEDIUM">{{ title }}</h2>
      <h5 *ngSwitchCase="Size.SMALL">{{ title }}</h5>
      <p *ngSwitchDefault class="font-medium text-lg">{{ title }}</p>

      <h6 *ngIf="subtitle" class="text-slate-600">{{ subtitle }}</h6>
    </div>

    <tui-badge 
      *ngIf="counter" 
      value="{{counter}}{{ maxCount ? '/'+maxCount : ''}}" 
      data-testid="counter" 
      class="!font-bold mt-1" size="m"
    ></tui-badge>
  </div>