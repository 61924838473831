import { MediaRetryStrategyConfig } from './media-retry-strategy-config.interface';

type UnknownFn = () => unknown;
export const MAX_RETRIES = 5;

export class MediaRetryStrategy {

  hasReachedMaxRetries = false;

  private shouldRetry = false;
  private currentRetry;
  private maxRetries;
  private action: UnknownFn;


  constructor(
    action: UnknownFn,
    config: MediaRetryStrategyConfig = {
      maxRetries: MAX_RETRIES,
    },
  ) {
    this.maxRetries = config.maxRetries;
    this.action = action;
    this.currentRetry = 0;
  }

  startRetry(): void {
    if (this.shouldRetry) {
      return;
    }

    this.shouldRetry = true;
    this.retry();
  }

  stopRetry(): void {
    this.shouldRetry = false;
  }

  private async retry(): Promise<void> {
    setTimeout(() => {
      if (this.currentRetry >= this.maxRetries) {
        this.hasReachedMaxRetries = true;
        this.shouldRetry = false;
        return;
      }

      this.currentRetry++;

      if (this.shouldRetry) {
        this.action();
        this.retry();
      }
    }, this.currentRetry * 1000);
  }

}
