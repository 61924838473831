<div *ngIf="sadms$ | async as sadms" class="flex flex-col gap-2">
  <div *ngIf="sadms.entities.length > 0; else emptyBlock" class="flex flex-col gap-1">
    <pxc-sadm-entity-preview
      *ngFor="let entity of sadms.entities"
      [sadmEntity]="entity"
      [sadmType]='sadms.client.sadmType'
      [isSelected]="entity.id === mergeToEntityId"
      (click)="mergeToEntityId = entity.id"
      [attr.data-testid]="entity.id"
    ></pxc-sadm-entity-preview>
  </div>

  <ng-template #emptyBlock>
    <p class="text-slate-500 my-5 text-center" data-testid="no-other-sadm">
      Vous n'avez aucune autre {{SadmTypeLabel[sadms.client.sadmType]}} pour ce patient.
    </p>
  </ng-template>

  <div class="flex justify-between gap-2 mt-2 max-md:flex-col-reverse">
    <button tuiButton appearance="outline" type="button" (click)="cancel()" data-testid="cancel">Annuler</button>
    <button [disabled]="!mergeToEntityId" tuiButton type="button" (click)="merge()" data-testid="confirm">Confirmer</button>
  </div>
</div>