import { Injectable, inject } from '@angular/core';
import {
  Firestore,
  Query,
  Timestamp,
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionChanges,
  collectionData,
  doc,
  endBefore,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Channel,
  ChannelMessage,
  ChannelMessageGroup,
  CreateChannelMessageRequest,
  ObjectType,
  getUsername,
} from '@pixacare/pxc-ts-core';
import { BehaviorSubject, Observable, Subscription, map } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ChatHttpService } from './chat.http.service';
import { MessageGroupService } from './message-group.service';
import { Message } from 'src/app/shared/models/chat/message';
import { ChannelNamePipe } from './pipes/channel-name.pipe';
import { ShareHttpService } from 'src/app/services/http/share.http.service';

@UntilDestroy()
@Injectable()
export class ChatService {

  channels$: Observable<Channel[]>;
  currentChannelMessages$: Observable<{ [messageId: string]: ChannelMessage }>;
  hasUnseenMessages$: Observable<boolean>;
  unreadMessagesCounter$: Observable<number>;

  private readonly fireStore: Firestore = inject(Firestore);
  private readonly authenticationService: AuthenticationService = inject(AuthenticationService);
  private readonly messageGroupService = inject(MessageGroupService);
  private readonly channelNamePipe = inject(ChannelNamePipe);
  private readonly chatHttpService = inject(ChatHttpService);
  private readonly shareService = inject(ShareHttpService);

  private currentChannelMessages: BehaviorSubject<{ [messageId: string]: ChannelMessage }> = new BehaviorSubject({});
  private hasUnseenMessages = new BehaviorSubject<boolean>(false);
  private channelQuery: Query;

  private channelMessageSubscription: Subscription;

  private pageSize = 50;
  private lastCursor: ChannelMessage = null;
  private firstCursor: ChannelMessage = null;

  constructor() {
    const channelCollection = collection(this.fireStore, 'channels');
    this.channelQuery = query(
      channelCollection,
      where('userIds', 'array-contains', this.authenticationService.currentUser.id),
      orderBy('lastMessage.createdOn', 'desc'),
    );
    this.channels$ = (collectionData(this.channelQuery, { idField: 'id' }) as Observable<Channel[]>).pipe(
      untilDestroyed(this),
    );
    this.currentChannelMessages$ = this.currentChannelMessages.asObservable();
    this.hasUnseenMessages$ = this.hasUnseenMessages.asObservable();
    this.unreadMessagesCounter$ = this.channels$.pipe(
      map((channels) => channels.reduce(
        (totalUnreads, channel) => totalUnreads + channel?.unreads[this.authenticationService.currentUser.id] || 0,
        0,
      )),
    );
  }

  markMessagesAsSeen(): void {
    this.hasUnseenMessages.next(false);
  }

  updateChannelUnreads(channelId: string): void {
    this.chatHttpService.updateChannelUserUnreads(channelId, this.authenticationService.currentUser.id).subscribe();
  }

  getMessageGroups(): Observable<ChannelMessageGroup[]> {
    return this.messageGroupService.messageGroups$;
  }

  async updateNotifications(channelId: string, enabled: boolean): Promise<void> {
    const channelDocRef = doc(this.fireStore, `channels/${channelId}`);
    try {
      await updateDoc(channelDocRef, {
        muted: !enabled
          ? arrayUnion(this.authenticationService.currentUser.id)
          : arrayRemove(this.authenticationService.currentUser.id),
      });
    } catch (e) {
      console.error('Error while updating channel notifications', e);
    }
  }

  listenToChannelMessages(channelId: string): void {
    const messagesDocRef = collection(
      this.channelQuery.firestore,
      `channels/${channelId}/messages`,
    );
    const messagesQuery = query(
      messagesDocRef,
      orderBy('createdOn', 'desc'),
      endBefore(Timestamp.now()),
    );

    this.channelMessageSubscription?.unsubscribe();
    this.channelMessageSubscription = collectionChanges(messagesQuery, { events: ['added'] }).pipe(
      untilDestroyed(this),
    ).subscribe((changes) => {
      const newMessages = changes.map((change) => ({
        ...change.doc.data(),
        id: change.doc.id,
      }) as ChannelMessage);

      if (newMessages.length === 0) {
        return;
      }

      this.messageGroupService.addMessages(newMessages, 'prepend', this.firstCursor);
      this.firstCursor = newMessages[0] as unknown as ChannelMessage;

      this.hasUnseenMessages.next(true);
      this.currentChannelMessages.next({
        ...this.indexMessagesById(newMessages),
        ...this.currentChannelMessages.value,
      });
    });
  }

  async loadChannelMessages(
    channelId: string, { reset } = { reset: true },
  ): Promise<void> {
    if (reset) {
      this.listenToChannelMessages(channelId);
      this.updateChannelUnreads(channelId);
    }

    const messagesDocRef = collection(this.channelQuery.firestore, `channels/${channelId}/messages`);
    const messagesQuery = query(
      messagesDocRef,
      orderBy('createdOn', 'desc'),
      ...((reset || !this.lastCursor) ? [] : [startAfter(this.lastCursor.createdOn)]),
      limit(this.pageSize),
    );

    const messages = (await getDocs(messagesQuery)).docs.map((document) => ({
      ...document.data(),
      id: document.id,
    }) as ChannelMessage);
    const indexedMessages = this.indexMessagesById(messages);
    if (reset) {
      this.messageGroupService.reset();
      this.currentChannelMessages.next(indexedMessages);
      this.firstCursor = messages[0] as unknown as ChannelMessage;
    } else {
      this.currentChannelMessages.next(
        { ...this.currentChannelMessages.value, ...indexedMessages },
      );
    }

    if (messages.length) {
      this.messageGroupService.addMessages(messages);
      this.lastCursor = messages.slice(-1)[0] as unknown as ChannelMessage;
    }

  }

  async sendMessage(channelId: string, newMessage: Message): Promise<void> {
    if (newMessage.attachment?.sequence) {
      await this.sendSequence(channelId, newMessage);
    } else {
      await this.sendSimpleMessage(channelId, newMessage.text);
    }
  }

  searchChannels$(search: string): Observable<Channel[]> {
    return this.channels$.pipe(
      map((channels) => {

        if (!search) {
          return channels;
        }

        const channelsWithNames = channels.map((channel) => ({
          ...channel,
          name: this.channelNamePipe.transform(channel),
        }));

        return channelsWithNames.filter((channel) =>
          channel.name?.toLowerCase().includes(search.toLowerCase()),
        );
      }),
    );
  }

  private async sendSimpleMessage(channelId: string, text: string): Promise<void> {
    const messagesDocRef = collection(this.channelQuery.firestore, `channels/${channelId}/messages`);
    await addDoc(messagesDocRef, {
      channelId,
      senderId: this.authenticationService.currentUser.id,
      senderName: getUsername(this.authenticationService.currentUser),
      createdOn: Timestamp.now(),
      text,
      special: null,
    } as CreateChannelMessageRequest);
  }

  private async sendSequence(channelId: string, newMessage: Message): Promise<void> {
    this.shareService.shareObject({
      clientCode: newMessage.attachment.sequence.clientCode,
      toMailAddresses: [],
      toUserIds: [],
      toChannelIds: [channelId],
      objectId: newMessage.attachment.sequence.sequenceInstance.id,
      objectType: ObjectType.SEQUENCE,
      withAdminPrivileges: false,
      message: newMessage.text,
      expiryInDays: 7,
    }).subscribe();
  }

  private indexMessagesById(messages: ChannelMessage[]): { [messageId: string]: ChannelMessage } {
    return messages.reduce((acc, message) => {
      acc[message.id] = message;
      return acc;
    }, {});
  }

}

