import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { BaseSadmEntity, FlattenedSequence, FormAnswers, Patient } from '@pixacare/pxc-ts-core';
import { SequenceContext } from 'src/app/shared/models/enums/sequence-context.enum';
import { ActionsService } from 'src/app/shared/models/helpers/actions-service';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { MenuActionId } from 'src/app/shared/models/menu-actions/menu-action-id.enum';
import { telemonitoringsActions } from 'src/app/shared/store/telemonitorings/telemonitorings.actions';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { DialogService } from 'src/app/services/dialog.service';
import { selectClients } from 'src/app/shared/store/clients/clients.selectors';
import { selectTelemonitoring } from 'src/app/shared/store/telemonitorings/telemonitorings.selectors';
import { Observable, iif, of, first, map } from 'rxjs';
import { SequenceEditComponent } from '../sequence-edit/sequence-edit.component';
import { TelemonitoringRequestComponent }
  from '../../telemonitoring/telemonitoring-request/telemonitoring-request.component';
import { SequenceMoveComponent } from '../sequence-move/sequence-move.component';
import { SequenceCreateComponent } from '../sequence-create/sequence-create.component';
import { AuthorizationsService } from 'src/app/services/authorizations.service';
import { SequenceCreateStep } from 'src/app/shared/models/enums/sequence-create-step.enum';
import { selectUserEnabledClientDepartments } from 'src/app/shared/store/departments/departments.selectors';
import { SequenceShareComponent } from '../sequence-share/sequence-share.component';
import { MessageWithReceivers } from 'src/app/shared/models/chat/message-with-receivers';
import { selectDepartmentId } from 'src/app/shared/store/router/router.selectors';
import { isEverythingTrue, isEverythingTrue$ } from '@shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class SequenceActionsService extends ActionsService {

  actions: MenuAction<FlattenedSequence>[] = [
    {
      id: MenuActionId.SEQUENCE_EDIT,
      label: 'Modifier',
      icon: 'tuiIconEdit2',
      condition: (sequence: FlattenedSequence): boolean => this.isPatientAnonymousOrActive(sequence),
      execute: (sequence: FlattenedSequence): void => {

        this.dialogService.openComponent(SequenceEditComponent, {
          label: 'Modifier la séquence',
          size: 'l',
          dismissible: this.dialogService.exitConfirmDialog,
          data: {
            sequenceId: sequence.sequenceInstance.id,
            clientCode: sequence.clientCode,
          },
        }).subscribe();

      },
    },
    {
      id: MenuActionId.SEQUENCE_SHARE,
      label: 'Partager',
      icon: 'tuiIconExternalLink',
      condition: (sequence: FlattenedSequence): boolean => isEverythingTrue(
        this.isPatientAnonymousOrActive(sequence),
        this.isUserOwner(sequence),
      ),
      execute: (sequence: FlattenedSequence): void => {
        this.dialogService.openComponent(SequenceShareComponent, {
          label: 'Partager la séquence',
          size: 'l',
          data: {
            sequence,
          },
        }).pipe(
          first(),
        ).subscribe((newMessage: MessageWithReceivers) => {
          if (newMessage.receivers.toChannelIds.length === 1) {
            this.router.navigate(['dashboard', 'chat', newMessage.receivers.toChannelIds[0]], {
              queryParamsHandling: 'merge',
            });
          } else if (newMessage.receivers.toUserIds.length > 0 || newMessage.receivers.toChannelIds.length > 0) {
            this.router.navigate(['dashboard', 'chat'], { queryParamsHandling: 'merge' });
          }
        });
      },
    },
    {
      id: MenuActionId.SEQUENCE_MOVE,
      label: 'Déplacer',
      icon: 'tuiIconShuffle',
      condition$: (sequence: FlattenedSequence): Observable<boolean> => isEverythingTrue$(
        this.isPatientAnonymousOrActive(sequence),
        this.isUserAdminOrOwner$(sequence),
        this.hasMultipleClients$(),
      ),
      execute: (sequence: FlattenedSequence): void => {
        this.dialogService.openComponent
        <SequenceMoveComponent, string>(SequenceMoveComponent, {
          label: 'Déplacer la séquence',
          data: {
            flattenedSequence: sequence,
          },
        }).subscribe((result) => {
          if (result) {
            this.store.dispatch(
              sequencesActions.moveSequence({
                fromClientCode: sequence.clientCode,
                toClientCode: result,
                overridePatient: false,
                sequenceId: sequence.sequenceInstance.id,
              }),
            );
          }
        });
      },
    },
    {
      id: MenuActionId.SEQUENCE_TELEMONITORING,
      label: 'Télésuivi',
      icon: 'telemonitoring',
      context: SequenceContext.SEQUENCE,
      condition: (sequence: FlattenedSequence): boolean => this.isPatientActive(sequence),
      execute: (sequence: FlattenedSequence): void => {

        this.store.select(selectDepartmentId).pipe(
          first(),
        ).subscribe((departmentId) => {
          this.dialogService.openComponentWithCloseConfirmation(TelemonitoringRequestComponent, {
            label: 'Initier un télésuivi',
            size: 'l',
            data: {
              patient: sequence.patientInstance,
              clientCode: sequence.clientCode,
              departmentIds: departmentId ? [departmentId] : [],
              sequences: [sequence],
            },
          }).subscribe();
        });
      },
    },
    {
      id: MenuActionId.SEQUENCE_UNLINK,
      label: 'Retirer',
      icon: 'tuiIconMinusLarge',
      status: Status.ERROR,
      context: SequenceContext.TELEMONITORING,
      condition$: (sequence: FlattenedSequence): Observable<boolean> => isEverythingTrue$(
        this.isPatientAnonymousOrActive(sequence),
        this.isUserTelemonitoringOrSequenceOwner(sequence),
      ),
      execute: (sequence: FlattenedSequence): void => {
        this.dialogService.openConfirm(
          'Êtes-vous certain de vouloir retirer cette séquence de ce télésuivi ?',
        ).subscribe((result) => {
          if (result) {
            this.store.dispatch(
              telemonitoringsActions.unlinkSequence({
                telemonitoringId: sequence.sequenceInstance.telemonitoringId,
                clientCode: sequence.clientCode,
                sequenceId: sequence.sequenceInstance.id,
              }),
            );
          }
        });
      },
    },
    {
      id: MenuActionId.SEQUENCE_DELETE,
      label: 'Supprimer',
      icon: 'tuiIconTrash',
      status: Status.ERROR,
      context: SequenceContext.SEQUENCE,
      condition$: (sequence: FlattenedSequence): Observable<boolean> => isEverythingTrue$(
        this.isPatientAnonymousOrActive(sequence),
        this.isUserAdminOrOwner$(sequence),
      ),
      execute: (sequence: FlattenedSequence): void => {
        this.dialogService.openConfirm('Êtes-vous certain de vouloir supprimer cette séquence ?', {
          content: 'Cette action est définitive. '
           + 'Si votre séquence appartient à plusieurs groupes, celle-ci sera supprimée dans tous les groupes.',
          yes: 'Supprimer',
        }).subscribe((result) => {
          if (result) {
            this.store.dispatch(
              sequencesActions.deleteSequence({
                sequenceId: sequence.sequenceInstance.id,
                clientCode: sequence.clientCode,
              }),
            );
          }
        });
      },
    },
  ];

  constructor(
    private authorizationsService: AuthorizationsService,
    public store: Store,
    private router: Router,
    private readonly dialogService: DialogService,
  ) {
    super();
  }

  getActions(context: SequenceContext = SequenceContext.SEQUENCE): MenuAction<FlattenedSequence>[] {
    return this.actions.filter((action) => !action.context || action.context === context);
  }

  async addSequence(clientCode: string,
    { departmentIds, patient, telemonitoringId, sadmEntityId,
      sadmEntity, analysisFormAnswers, protocolFormAnswers, step }: {
      departmentIds?: number[];
      patient?: Patient;
      telemonitoringId?: number;
      sadmEntityId?: number;
      sadmEntity?: BaseSadmEntity;
      analysisFormAnswers?: FormAnswers;
      protocolFormAnswers?: FormAnswers;
      step?: SequenceCreateStep;
    }): Promise<void> {
    return new Promise((resolve, reject) => {

      iif(
        () => !departmentIds && step > SequenceCreateStep.INFORMATION,
        this.getUserDepartmentIds$(clientCode),
        of(null),
      ).pipe(first()).subscribe((dptIds) => {
        this.dialogService.openComponentWithCloseConfirmation(SequenceCreateComponent, {
          label: 'Créer une séquence',
          size: 'l',
          data: {
            clientCode,
            ...(departmentIds && { departmentIds }),
            ...(dptIds && { departmentIds: dptIds }),
            ...(patient && { patient }),
            ...(telemonitoringId && { telemonitoringId }),
            ...(sadmEntityId && { sadmEntityId }),
            ...(sadmEntity && { sadmEntity }),
            ...(analysisFormAnswers && { analysisFormAnswers }),
            ...(protocolFormAnswers && { protocolFormAnswers }),
            ...(step && { step }),
          },
        }).subscribe((result) => {

          this.router.navigate([], {
            queryParams: {
              create: null,
            },
            queryParamsHandling: 'merge',
          });

          if (result) {
            resolve();
          } else {
            reject();
          }
        });
      });
    });
  }

  isUserOwner(sequence: FlattenedSequence): boolean {
    return this.authorizationsService.getUserId() === sequence.sequenceInstance.createdBy ||
    this.authorizationsService.getUserId() === sequence.sequenceInstance.ownedBy;
  }

  isUserAdminOrOwner$(sequence: FlattenedSequence): Observable<boolean> {

    if (this.isUserOwner(sequence)) {
      return of(true);
    }

    return this.authorizationsService.isDepartmentAdmin$();
  }

  getUserDepartmentIds$(clientCode: string): Observable<number[]> {
    return this.store.select(selectUserEnabledClientDepartments(
      clientCode,
      this.authorizationsService.getUserId(),
    ));
  }

  isPatientAnonymousOrActive(sequence: FlattenedSequence): boolean {
    return !sequence.patientInstance || this.isPatientActive(sequence);
  }

  isPatientActive(sequence: FlattenedSequence): boolean {
    return sequence.patientInstance && !sequence.patientInstance?.isArchived;
  }

  hasMultipleClients$(): Observable<boolean> {
    return this.store.select(selectClients).pipe(
      map((clients) => Object.keys(clients).length > 1),
    );
  }

  isUserTelemonitoringOrSequenceOwner(sequence: FlattenedSequence): Observable<boolean> {
    return this.store.select(selectTelemonitoring(sequence.sequenceInstance.telemonitoringId)).pipe(
      map((telemonitoring) => {
        const userId = this.authorizationsService.getUserId();
        const isTelemonitoringOwner = userId === telemonitoring.telemonitoring.createdBy;
        const isSequenceOwner = userId === sequence.sequenceInstance.createdBy;
        return isTelemonitoringOwner || isSequenceOwner;
      }),
    );
  }

}
