<tui-elastic-container *tuiLet="channels.length > 0 || users.length > 0 || mailAddresses.length > 0 as hasReceivers">
    <tui-stepper [(activeItemIndex)]="currentPage" class="justify-start mb-4">
        <button tuiStep 
            [stepState]="
                currentPage !== SharePickerPage.SELECT_RECEIVERS && !hasReceivers ? 'error' : 
                !hasReceivers ? 'normal' : 'pass'
            "
        >
            Destinataires
        </button>
        <button tuiStep>Message</button>
    </tui-stepper>
    
    <pxc-receivers-picker 
        *ngIf="currentPage === SharePickerPage.SELECT_RECEIVERS"
        [(selectedChannels)]="channels"
        [(selectedUsers)]="users"
        [(selectedMailAddresses)]="mailAddresses"
    ></pxc-receivers-picker>

    <div *ngIf="currentPage === SharePickerPage.MESSAGE" 
        class="flex flex-col gap-4 pb-4"
        data-testid="summary" 
    >
        <p>Vérifiez les informations qui seront partagées</p>
        <ng-content></ng-content>
        <div class="flex flex-col gap-4">
            <pxc-title
                title="Destinataires"
                [titleSize]="Size.TINY"
                icon="tuiIconUsers"
            ></pxc-title>
            <pxc-receiver-list *ngIf="hasReceivers; else receiversEmptyState"
                [channels]="channels"
                [users]="users"
                [mailAddresses]="mailAddresses"
                [removable]="false"
            ></pxc-receiver-list>
            <ng-template #receiversEmptyState>
                <pxc-status 
                    [state]="Status.BASIC"
                    icon="tuiIconUserX"
                    [showTitle]="false"
                    description="Vous devez sélectionner au moins un destinataire !"
                ></pxc-status>
            </ng-template>
    
            <pxc-title
                title="Message"
                [titleSize]="Size.TINY"
                icon="tuiIconMessageSquare"
            ></pxc-title>
    
            <tui-textarea class="flex-1" [formControl]="shareText" [tuiTextfieldLabelOutside]="true">
                Entrez un message
            </tui-textarea>
        </div>
    </div>
    
    <div class="flex justify-between gap-2">
        <button
            tuiButton
            appearance="outline"
            (click)="currentPage === 0 ? close() : previous()"
            data-testid="cancel-page-button"
        >{{currentPage === 0 ? 'Annuler' : 'Précédent'}}</button>
        <button
            tuiButton
            (click)="next()"
            [disabled]="!hasReceivers"
            data-testid="next-page-button"
        >{{currentPage === SharePickerPage.MESSAGE ? 'Confirmer' : 'Suivant'}}</button>
    </div>
</tui-elastic-container>
