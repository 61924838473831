import {
  Component, Input, EventEmitter, Output, ChangeDetectionStrategy,
} from '@angular/core';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { SequenceContext } from 'src/app/shared/models/enums/sequence-context.enum';
import { MediaClickedOutput } from 'src/app/shared/models/media-clicked-output';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-sequence-list',
  templateUrl: './sequence-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceListComponent {

  @Input() sequences: FlattenedSequence[];
  @Input() actions: MenuAction<FlattenedSequence>[] = null;
  @Input() context: SequenceContext = SequenceContext.SEQUENCE;
  @Input() isNextPageLoading = false;
  @Input() isPreviousPageLoading = false;
  @Input() compactMode = false;
  @Input() patientRedirect = true;

  @Output() loadPrevious: EventEmitter<null> = new EventEmitter();
  @Output() loadNext: EventEmitter<null> = new EventEmitter();
  @Output() sequenceMediaClicked: EventEmitter<MediaClickedOutput> = new EventEmitter();

  SequenceContext = SequenceContext;

  mediaClicked($event: MediaClickedOutput): void {
    this.sequenceMediaClicked.emit($event);
  }

  trackBySequenceId(index: number, sequence: FlattenedSequence): number {
    return sequence.sequenceInstance.id * sequence.sequenceInstance.createdOn.getTime();
  }


}
