<div id="canvas-tools"
    [ngClass]="{'hidden':isPictureLoading}"
    class="flex justify-center items-center h-full"
></div>
<div class="flex flex-col justify-center items-center gap-1 p-1 shadow-pixablue-1/20 drop-shadow-xl bg-white rounded-xl absolute left-2.5 top-1/2 -translate-y-1/2">
    <button *ngFor="let tool of studioTools; let index = index"
        tuiIconButton
        [ngClass]="tool.classes"
        [appearance]="selectedToolName === tool.commandName ? 'whiteblock-active' : 'flat'"
        [icon]="tool.icon"
        [tuiHint]="getTooltipString(tool.name, tool.shortcut)"
        tuiHintDirection="right"
        tuiHintAppearance="onDark"
        (click)="onToolClick(tool.commandName)"
    >
    </button>
    <tui-hosted-dropdown
        [content]="colorPicker"
        tuiDropdownAlign="right"
        [sided]="true"
        [tuiDropdownOffset]="12"
    >
        <button class="group flex p-1 bg-transparent border-none outline-none">
            <div class="flex relative justify-center items-center rounded-full border-pixablue-1 border-2 border-solid"
                [style.width.px]="colorPickerConfig.displayWidth"
                [style.height.px]="colorPickerConfig.displayWidth"
            >
                <div class="absolute rounded-full inset-1/2 -translate-x-1/2 -translate-y-1/2 border border-solid border-black/20"
                    [style.background-color]="drawConfig.color.code"
                    [style.width.px]="getWidthDisplay()"
                    [style.height.px]="getWidthDisplay()"
                ></div>
            </div>
        </button>
    </tui-hosted-dropdown>
</div>

<ng-template #colorPicker>
    <pxc-color-picker 
        [(color)]="drawConfig.color"
        [(width)]="drawConfig.width"
        [colorPickerConfig]="colorPickerConfig"
    ></pxc-color-picker>
</ng-template>

<div class="flex items-center justify-center absolute w-full top-1 left-0 h-12 ">
    <div *ngIf="selectedToolName === tool.PREPARE_CROP"
        class="shadow-pixablue-1/20 drop-shadow-xl gap-2 bg-white rounded-md relative flex items-center p-1 px-2 h-full ml-auto translate-x-1/2"
    >
        <tui-svg
            class="m-1"
            src="tuiIconCropLarge"
        ></tui-svg>

        <span class="bg-black w-px h-1/2"></span>

        <button
            tuiIconButton   
            appearance="flat"
            icon="tuiIconUndoLarge"
            tuiHint="Rétablir"
            tuiHintDirection="bottom"
            tuiHintAppearance="onDark"
            (click)="resetCrop()"
        ></button>
        <button
            tuiIconButton
            appearance="flat"
            icon="tuiIconXCircleLarge"
            tuiHint="Annuler (Echap)"
            tuiHintDirection="bottom"
            tuiHintAppearance="onDark"
            (click)="onToolClick(tool.DEFAULT_TOOL)"
        ></button>
        <button
            tuiIconButton   
            appearance="flat"
            icon="tuiIconCheckCircleLarge"
            tuiHint="Valider (Entrer)"
            tuiHintDirection="bottom"
            tuiHintAppearance="onDark"
            (click)="executeCrop()"
        ></button>
    </div>

    <div class="shadow-pixablue-1/20 drop-shadow-xl bg-white rounded-md flex flex-row p-1 px-2 ml-auto mr-3">
        <button
            tuiIconButton
            appearance="flat"
            icon="tuiIconDownloadLarge"
            tuiHint="Télécharger l'image"
            tuiHintDirection="bottom"
            tuiHintAppearance="onDark"
            (click)="downloadPicture()"
        ></button>
        <button
            tuiIconButton
            appearance="flat"
            icon="tuiIconCloseLarge"
            tuiHint="Quitter"
            tuiHintDirection="bottom"
            tuiHintAppearance="onDark"
            (click)="closeStudio()"
        ></button>
    </div>
</div>

<pxc-loader 
    *ngIf="isPictureLoading"
    class="my-auto"
>
    Chargement de l'image ...
</pxc-loader>

<ng-template #closeConfirm let-observer>
    <p>
        Si vous quittez le studio sans enregistrer, vous perdrez toutes les modifications actuelles.
    </p>
    <div class="flex max-md:flex-col items-stretch md:items-center gap-2 mt-4">
        <button
            tuiButton
            appearance="secondary-destructive"
            (click)="observer.complete(); this.closeWithoutSaving();"
            class="md:mr-auto"
        >
            Ne pas enregistrer
        </button>
        <button
            tuiButton
            appearance="outline"
            (click)="observer.complete()"
        >
            Annuler
        </button>

        <button
            tuiButton
            [showLoader]="isSaveOngoing"
            appearance="primary"
            (click)="this.saveAndClose(observer)"
        >
            Enregistrer
        </button>

    </div>
</ng-template>