import { Command } from 'src/app/shared/models/helpers/command';
import { StudioCanvas } from '../canvas/studio-canvas';
import { EventTopic } from '../event-bus/event-bus.interfaces';
import { EventBus } from '../event-bus/event-bus';

export class RotateCommand implements Command {

  receiver: StudioCanvas;
  eventBus: EventBus;
  constructor(receiver: StudioCanvas, eventBus: EventBus) {
    this.receiver = receiver;
    this.eventBus = eventBus;
  }

  execute(): void {
    this.receiver.stage.setAttrs({
      offsetX: this.receiver.stage.width() / 2,
      offsetY: this.receiver.stage.height() / 2,
      x: this.receiver.stage.width() / 2,
      y: this.receiver.stage.height() / 2,
    });
    this.receiver.stage.rotate(90);

    this.eventBus.publish(EventTopic.ROTATION_FINISHED);
  }

}
