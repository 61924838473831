<tui-elastic-container>
    <div class="flex flex-col gap-3">

        <pxc-user-input
            [allowOnlyAutoComplete]="true"
            placeholder="Rechercher un utilisateur"
            (selectedUsersChange)="setUsers($event)"
        ></pxc-user-input>
    
        <div class="flex lg:items-center justify-between gap-2 max-lg:flex-col-reverse">
            <button
                type="button"
                tuiButton
                appearance="outline"
                (click)="close()"
            >
                Annuler
            </button>
            <button
                type="button"
                tuiButton
                (click)="create()"
                [disabled]="!users.length"
            >
                Créer
            </button>
        </div>
    
    </div>
</tui-elastic-container>