<div class="flex flex-col gap-4">

  <p>Entrez les adresses email des utilisateurs que vous souhaitez inviter.</p>

  <pxc-tag-input
    [validators]="validators"
    [(tags)]="userMails"
    [(search)]="search"
    disableAutocomplete="true"
  ></pxc-tag-input>
  <div class="flex justify-between gap-2">
    <button tuiButton appearance="outline" (click)="close()" data-testid="cancel">
      Annuler
    </button>
    <button tuiButton (click)="addUser()" [disabled]="!(userMails && userMails.length > 0)" data-testid="confirm">
      Ajouter
    </button>
  </div>
</div>