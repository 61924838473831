import { Status } from './enums/status.enum';
import { Information } from './information';

export const statusInformations: { [state in Status]: Information } = {
  [Status.BASIC]: {
    title: 'Information',
    icon: 'tuiIconInfoLarge',
    color: 'text-slate-400',
  },
  [Status.ERROR]: {
    icon: 'tuiIconAlertCircleLarge',
    title: 'Echec',
    description: "Un problème est survenu lors de l'opération. Veuillez réessayer.",
    color: 'text-red-600',
  },
  [Status.LOADING]: {
    icon: 'tuiIconLoaderLarge',
    title: 'Chargement ...',
    description: 'Veuillez patienter quelques instants ...',
    color: 'text-slate-500',
  },
  [Status.SUCCESS]: {
    icon: 'tuiIconCheckCircleLarge',
    title: 'Succès',
    description: "L'opération a été réalisée avec succès !",
    color: 'text-emerald-600',
  },
  [Status.WARNING]: {
    icon: 'tuiIconAlertTriangleLarge',
    title: 'Avertissement',
    color: 'text-orange-600',
  },
};
