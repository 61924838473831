import { Tool } from './studio-enums';
import { StudioTool } from './studio-interfaces';

export const studioTools: StudioTool[] = [
  {
    commandName: Tool.SHAPE_SELECTION, icon: 'tuiIconNavigationLarge', name: 'Sélection', shortcut: 'v',
  },
  {
    commandName: Tool.FREE_DRAW, icon: 'tuiIconPenToolLarge', name: 'Dessin', shortcut: 'b',
  },
  {
    commandName: Tool.DRAW_RECTANGLE, icon: 'tuiIconSquareLarge', name: 'Rectangle', shortcut: 's',
  },
  {
    commandName: Tool.DRAW_ELLIPSE, icon: 'tuiIconCircleLarge', name: 'Ellipse', shortcut: 'c',
  },
  {
    commandName: Tool.DRAW_LINE, icon: 'tuiIconMinusLarge', name: 'Ligne', shortcut: 'l',
  },
  {
    commandName: Tool.ROTATE, icon: 'tuiIconRefreshCwLarge', name: 'Rotation', shortcut: 'r',
  },
  {
    commandName: Tool.PREPARE_CROP, icon: 'tuiIconCropLarge', name: 'Rogner', shortcut: 'p',
  },
];
