import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { PatientHttpService } from '../services/http/patient.http.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectToPatientGuard implements CanActivate {

  private readonly router = inject(Router);
  private readonly patientService = inject(PatientHttpService);

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { cc: clientCode, ipp: businessIdentifier } = route.queryParams;
    if (!clientCode || !businessIdentifier) {
      return of(true);
    }

    return this.redirectToPatient(clientCode, businessIdentifier);
  }

  redirectToPatient(clientCode: string, businessIdentifier: string): Observable<boolean> {
    return this.patientService.search({ clientCode, businessIdentifier }).pipe(
      map((patients) => {
        const patientId = patients.length ? [patients[0].id] : [];
        const routeSegments = [
          '/dashboard/patients',
          ...patientId,
        ];
        this.router.navigate(routeSegments, {
          queryParams: { cc: clientCode, ipp: null },
          queryParamsHandling: 'merge',
        });
        return false;
      }),
      catchError((err) => {
        console.error(err);
        this.router.navigate(['/']);
        return of(false);
      }),
    );
  }

}
