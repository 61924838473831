<tui-island class="p-1 w-full">
  <div class="flex max-md:flex-col md:gap-1 items-stretch w-full">

    <div class="flex items-stretch md:items-center gap-1 flex-1 max-md:flex-col">
      
      <div 
        class="flex items-center gap-2 min-h-10 lg:w-52 flex-1 cursor-text h-full"
        (click)="focusSearch()"
      >
        <tui-svg src="tuiIconSearch" class="text-slate-400 ml-2"></tui-svg>
        <input #searchInput
          type="text"
          [ngModel]="search$ | async"
          (ngModelChange)="updateSearch($event)"
          data-testid="search"
          [placeholder]="searchPlaceholder"
          class="flex-1 h-full placeholder:text-slate-400 placeholder:text-sm placeholder:truncate focus:outline-none"
        />
      </div>
  
      <ng-container *ngIf="templates$ | async as templates">
        <div 
          *ngIf="templates?.length > 0" 
          class="flex flex-wrap max-lg:flex-nowrap gap-1 mx-1 max-md:mb-1 md:max-w-[60vw] items-center" 
          data-testid="templates"
        >
          <ng-container *ngFor="let template of templates; trackBy: trackByTemplate" [ngSwitch]="template.type" >
          
            <pxc-filter-date-range 
              *ngSwitchCase="FilterType.DATE_RANGE" 
              [template]="template"
              (templateChange)="updateTemplate($event)"
              [attr.data-testid]="template.name"
            ></pxc-filter-date-range>
        
            <pxc-filter-selector
              *ngSwitchCase="FilterType.SELECTOR"
              [template]="template"
              (templateChange)="updateTemplate($event)"
              [attr.data-testid]="template.name"
            ></pxc-filter-selector>
        
          </ng-container>
    
          <button
            *tuiLet="(isClearable$ | async) as clearable"
            tuiIconButton
            [icon]="'tuiIconCloseLarge'" 
            appearance="flat"
            size="s"
            class="ml-auto"
            (click)="clear()"
            [disabled]="!clearable"
            data-testid="clear-button"
          ></button>
    
        </div>
      </ng-container>
  
      <tui-hosted-dropdown
        *ngIf="actions.length > 0"
        [content]="actionsDropdown"
        tuiDropdownAlign="right"
      >
        <button tuiIconButton icon="tuiIconMoreVer" appearance="flat" size="s" data-testid="action-button"></button>
      </tui-hosted-dropdown>
  
      <ng-template #actionsDropdown>
        <pxc-menu-actions [actions]="actions"></pxc-menu-actions>
      </ng-template>
    </div>
  </div>
</tui-island>
