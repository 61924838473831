import { Injectable } from '@angular/core';
import { PatientPageParams } from '@modules/patient/patient-page-params';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  Patient, FlattenedTelemonitoring, SadmClient, FilterOperator, PaginationQuery,
  PaginationDirection, FlattenedSequence,
} from '@pixacare/pxc-ts-core';
import { SequenceStorePaginationService } from '@services/sequence-store-pagination.service';
import { TelemonitoringStorePaginationService } from '@services/telemonitoring-store-pagination.service';
import { FilteredSearch } from '@shared/models/filters/filtered-search';
import { PagedCollection } from '@shared/models/pagination/paged-collection';
import { patientPaginationContext } from '@shared/models/pagination/pagination-context.config';
import { selectHasLicenseWriteAccess } from '@shared/store/licenses/licenses.selectors';
import { patientsActions } from '@shared/store/patients/patients.actions';
import { selectIsPatientStateLoaded, selectClientPatient } from '@shared/store/patients/patients.selectors';
import {
  selectPatientId, selectClientCode, selectDepartmentId, selectQueryParams,
} from '@shared/store/router/router.selectors';
import { sadmActions } from '@shared/store/sadm/sadm.actions';
import { selectSadmClientsWithEntities } from '@shared/store/sadm/sadm.selectors';
import { sequencesActions } from '@shared/store/sequences/sequences.actions';
import { selectIsSequenceStateLoaded } from '@shared/store/sequences/sequences.selectors';
import { telemonitoringsActions } from '@shared/store/telemonitorings/telemonitorings.actions';
import { stringToBoolean } from '@shared/utils/utils';
import { Observable, combineLatest, filter, map, switchMap, Subscription, first, skipWhile } from 'rxjs';


@UntilDestroy()
@Injectable()
export class PatientService {

  private latestParams: PatientPageParams = {
    clientCode: undefined,
    patientId: undefined,
    departmentId: undefined,
    isArchived: undefined,
  };

  private latestFilteredSearch: FilteredSearch = { search: '', filters: [] };
  private patient$: Observable<Patient>;
  private telemonitorings$: Observable<PagedCollection<FlattenedTelemonitoring[]>>;
  private sadms$: Observable<SadmClient[]>;

  private params$: Observable<PatientPageParams> = combineLatest({
    patientId: this.store.select(selectPatientId).pipe(filter(Boolean)),
    clientCode: this.store.select(selectClientCode),
    departmentId: this.store.select(selectDepartmentId),
    isArchived: this.store.select(selectQueryParams).pipe(map((params) => stringToBoolean(params.is_archived))),
  }).pipe(untilDestroyed(this));

  private readonly hasLicenseWriteAccess$ = this.store.select(selectHasLicenseWriteAccess).pipe(untilDestroyed(this));

  private isPatientStateLoaded$ = this.params$.pipe(
    untilDestroyed(this),
    switchMap(({ clientCode }) => this.store.select(selectIsPatientStateLoaded(clientCode))),
  );

  private readonly isSequenceStateLoaded$ = this.store.select(selectIsSequenceStateLoaded).pipe(untilDestroyed(this));

  private sequenceFiltersSubscription: Subscription;

  constructor(
    private readonly store: Store,
    private readonly telemonitoringPaginationService: TelemonitoringStorePaginationService,
    private readonly sequencePaginationService: SequenceStorePaginationService,
  ) { }

  resetSequences(): void {
    this.store.dispatch(sequencesActions.resetSequencesState());
  }

  getPatient(): Observable<Patient> {

    if (!this.patient$) {

      this.patient$ = this.params$.pipe(
        switchMap(({ clientCode, patientId }) => this.store.select(selectClientPatient(clientCode, patientId))),
        untilDestroyed(this),
      );

      combineLatest([
        this.params$,
        this.patient$,
      ]).pipe(first()).subscribe(([parameters, patient]) => {

        if (!patient) {
          this.store.dispatch(patientsActions.getPatient(parameters));
        }

      });

    }

    return this.patient$;

  }

  getParams(): Observable<PatientPageParams> {
    return this.params$;
  }

  getHasLicenseWriteAccess(): Observable<boolean> {
    return this.hasLicenseWriteAccess$;
  }

  getSadms(): Observable<SadmClient[]> {
    if (!this.sadms$) {
      this.sadms$ = this.params$.pipe(
        untilDestroyed(this),
        switchMap(({ clientCode, patientId, isArchived }) => {
          this.store.dispatch(sadmActions.getSadmEntities({ clientCode, patientId, isArchived }));
          return this.store.select(selectSadmClientsWithEntities(clientCode, patientId, isArchived ));
        }));
    }
    return this.sadms$;
  }

  getTelemonitorings(): Observable<PagedCollection<FlattenedTelemonitoring[]>> {

    if (!this.telemonitorings$) {

      this.store.dispatch(telemonitoringsActions.resetTelemonitoringsState());

      this.params$.pipe(first()).subscribe(({ clientCode, patientId, departmentId }) => {
        const departmentFilters = departmentId
          ? [{ prop: 'department_id', op: FilterOperator.EQUAL, val: departmentId.toString() }]
          : [];
        this.telemonitoringPaginationService.load(clientCode, {
          query: new PaginationQuery({
            filter: [
              {
                prop: 'patient_id',
                op: FilterOperator.EQUAL,
                val: patientId.toString(),
              },
              ...departmentFilters,
            ],
          }),
          reset: true,
          direction: PaginationDirection.NEXT,
        });

      });

      this.telemonitorings$ = this.telemonitoringPaginationService.select().pipe(untilDestroyed(this));
    }

    return this.telemonitorings$;

  }

  loadNextTelemonitorings(): void {
    this.params$.pipe(first()).subscribe(({ clientCode }) => {
      this.telemonitoringPaginationService.loadNextPage(clientCode);
    });
  }

  loadPreviousTelemonitorings(): void {
    this.params$.pipe(first()).subscribe(({ clientCode }) => {
      this.telemonitoringPaginationService.loadPreviousPage(clientCode);
    });
  }

  getSequences(filteredSearch$: Observable<FilteredSearch>): Observable<PagedCollection<FlattenedSequence[]>> {

    // unsubscribing from previous filter bar subscription
    if (this.sequenceFiltersSubscription) {
      this.sequenceFiltersSubscription.unsubscribe();
    }
    // setting up new filter bar subscription
    this.sequenceFiltersSubscription = combineLatest([filteredSearch$, this.params$])
      .pipe(
        untilDestroyed(this),
        filter(([{ search, filters }, params]) => {

          const hasParamsChanged = Object.keys(this.latestParams).some(
            (paramKey) => this.latestParams[paramKey] !== params[paramKey],
          );
          this.latestParams = params;

          const hasFilteredSearchChanged = this.latestFilteredSearch.search !== search
            || Object.keys(this.latestFilteredSearch.filters).some((filterKey, index) =>
              this.latestFilteredSearch.filters[index].val.toString() !== filters[index].val.toString(),
            );
          this.latestFilteredSearch = { search, filters };

          return hasParamsChanged || hasFilteredSearchChanged;
        }),
      )
      .subscribe(([{ search, filters }, { clientCode, departmentId }]) => {
        this.resetSequences();

        this.sequencePaginationService.load(clientCode, {
          query: new PaginationQuery({
            orderBy: ['created_on|desc'],
            filter: [
              ...filters,
              ...(departmentId ?
                [{ prop: 'department_id', op: FilterOperator.EQUAL, val: departmentId.toString() }] : []),
            ],
            size: patientPaginationContext.countPerPage,
            search,
          }),
          reset: true,
        });

      });

    return combineLatest([
      this.sequencePaginationService.select(),
      this.getParams(),
    ]).pipe(
      untilDestroyed(this),
      skipWhile(([data, { patientId }]) =>
        data?.data.some((sequence) => sequence.patientInstance?.id !== patientId),
      ),
      map(([data]) => data),
    );

  }

  getIsSequenceStateLoaded(): Observable<boolean> {
    return this.isSequenceStateLoaded$;
  }

  getIsPatientStateLoaded(): Observable<boolean> {
    return this.isPatientStateLoaded$;
  }

  loadNextSequences(): void {
    this.getParams().pipe(first())
      .subscribe(({ clientCode }) => {
        this.sequencePaginationService.loadNextPage(clientCode);
      });
  }

  loadPreviousSequences(): void {
    this.getParams().pipe(first())
      .subscribe(({ clientCode }) => {
        this.sequencePaginationService.loadPreviousPage(clientCode);
      });
  }

}
