import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TuiSvgModule } from '@taiga-ui/core';
import { TuiBadgeModule } from '@taiga-ui/kit';

@Component({
  selector: 'pxc-cross-badge',
  standalone: true,
  imports: [
    CommonModule,
    TuiSvgModule,
    TuiBadgeModule,
  ],
  templateUrl: './cross-badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrossBadgeComponent {

  @Input() isActive = true;

}
