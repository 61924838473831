import {
  Component, OnInit,
} from '@angular/core';
import {
  CdkDragDrop, CdkDrag, CdkDropList,
} from '@angular/cdk/drag-drop';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { DrawerOpeningState } from '../../../shared/models/enums/drawer-state.enum';
import { first, take } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DrawerGalleryService } from '../drawer-gallery.service';
import { drawerActions } from 'src/app/shared/store/drawer/drawer.actions';
import { selectInteractiveDrawer } from 'src/app/shared/store/drawer/drawer.selectors';
import { InteractiveDrawer } from 'src/app/shared/models/interactive-drawer';
import { Media } from '@pixacare/pxc-ts-core';

@UntilDestroy()
@Component({
  selector: 'pxc-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})

export class DrawerComponent implements OnInit {

  DrawerState = DrawerOpeningState;
  drawer$: Observable<InteractiveDrawer>;
  isUserSorting = false;

  constructor(
    private readonly store: Store,
    private readonly drawerGalleryService: DrawerGalleryService,
  ) {}

  ngOnInit(): void {
    this.drawer$ = this.store.select(selectInteractiveDrawer);
  }

  alreadyPresentPredicate = (thumb: CdkDrag<Media>, dropList: CdkDropList): boolean =>
    !dropList.data.map((media) => media.filename).includes(thumb.data?.filename);

  clearItems(): void {
    this.store.dispatch(drawerActions.deleteMedias());
  }

  removeThumbnail(idx: number): void {
    this.store.dispatch(drawerActions.deleteMedia({ idx }));
  }

  updateDrawerState(): void {
    let drawerState = DrawerOpeningState.OPEN;
    this.drawer$.pipe(take(1))
      .subscribe((drawer) => {
        if (drawer.state === DrawerOpeningState.OPEN) {
          drawerState = drawer.medias.length > 0 ? DrawerOpeningState.COMPACT : DrawerOpeningState.TINY;
        }
        this.store.dispatch(drawerActions.updateState({ drawerState }));
      });
  }

  drop(event: CdkDragDrop<string[]>): void {
    if (event.container.id === event.previousContainer.id) {
      // move inside same list
      this.store.dispatch(drawerActions.moveMedia({
        previousIdx: event.previousIndex,
        newIdx: event.currentIndex,

      }));
    } else {
      // move between lists
      this.store.dispatch(drawerActions.insertMedia({
        newMedia: event.item.data,
        insertIdx: event.currentIndex,
      }));
    }
    this.isUserSorting = false;
  }

  loadImageInGallery(): void {
    this.drawer$.pipe(first()).subscribe((drawer) => {
      this.drawerGalleryService.openGallery(drawer.medias);
    });
  }

}
