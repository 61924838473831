<p>Sélectionnez la lésion pour laquelle vous souhaitez remplir un questionnaire.</p>
<ng-container *ngIf="sadms$ | async as sadms">
    <div class="flex flex-col gap-6 my-3">
        <div class="flex flex-col gap-1" *ngFor="let sadm of sadms" [attr.data-testid]="'sadm-'+sadm.id">
            <div class="flex align-items-center justify-between gap-4">
                <p class="text-slate-700 font-bold">
                    {{ SadmTypeLabel[sadm.sadmType] | pluralize | titlecase }}
                </p>
                <button tuiButton appearance="secondary" size="s" (click)="createEntity(sadm)" icon="tuiIconPlusLarge" data-testid="create-entity">
                    Ajouter
                </button>
            </div>
            <ng-container *ngIf="isDataLoaded$ | async; else loadingBlock">
                <div *ngIf="sadm.entities?.length > 0; else emptyState" class="flex flex-col gap-4 py-2 px-3">
                    <tui-radio-labeled
                        *ngFor="let sadmEntity of sadm.entities"
                        [formControl]="selectedSadmIdControl"
                        [item]="sadmEntity.id"
                        [attr.data-testid]="'sadm-entity-'+sadmEntity.id"
                        size="l"
                        ngDefaultControl
                    >
                        {{ sadmEntity | sadmEntityName: sadm.sadmType | titlecase }}
                    </tui-radio-labeled>
                </div>
            </ng-container>

            <ng-template #emptyState>
                <p class="text-slate-500 p-3" data-testid="no-sadm">
                  Vous n'avez aucune {{SadmTypeLabel[sadm.sadmType]}} pour ce patient.
                </p>
            </ng-template>

            <ng-template #loadingBlock>
                <div class="flex gap-2 items-center justify-center h-14" data-testid="loading-sadms">
                    <pxc-spinner></pxc-spinner>
                    <p class="text-slate-500">Chargement des questionnaires ...</p>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>

<div class="flex justify-between">
    <button 
        tuiButton 
        appearance="flat" 
        (click)="close()"
        data-testid="cancel-button"
    >
        Annuler
    </button>
    <button 
        tuiButton 
        [disabled]="selectedSadmIdControl.invalid" 
        (click)="validate()"
        data-testid="confirm-button"
    >
        Valider
    </button>
</div>