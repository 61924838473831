<div class="flex flex-col gap-2">
    <div class="flex max-md:flex-col md:items-center gap-2">
        <pxc-title 
            title="Emplacement" 
            titleSize="tiny"
            icon="tuiIconFolderLarge" 
            class="basis-1/3"
        ></pxc-title>
        <pxc-client-picker 
            [clients]="clients$ | async" 
            [selectedClient]="clientCode"
            (selectedClientChange)="clientCodeChange.emit($event)" 
            data-testid="client-picker"
            class="flex-1"
        ></pxc-client-picker>
    </div>
    <tui-island class="p-0" *ngIf="!!departments$">
        <pxc-list-picker 
            data-testid="department-picker" 
            [items]="departments$ | async" 
            title="Groupes" 
            [emptyMessage]="clientCode === 'common'
                ? 'Cet espace ne contient aucun groupe : votre séquence sera privée.'
                : 'Cet espace ne contient aucun groupe : vous ne pouvez pas créer de séquence ici.'"
            [selection]="departmentIds" 
            (selectionChange)="departmentIdsChange.emit($event)"
            class="h-[30vh]"
        ></pxc-list-picker>
    </tui-island>
</div>