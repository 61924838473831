import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, catchError, first, iif, map, mergeMap, of, skipWhile, tap, throwError } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { selectSadmClient, selectSadmEntity }
  from 'src/app/shared/store/sadm/sadm.selectors';
import { FormComponent } from '../forms/form/form.component';
import { SadmEntityPickerComponent } from './sadm-entity-picker/sadm-entity-picker.component';
import { SadmEntityNamePipe } from 'src/app/shared/pipes/sadm-entity-name.pipe';
import { SadmEntityCreationService } from './sadm-entity-creation.service';
import { TuiDialogSize } from '@taiga-ui/core';
import { BaseSadmEntity, Form, FormAnswers, SadmClient, SadmEntity } from '@pixacare/pxc-ts-core';

@Injectable()
export class SadmFormService {

  constructor(
    private readonly store: Store,
    private readonly dialogService: DialogService,
    private readonly sadmEntityNamePipe: SadmEntityNamePipe,
    private readonly sadmEntityCreationService: SadmEntityCreationService,
  ) { }

  createAnalysis({ selectedSadmEntityId, clientCode, patientId, selectedSadmClient }: {
    selectedSadmEntityId?: number;
    clientCode: string;
    patientId?: number;
    selectedSadmClient?: SadmClient;
  }): Observable<{
      sadmEntityId?: number;
      analysisFormAnswers: FormAnswers;
      sadmEntity?: BaseSadmEntity;
      protocolFormAnswers?: FormAnswers;
    }> {

    return this.pickAnalysisForm({
      clientCode,
      patientId,
      sadmEntityId: selectedSadmEntityId,
      sadmClient: selectedSadmClient,
    }).pipe(
      tap((data) => {
        if (!data.sadmEntityId && !data.sadmEntity) {
          throwError(() => 'sadmEntity or sadmEntityId should be defined');
        }
      }),
      mergeMap((data) =>
        // Create protocol only if we just created a new entity
        iif(() => !!data.protocolForm && !!data.sadmEntity,
          this.openForm(data.protocolForm, null, 'Protocole de soin', 'l'),
          of(null),
        ).pipe(map((protocolFormAnswers) => ({ ...data, protocolFormAnswers }))),
      ),
      mergeMap(({ sadmEntity, analysisForm, sadmEntityId, protocolFormAnswers }) =>
        // Fill analysis form
        this.openForm(analysisForm, null).pipe(
          map((analysisFormAnswers) => {
            if (!analysisFormAnswers) {
              throwError(() =>'analysisFormAnswers should not be null');
            }
            return ({
              sadmEntity,
              sadmEntityId,
              analysisFormAnswers,
              protocolFormAnswers,
            });
          }),
        )),
      catchError(() => {
        console.warn('Error while creating analysis');
        return of(null);
      }),
      first(),
    );

  }

  getSadmEntityName({ sadmEntityId, sadmEntity: baseSadmEntity }: {
    sadmEntityId?: number;
    sadmEntity?: BaseSadmEntity;
  }): Observable<string> {

    if (sadmEntityId) {
      return this.selectSadmEntity(sadmEntityId)
        .pipe(
          skipWhile((sadmEntity) => !sadmEntity),
          map((sadmEntity) =>
            this.sadmEntityNamePipe.transform(sadmEntity, sadmEntity.sadmClient?.sadmType)),
        );
    }

    if (baseSadmEntity) {
      return of(baseSadmEntity.name);
    }

    return of('');

  }

  editAnalysis({ sadmEntityId, sadmEntity, analysisFormAnswers }: {
    sadmEntityId?: number;
    analysisFormAnswers: FormAnswers;
    sadmEntity?: BaseSadmEntity;
  }): Observable<FormAnswers> {
    return this.selectForms({ sadmEntityId, sadmEntity })
      .pipe(
        mergeMap(({ analysisForm }) =>
          this.openForm(analysisForm, analysisFormAnswers)));
  }

  openEntityPicker({ clientCode, patientId }: {
    clientCode: string;
    patientId?: number;
  }): Observable<{
      sadmEntityId: number;
      sadmEntity: BaseSadmEntity;
    }> {
    return this.dialogService.openComponent
    <SadmEntityPickerComponent, {
      sadmEntityId: number;
      sadmEntity: BaseSadmEntity;
    }>(
      SadmEntityPickerComponent, {
        label: 'Remplir un questionnaire',
        data: { clientCode, patientId },
      },
    );
  }

  private pickAnalysisForm({
    clientCode, patientId, sadmEntityId, sadmClient,
  }: {
    clientCode: string;
    patientId?: number;
    sadmEntityId?: number;
    sadmClient?: SadmClient;
  }): Observable<{
      sadmEntity?: BaseSadmEntity;
      analysisForm: Form;
      protocolForm?: Form;
      sadmEntityId?: number;
    }> {

    if (sadmEntityId) {
      return this.selectFormsByEntityId(sadmEntityId)
        .pipe(
          map((forms) => ({ ...forms, sadmEntityId })),
        );
    }

    return (sadmClient
      ? this.sadmEntityCreationService.openEntityCreation(sadmClient)
        .pipe(
          map((sadmEntity) => ({ sadmEntity })),
        )
      : this.openEntityPicker({ clientCode, patientId })
    ).pipe(
      mergeMap((result) => this.selectForms(result)
        .pipe(
          map((forms) => ({ ...forms, ...result })),
        )),
    );

  }

  private selectSadmEntity(sadmEntityId: number): Observable<SadmEntity> {
    return this.store.select(selectSadmEntity(sadmEntityId));
  }

  private selectForms({ sadmEntity, sadmEntityId }: {
    sadmEntityId?: number;
    sadmEntity?: BaseSadmEntity;
  }): Observable<{
      analysisForm: Form;
      protocolForm?: Form;
    }> {
    return sadmEntityId
      ? this.selectFormsByEntityId(sadmEntityId)
      : this.selectFormsByClientId(sadmEntity.sadmClientId);
  }

  private selectFormsByEntityId(sadmEntityId: number): Observable<{
    analysisForm: Form;
    protocolForm?: Form;
  }> {
    return this.selectSadmEntity(sadmEntityId)
      .pipe(
        map((sadmEntity) => ({
          analysisForm: sadmEntity.sadmClient?.analysisCreationForm,
          protocolForm: sadmEntity.sadmClient?.protocolCreationForm,
        })),
        first(),
      );
  }

  private selectFormsByClientId(sadmClientId: number): Observable<{
    analysisForm: Form;
    protocolForm?: Form;
  }> {
    return this.store.select(selectSadmClient(sadmClientId))
      .pipe(
        map((sadmClient) => ({
          analysisForm: sadmClient.analysisCreationForm,
          protocolForm: sadmClient.protocolCreationForm,
        })),
        first(),
      );
  }

  private openForm(form: Form, formAnswers: FormAnswers, label = 'Remplir un questionnaire',
    size: TuiDialogSize = undefined): Observable<FormAnswers> {
    return this.dialogService.openComponent(
      FormComponent, {
        label,
        data: {
          form,
          formAnswers,
        },
        dismissible: false,
        size,
      },
    );
  }

}
