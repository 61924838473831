import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CodeInputModule } from 'angular-code-input';
import { SharedModule } from '../shared/shared.module';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { LoginComponent } from './components/login/login.component';
import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { RegisterComponent } from './components/register/register.component';
import {
  ResetPasswordRequestComponent,
} from './components/reset-password-request/reset-password-request.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ValidateComponent } from './components/validate/validate.component';
import { TuiButtonModule, TuiLinkModule, TuiSvgModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiCheckboxLabeledModule, TuiInputModule, TuiInputPasswordModule, TuiIslandModule } from '@taiga-ui/kit';

@NgModule({
  declarations: [
    AuthLayoutComponent,
    LoginComponent,
    RegisterComponent,
    ValidateComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    ResetPasswordRequestComponent,
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    ReactiveFormsModule,
    CodeInputModule,
    SharedModule,
    TuiButtonModule,
    TuiInputModule,
    TuiInputPasswordModule,
    TuiButtonModule,
    TuiTextfieldControllerModule,
    TuiCheckboxLabeledModule,
    TuiIslandModule,
    TuiLinkModule,
    TuiSvgModule,
  ],
})
export class AuthenticationModule { }
