<div class="w-full border-t border-slate-200 flex flex-col gap-3 p-3">
  <div class="flex gap-3 items-center">
    <button
      tuiIconButton
      appearance="flat"
      icon="tuiIconPlusLarge"
      size="l"
      (click)="addAttachment()"
    ></button>
    <form class="flex gap-3 items-center w-full" (ngSubmit)="send()">
      <tui-input class="flex-1" [formControl]="newMessageText" [tuiTextfieldLabelOutside]="true" tuiAutoFocus>
        Entrez un message
      </tui-input>
      <button
        tuiIconButton
        type="submit"
        appearance="flat"
        icon="tuiIconSendLarge"
        size="l"
      ></button>
    </form>
  </div>
</div>