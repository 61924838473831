<div class="flex flex-col gap-6 bg-white p-3">

  <div class="flex flex-col gap-2">
    <p class="text-sm text-slate-600">Epaisseur: {{width}}</p>
    <input type="range" 
      tuiSlider
      [min]="colorPickerConfig.minWidth" 
      [max]="colorPickerConfig.maxWidth"
      [ngModel]="width"
      (ngModelChange)="selectWidth($event)"
      data-testid="slider-color-picker"
    >
  </div>

  <div class="grid grid-cols-4 gap-2">
    <button
      type="button" 
      *ngFor="let defaultColor of defaultColors"
      class="rounded-full w-8 h-8 border transition"
      [style.background-color]="defaultColor.code"
      (click)="selectColor(defaultColor)"
      [attr.data-testid]="defaultColor.name"
      [tuiHint]="defaultColor.name"
      [ngClass]="
        defaultColor.name === color.name 
        ? 'ring-4 ring-pixablue-1/10 !shadow-lg' 
        : 'border-slate-200'
      "
    ></button>
  </div>  

</div>