<tui-tabs 
  *ngIf="tabs$ | async as tabs"
  [(activeItemIndex)]="activeItemIndex"
>
  <button tuiTab 
    *ngFor="let tab of tabs; trackBy: trackByTab" 
    (click)="onTabChange(tab)"
  > 
    {{ tab.display }}
  </button>
</tui-tabs>