<pxc-tag-input 
  prefixIcon="person-outline" 
  placeholder="Entrer un nom ou une adresse mail" 
  [validators]="validators"
  [autocompleteModels]="autocompleteModels$ | async" 
  [autocompleteUsers]="autocompleteUsers$ | async" 
  [tags]="selectedValues" 
  (searchChange)="inputChange$.next($event)"
  [search]="inputChange$ | async" 
  (tagsChange)="setUsers($event)" 
  data-testid="tag-input"
></pxc-tag-input>