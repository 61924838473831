<ng-template #menuActions>
  <pxc-menu-actions
    [actions]="actions"
    [entity]="user"
  ></pxc-menu-actions>
</ng-template>

<tui-hosted-dropdown
  [content]="menuActions"
  [sided]="true"
  class="w-full"
>
  <pxc-tile 
    class="rounded-xl hover:bg-slate-100 transition py-2 cursor-pointer" 
    [ngClass]="collapsed ? 'px-2.5' : 'px-1'"
    [tuiHint]="collapsed ? 'Votre compte' : null"
    tuiHintAppearance="onDark"
    tuiHintDirection="right"
  >
    <pxc-avatar 
      leading 
      [name]="user.firstName + ' ' + user.lastName"
      size="tiny"
      class="transition-all"
    ></pxc-avatar>
    <p class="line-clamp-1 break-all text-sm" title [animateFade]="!collapsed">{{user.firstName}} {{user.lastName}}</p>
    <p class="line-clamp-1 break-all text-slate-500 text-xs" subtitle [animateFade]="!collapsed">{{user.mailAddress}}</p>
    <tui-svg src="tuiIconMoreVertical" class="text-slate-400" [animateFade]="!collapsed" trailing></tui-svg>
  </pxc-tile>
</tui-hosted-dropdown>