<tui-hosted-dropdown
  class="w-full"
  [tuiDropdownLimitWidth]="collapsed ? 'auto': 'fixed'"
  [(open)]="open"
  [content]="dropdownContent"
>
  <div tuiWrapper appearance="textfield"
    class="flex justify-between items-center py-1.5 rounded-xl bg-white cursor-pointer transition-all px-3"
    [ngClass]="{ 
      '!ring ring-inset ring-pixablue-1': open
    }"
    data-testid="client-picker-toggle"
  >
    <div class="flex items-center">
      <ng-container
        [ngTemplateOutlet]="client"
        [ngTemplateOutletContext]="{ 
        clientCode: selectedClient,
        hideName: collapsed
      }"
      ></ng-container>
    </div>
    <tui-svg
      class="text-slate-300 !transition-all"
      [ngClass]="{ 'rotate-180': open }"
      src="tuiIconChevronDownLarge"
      [animateFade]="!collapsed"
    ></tui-svg>
  </div>
</tui-hosted-dropdown>

<ng-template #dropdownContent>
  <div class="p-2 flex flex-col" *ngIf="clientCodes?.length > 0; else emptyState">
    <div *ngFor="let clientCode of clientCodes"
      class="flex items-center justify-between p-2 rounded-xl hover:bg-slate-100 transition-colors cursor-pointer"
      (click)="selectClient(clientCode)"
      [attr.data-testid]="'client-' + clientCode"
    >
      <ng-container
        [ngTemplateOutlet]="client"
        [ngTemplateOutletContext]="{ clientCode: clientCode }"
      ></ng-container>
      <tui-svg *ngIf="clientCode === selectedClient"
        class="text-slate-700"
        src="tuiIconCheck"
      ></tui-svg>
    </div>
  </div>
</ng-template>

<ng-template #emptyState>
  <p class="text-slate-500 text-sm text-center">Vous n'avez aucun autre espace.</p>
</ng-template>

<ng-template #client let-clientCode="clientCode" let-hideName="hideName">
  <div class="flex gap-4 items-center my-auto" *ngIf="clientCodes?.length > 0">
    <img 
      [src]="cdnClientBaseUrl+(clients[clientCode]?.cdnImagePath || defaultClientImagePath)" 
      data-testid="client-image"
      [alt]="clientCode + ' client logo'"
      class="rounded-full border border-slate-100" [ngClass]="{
        'h-10 w-10': size !== Size.SMALL,
        'h-8 w-8': size === Size.SMALL
      }" />
    <p class="line-clamp-2 leading-snug" [ngClass]="{'text-sm': size === Size.SMALL}" [animateFade]="!hideName">{{ clients[clientCode]?.fullName }}</p>
  </div>
</ng-template> 
