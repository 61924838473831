import { PagedCollection } from '../pagination/paged-collection';
import { defaultPaginationContext } from '../pagination/pagination-context.config';
import { lens } from '../../utils/utils';
import { PagedResponse, PaginationContext, PaginationQuery, QueryNavigation } from '@pixacare/pxc-ts-core';


export const getNextPagedCollection = <T, U>({
  currentCollection,
  nextCollection,
}: { currentCollection: PagedCollection<T>; nextCollection: PagedResponse<U> },
  {
    reset = false,
    truncate = false,
    dataIdentifier = 'id',
    dataReducer = (entities) => entities.reduce((acc, entity) => ({
      ...acc,
      [lens(entity, dataIdentifier) as number]: entity,
    }), {}),
    sortPredicate = (entityId1, entityId2, entities) => (
      entities[entityId2].createdOn?.getTime() - entities[entityId1].createdOn?.getTime()
    ),
    paginationContext = defaultPaginationContext,
  }: {
    reset: boolean;
    truncate?: boolean;
    dataIdentifier?: string;
    dataReducer?: (entities: U[], dataIdentifier?: string) => { [id: number]: any };
    sortPredicate?: (entityId1: number, entityId2: number, entities: T) => number;
    paginationContext?: PaginationContext;
  }): PagedCollection<T> => {

  const context = {
    ...paginationContext,
    ...(!reset && currentCollection.context),
  };

  const navigation = {
    next: [],
    previous: [],
    ...(!reset && JSON.parse(JSON.stringify(currentCollection.navigation || {}))),
  } as QueryNavigation;

  const lastPage = context.lastPage + 1;
  let data = currentCollection?.data;
  let sorted = currentCollection?.sorted;

  sorted = [
    ...(reset ? [] : (sorted || [])),
    ...nextCollection.data?.map((entity) => lens(entity, dataIdentifier)) as number[],
  ];

  if (nextCollection.data.length) {

    if (!reset && truncate && data) {

      const idsToRemove = new Set(sorted.splice(0, sorted.length - context.countPerPage * context.currentPages));

      data = Object.keys(data)
        .filter((entityId) => !idsToRemove.has(+entityId))
        .reduce((acc, entityId) => ({
          ...acc,
          [entityId]: data[entityId],
        }), {}) as T;
    } else if (reset && !data) {
      data = {} as any;
    }

    data = {
      ...data,
      ...dataReducer(nextCollection.data, dataIdentifier),
    };
  }

  navigation.next[lastPage] = (
    PaginationQuery.fromEncodedQuery(nextCollection.navigation.next)
  );
  navigation.previous[lastPage] = (
    PaginationQuery.fromEncodedQuery(nextCollection.navigation.previous)
  );

  sorted = sorted.sort((firstEntityId, secondEntityId) => sortPredicate(firstEntityId, secondEntityId, data));

  return {
    ...currentCollection,
    totalCount: nextCollection.totalCount,
    data,
    navigation,
    context: {
      ...context,
      hasNextPage: nextCollection.data.length >= context.countPerPage,
      hasPreviousPage: lastPage >= context.currentPages,
      lastPage,
      isNextPageLoading: false,
    },
    sorted,
  };

};

export const getPreviousPagedCollection = <T, U>({
  currentCollection, nextCollection,
}: { currentCollection: PagedCollection<T>; nextCollection: PagedResponse<U> },
  {
    dataIdentifier = 'id',
    dataReducer = (entities) => entities.reduce((acc, entity) => ({
      ...acc,
      [lens(entity, dataIdentifier) as number]: entity,
    }), {}),
    sortPredicate = (entityId1, entityId2, entities) => (
      entities[entityId2].createdOn?.getTime() - entities[entityId1].createdOn?.getTime()
    ),
  }: {
    dataIdentifier?: string;
    dataReducer?: (entities: U[], dataIdentifier?: string) => { [id: number]: any };
    sortPredicate?: (entityId1: number, entityId2: number, entities: T) => number;
  }): PagedCollection<T> => {

  let { lastPage } = currentCollection.context;

  const context = { ...currentCollection.context };
  const page = lastPage - context.currentPages;
  const navigation = JSON.parse(JSON.stringify(currentCollection.navigation));

  let sorted = currentCollection.sorted;
  let data = currentCollection?.data;

  if (page >= 0) {
    lastPage--;
    navigation.next[page] = PaginationQuery.fromEncodedQuery(nextCollection.navigation.next);
    navigation.previous[page] = PaginationQuery.fromEncodedQuery(nextCollection.navigation.previous);
  }

  const sortedFromCollection = nextCollection.data.map((entity) => lens(entity, dataIdentifier)) as number[];

  sorted = [
    ...sortedFromCollection,
    ...sorted.filter((entityId) => !sortedFromCollection.includes(entityId)),
  ];

  const idsToRemove = new Set(
    sorted.splice(
      context.countPerPage * context.currentPages,
      sorted.length - context.countPerPage * context.currentPages,
    ),
  );

  data = Object.keys(data || {})
    .filter((entityId) => !idsToRemove.has(+entityId))
    .reduce((acc, entityId) => ({
      ...acc,
      [entityId]: data[entityId],
    }), {}) as T;

  data = {
    ...data,
    ...dataReducer(nextCollection.data, dataIdentifier),
  };

  sorted = sorted.sort((firstEntityId, secondEntityId) => sortPredicate(firstEntityId, secondEntityId, data));

  return {
    ...currentCollection,
    totalCount: nextCollection.totalCount,
    data,
    navigation,
    context: {
      ...context,
      hasNextPage: true,
      hasPreviousPage: nextCollection.data.length === context.countPerPage || nextCollection.data.length === 0,
      lastPage,
      isPreviousPageLoading: false,
    },
    sorted,
  };
};


