import { labelsActions } from './labels.actions';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { notificationsActions } from '../notifications/notifications.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, map, mergeMap, Observable, switchMap } from 'rxjs';
import { LabelHttpService } from 'src/app/services/http/label.http.service';
import { FlattenedLabel, Label } from '@pixacare/pxc-ts-core';

@Injectable()
export class LabelsEffect {

  getUserLabels$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(labelsActions.getUserLabels),
      mergeMap(() =>
        this.labelService.getFlattenedLabels()
          .pipe(
            map((flattenedLabels: FlattenedLabel[]) =>
              labelsActions.getUserLabelsSuccess({
                flattenedLabels,
              }),
            ),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  createLabels$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(labelsActions.createLabels),
      mergeMap(({ labels }) =>
        this.labelService.createLabel(labels)
          .pipe(
            switchMap(() => [
              labelsActions.getUserLabels(),
              notificationsActions.success({
                title: 'Les mots clés ont bien été ajoutés.',
                message: 'Mots clés créés',
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  unsubscribeFromLabel$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(labelsActions.unsubscribeFromLabel),
      mergeMap(({ labelId, removeFromSequences }) =>
        this.labelService.unsubscribeFromLabel(labelId, removeFromSequences)
          .pipe(
            map(() => labelsActions.unsubscribeFromLabelSuccess({ labelId })),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  // ---- Label packs ---

  createLabelPackLabels$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(labelsActions.createLabelPackLabels),
      mergeMap(({ labelPackId, clientCode, departmentId, labels }) =>
        this.labelService.createLabelPackLabels(labelPackId, clientCode, labels)
          .pipe(
            switchMap((createdLabels: Label[]) => [
              labelsActions.addLabels({
                labels: createdLabels,
              }),
              labelsActions.createLabelPackLabelsSuccess({
                labelPackId, clientCode, departmentId,
                labelIds: createdLabels.map((label) => label.id),
              }),
              notificationsActions.success({
                title: 'Les mots clés ont bien été ajoutés.',
                message: 'Mots clés créés',
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  deleteLabelPackLabels$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(labelsActions.deleteLabelPackLabels),
      mergeMap(({ labelPackId, clientCode, departmentId, labels }) =>
        this.labelService.deleteLabelPackLabels(labelPackId, clientCode, labels.map((label) => label.word))
          .pipe(
            switchMap(() => [
              labelsActions.deleteLabelPackLabelsSuccess({
                labelPackId,
                clientCode,
                departmentId,
                labelIds: labels.map((label) => label.id),
              }),
              notificationsActions.success({
                title: 'Le mot clé a bien été supprimé.',
                message: 'Mot clé supprimé',
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          )),
    ),
  );

  constructor(
    private readonly action$: Actions,
    private readonly labelService: LabelHttpService,
  ) { }

}
