<form [formGroup]='form' class="grid md:grid-cols-2 gap-4 py-2">

    <tui-input
      class="md:col-span-2"
      id="businessIdentifier"
      formControlName="businessIdentifier"
      [pseudoInvalid]="form.controls['businessIdentifier'].invalid"
      data-testid="patient-business-identifier"
    >
      Numéro d'identification
      <input
        tuiTextfield
        maxlength="64"
      />
    </tui-input>

    <tui-input
      id="lastName"
      formControlName="lastName"
      [pseudoInvalid]="form.controls['lastName'].invalid"
      data-testid="patient-last-name"
    >
      Nom
      <input
        tuiTextfield
        maxlength="255"
      />
    </tui-input>

    <tui-input
      id="firstName"
      formControlName="firstName"
      [pseudoInvalid]="form.controls['firstName'].invalid"
      data-testid="patient-first-name"
    >
      Prénom
      <input
        tuiTextfield
        maxlength="255"
      />
    </tui-input>
  
    <tui-input
      id="birthName"
      formControlName="birthName"
      [pseudoInvalid]="form.controls['birthName'].invalid"
      data-testid="patient-birth-name"
    >
      Nom de naissance
      <input
        tuiTextfield
        maxlength="255"
      />
    </tui-input>
    
    <tui-input-date
      id="birthDate"
      formControlName="birthDate"
      [pseudoInvalid]="form.controls['birthDate'].invalid"
      data-testid="patient-birth-date"
    >
      Date de naissance
    </tui-input-date>

</form>