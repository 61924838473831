<div class="py-3 flex flex-col gap-4">
  <div class="my-5 flex flex-col xl:flex-row xl:items-center gap-6 animate-in fade-in slide-in-from-top-1">
    <div class="flex-1">
      <h1>Bonjour {{ currentUser.firstName }},</h1>
      <p class="text-slate-600 leading-relaxed">Bienvenue sur votre espace sécurisé</p>
    </div>
  
    <div class="flex flex-wrap flex-row items-center gap-3">

      <button tuiButton appearance="outline" routerLink="/dashboard/profile/details" queryParamsHandling="merge" data-testid="profile-button" icon="tuiIconEdit2">
        Modifier mon profil
      </button>
      <button tuiButton (click)="createSequence()" data-testid="create-sequence" icon="tuiIconPlus" class="max-xl:order-first">
        Créer une séquence
      </button>
    </div>
  </div>
  
  <tui-island>
    <div class="flex flex-col gap-3 animate-in fade-in slide-in-from-bottom-1">
      <h5>Mes statistiques</h5>
      <div class="grid lg:grid-cols-3 items-stretch gap-4" *ngIf="
        userStatistics$ | async as userStatistics;
        else loading
      "> 

        <div
          *ngFor="let item of stats"
          class="flex items-center p-5 lg:p-6 gap-4 rounded-xl cursor-pointer transition-all bg-opacity-50 hover:bg-opacity-100 hover:shadow-xl hover:-translate-y-1 group active:brightness-90"
          [ngClass]="{
            'pixablue': 'bg-pixablue-3 text-pixablue-1 !shadow-blue-100/50',
            'pixapurple': 'bg-pixapurple-3 text-pixapurple-1 !shadow-purple-100/50',
            'pixared': 'bg-pixared-3 text-pixared-1 !shadow-red-100/50'
          }[item.color]"
          [routerLink]="item.link"
          [queryParams]="{cc: clientCode$ | async}"
          queryParamsHandling="merge"
          [attr.data-testid]="item.label"
        >
          <div class="flex-1 flex flex-col gap-2">
            <p class="leading-tight">{{ item.label }} </p>
            <div class="flex items-center gap-2">
              <tui-svg [src]="item.icon" class="!h-8 !w-8 scale-125"></tui-svg>
              <p class="text-3xl tracking-tight">{{ userStatistics[item.value] }}</p>
            </div>
          </div>
          <tui-svg src="tuiIconChevronRightLarge" class="opacity-50 group-hover:opacity-100"></tui-svg>
        </div>

      </div>
    </div>
  </tui-island>

  <div class="my-5 flex flex-col gap-2 animate-in fade-in slide-in-from-top-1">
    <h5>Besoin d'aide ?</h5>
    <p class="text-slate-600">Notre équipe est à votre disposition pour vous aider.</p>
    <div class="flex flex-col md:flex-row md:items-center gap-6 mt-2">
      <ng-container *ngFor="let item of helpActions">
        <ng-container *ngTemplateOutlet="helpItem; context: item"></ng-container>
      </ng-container>
    </div>
  </div>

</div>

<ng-template #helpItem let-icon="icon" let-label="label" let-execute="execute">
  <a 
    tuiLink
    (click)="execute()" 
    target="_blank"
  >
    <tui-svg [src]="icon" class="mr-1"></tui-svg>
    {{ label }}
  </a>
</ng-template>

<ng-template #loading>
  <pxc-loader></pxc-loader>
</ng-template>