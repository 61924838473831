import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Size } from 'src/app/shared/models/enums/size.enum';

@Component({
  selector: 'pxc-title',
  templateUrl: './title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TitleComponent {

  @Input() icon?: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() counter?: number;
  @Input() maxCount?: number | string;
  @Input() titleSize = Size.MEDIUM;

  Size = Size;

}
