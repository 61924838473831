<pxc-filter-dropdown-button
  [isActive]="isActive"
  [buttonText]="buttonText"
  icon="tuiIconCalendar"
>
  <div class="flex p-3 flex-col gap-4" data-testid="dropdown-wrapper">

    <div class="grid grid-cols-2 gap-4">

          <tui-input-date
            toNativeDate
            [ngModel]="dates.min"
            (ngModelChange)="updateDates({min: $event, max: dates.max})"
            class="w-full"
            tuiTextfieldCleaner="true"
            [min]="toTuiDay(template.min) ?? MIN_DATE"
            [max]="toTuiDay(!!dates.max ? dates.max : template.max) ?? MAX_DATE"
            data-testid="from-input"
          >
            depuis le
          </tui-input-date>

          <tui-input-date 
            toNativeDate 
            [ngModel]="dates.max"
            (ngModelChange)="updateDates({min: dates.min, max: $event})"
            class="w-full"
            tuiTextfieldCleaner="true" 
            [min]="toTuiDay(!!dates.min ? dates.min : template.min) ?? MIN_DATE"
            [max]="toTuiDay(template.max) ?? MAX_DATE"
            data-testid="to-input"
          >
            jusqu'au
          </tui-input-date>

    </div>

    <tui-range 
      *ngIf="template.min && template.max"
      [ngModel]="[lerpDate(dates.min || template.min), lerpDate(dates.max || template.max)]"
      (ngModelChange)="slider$.next($event)"
      [min]="0"
      [max]="1"
      [step]=".01"
      data-testid="range-slider"
    ></tui-range>

    <div *ngIf="enableShortcuts" class="flex gap-2 flex-wrap justify-center" data-testid="shortcuts">
      <button 
        tuiButton 
        *ngFor="let shortcut of shortcuts; let index = index" 
        [appearance]="index === activeShortcutIndex ? 'primary' : 'secondary'" 
        size="s"
        shape="rounded"
        [attr.data-testid]="shortcut.displayName"
        (click)="applyShortcut(shortcut)"
      >
        {{shortcut.displayName}}
      </button>
    </div>

    <button tuiButton [disabled]="!isActive" (click)="clearFilters()" class="w-full" size="s" data-testid="reset-button" appearance="secondary-destructive">
      Réinitialiser
    </button>

  </div>

</pxc-filter-dropdown-button>