import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { TuiLetModule } from '@taiga-ui/cdk';
import { map, startWith } from 'rxjs';
import { IsMobileService } from '../../../services/is-mobile.service';
import { SharedModule } from '../../shared/shared.module';
import { ChannelListComponent } from '../channel-list/channel-list.component';
import { ChatService } from '../chat.service';
import { MessageGroupService } from '../message-group.service';
import { ChannelNamePipe } from '../pipes/channel-name.pipe';

@Component({
  templateUrl: './chat-page.component.html',
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    ChannelListComponent,
    RouterModule,
    TuiLetModule,
  ],
  providers: [
    ChatService,
    MessageGroupService,
    ChannelNamePipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatPageComponent {

  readonly activatedRoute = inject(ActivatedRoute);

  isMobile$ = inject(IsMobileService).maxWidth(1024);

  displayChannelPane$ = inject(Router).events.pipe(
    map(() => this.isChannelOpen()),
    startWith(this.isChannelOpen()),
  );

  isChannelOpen(): boolean {
    return !!this.activatedRoute.firstChild;
  }

}
