import {
  FormQuestion,
  FormComputations,
  ComputationType,
  FormComputationTarget,
  ValidatorType,
  FormComputation,
} from '@pixacare/pxc-ts-core';
import { FormEngineService } from './form-engine.service';

export class FormEngineComputations {

  questions: FormQuestion[] = [];
  computations: FormComputations = {};

  constructor(questions: FormQuestion[]) {
    this.questions = questions;
  }

  getFormComputations(): FormComputations {

    this.questions.forEach((question) => {
      const computation = question.parameters?.computation;
      if (computation) {
        computation.match(/#{([^}]*)}/g).forEach((target) => {

          const targetId = target.replace(FormEngineService.DYNAMIC_FLAG_REPLACE_REGEX, '');
          this.initQuestionComputations(question.id, targetId, computation);
          this.addComputationHook(this.computations[targetId].hooks, question.id);
          this.addComputationDependency(this.computations[question.id].dependencies, question.id, targetId);
          this.addComputation(this.computations[question.id], computation);
        });
      }

      question.validators?.forEach((validator) => {
        const validatorComputation = validator.value?.toString();
        if (FormEngineService.EXTRACT_QUESTIONS_IDS_REGEX.test(validatorComputation)) {

          validatorComputation.match(FormEngineService.EXTRACT_QUESTIONS_IDS_REGEX).forEach((target) => {

            const targetId = target.replace(FormEngineService.DYNAMIC_FLAG_REPLACE_REGEX, '');
            this.initQuestionComputations(question.id, targetId, computation);
            this.addComputationHook(
              this.computations[targetId].hooks,
              question.id,
              ComputationType.VALIDATOR,
              validator.type,
            );
            this.addComputationDependency(
              this.computations[question.id].dependencies,
              question.id,
              targetId,
              ComputationType.VALIDATOR,
              validator.type,
            );
            this.addComputation(this.computations[question.id], validatorComputation);
          });
        }


      });
    });

    return this.computations;
  }

  private initQuestionComputations(
    questionId: string,
    hookedQuestionId: string,
    computation: string,
  ): void {
    this.computations = {
      ...this.computations,
      ...(!this.computations[questionId] && { [questionId]: { hooks: [], dependencies: [], computation } }),
      ...(!this.computations[hookedQuestionId] && { [hookedQuestionId]: { hooks: [], dependencies: [] } }),
    };
  }

  private addComputationHook(
    hooks: FormComputationTarget[],
    hookedQuestionId: string,
    type: ComputationType = ComputationType.VALUE,
    subType?: ValidatorType,
  ): void {
    if (hooks.findIndex((hook) =>
      hook.questionId === hookedQuestionId && hook.type === type && (!hook.subType || hook.subType === subType),
    ) < 0) {
      hooks.push({ questionId: hookedQuestionId, type, ...(subType && { subType }) });
    }

  }

  private addComputationDependency(
    dependencies: FormComputationTarget[],
    questionId: string,
    targetId: string,
    type: ComputationType = ComputationType.VALUE,
    subType?: ValidatorType,
  ): void {
    if (dependencies.findIndex((dep) =>
      dep.questionId === questionId && dep.type === type && (!dep.subType || dep.subType === subType),
    ) < 0) {
      dependencies.push({ questionId: targetId, type, ...(subType && { subType }) });
    }
  }

  private addComputation(
    questionComputation: FormComputation,
    computation: string,
  ): void {
    if (!questionComputation.computation) {
      questionComputation.computation = computation;
    }
  }

}
