import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PagedResponse, FlattenedTelemonitoring, PaginationOptions, FlattenedSequence } from '@pixacare/pxc-ts-core';
import { SequenceHttpService } from '@services/http/sequence.http.service';
import { TelemonitoringHttpService } from '@services/http/telemonitoring.http.service';
import { Observable, mergeMap, catchError, concatMap, concat, of, switchMap } from 'rxjs';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { notificationsActions } from '../notifications/notifications.actions';
import { patientsActions } from '../patients/patients.actions';
import { sequencesActions } from '../sequences/sequences.actions';
import { telemonitoringsActions } from './telemonitorings.actions';

const navigationActions = (collection: PagedResponse<FlattenedTelemonitoring>) => [
  ...(collection.data || []).map((telemonitoring: FlattenedTelemonitoring) =>
    patientsActions.updatePatientSuccess({
      clientCode: telemonitoring.telemonitoring.clientCode,
      patient: telemonitoring.patient,
    }),
  ),
  ...(collection.data || []).map((telemonitoring: FlattenedTelemonitoring) =>
    telemonitoringsActions.getSequences({
      clientCode: telemonitoring.telemonitoring.clientCode,
      tmId: telemonitoring.telemonitoring.id,
    }),
  ),
];

const handlePreviousNavigation = (
  collection: PagedResponse<FlattenedTelemonitoring>,
  options?: PaginationOptions,
) => [
  telemonitoringsActions.getPreviousUserTelemonitoringsSuccess({
    reset: options?.reset,
    collection,
  }),
  ...navigationActions(collection),
];

const handleNextNavigation = (
  collection: PagedResponse<FlattenedTelemonitoring>,
  options?: PaginationOptions,
) => [
  telemonitoringsActions.getNextUserTelemonitoringsSuccess({
    reset: options?.reset,
    collection,
  }),
  ...navigationActions(collection),
];


@Injectable()
export class TelemonitoringsEffects {

  getNextUserTelemonitorings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(telemonitoringsActions.getNextUserTelemonitorings),
      mergeMap(({ options: { query, reset } }) =>
        this.tlmService.getTelemonitorings({ query })
          .pipe(
            mergeMap((collection: PagedResponse<FlattenedTelemonitoring>) =>
              handleNextNavigation(collection, { query, reset }),
            ),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  getPreviousUserTelemonitorings$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(telemonitoringsActions.getPreviousUserTelemonitorings),
      mergeMap(({ options: { query, reset } }) =>
        this.tlmService.getTelemonitorings({ query })
          .pipe(
            mergeMap((collection: PagedResponse<FlattenedTelemonitoring>) =>
              handlePreviousNavigation(collection, { query, reset }),
            ),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ),
  );

  getTelemonitoring$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(telemonitoringsActions.getTelemonitoring),
      mergeMap(({ telemonitoringId }) =>
        this.tlmService.getTelemonitoring(telemonitoringId)
          .pipe(
            mergeMap((telemonitoring: FlattenedTelemonitoring) => [
              ...(telemonitoring.sequences && telemonitoring.sequences.length ? [
                sequencesActions.loadSequences({
                  clientCode: telemonitoring.telemonitoring.clientCode,
                  data: telemonitoring.sequences,
                }),
              ] : []),
              telemonitoringsActions.getTelemonitoringSuccess({ telemonitoring }),
            ],
            ),
            catchError((error: Error) => [
              lifeCycleActions.loadError({ error }),
              notificationsActions.error({
                error,
                title: 'Télésuivi introuvable.',
                message: 'Le télésuivi que vous recherchez n\'existe pas ou a été supprimé.',
              }),
            ]),
          ),
      ),
    ),
  );

  getSequences$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(telemonitoringsActions.getSequences),
      mergeMap(({ tmId, clientCode }) =>
        this.sequenceService.getUserTelemonitoringSequences(tmId)
          .pipe(
            mergeMap((sequences: FlattenedSequence[]) => [
              sequencesActions.loadSequences({
                clientCode,
                data: sequences,
              }),
              telemonitoringsActions.loadTelemonitoringSequences({
                tmId,
                sequences,
              }),
            ]),
            catchError((error: HttpErrorResponse) => [
              lifeCycleActions.loadError({ error }),
            ]),
          ),
      ),
    ),
  );

  deleteTelemonitoring$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(telemonitoringsActions.deleteTelemonitoring),
      concatMap(({ telemonitoringId }) => concat(
        of(notificationsActions.info({
          title: 'Le télésuivi est en cours de suppression.',
        })),
        this.tlmService.deleteTelemonitoring(telemonitoringId)
          .pipe(
            concatMap(() => [
              telemonitoringsActions.deleteTelemonitoringSuccess({ telemonitoringId }),
              notificationsActions.success({
                title: 'Le télésuivi a bien été supprimée.',
                message: 'Votre télésuivi a été supprimée avec succès.',
              }),
            ]),
            catchError((error: Error) => [
              lifeCycleActions.loadError({ error }),
              notificationsActions.error({
                error,
                title: 'Impossible de supprimer le télésuivi.',
                message: 'Un problème est survenu.',
              }),
            ]),
          ),
      ),
      )),
  );

  updateTelemonitoringActiveState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(telemonitoringsActions.updateTelemonitoringActiveState),
      mergeMap(({ telemonitoringId, isActive }) =>
        this.tlmService.patchTelemonitoring(telemonitoringId, { isActive })
          .pipe(
            switchMap(() => [
              telemonitoringsActions.updateTelemonitoringActiveStateSuccess({ telemonitoringId, isActive }),
              notificationsActions.success({
                title: 'Le télésuivi a été mis à jour.',
              }),
            ]),
            catchError((error: Error) => [lifeCycleActions.loadError({ error })]),
          ),
      ),
    ));

  unlinkSequence$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(telemonitoringsActions.unlinkSequence),
      concatMap((payload) => concat(
        of(notificationsActions.info({
          title: 'La séquence est en cours de retrait du télésuivi.',
        })),
        this.tlmService.unlinkSequence(
          payload.telemonitoringId,
          payload.clientCode,
          payload.sequenceId,
        ).pipe(
          switchMap(() => [
            sequencesActions.deleteSequenceSuccess({ sequenceId: payload.sequenceId }),
            telemonitoringsActions.unlinkSequenceSuccess(payload),
            notificationsActions.success({
              title: 'La séquence a bien été retirée du télésuivi.',
              message: 'Retrait réussi',
            }),
          ]),
          catchError((error: Error) => [
            lifeCycleActions.loadError({ error }),
            notificationsActions.error({
              error,
              title: 'Impossible de retirer la séquence.',
              message: 'Un problème est survenu.',
            }),
          ]),
        ),
      ),
      ),
    ));

  linkSequences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(telemonitoringsActions.linkSequences),
      concatMap(({ telemonitoringId, clientCode, sequenceIds }) => concat(
        of(
          notificationsActions.info({
            title: 'Les séquences sont en cours de liaison au télésuivi.',
          }),
        ),
        this.tlmService.linkSequences(telemonitoringId, clientCode, sequenceIds)
          .pipe(
            switchMap(() => [
              telemonitoringsActions.getSequences({
                tmId: telemonitoringId,
                clientCode,
              }),
              telemonitoringsActions.linkSequencesSuccess({ telemonitoringId, sequenceIds }),
              notificationsActions.success({
                title: 'Les séquences ont bien été liées au télésuivi.',
                message: 'Liaison réussie',
              }),
            ]),
            catchError((error: Error) => [
              lifeCycleActions.loadError({ error }),
              notificationsActions.error({
                error,
                title: 'Impossible de lier les séquences.',
                message: 'Un problème est survenu.',
              }),
            ]),
          ),
      )),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly tlmService: TelemonitoringHttpService,
    private readonly sequenceService: SequenceHttpService,
  ) {}

}
