import { Component, ChangeDetectionStrategy, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FilterBarService, FILTER_TEMPLATES } from '@modules/filters/filter-bar.service';
import {
  CloseConfirmableDialogComponent,
} from '@modules/shared/close-confirmable-dialog/close-confirmable-dialog.component';
import { TelemonitoringPaginationService } from '@modules/telemonitoring/telemonitoring-pagination.service';
import { TelemonitoringService } from '@modules/telemonitoring/telemonitoring.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  Patient, FlattenedSequence, FilterOperator, Filter, PaginationQuery,
} from '@pixacare/pxc-ts-core';
import { SadmHttpService } from '@services/http/sadm.http.service';
import { SequenceContext } from '@shared/models/enums/sequence-context.enum';
import { Size } from '@shared/models/enums/size.enum';
import { sequenceFilterTemplates } from '@shared/models/filters/filter-template.config';
import { FilteredSequenceSearch } from '@shared/models/filters/filtered-sequence-search';
import { MediaClickedOutput } from '@shared/models/media-clicked-output';
import { NameBySequenceId } from '@shared/models/name-by-sequence-id';
import { FilteredSequenceReportConfig } from '@shared/models/report-config';
import { SadmEntityNamePipe } from '@shared/pipes/sadm-entity-name.pipe';
import { selectClientPatient } from '@shared/store/patients/patients.selectors';
import { sequencesCountPluralMapping, sequenceCountPluralMapping } from '@shared/utils/plural-mappings';
import { TuiDialogContext, TuiAlertService } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { Observable, of, Subject, catchError, map, first } from 'rxjs';
import { SequencePaginationService } from '../services/sequence-pagination.service';
import { UserSequenceGalleryService } from '../services/user-sequence-gallery.service';
import { PagedCollection } from '@shared/models/pagination/paged-collection';
import { SadmEntityTypePipe } from '@shared/pipes/sadm-entity-type.pipe';

@UntilDestroy()
@Component({
  selector: 'pxc-sequence-report',
  templateUrl: './sequence-report.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: sequenceFilterTemplates,
    },
    SequencePaginationService,
    TelemonitoringPaginationService,
    UserSequenceGalleryService,
    SadmEntityNamePipe,
    SadmEntityTypePipe,
  ],
})
export class SequenceReportComponent extends CloseConfirmableDialogComponent<FilteredSequenceReportConfig>
  implements OnInit {

  @Input() patientId: number = this.tuiDialogContext.data.patientId;
  @Input() clientCode: string = this.tuiDialogContext.data.clientCode;
  @Input() departmentId?: number = this.tuiDialogContext.data.departmentId;
  @Input() telemonitoringId?: number = this.tuiDialogContext.data.telemonitoringId;
  @Input() context: SequenceContext = SequenceContext.SEQUENCE;

  sequencesCountPluralMapping = sequencesCountPluralMapping;
  sequenceCountPluralMapping = sequenceCountPluralMapping;
  Size = Size;

  pageSize = 200;

  step = 1;

  customReportForm = new FormGroup({
    includeReports: new FormControl(true),
    includeCharts: new FormControl(true),
  });

  patient$: Observable<Patient>;
  selectedSequenceIds: number[] = [];
  selectedSequences: FlattenedSequence[] = [];

  selectedTelemonitoringIds: number[] = [];
  selectedSadmEntityIds: number[] = [];
  sequences$: Observable<PagedCollection<FlattenedSequence[]>>;
  telemonitoringNames$: Observable<NameBySequenceId> = of({});
  sadmEntityNames$: Observable<NameBySequenceId>;

  constructor(
    private readonly sequencePaginationService: SequencePaginationService,
    private readonly telemonitoringPaginationService: TelemonitoringPaginationService,
    private readonly telemonitoringService: TelemonitoringService,
    private readonly sadmHttpService: SadmHttpService,
    private readonly filterBarService: FilterBarService,
    private readonly sadmEntityNamePipe: SadmEntityNamePipe,
    private readonly store: Store,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly tuiDialogContext: TuiDialogContext<FilteredSequenceReportConfig, {
      clientCode: string;
      patientId: number;
      departmentId: number;
      telemonitoringId?: number;
      shouldAskConfirmation$?: Subject<boolean>;
    }>,
    private readonly galleryService: UserSequenceGalleryService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
  ) {
    super(tuiDialogContext);
  }

  ngOnInit() {
    this.patient$ = this.store.select(selectClientPatient(this.clientCode, this.patientId));

    this.sequences$ = this.sequencePaginationService.select()
      .pipe(
        catchError((e) => {
          console.error('SequenceReport : Error while loading sequences', e);
          this.alertService.open(e.statusText, {
            status: 'error',
            label: 'Une erreur est survenue',
          }).subscribe();
          this.closeDialog();
          return [];
        }),
      );

    const persistentFilters = [{
      prop: 'patient_id',
      op: FilterOperator.EQUAL,
      val: this.patientId.toString(),
    }];

    if (this.departmentId) {
      persistentFilters.push({
        prop: 'department_id',
        op: FilterOperator.EQUAL,
        val: this.departmentId.toString(),
      });
    }

    if (this.telemonitoringId) {
      persistentFilters.push({
        prop: 'telemonitoring_id',
        op: FilterOperator.EQUAL,
        val: this.telemonitoringId.toString(),
      });
    }

    this.filterBarService.setPersistentFilters(persistentFilters);

    this.filterBarService.filteredSearch$
      .pipe(
        untilDestroyed(this),
      ).subscribe(({ search, filters }) => {
        this.getSequences(search, filters);
      });

    this.getTelemonitorings();
    this.getSadmEntities();
  }

  getSequences(
    search: string,
    filters: Filter[],
  ): void {
    const query = new PaginationQuery({
      orderBy: ['created_on|desc'],
      filter: filters,
      search,
      size: this.pageSize,
    });

    this.sequencePaginationService.load(this.clientCode, {
      query,
      reset: true,
    });
  }

  getTelemonitorings(): void {
    this.telemonitoringPaginationService.load(this.clientCode, {
      query: new PaginationQuery({
        size: this.pageSize,
        orderBy: ['last_activity|desc'],
        filter: [{
          prop: 'patient_id',
          op: FilterOperator.EQUAL,
          val: this.patientId.toString(),
        }, {
          prop: 'client_code',
          op: FilterOperator.EQUAL,
          val: this.clientCode,
        }],
      }),
    });

    this.telemonitoringNames$ = this.telemonitoringPaginationService.select().pipe(
      untilDestroyed(this),
      map((pagedTelemonitorings) => pagedTelemonitorings?.data.reduce((acc, telemonitoring) => {
        acc[telemonitoring.telemonitoring.id] = this.telemonitoringService.getTelemonitoringName(telemonitoring);
        return acc;
      }, {})),
    );
  }

  getSadmEntities(): void {
    this.sadmEntityNames$ = this.sadmHttpService.getSadmEntities({
      patientId: this.patientId,
      clientCode: this.clientCode,
      flattened: false,
      includeSadmClients: true,
    })
      .pipe(
        first(),
        map((sadmEntities) =>
          sadmEntities.reduce((acc, sadmEntity) => {
            acc[sadmEntity.id] = this.sadmEntityNamePipe.transform(sadmEntity, sadmEntity.sadmClient?.sadmType);
            return acc;
          }, {}),
        ),
      );
  }

  selectSequences(sequenceIds: number[], sequences: FlattenedSequence[]): void {
    const filteredSequenceIds = sequenceIds.filter((id) => !this.selectedSequenceIds.includes(id));
    this.selectedSequenceIds = [...this.selectedSequenceIds, ...filteredSequenceIds];

    sequenceIds.forEach((id) => {
      const telemonitoringId = sequences
        .find((seq) => seq.sequenceInstance.id === id).sequenceInstance.telemonitoringId;

      const sadmEntityId = sequences
        .find((seq) => seq.sequenceInstance.id === id).sequenceInstance.sadmEntityId;

      if (telemonitoringId && !this.selectedTelemonitoringIds.includes(telemonitoringId)) {
        this.selectedTelemonitoringIds.push(telemonitoringId);
      }

      if (sadmEntityId && !this.selectedSadmEntityIds.includes(sadmEntityId)) {
        this.selectedSadmEntityIds.push(sadmEntityId);
      }
    });

    this.selectedSequences = this.selectedSequenceIds
      .map((id) => sequences.find((seq) => seq.sequenceInstance.id === id));

  }

  unselectSequences(sequenceIds: number[], sequences: FlattenedSequence[]): void {
    this.selectedSequenceIds = this.selectedSequenceIds.filter((id) => !sequenceIds.includes(id));

    this.selectedTelemonitoringIds = this.selectedTelemonitoringIds.filter((telemonitoringId) =>
      this.selectedSequenceIds.some((id) => {
        const sequence = sequences.find((seq) => seq.sequenceInstance.id === id);
        return sequence.sequenceInstance.telemonitoringId === telemonitoringId;
      }),
    );

    this.selectedSadmEntityIds = this.selectedSadmEntityIds.filter((sadmEntityId) =>
      this.selectedSequenceIds.some((id) => {
        const sequence = sequences.find((seq) => seq.sequenceInstance.id === id);
        return sequence.sequenceInstance.sadmEntityId === sadmEntityId;
      }),
    );
  }


  confirmSelection(): void {

    if (this.selectedSequenceIds.length <= 0) {
      return;
    }

    const filteredSequenceSearch: FilteredSequenceSearch = {
      search: null,
      filters: [{
        prop: 'id',
        op: FilterOperator.IN,
        val: this.selectedSequenceIds.join(','),
      }],
      sequenceIds: this.selectedSequenceIds,
      sequences: this.selectedSequences,
    };

    this.tuiDialogContext.completeWith({
      ...this.customReportForm.value,
      ...filteredSequenceSearch,
    });

  }

  closeDialog(): void {
    this.tuiDialogContext.completeWith(null);
  }

  nextStep(): void {
    if (this.step < 2) {
      this.step++;
    } else {
      this.confirmSelection();
    }
  }

  previousStep(): void {
    if (this.step > 1) {
      this.step--;
    } else {
      this.closeDialog();
    }
  }

  openGallery({ sequenceId, mediaIdx, clientCode }: MediaClickedOutput): void {
    this.galleryService.openGallery(
      clientCode,
      sequenceId,
      mediaIdx,
    );
  }

}
