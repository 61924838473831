import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { filter, first, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { SequenceHttpService } from 'src/app/services/http/sequence.http.service';
import { VirtualScrollService } from 'src/app/services/virtual-scroll.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MediaClickedOutput } from 'src/app/shared/models/media-clicked-output';
import { SharedSequenceGalleryService } from '../../sequence/services/shared-sequence-gallery.service';
import { selectAreSharedSequencesFetched, selectShares } from 'src/app/shared/store/shares/shares.selectors';
import { sharesActions } from 'src/app/shared/store/shares/shares.actions';
import { selectSequence, selectSequences } from 'src/app/shared/store/sequences/sequences.selectors';
import { FILTER_TEMPLATES, FilterBarService } from '../../filters/filter-bar.service';
import { FlattenedSequence, Share } from '@pixacare/pxc-ts-core';

@UntilDestroy()
@Component({
  templateUrl: './sharedtome.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    VirtualScrollService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: of([]),
    },
  ],
})
export class SharedtomeComponent implements OnInit {

  areSharedSequencesFetched$: Observable<boolean>;
  shares$: Observable<Share[]>;
  sharedSequences$: Observable<FlattenedSequence[]> = null;

  constructor(
    private readonly store: Store,
    public virtualScrollService: VirtualScrollService<FlattenedSequence>,
    private readonly sequenceService: SequenceHttpService,
    private readonly sharedSequenceGalleryService: SharedSequenceGalleryService,
    private readonly filterBarService: FilterBarService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(sharesActions.getSharesToCurrentUser());

    this.shares$ = this.store.select(selectShares)
      .pipe(
        filter((shares) => !!shares),
      );

    this.areSharedSequencesFetched$ = this.store.select(selectAreSharedSequencesFetched);

    combineLatest([
      this.shares$,
      this.areSharedSequencesFetched$,
    ]).pipe(
      first(([shares, areSharedSequencesFetched]) => !areSharedSequencesFetched),
      untilDestroyed(this),
    ).subscribe(([shares]) => {
      this.store.dispatch(sharesActions.getSharedSequences({ shares }));
    });

    this.sharedSequences$ = this.virtualScrollService.init(
      combineLatest([
        this.store.select(selectSequences).pipe(
          map((paginatedSequences) => paginatedSequences.data || []),
        ),
        this.filterBarService.search$,
      ])
        .pipe(
          map(([sequences, searchQuery]) =>
            sequences?.filter((sequence) => (this.sequenceService.search(sequence, searchQuery ?? ''))),
          ),
        ),
    );
  }

  loadNextEntities(): void {
    this.virtualScrollService.loadNextPage();
  }

  openGallery(clickedMedia: MediaClickedOutput): void {
    this.store.select(selectSequence(clickedMedia.sequenceId)).pipe(first()).subscribe(
      (sequence) => this.sharedSequenceGalleryService.openGallery(sequence, clickedMedia.mediaIdx),
    );
  }

}
