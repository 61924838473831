import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SequenceReportComponent } from '@modules/sequence/sequence-report/sequence-report.component';
import {
  TelemonitoringRequestComponent,
} from '@modules/telemonitoring/telemonitoring-request/telemonitoring-request.component';
import { Store } from '@ngrx/store';
import { Patient, FilterOperator, PaginationQuery } from '@pixacare/pxc-ts-core';
import { DialogService } from '@services/dialog.service';
import { DocumentService } from '@services/document.service';
import { PatientHttpService } from '@services/http/patient.http.service';
import { ActionsService } from '@shared/models/helpers/actions-service';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { MenuActionId } from '@shared/models/menu-actions/menu-action-id.enum';
import { FilteredSequenceReportConfig, QueryReportConfig } from '@shared/models/report-config';
import { selectIsEhrEnabled } from '@shared/store/clients/clients.selectors';
import { patientsActions } from '@shared/store/patients/patients.actions';
import { selectClientCode, selectDepartmentId } from '@shared/store/router/router.selectors';
import { combineLatest, first, mergeMap, map } from 'rxjs';
import { PatientEditComponent } from './patient-edit/patient-edit/patient-edit.component';
import { PatientMergeComponent } from './patient-merge/patient-merge.component';
import { PatientMergeService } from './patient-merge/patient-merge.service';
import { PatientNamePipe } from './patient-name.pipe';
import { PatientReportPreviewComponent } from './patient-report-preview/patient-report-preview.component';
import {
  PatientTeleexpertiseRequestComponent,
} from './patient-teleexpertise-request/patient-teleexpertise-request.component';

@Injectable({
  providedIn: 'root',
})
export class PatientActionsService extends ActionsService {

  clientCode$ = this.store.select(selectClientCode);
  departmentId$ = this.store.select(selectDepartmentId);
  params$ = combineLatest({
    clientCode: this.clientCode$,
    departmentId: this.departmentId$,
  });

  actions: MenuAction<Patient>[] = [
    {
      id: MenuActionId.PATIENT_EDIT,
      label: 'Modifier',
      icon: 'tuiIconEdit2',
      condition: (patient: Patient): boolean => this.isPatientActive(patient),
      execute: (patient: Patient): void => {

        this.clientCode$.pipe(first()).subscribe((clientCode) => {
          this.dialogService.openComponentWithCloseConfirmation(PatientEditComponent, {
            label: 'Modifier le patient',
            size: 'l',
            data: {
              patient,
              clientCode,
            },
          }).subscribe();
        });

      },
    },
    {
      id: MenuActionId.PATIENT_ARCHIVE,
      label: 'Archiver',
      icon: 'tuiIconArchive',
      condition: (patient: Patient): boolean => this.isPatientActive(patient),
      execute: (patient: Patient): void => {
        this.clientCode$.pipe(first()).subscribe((clientCode) => {
          this.store.dispatch(
            patientsActions.archivePatient({
              clientCode,
              patientId: patient.id,
            }),
          );
        });
      },
    },
    {
      id: MenuActionId.PATIENT_UNARCHIVE,
      label: 'Désarchiver',
      icon: 'tuiIconArchive',
      condition: (patient: Patient): boolean => this.isPatientArchived(patient),
      execute: (patient: Patient): void => {
        this.clientCode$.pipe(first()).subscribe((clientCode) => {
          this.store.dispatch(
            patientsActions.unarchivePatient({
              clientCode,
              patientId: patient.id,
            }),
          );
        });
      },
    },
    {
      id: MenuActionId.PATIENT_REPORT,
      label: 'Rapport',
      icon: 'tuiIconFileText',
      execute: (patient: Patient): void => {

        this.params$.pipe(
          mergeMap(({ clientCode, departmentId }) =>
            this.store.select(selectIsEhrEnabled(clientCode)).pipe(
              map((isEhrEnabled) => ({ clientCode, isEhrEnabled, departmentId })),
            ),
          ),
          first(),
        ).subscribe(({ clientCode, isEhrEnabled, departmentId }) => {

          this.dialogService.openComponentWithCloseConfirmation
          <SequenceReportComponent, FilteredSequenceReportConfig>(SequenceReportComponent, {
            label: 'Sélectionner les séquences à exporter',
            data: {
              patientId: patient.id,
              clientCode,
              departmentId,
            },
            size: 'l',
          }).subscribe((reportConfig) => {

            if (reportConfig) {
              if (departmentId) {
                reportConfig.filters.push({
                  prop: 'department_id',
                  op: FilterOperator.EQUAL,
                  val: departmentId.toString(),
                });
              }

              const patientReportConfig: QueryReportConfig = {
                query: new PaginationQuery({
                  orderBy: ['created_on|desc'],
                  filter: reportConfig.filters,
                  search: reportConfig.search,
                  size: 200,
                }),
                includeReports: reportConfig.includeReports,
                includeCharts: reportConfig.includeCharts,
              };

              if (isEhrEnabled && patient.isGamLinked) {
                this.dialogService.openComponentWithCloseConfirmation(PatientReportPreviewComponent, {
                  label: `Rapport de suivi de ${this.patientNamePipe.transform(patient)}`,
                  size: 'l',
                  data: { patient, clientCode, patientReportConfig },
                }).subscribe();
              } else {
                this.documentService.downloadDocument({
                  name: `${this.patientNamePipe.transform(patient)} - Rapport de suivi`,
                  document$: this.patientService.report(
                    clientCode,
                    patient.id,
                    patientReportConfig,
                  ),
                });
              }
            }


          },

          );
        });
      },
    },
    {
      id: MenuActionId.PATIENT_TELEMONITOR,
      label: 'Télésurveiller',
      icon: 'telemonitoring',
      condition: (patient: Patient): boolean => this.isPatientActive(patient),
      execute: (patient: Patient): void => {

        this.params$.pipe(first()).subscribe(({ clientCode, departmentId }) => {

          this.dialogService.openComponentWithCloseConfirmation(TelemonitoringRequestComponent, {
            label: 'Initier un télésuivi',
            size: 'l',
            data: {
              patient,
              clientCode,
              departmentIds: departmentId ? [departmentId] : [],
            },
          }).subscribe();

        });
      },
    },
    {
      id: MenuActionId.PATIENT_MERGE,
      label: 'Fusionner',
      icon: 'tuiIconUsers',
      condition: (patient: Patient): boolean => this.isPatientActive(patient),
      execute: (patient: Patient): void => {

        this.params$.pipe(first()).subscribe(({ clientCode, departmentId }) => {
          this.dialogService.openComponent
          <PatientMergeComponent, Patient>(PatientMergeComponent, {
            label: 'Fusionner deux patients',
            data: {
              patient,
              clientCode,
              departmentId,
            },
          }).subscribe((targetPatient) => {
            if (targetPatient) {
              this.patientMergeService.mergePatient(
                patient,
                targetPatient,
                {
                  confirmAction: () => {
                    this.router.navigate(['/dashboard/patients', targetPatient.id], { queryParamsHandling: 'merge' });
                  },
                },
              );
            }
          });
        });
      },
    },
    {
      id: MenuActionId.PATIENT_TELEEXPERTISE_WITH_OMNIDOC,
      label: 'Téléexpertiser avec Omnidoc',
      icon: 'omnidoc',
      condition: (patient: Patient): boolean => this.isPatientActive(patient),
      execute: (patient: Patient): void => {

        this.params$.pipe(first()).subscribe(({ clientCode, departmentId }) => {

          this.dialogService.openComponentWithCloseConfirmation
          <PatientTeleexpertiseRequestComponent, any>(PatientTeleexpertiseRequestComponent, {
            label: 'Créer une téléexpertise avec Omnidoc',
            size: 'l',
            data: {
              patient,
              clientCode,
              departmentIds: departmentId ? [departmentId] : [],
            },
          }).subscribe();

        });
      },
    },
  ];

  constructor(
    private readonly router: Router,
    private patientService: PatientHttpService,
    private documentService: DocumentService,
    private store: Store,
    private dialogService: DialogService,
    private readonly patientMergeService: PatientMergeService,
    private readonly patientNamePipe: PatientNamePipe,
  ) {
    super();
  }

  isPatientActive(patient: Patient): boolean {
    return patient && !patient.isArchived;
  }

  isPatientArchived(patient: Patient): boolean {
    return patient && patient.isArchived;
  }

}
