import { Injectable, inject } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { FirebaseAuthenticationService } from '../modules/chat/firebase-authentication.service';

@Injectable({
  providedIn: 'root',
})
export class IsFirebaseAuthenticatedGuard implements CanActivate {

  private firebaseAuthenticationService = inject(FirebaseAuthenticationService);

  canActivate(): Observable<boolean> {
    return this.firebaseAuthenticationService.authenticate();
  }

}
