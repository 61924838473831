import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { first } from 'rxjs/operators';
import { sequencesActions } from 'src/app/shared/store/sequences/sequences.actions';
import { selectSequence } from 'src/app/shared/store/sequences/sequences.selectors';
import { SequenceGalleryService } from './sequence-gallery.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';

@UntilDestroy()
@Injectable()
export class UserSequenceGalleryService {

  constructor(
    private readonly sequenceGalleryService: SequenceGalleryService,
    private readonly store: Store,
  ) { }

  openGallery(clientCode: string, sequenceId: number, openIdx: number): void {
    const sequence$: Observable<FlattenedSequence> = this.store.select(
      selectSequence(sequenceId),
    ).pipe(untilDestroyed(this));

    this.sequenceGalleryService.open(
      sequence$,
      openIdx,
      (mediaIdx: number) => {
        sequence$.pipe(first()).subscribe((sequence) => {
          this.store.dispatch(sequencesActions.updateSequenceMedia({
            clientCode,
            sequenceId,
            mediaId: sequence.medias[mediaIdx].id,
          }));
        });
      },
    );
  }

}
