<tui-elastic-container>
  <div class="flex flex-col gap-3">

    <pxc-filter-bar 
      searchPlaceholder="Rechercher une séquence par mot clé ou date ..."
      data-testid="filter-bar"
    ></pxc-filter-bar>
  
    <ng-container *ngIf="(isCollectionLoaded$ | async); else loadingBlock">
      <ng-container *ngIf="(collection$ | async) as collection">
        <pxc-sequence-select-list
          [sequences]="collection.data"
          [selectedSequenceIds]="selectedSequenceIds"
          [disabledSequenceIds]="disableSelectedSequences ? alreadySelectedSequenceIds : []"
          [context]="sequenceContext"
          [singleSelectMode]="singleSelectMode"
          (selectSequences)="selectSequences($event)"
          (loadNext)="loadNextPage()"
          data-testid="sequences"
          class="flex flex-col max-h-[60vh]"
          ></pxc-sequence-select-list>
      </ng-container>
    </ng-container>

    <ng-template #loadingBlock>
      <pxc-loader>Récupération de vos séquences en cours ...</pxc-loader>
    </ng-template>
  
    <div class="flex justify-between gap-2 max-md:flex-col-reverse mt-2">
      <button tuiButton appearance="outline" (click)="close()">Annuler</button>
      <button tuiButton (click)="confirmSelection()" data-testid="confirm-selection-button" >Confirmer</button>
    </div>
  </div>
</tui-elastic-container>