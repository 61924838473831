import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { TuiButtonModule, TuiErrorModule, TuiHintModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import {
  TuiCheckboxBlockModule,
  TuiElasticContainerModule,
  TuiFieldErrorPipeModule,
  TuiInputModule,
  TuiInputNumberModule,
  TuiInputSliderModule,
  TuiProgressModule,
  TuiRadioBlockModule,
  TuiRadioListModule,
  TuiTextareaModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import { FormComponent } from './form/form.component';
import { TuiLetModule } from '@taiga-ui/cdk';
import { FormsModule as AngularFormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormDisplayComponent } from './form-display/form-display.component';

@NgModule({
  declarations: [
    FormComponent,
    FormDisplayComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TuiButtonModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiProgressModule,
    TuiLetModule,
    TuiElasticContainerModule,
    TuiCheckboxBlockModule,
    TuiRadioBlockModule,
    TuiInputNumberModule,
    TuiToggleModule,
    TuiProgressModule,
    TuiRadioListModule,
    TuiInputSliderModule,
    AngularFormsModule,
    ReactiveFormsModule,
    TuiErrorModule,
    TuiFieldErrorPipeModule,
    TuiHintModule,
    TuiTextareaModule,
  ],
  exports: [
    FormComponent,
    FormDisplayComponent,
  ],
})
export class FormsModule { }
