<div class="flex flex-col gap-2 py-2">
  <h2>Bonjour,</h2>
  <p class="max-w-prose text-balance">Connectez-vous à votre environnement sécurisé de photographies médicales.</p>
</div>

<form [formGroup]="loginForm" (ngSubmit)="login()" data-testid="login-form" class="flex flex-col gap-4 mt-6">
  <div>
    <tui-input
      formControlName="inputEmail"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
      tuiTextfieldIconLeft="tuiIconMailLarge"
    >
      Adresse E-Mail
      <input tuiTextfield type="email" data-testid="email-input">
    </tui-input>
    <app-input-validation-error-display [control]="inputEmail">
    </app-input-validation-error-display>
  </div>

  <div>
    <tui-input-password
      formControlName="inputPassword"
      tuiTextfieldIconLeft="tuiIconLockLarge"
      [disabled]="(actionOnGoing$ | async) ? '' : null"
    >
      Mot de passe
      <input tuiTextfield type="password" data-testid="password-input">
    </tui-input-password>
    <app-input-validation-error-display [control]="inputPassword">
    </app-input-validation-error-display>
  </div>


  <a tuiLink data-appearance="primary" class="!text-sm" routerLink="../reset-password-request">
    Mot de passe oublié ?
  </a>


  <tui-checkbox-labeled
    formControlName="rememberEmail"
    [disabled]="(actionOnGoing$ | async) ? '' : null"
    data-testid="remember-email-checkbox"
    class="mt-3"
  >
    Se souvenir de mon e-mail
  </tui-checkbox-labeled>

  <div class="flex flex-row-reverse justify-between flex-wrap gap-3 mt-4">

    <button
      tuiButton
      type="submit"
      appearance="primary"
      [disabled]="actionOnGoing$ | async"
      [showLoader]="actionOnGoing$ | async"
      data-testid="login-button"
      class="max-sm:order-first max-sm:w-full"
    >
      Se connecter
    </button>

    <button
      tuiButton
      appearance="outline"
      routerLink="../register"
      [disabled]="actionOnGoing$ | async"
      class="max-sm:w-full"
    >
      Je n'ai pas de compte
    </button>

  </div>

</form>

