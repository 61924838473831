import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UserActionsService } from '../user-actions.service';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { TuiHintModule, TuiHostedDropdownModule, TuiSvgModule } from '@taiga-ui/core';
import { AnimateFadeDirective } from '../../shared/directives/animate-fade.directive';
import { TileComponent } from 'src/app/shared/components/tile/tile.component';
import { AvatarComponent } from 'src/app/shared/components/avatar/avatar.component';
import { User } from '@pixacare/pxc-ts-core';

@Component({
  selector: 'pxc-user-account-card',
  templateUrl: './user-account-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    UserActionsService,
  ],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    TuiHostedDropdownModule,
    TuiSvgModule,
    TuiHintModule,
    AnimateFadeDirective,
    TileComponent,
    AvatarComponent,
  ],
})
export class UserAccountCardComponent {

  @Input() collapsed = false;

  user: User = this.authenticationService.currentUser;
  actions: MenuAction<User>[] = this.userActionsService.getActions();

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly userActionsService: UserActionsService,
  ) { }

}
