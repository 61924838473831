import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TuiStatus, tuiInputTagOptionsProvider, tuiTagOptionsProvider } from '@taiga-ui/kit';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AutoCompleteLabelModel } from 'src/app/shared/models/helpers/auto-complete-label-model';
import { selectFlattenedLabels, selectLabels } from 'src/app/shared/store/labels/labels.selectors';
import { select } from 'src/app/shared/utils/utils';
import { LabelComponent } from '../label/label.component';
import { TuiDataListWrapperModule, TuiInputTagModule } from '@taiga-ui/kit';
import { TuiDataListModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlattenedLabel } from '@pixacare/pxc-ts-core';

@UntilDestroy()
@Component({
  selector: 'pxc-select-label',
  templateUrl: './select-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    tuiTagOptionsProvider({
      status: 'label' as unknown as TuiStatus,
      size: 'l',
    }),
    tuiInputTagOptionsProvider({
      tagStatus: 'label' as unknown as TuiStatus,
    }),
  ],
  standalone: true,
  imports: [
    CommonModule,
    LabelComponent,
    TuiInputTagModule,
    TuiTextfieldControllerModule,
    TuiDataListModule,
    TuiDataListWrapperModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})

export class SelectLabelComponent implements OnInit, OnChanges {

  @Input() selectedLabels: AutoCompleteLabelModel[] = [];
  @Input() showFavoriteLabels = false;

  @Input() autocomplete = true;

  @Output() selectedLabelsChange = new EventEmitter<AutoCompleteLabelModel[]>();

  selectedLabelIds$ = new BehaviorSubject<number[]>([]);
  autoCompleteLabels$: Observable<string[]>;
  favoriteLabels$: Observable<AutoCompleteLabelModel[]>;
  FAVORITE_LABELS_COUNT = 5;

  labelSearch = new BehaviorSubject<string>('');
  value = [];

  constructor(private readonly store: Store) {}

  ngOnChanges({ selectedLabels }: SimpleChanges): void {
    this.selectedLabelIds$.next(selectedLabels.currentValue.map((l) => l.value.id));
    this.value = selectedLabels.currentValue.map((l) => l.display);
  }

  onSearchChange(search: string): void {
    this.labelSearch.next(search);
  }

  ngOnInit(): void {

    if (this.showFavoriteLabels) {
      this.favoriteLabels$ = combineLatest([
        this.selectedLabelIds$,
        this.store.select(selectFlattenedLabels),
      ]).pipe(
        map(([selectedLabelIds, flattenedLabels]) => {
          const sortedLabels: FlattenedLabel[] = [...flattenedLabels].sort((fl1, fl2) => (
            (fl2.userLabelStatsInstance?.count || 0) - (fl1.userLabelStatsInstance?.count || 0)
          ));

          return select(
            sortedLabels,
            (label: FlattenedLabel) => !selectedLabelIds.includes(label.labelInstance.id),
            this.FAVORITE_LABELS_COUNT,
          ).map((fLabel) => ({
            display: fLabel.labelInstance.word,
            value: fLabel.labelInstance,
          }));
        }),
        untilDestroyed(this),
      );
    }

    if (this.autocomplete) {
      this.autoCompleteLabels$ = combineLatest([
        this.labelSearch,
        this.store.select(selectLabels),
      ]).pipe(
        map(([labelInput, userLabels]) => {
          if (labelInput.length > 1) {
            return Object.values(userLabels)
              .filter((label) => label.word.toUpperCase().includes(labelInput.toUpperCase())
                && !this.selectedLabelIds$.getValue().includes(label.id),
              )
              .map((label) => label.word);
          }
          return [];
        }),
        untilDestroyed(this),
      );
    }

  }

  onLabelChange(value: string[]): void {
    this.store.select(selectLabels).pipe(first()).subscribe((labels) => {
      this.selectedLabelsChange.emit(value.map((selectedLabel) => ({
        display: selectedLabel,
        value: Object.values(labels).find((l) => l.word === selectedLabel) || selectedLabel,
      })));
    });
  }

  addLabel(label: string): void {
    this.onLabelChange([...this.value, label]);
  }

}
