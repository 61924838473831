import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate, RouterStateSnapshot,
} from '@angular/router';
import { AuthenticationService } from '@services/authentication.service';
import { LogoutStrategyService } from '@services/logout-strategy.service';
import { isTokenValid, decodeToken } from '@shared/utils/token-utils';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly logoutStrategyService: LogoutStrategyService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationService.currentUser
      && isTokenValid(decodeToken(this.authenticationService.getToken()))) {
      return true;
    }

    this.logoutStrategyService.logout(state.url);
    return false;
  }

}
