import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { FilterBarService, FILTER_TEMPLATES } from '@modules/filters/filter-bar.service';
import { SadmEntityCreationService } from '@modules/sadm/sadm-entity-creation.service';
import { SadmFormService } from '@modules/sadm/sadm-form.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { SadmTypeLabel, SadmClient, Patient } from '@pixacare/pxc-ts-core';
import { FilterType } from '@shared/models/filters/filter-type.enum';
import { sadmActions } from '@shared/store/sadm/sadm.actions';
import { selectIsPatientSadmStateLoaded } from '@shared/store/sadm/sadm.selectors';
import { of, Observable, switchMap, map, first } from 'rxjs';
import { PatientService } from '../patient/patient.service';

@UntilDestroy()
@Component({
  templateUrl: './patient-analyses.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SadmFormService,
    SadmEntityCreationService,
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useFactory: () => of(
        [{
          type: FilterType.BUTTONS,
          property: 'is_archived',
          name: 'Archivable',
          getValue: () => of([
            { value: 'false', display: 'Actives' },
            { value: 'true', display: 'Archivées' },
            { value: undefined, display: 'Toutes' },
          ]),
        }],
      ),
    },
  ],
})
export class PatientAnalysesComponent implements OnInit {

  SadmTypeLabel = SadmTypeLabel;
  sadms$: Observable<SadmClient[]>;
  patient$: Observable<Patient>;

  isDataLoaded$ = this.patientPageService.getParams().pipe(
    untilDestroyed(this),
    switchMap(({ clientCode, patientId }) =>
      this.store.select(selectIsPatientSadmStateLoaded(clientCode, patientId)),
    ));

  constructor(
    private readonly patientPageService: PatientService,
    private readonly sadmEntityCreationService: SadmEntityCreationService,
    private readonly store: Store,
  ) { }

  ngOnInit() {
    this.sadms$ = this.patientPageService.getSadms();
    this.patient$ = this.patientPageService.getPatient();
  }

  createEntity(sadmClient: SadmClient): void {
    this.patient$.pipe(
      switchMap((patient) =>
        this.sadmEntityCreationService.openEntityCreation(sadmClient)
          .pipe(
            map((sadmEntity) => ({
              sadmEntity,
              patient,
            })),
          )),
      first(),
    ).subscribe(({ sadmEntity: baseSadmEntity, patient }) => {

      if (!baseSadmEntity) {
        return;
      }

      this.store.dispatch(sadmActions.createSadmEntity({
        sadmEntityPayload: {
          ...baseSadmEntity,
          patientId: patient.id,
          clientCode: patient.clientCode,
        },
      }));

    });
  }

}
