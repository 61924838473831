import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';
import { trackBySequenceId } from 'src/app/shared/utils/trackBySequenceId';

@UntilDestroy()
@Component({
  selector: 'pxc-sequence-select-radio-list',
  templateUrl: './sequence-select-radio-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceSelectRadioListComponent implements OnInit {

  @Input() sequences: FlattenedSequence[];
  @Input() selectedSequenceId: number;
  @Input() compactMode = true;
  @Output() selectSequence: EventEmitter<number> = new EventEmitter();

  trackBySequenceId = trackBySequenceId;

  sequenceForm = new FormGroup({
    selectedSequenceId: new FormControl<number>(null),
  });

  ngOnInit(): void {
    if (this.selectedSequenceId) {
      this.sequenceForm.patchValue({
        selectedSequenceId: this.selectedSequenceId,
      });
    }
    this.sequenceForm.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.selectSequence.emit(value.selectedSequenceId);
    });
  }

}
