<div class="w-screen h-screen flex items-center justify-center bg-blue-50">

  <div class="absolute blur-3xl bg-pixablue-2/30 w-64 h-full max-h-80 rounded-full -translate-x-40 -translate-y-48"></div>
  <div class="absolute blur-3xl bg-pixapurple-2/30 rounded-full w-64 h-full max-h-80 translate-x-48"></div>
  <div class="absolute blur-3xl bg-pixared-2/20 w-full max-w-sm h-64 rounded-full translate-y-40"></div>

  <div class="container flex justify-center z-10">
    <tui-island class="md:max-w-lg md:w-full animate-in fade-in slide-in-from-top-1 bg-white/90 backdrop-blur-xl">
      <div class="flex flex-col gap-2 p-3">
        <img 
          src="https://cdn.pixacare.com/logo/left-transparent.svg" 
          data-testid="logo"
          class="w-48"
          alt="Pixacare Logo"
        />
        <router-outlet data-testid="content"></router-outlet>
      </div>
    </tui-island>
  </div>

</div>
