import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Channel } from '@pixacare/pxc-ts-core';
import { TuiSvgModule } from '@taiga-ui/core';
import { TuiToggleModule } from '@taiga-ui/kit';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ChatService } from '../../chat.service';
import { BehaviorSubject, first } from 'rxjs';
import { TileComponent } from 'src/app/shared/components/tile/tile.component';

@Component({
  selector: 'pxc-channel-notifications',
  standalone: true,
  imports: [
    CommonModule,
    TileComponent,
    TuiSvgModule,
    TuiToggleModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './channel-notifications.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelNotificationsComponent implements OnChanges {

  @Input() channel: Channel;

  notificationsEnabled = false;
  currentUserId = inject(AuthenticationService).currentUser.id;
  loading$ = new BehaviorSubject<boolean>(false);

  private readonly chatService = inject(ChatService);

  ngOnChanges(): void {

    if (!this.channel) {
      return;
    }

    this.notificationsEnabled = !this.channel.muted.includes(this.currentUserId);
  }

  toggle(): void {
    this.loading$.pipe(first()).subscribe((loading) => {

      if (loading) {
        return;
      }

      this.loading$.next(true);
      this.chatService.updateNotifications(this.channel.id, !this.notificationsEnabled).finally(() => {
        this.loading$.next(false);
      });

    });
  }

}
