import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { Channel } from '@pixacare/pxc-ts-core';
import { ChannelAvatarComponent } from '../channel-avatar/channel-avatar.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TuiBadgeModule, TuiLineClampModule } from '@taiga-ui/kit';
import { timestampToDate } from '../chat.utils';
import { TileComponent } from 'src/app/shared/components/tile/tile.component';
import { ChannelSubtitlePipe } from '../pipes/channel-subtitle.pipe';
import { ChannelNamePipe } from '../pipes/channel-name.pipe';
import { Size } from 'src/app/shared/models/enums/size.enum';

@Component({
  selector: 'pxc-channel-preview',
  standalone: true,
  imports: [
    CommonModule,
    ChannelAvatarComponent,
    ChannelSubtitlePipe,
    TileComponent,
    TuiBadgeModule,
    ChannelNamePipe,
    TuiLineClampModule,
  ],
  templateUrl: './channel-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelPreviewComponent implements OnChanges {

  @Input() channel: Channel;
  @Input() size: Size = Size.SMALL;
  @Input() showDetails = true;
  @Input() vertical = false;

  unreadsCount = 0;
  date: Date;

  constructor(private readonly authenticationService: AuthenticationService) { }

  ngOnChanges() {
    this.unreadsCount = this.channel.unreads[this.authenticationService.currentUser.id];

    this.date = timestampToDate(this.channel.lastMessage?.createdOn);
  }

}
