import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { PatientActionsService } from '@modules/patient/patient-actions.service';
import { TelemonitoringActionsService } from '@modules/telemonitoring/telemonitoring-actions.service';
import { Store } from '@ngrx/store';
import { FlattenedTelemonitoring, Patient, Telemonitoring } from '@pixacare/pxc-ts-core';
import { MenuAction } from '@shared/models/menu-actions/menu-action';
import { MenuActionId } from '@shared/models/menu-actions/menu-action-id.enum';
import { selectIsTelemonitoringStateLoaded } from '@shared/store/telemonitorings/telemonitorings.selectors';
import { Observable, first } from 'rxjs';
import { PatientService } from '../patient/patient.service';
import { PagedCollection } from '@shared/models/pagination/paged-collection';

@Component({
  templateUrl: './patient-telemonitorings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientTelemonitoringsComponent implements OnInit  {

  telemonitorings$: Observable<PagedCollection<FlattenedTelemonitoring[]>>;
  patient$: Observable<Patient>;
  isDataLoaded$ = this.store.select(selectIsTelemonitoringStateLoaded);

  constructor(
    private readonly telemonitoringActionsService: TelemonitoringActionsService,
    private readonly patientPageService: PatientService,
    private readonly store: Store,
    private readonly patientActionsService: PatientActionsService,
  ) { }

  get telemonitoringActions(): MenuAction<Telemonitoring>[] {
    return this.telemonitoringActionsService.getActions();
  }

  ngOnInit(): void {
    this.telemonitorings$ = this.patientPageService.getTelemonitorings();
    this.patient$ = this.patientPageService.getPatient();
  }

  loadNext(): void {
    this.patientPageService.loadNextTelemonitorings();
  }

  loadPrevious(): void {
    this.patientPageService.loadPreviousTelemonitorings();
  }

  createTelemonitoring(): void {
    this.patient$.pipe(first()).subscribe((patient) => {
      this.patientActionsService.getAction(MenuActionId.PATIENT_TELEMONITOR).execute(patient);
    });
  }

}
