<tui-elastic-container>
  <p
    *ngIf="control.invalid && (control.dirty || control.touched)"
    class="mt-2 text-red-700 text-sm leading-4" data-testid="message"
  >

    <ng-container *ngIf="control.errors.required">
      Ce champ est requis.
    </ng-container>
    <ng-container *ngIf="control.errors.maxlength">
      Vous ne pouvez pas entrer plus de {{control.errors.maxlength.requiredLength}} caractères.
    </ng-container>
    <ng-container *ngIf="control.errors.minlength">
      Veuillez entrer au moins {{control.errors.minlength.requiredLength}} caractères.
    </ng-container>
    <ng-container *ngIf="control.errors.email">
      Veuillez entrer une adresse email valide.
    </ng-container>
    <ng-container *ngIf="control.errors.password">
      {{ configuration.password.message }}
    </ng-container>
    <ng-container *ngIf="control.errors.phoneNumber">
      {{ configuration.phoneNumber.message }}
    </ng-container>
    <ng-container *ngIf="control.errors.min">
    {{ configuration.min.message }}
    </ng-container>
    <ng-container *ngIf="control.errors.max">
      {{ configuration.max.message }}
    </ng-container>
  </p>
</tui-elastic-container>