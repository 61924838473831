import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { EXPORTS_FEATURE_KEY } from './exports.selectors';
import { DataExport } from '@pixacare/pxc-ts-core';

export const exportsActions = createActionGroup({
  source: EXPORTS_FEATURE_KEY,
  events: {
    'Get exports': emptyProps(),
    'Get exports success': props<{ dataExports: DataExport[] }>(),

    'Create export': emptyProps(),
    'Create export success': props<{ newExport: DataExport }>(),

    'Delete export': props<{ exportId: number }>(),
    'Delete export success': emptyProps(),
  },
});


