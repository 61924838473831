import { createActionGroup, props } from '@ngrx/store';
import { ClientData } from '../../models/base/client-data';
import { CLIENTS_FEATURE_KEY } from './clients.selectors';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';

export const clientsActions = createActionGroup({
  source: CLIENTS_FEATURE_KEY,
  events: {
    'Update clients': props<{ clients: ClientData[] }>(),
    'Update data consumption': props<{ clientCode: string; sequence: FlattenedSequence }>(),
  },
});
