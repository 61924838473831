import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { DrawerOpeningState } from '../../models/enums/drawer-state.enum';
import { DRAWER_FEATURE_KEY } from './drawer.selectors';
import { Media } from '@pixacare/pxc-ts-core';

export const drawerActions = createActionGroup({
  source: DRAWER_FEATURE_KEY,
  events: {
    'Update state': props<{ drawerState: DrawerOpeningState }>(),
    'Update is user dragging': props<{ isUserDragging: boolean }>(),
    'Insert media': props<{ newMedia: Media; insertIdx: number }>(),
    'Move media': props<{ previousIdx: number;newIdx: number }>(),
    'Delete media': props<{ idx: number }>(),
    'Delete medias': emptyProps(),
  },
});
