<div class="!shadow-none 
  transition active:bg-slate-100 rounded-lg"
  [ngClass]="{'ring-2 ring-pixablue-1': isSelected}"
>
    <img *ngIf="sadmEntity.previewMedia && !imageError; else noImageTemplate"
      class="h-48 w-full rounded-xl object-cover" 
      data-testid="image"
      [src]="sadmEntity.previewMedia.thumbnailUri"
      [alt]="sadmEntity | sadmEntityName: sadmType | titlecase"
      (error)="onImageLoadingError()"
    >
    <p class="mt-2 leading-tight">
      {{ sadmEntity | sadmEntityName | titlecase }}
    </p>
    <p class="text-slate-600 text-sm mt-1">
      {{ sadmEntity | sadmEntityType: sadmType | firstLetterUppercase  }}
    </p>
    <p class="text-slate-400 text-sm" data-testid="date">
      {{ createdOn | date }}
    </p>
</div> 

<ng-template #noImageTemplate>
    <pxc-image-broken
      class="block h-48 w-full rounded-xl aspect-square overflow-hidden"
    ></pxc-image-broken> 
</ng-template>
