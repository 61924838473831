import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientListComponent } from './patient-list/patient-list.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PatientMergeComponent } from './patient-merge/patient-merge.component';
import { SharedModule } from '../shared/shared.module';
import { PatientDataComponent } from './patient-data/patient-data.component';
import { PatientEditComponent } from './patient-edit/patient-edit/patient-edit.component';
import { PatientEditFormComponent } from './patient-edit/patient-edit-form/patient-edit-form.component';
import { PatientLinkQuickActionComponent } from './patient-link-quick-action/patient-link-quick-action.component';
import { PatientEncounterPickerComponent } from './patient-encounter-picker/patient-encounter-picker.component';
import { PatientReportPreviewComponent } from './patient-report-preview/patient-report-preview.component';
import { PatientAvatarComponent } from './patient-avatar/patient-avatar.component';
import { PatientCardComponent } from './patient-card/patient-card.component';
import { TuiButtonModule, TuiHintModule, TuiNotificationModule, TuiScrollbarModule, TuiSvgModule, TuiTooltipModule }
  from '@taiga-ui/core';
import {
  TuiBadgeModule,
  TuiElasticContainerModule,
  TuiInputDateModule,
  TuiInputModule,
  TuiIslandModule,
  TuiRadioBlockModule,
} from '@taiga-ui/kit';
import { TuiLetModule } from '@taiga-ui/cdk';
import { PatientCreateComponent } from './patient-create/patient-create.component';
import { PatientSearchListComponent } from './patient-search-list/patient-search-list.component';
import { FilterBarComponent } from '../filters/filter-bar/filter-bar.component';
import { FilterTabsComponent } from '../filters/filter-tabs/filter-tabs.component';
import { PatientLinkDirective } from './patient-link.directive';
import { StatusComponent } from '../shared/status/status.component';
import { SpinnerComponent } from '../shared/spinner/spinner.component';
import { PatientNamePipe } from './patient-name.pipe';
import { ArchivedBadgeComponent } from '@modules/shared/archived-badge/archived-badge.component';

@NgModule({
  declarations: [
    PatientMergeComponent,
    PatientDataComponent,
    PatientListComponent,
    PatientEditComponent,
    PatientEditFormComponent,
    PatientLinkQuickActionComponent,
    PatientEncounterPickerComponent,
    PatientReportPreviewComponent,
    PatientAvatarComponent,
    PatientCardComponent,
    PatientCreateComponent,
    PatientSearchListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    TuiButtonModule,
    TuiRadioBlockModule,
    TuiButtonModule,
    TuiLetModule,
    TuiSvgModule,
    TuiBadgeModule,
    TuiIslandModule,
    TuiTooltipModule,
    TuiHintModule,
    TuiInputModule,
    TuiInputDateModule,
    TuiNotificationModule,
    TuiElasticContainerModule,
    FilterBarComponent,
    FilterTabsComponent,
    TuiScrollbarModule,
    PatientLinkDirective,
    StatusComponent,
    SpinnerComponent,
    PatientNamePipe,
    ArchivedBadgeComponent,
  ],
  exports: [
    PatientListComponent,
    PatientDataComponent,
    PatientEditComponent,
    PatientLinkQuickActionComponent,
    PatientEncounterPickerComponent,
    PatientCardComponent,
    PatientAvatarComponent,
    PatientCreateComponent,
  ],
  providers: [PatientNamePipe],
})
export class PatientModule {}
