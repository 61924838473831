import { Injectable } from '@angular/core';
import { DialogService } from 'src/app/services/dialog.service';
import { ActionsService } from 'src/app/shared/models/helpers/actions-service';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { MenuActionId } from 'src/app/shared/models/menu-actions/menu-action-id.enum';
import { SadmEntityMergeComponent } from './sadm-entity-merge/sadm-entity-merge.component';
import { Status } from 'src/app/shared/models/enums/status.enum';
import { Store } from '@ngrx/store';
import { sadmActions } from 'src/app/shared/store/sadm/sadm.actions';
import { Router } from '@angular/router';
import { Observable, first, of } from 'rxjs';
import { AuthorizationsService } from 'src/app/services/authorizations.service';
import { SadmEntity } from '@pixacare/pxc-ts-core';
import { Validators } from '@angular/forms';
import { DialogFormComponent } from 'src/app/shared/components/dialog-form/dialog-form.component';
import { isEverythingTrue, isEverythingTrue$ } from '@shared/utils/utils';

@Injectable({
  providedIn: 'root',
})
export class SadmActionsService extends ActionsService {

  actions: MenuAction<SadmEntity>[] = [
    {
      id: MenuActionId.SADM_ENTITY_EDIT,
      label: 'Modifier',
      icon: 'tuiIconEdit2',
      condition: (sadmEntity: SadmEntity): boolean => isEverythingTrue(
        this.isPatientActive(sadmEntity),
        this.isSadmActive(sadmEntity),
      ),
      execute: (sadmEntity: SadmEntity): void => {
        this.dialogService.openComponent<DialogFormComponent, SadmEntity>(DialogFormComponent, {
          label: 'Modifier la plaie',
          data: {
            entity: sadmEntity,
            inputs: [{
              label: 'Nom',
              property: 'name',
              validators: [Validators.required, Validators.maxLength(127), Validators.minLength(3)],
            }],
          },
        }).pipe(first()).subscribe((entity: SadmEntity) => {
          if (entity) {
            this.store.dispatch(sadmActions.updateSadmEntity({
              sadmEntity: {
                id: sadmEntity.id,
                ...entity,
              },
            }));
          }
        });
      },
    },
    {
      id: MenuActionId.SADM_MERGE,
      label: 'Fusionner',
      icon: 'tuiIconCopy',
      condition: (sadmEntity: SadmEntity): boolean => isEverythingTrue(
        this.isPatientActive(sadmEntity),
        this.isSadmActive(sadmEntity),
      ),
      execute: (sadmEntity: SadmEntity): void => {
        this.dialogService.openComponent(SadmEntityMergeComponent, {
          label: 'Fusionner deux lésions',
          data: {
            sadmEntity,
          },
        }).subscribe();
      },
    },
    {
      id: MenuActionId.SADM_ENTITY_ARCHIVE,
      label: 'Archiver',
      icon: 'tuiIconArchive',
      condition: (sadmEntity: SadmEntity): boolean => isEverythingTrue(
        this.isPatientActive(sadmEntity),
        this.isSadmActive(sadmEntity),
      ),
      execute: (sadmEntity: SadmEntity): void => {
        this.store.dispatch(
          sadmActions.archiveSadmEntity({
            sadmEntityId: sadmEntity.id,
          }),
        );
      },
    },
    {
      id: MenuActionId.SADM_ENTITY_UNARCHIVE,
      label: 'Désarchiver',
      icon: 'tuiIconArchive',
      condition: (sadmEntity: SadmEntity): boolean => isEverythingTrue(
        this.isPatientActive(sadmEntity),
        this.isSadmArchived(sadmEntity),
      ),
      execute: (sadmEntity: SadmEntity): void => {
        this.store.dispatch(
          sadmActions.unarchiveSadmEntity({
            sadmEntityId: sadmEntity.id,
          }),
        );
      },
    },
    {
      id: MenuActionId.SADM_ENTITY_DELETE,
      label: 'Supprimer',
      icon: 'tuiIconTrash',
      status: Status.ERROR,
      condition$: (sadmEntity: SadmEntity): Observable<boolean> => isEverythingTrue$(
        this.isPatientActive(sadmEntity),
        this.isSadmActive(sadmEntity),
        this.isUserAdminOrOwner$(sadmEntity),
      ),
      execute: (sadmEntity: SadmEntity): void => {
        this.dialogService.openConfirm('Êtes-vous certain de vouloir supprimer ce questionnaire ?', {
          content: 'Cette action est irréversible',
          yes: 'Supprimer',
        }).subscribe((result) => {
          if (result) {
            this.store.dispatch(
              sadmActions.deleteSadmEntity({
                sadmEntityId: sadmEntity.id,
              }),
            );
            this.router.navigate(['/dashboard/patients', sadmEntity.patient.id], { queryParamsHandling: 'merge' });
          }
        });
      },
    },
  ];

  constructor(
    private readonly dialogService: DialogService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly authorizations: AuthorizationsService,
  ) {
    super();
  }

  isUserOwner(sadmEntity: SadmEntity): boolean {
    return this.authorizations.getUserId() === sadmEntity.createdBy;
  }

  isUserAdminOrOwner$(sadmEntity: SadmEntity): Observable<boolean> {

    if (this.isUserOwner(sadmEntity)) {
      return of(true);
    }

    return this.authorizations.isClientOrDepartmentAdmin$();
  }

  isPatientActive(sadmEntity: SadmEntity): boolean {
    return sadmEntity.patient && !sadmEntity.patient.isArchived;
  }

  isSadmActive(sadmEntity: SadmEntity): boolean {
    return !sadmEntity.isArchived;
  }

  isSadmArchived(sadmEntity: SadmEntity): boolean {
    return sadmEntity.isArchived;
  }

}
