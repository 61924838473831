import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { PaginationQuery, Patient } from '@pixacare/pxc-ts-core';
import { distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ContextualComponentMode } from 'src/app/shared/models/enums/contextual-component-mode.enum';
import { selectIsPatientStateLoaded } from 'src/app/shared/store/patients/patients.selectors';
import { PagedCollection } from 'src/app/shared/models/pagination/paged-collection';
import { PatientStorePaginationService } from 'src/app/services/patient-store-pagination.service';
import { patientPaginationContext } from 'src/app/shared/models/pagination/pagination-context.config';
import { FILTER_TEMPLATES, FilterBarService } from '../../filters/filter-bar.service';
import { selectDepartmentName } from 'src/app/shared/store/departments/departments.selectors';
import { selectIsGamEnabled } from 'src/app/shared/store/clients/clients.selectors';
import { FilterType } from 'src/app/shared/models/filters/filter-type.enum';
import { patientsActions } from 'src/app/shared/store/patients/patients.actions';
import { selectHasLicenseWriteAccess } from 'src/app/shared/store/licenses/licenses.selectors';
import { PatientCreationService } from './patient-creation.service';
import { PatientOrderService } from './patient-order.service';
import { SortOrderStrategy } from 'src/app/shared/models/enums/sort-order-strategy.enum';
import { patientOrderings } from './patient-order.config';
import { selectClientCode, selectDepartmentId } from 'src/app/shared/store/router/router.selectors';

@UntilDestroy()
@Component({
  templateUrl: './patients.component.html',
  providers: [
    FilterBarService,
    {
      deps: [Store],
      provide: FILTER_TEMPLATES,
      useFactory: (store: Store) =>
        store.select(selectClientCode).pipe(
          switchMap((clientCode) => store.select(selectIsGamEnabled(clientCode))),
          map((isGamEnabled) => [
            {
              type: FilterType.BUTTONS,
              property: 'is_archived',
              name: 'Archivable',
              getValue: () => of([
                { value: '0', display: 'Actifs' },
                { value: '1', display: 'Archivés' },
                { value: undefined, display: 'Tous' },
              ]),
            },
            ...(isGamEnabled ? [
              {
                type: FilterType.TABS,
                property: 'is_gam_linked',
                name: 'GAM Status',
                getValue: () => of([
                  { value: null, display: 'Tous' },
                  { value: '0', display: 'Non rattachés à l\'établissement' },
                ]),
              },
            ] : []),
          ]),
        ),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientsComponent implements OnInit {

  isDataLoaded$: Observable<boolean> = null;
  collection$: Observable<PagedCollection<Patient[]>>;
  departmentName$: Observable<string>;
  order$: Observable<SortOrderStrategy>;
  ContextualComponentMode = ContextualComponentMode;
  mode: ContextualComponentMode;
  clientCode: string = null;
  departmentId: number = null;
  hasLicenseWriteAccess$: Observable<boolean>;

  clientCode$ = this.store.select(selectClientCode).pipe(distinctUntilChanged());
  departmentId$ = this.store.select(selectDepartmentId).pipe(distinctUntilChanged());

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly patientPaginationService: PatientStorePaginationService,
    private readonly filterBarService: FilterBarService,
    private readonly patientCreationService: PatientCreationService,
    private readonly patientOrderService: PatientOrderService,
  ) { }

  get orderService(): PatientOrderService {
    return this.patientOrderService;
  }

  ngOnInit(): void {

    this.store.dispatch(patientsActions.resetPatientsState());

    this.order$ = this.patientOrderService.getPatientOrder();

    const params$ = combineLatest({
      clientCode: this.clientCode$,
      departmentId: this.departmentId$,
    }).pipe(untilDestroyed(this));

    params$.subscribe(({ clientCode, departmentId }) => {
      this.clientCode = clientCode;
      this.departmentId = departmentId;

      if (this.clientCode) {
        this.mode = this.departmentId ? ContextualComponentMode.DEPARTMENT : ContextualComponentMode.USER;

        this.isDataLoaded$ = this.store.select(selectIsPatientStateLoaded(this.clientCode));

        this.collection$ = this.patientPaginationService.select({
          clientCode: this.clientCode,
        });

        this.departmentName$ = this.store.select(selectDepartmentName(this.clientCode, this.departmentId));

        this.hasLicenseWriteAccess$ = this.store.select(selectHasLicenseWriteAccess);
      } else {
        this.router.navigate(['/']);
      }
    });

    combineLatest([this.filterBarService.filteredSearch$, params$, this.order$])
      .pipe(untilDestroyed(this))
      .subscribe(([{ search, filters }, { clientCode, departmentId }, order]) => {
        this.patientPaginationService.load(clientCode, {
          query: new PaginationQuery({
            orderBy: patientOrderings[order],
            filter: [
              ...(departmentId ? [{ prop: 'department_id', op: 'eq', val: departmentId.toString() }] : []),
              ...filters,
            ],
            size: patientPaginationContext.countPerPage,
            search,
          }),
          reset: true,
        });
      });

  }

  loadNextPage(): void {
    this.patientPaginationService.loadNextPage(this.clientCode);
  }

  createPatient(): void {
    this.patientCreationService.createPatient(this.clientCode);
  }

}
