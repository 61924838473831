/* eslint-disable no-bitwise */
import { Injectable } from '@angular/core';
import { getGlobalColorHex } from '../shared/utils/tailwind-utils';
import { AvatarStyle } from '@pixacare/pxc-ts-core';

@Injectable({
  providedIn: 'root',
})
export class AvatarStyleService {

  getAvatarColors = (value: string): AvatarStyle => {
    if (value === '' || value === null || value === undefined) {
      return {
        backgroundColor: '#fff',
        borderColor: getGlobalColorHex('pixablue-1'),
        textColor: getGlobalColorHex('pixablue-1'),
      };
    }

    let hash = 0;
    for (let i = 0; i < value.length; i++) {
      hash = value.charCodeAt(i) + ((hash << 5) - hash);
      // equivalent to : hash = value.charCodeAt(i) + ((hash * Math.pow(2, 5)) - hash);

      hash &= hash;
      // equivalent to : hash = Math.abs(hash);
    }

    const hue = hash % 360;
    const saturation = 55 + (hash % 5);
    const lightness = 90 + (hash % 5);

    return {
      backgroundColor: `hsl(${hue},${saturation}%,${lightness}%)`,
      borderColor: `hsl(${hue},${saturation}%,${lightness + 5}%)`,
      textColor: `hsl(${hue},${saturation}%,${lightness - 60}%)`,
    };
  };

}
