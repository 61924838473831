<tui-root class="h-full">
    <router-outlet *ngIf="!isBrowserIE; else IEBrowser" data-testid="content"></router-outlet>

    <ng-template #IEBrowser>
        <div class="h-screen flex justify-center items-center">
            <div class="flex flex-col items-center">
                <img class="max-h-24" src="https://cdn.pixacare.com/logo/top-transparent.svg" />
                <h1>Navigateur Incompatible</h1>
                <p>
                    Vous utilisez Internet Explorer qui n'est pas compatible avec les standards de sécurité de Pixacare.
                </p>
                <p>
                    Merci de vous connecter avec un autre navigateur (Edge, Chrome, Firefox, Safari, Opéra).
                </p>
            </div>
        </div>
    </ng-template>
</tui-root>