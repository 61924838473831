<tui-island 
  *tuiLet="(mobileMode || (isMobile$ | async)) as isMobile"
  class="select-none !p-0 overflow-hidden"
  [ngClass]="{
    'before:!border-0 !shadow-none !bg-transparent ': compactMode
  }"
>
  <div 
    class="flex flex-col divide-y divide-slate-100"
    [ngClass]="{'px-3 py-1': !compactMode}"
  >

    <!-- Header -->
    <div class="flex justify-between flex-wrap gap-2 items-center py-1.5">
      <p class="text-sm text-slate-600 text-balance">
        <span class="font-medium">{{sequence.sequenceInstance.createdOn | date: 'mediumDate' | uppercase}}</span> à 
        {{sequence.sequenceInstance.createdOn | date: 'shortTime'}}
        <span class="mx-1 text-slate-300">•</span>
        <pxc-user-preview [user]="sequence.createdByInstance" [colored]="false"></pxc-user-preview>
      </p>
      <div class="flex flex-wrap gap-3 sm:justify-end items-center text-sm">
        <a
          *ngIf="sequence.sequenceInstance.description" 
          tuiLink
          (click)="openSequenceDescription(descriptionDialog)"
          data-testid="description"
          data-appearance="primary"
          class="flex items-center gap-1.5"
        >
          Description
        </a>
        <ng-content></ng-content>
        <ng-container *ngIf="actions">
          <pxc-action-buttons 
            *showIfUserAuthorizedForSequence="sequence.sequenceInstance" 
            [actions]="actions" 
            [entity]="sequence"
            optionButtonLabel="Options"
          ></pxc-action-buttons>
        </ng-container>
      </div>
    </div>

    <div 
      class="flex gap-2 py-2.5" 
      [ngClass]="{'flex-col': isMobile}"
    >

      <!-- Patient -->
      <div class="flex-1" *ngIf="!compactMode">
        <a 
          *ngIf="!!sequence.patientInstance && patientRedirect; else patientDisplay" 
          [patientLink]="sequence.patientInstance.id"
        >
          <ng-container [ngTemplateOutlet]="patientDisplay"></ng-container>
        </a> 
      </div>

      <!-- Labels -->
      <tui-scrollbar class="min-h-0 max-h-24 xl:max-h-36 flex-1">
        <div class="flex gap-1 flex-wrap">
          <pxc-label *ngFor="let label of sequence.labels" [label]="label"></pxc-label>
        </div> 
      </tui-scrollbar>

      <!-- Medias -->
      <div class="flex-1 max-w-md">
        <pxc-sequence-preview-media-carousel 
          [sequenceId]="sequence.sequenceInstance.id" 
          [medias]="sequence.medias" 
          [clientCode]="sequence.clientCode" 
          (mediaClicked)="mediaClicked.emit($event)"
        ></pxc-sequence-preview-media-carousel>
      </div>
    </div>

  </div> 

  <!-- Description dialog -->
  <ng-template #descriptionDialog let-observer>
    <div class="flex flex-col gap-4">
      <p class="whitespace-pre-line">
        {{sequence.sequenceInstance.description}}
      </p>
      <div class="flex gap-2 justify-end">
        <button 
          tuiButton 
          appearance="outline" 
          (click)="observer.complete()"
        >
          Fermer
        </button>
      </div>
    </div>

  </ng-template>
    
  <!-- Patient display -->
  <ng-template #patientDisplay>
    <div class="flex gap-2">
      <pxc-patient-avatar 
        [patient]="sequence.patientInstance"
        size="small"
      ></pxc-patient-avatar>
      <pxc-patient-data 
        data-testid="patient" 
        [patient]="sequence.patientInstance" 
        class="max-w-full"
        size="small"
      ></pxc-patient-data>
    </div>
  </ng-template>

</tui-island>