<div
    class="w-full aspect-square rounded-xl object-cover overflow-hidden !shadow relative group"
>
    <button
        *ngIf="showDelete"
        type="button"
        class="p-1 rounded-full bg-red-200/80 text-red-600 backdrop-blur !absolute top-1 group-hover:top-2 right-2 z-10 opacity-0 group-hover:opacity-90 transition-all hover:!opacity-100 hover:brightness-110"
        (click)="delete.emit()"
        data-testid="delete-button"
    >
        <tui-svg src="tuiIconClose"></tui-svg>
    </button>
    <div *ngIf="caption" class="absolute -bottom-1 opacity-0 w-full bg-gradient-to-t from-slate-900/80 via-slate-900/50 via-60% p-2 pt-8 group-hover:bottom-0 group-hover:opacity-100 transition-all">
        <p class="text-xs line-clamp-2 text-slate-100 break-all">{{ caption }}</p>
    </div>
    <ng-content></ng-content>
</div>