<pxc-tile class="px-3 py-2 border-b border-slate-200">
  <pxc-channel-avatar 
    leading
    *showIfDesktop="{
      elseTemplate: backButton
    }"
    [channelType]="channel?.type" 
    [channelUsers]="channel?.users"
    data-testid="channel-avatar"
  ></pxc-channel-avatar>

  <h6 title>{{channel | channelName}}</h6>

  <button 
    subtitle
    *ngIf="channel?.users"
    type="button" 
    class="text-slate-500 hover:text-slate-600 transition w-fit"
    (click)="openChannelInfo.emit()"
    data-testid="channel-participants"
  >
    {{ channel.users?.length | i18nPlural: participantsPluralMapping }}
    <tui-svg src="tuiIconChevronRight"></tui-svg>
  </button>
  
</pxc-tile>

<ng-template #backButton>
  <a 
    leading
    tuiIconButton 
    appearance="flat" 
    icon="tuiIconArrowLeft" 
    routerLink=".."
    queryParamsHandling="merge"
  ></a>
</ng-template>
