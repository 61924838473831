<tui-items-with-more [required]="displayedSequencePreviews"
  [class.justify-center]="centered"
>
  <ng-container *ngFor="let sequence of sequences">
    <div *tuiItem class="relative w-32 h-32 group" [attr.data-testid]="'sequence-'+sequence.sequenceInstance.id">
      <ng-container *ngFor="let media of sequence.medias; let idx = index">
        <pxc-sequence-media
          *ngIf="idx < 3"
          [src]="media.thumbnailUri"
          [clientCode]="media.clientCode"
          [sequenceId]="media.sequenceId"
          [mediaId]="media.id"
          class="
            absolute w-24 h-24 
            cursor-zoom-in transition-all ease-out duration-300
            hover:brightness-110
            shadow-sm hover:!shadow-xl rounded-xl
            border-t border-white/20
          "
          [ngClass]="{
            'brightness-100 top-0 left-0 z-30': idx === 0,
            'brightness-95 scale-95 top-3 left-3 z-20 group-hover:top-4 group-hover:left-4': idx === 1,
            'brightness-90 scale-90 top-6 left-6 z-10 group-hover:top-8 group-hover:left-8': idx === 2
          }"
          (click)="mediaClick(idx, sequence)"
          [attr.data-testid]="'sequence-media-'+sequence.sequenceInstance.id"
        ></pxc-sequence-media>
      </ng-container>
    </div>
  </ng-container>
  <ng-template tuiMore let-index>
    <p *tuiLet="getRemaining(index) as remaining" class="text-center">
      <span class="text-xl font-bold">+{{ remaining }}</span><br>
      {{ remaining | i18nPlural : sequencesPluralMapping }}
    </p>
  </ng-template>
</tui-items-with-more>