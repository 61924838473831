<ng-container [ngSwitch]="channelType">

    <ng-container 
        *ngSwitchCase="ChannelType.DEPARTMENT" 
        [ngTemplateOutlet]="iconLogo" 
        [ngTemplateOutletContext]="{iconName: 'tuiIconFolderLarge'}">
    </ng-container>

    <ng-container 
        *ngSwitchCase="ChannelType.TELEMONITORING" 
        [ngTemplateOutlet]="iconLogo" 
        [ngTemplateOutletContext]="{iconName: 'telemonitoring'}">
    </ng-container>

    <ng-container *ngSwitchDefault>
        <ng-container *tuiLet="channelUsers | chatHideMyself as users">

            <pxc-avatar
                *ngIf="users?.length === 1; else multipleUsers"
                [name]="users[0].firstName + ' ' + users[0].lastName"
                [size]="size"
                [attr.data-testid]="'avatar-' + users[0].id"
                [ngClass]="{
                    'w-12 h-12': size === Size.MEDIUM || size === Size.SMALL,
                    'w-20 h-20': size === Size.LARGE
                }"
                class="flex justify-center items-center"
            ></pxc-avatar>

            <ng-template #multipleUsers>
                <div class="relative" [ngClass]="{
                    'w-12 h-12': size === Size.MEDIUM || size === Size.SMALL,
                    'w-20 h-20': size === Size.LARGE
                }">
                    <ng-container *ngFor="let user of users; let idx = index">
                        <pxc-avatar
                            *ngIf="idx < 3"
                            [name]="user.firstName + ' ' + user.lastName"
                            [size]="size === Size.MEDIUM || size === Size.SMALL ? Size.TINY : Size.SMALL"
                            class="absolute border-2 border-slate-50 rounded-full"
                            [ngClass]="{
                                'top-0 left-0 z-10': idx === 0,
                                'top-1.5 left-1.5 z-20': idx === 1,
                                'top-3 left-3 z-30': idx === 2
                            }"
                            [attr.data-testid]="'avatar-' + user.id"
                        ></pxc-avatar>
                    </ng-container> 
                </div>
            </ng-template>

        </ng-container>

    </ng-container>

</ng-container>

<ng-template #iconLogo let-iconName="iconName">
    <div 
        class="rounded-full bg-white flex items-center justify-center" [ngClass]="{
            'w-12 h-12': size === Size.SMALL,
            'w-14 h-14': size === Size.MEDIUM,
            'w-20 h-20': size === Size.LARGE
        }"
    >
        <tui-svg [src]="iconName" data-testid="icon"></tui-svg>
    </div>
</ng-template>