import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { SortOrderStrategy } from 'src/app/shared/models/enums/sort-order-strategy.enum';
import { MenuAction } from 'src/app/shared/models/menu-actions/menu-action';
import { patientsActions } from 'src/app/shared/store/patients/patients.actions';
import { selectPatientOrder } from 'src/app/shared/store/patients/patients.selectors';

@Injectable({
  providedIn: 'root',
})
export class PatientOrderService {

  orderMenuActions: { [key: string]: MenuAction } = {
    [SortOrderStrategy.LAST_ACTIVITY]: {
      title: 'Tri par date',
      label: 'Par ordre de dernière mise à jour',
      icon: 'dateOrder',
      execute: (): void => this.setPatientOrder(SortOrderStrategy.LAST_ACTIVITY),
    },
    [SortOrderStrategy.ALPHABETICAL]: {
      title: 'Tri par nom',
      label: 'Par ordre alphabétique',
      icon: 'alphabeticalOrder',
      execute: (): void => this.setPatientOrder(SortOrderStrategy.ALPHABETICAL),
    },
  };

  DEFAULT_ORDER = SortOrderStrategy.LAST_ACTIVITY;
  PATIENT_ORDER_KEY = 'patient-order';


  constructor(
    private readonly store: Store,
    private readonly localStorageService: LocalStorageService,
  ) { }

  getPatientOrder(): Observable<SortOrderStrategy> {

    const localOrder = this.localStorageService.get(this.PATIENT_ORDER_KEY) as SortOrderStrategy;
    if (!localOrder || !Object.values(SortOrderStrategy).includes(localOrder)) {
      this.setPatientOrder(this.DEFAULT_ORDER);
    }

    this.store.dispatch(patientsActions.setOrder({ order: localOrder }));
    return this.store.select(selectPatientOrder);
  }

  setPatientOrder(order: SortOrderStrategy): void {
    this.store.dispatch(patientsActions.setOrder({ order }));
    this.localStorageService.set(this.PATIENT_ORDER_KEY, order);
  }

}
