import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, map, startWith, switchMap, takeWhile, timer } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TuiAlertService } from '@taiga-ui/core';
import { ValidationCodeActionType } from 'src/app/shared/models/enums/validation-code-action-type.enum';
import { ValidationCode } from 'src/app/shared/models/validation-code';
import { CodeInputComponent } from 'angular-code-input';
import { Size } from 'src/app/shared/models/enums/size.enum';
import { HttpCustomErrorCode } from '@pixacare/pxc-ts-core';

@Component({
  templateUrl: './validate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidateComponent implements OnInit {

  @ViewChild('codeInputComponent') codeInputComponent: CodeInputComponent;

  intentUrl: string;
  validationCode: ValidationCode;
  isCodeSent = false;
  title = '';

  size = Size;

  readonly resend$ = new Subject<void>();
  readonly countdown$ = this.resend$.pipe(
    startWith(0),
    switchMap(()=> this.countdownFrom(30)),
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly authenticationService: AuthenticationService,
    @Inject(TuiAlertService) private readonly alertService: TuiAlertService,
  ) { }

  ngOnInit(): void {

    if (history.state?.actionType === undefined) {
      this.router.navigate(['/']);
      return;
    }

    this.validationCode = {
      actionType: history.state?.actionType,
    };
    this.intentUrl = this.route.snapshot.queryParams.intentUrl || '/';
    this.sendCode();

    this.title = this.getTitle();
  }

  countdownFrom(start: number): Observable<number> {
    return timer(0, 1000).pipe(
      map((tick) => start - tick),
      takeWhile((n) => n > 0, true),
    );
  }

  getTitle(): string {
    switch (this.validationCode.actionType) {
      case ValidationCodeActionType.LOGIN:
        return 'Vérification de connexion';
      case ValidationCodeActionType.REGISTER:
        return 'Validation d\'inscription';
      default:
        return 'Validation';
    }
  }

  onOtpComplete(otp: string): void {
    this.authenticationService.validate({
      actionId: this.validationCode.actionId,
      actionType: this.validationCode.actionType,
      validationCode: otp,
    }).subscribe({
      next: () => {
        this.router.navigateByUrl(this.intentUrl);
      },
      error: (err: HttpErrorResponse) => {

        let title = 'Erreur serveur';
        let message = "Une erreur s'est produite, merci de réessayer plus tard.";

        this.codeInputComponent.reset();
        this.codeInputComponent.focusOnField(0);

        if (err.status === 400) {
          title = 'Code invalide';
          message = 'Le code ne correspond pas.';
        } else if (err.status === 403 && err.error.customErrorCode === HttpCustomErrorCode.ACCOUNT_DISABLED) {
          title = 'Compte bloqué';
          message = 'Votre compte a été bloqué, merci de contacter votre support.';
          this.authenticationService.logout();
          this.router.navigate(['/']);
        }

        this.alertService.open(message, {
          label: title,
          status: 'error',
        }).subscribe();
      },
    });
  }

  sendCode(): void {
    this.isCodeSent = true;
    this.authenticationService.sendValidationCode({ actionType: this.validationCode.actionType })
      .subscribe({
        next: (validationCode) => {
          this.alertService.open('Nous vous avons envoyé un code de validation sur votre adresse e-mail.', {
            label: 'Code envoyé !',
            status: 'success',
          }).subscribe();
          this.validationCode.actionId = validationCode.actionId;
        },
        error: (err: HttpErrorResponse) => {
          if (err.error.customErrorCode === HttpCustomErrorCode.ACCOUNT_INVALID) {
            this.validationCode.actionType = ValidationCodeActionType.REGISTER;
          }
          if (err.error.customErrorCode === HttpCustomErrorCode.ACCOUNT_ALREADY_REGISTERED) {
            this.validationCode.actionType = ValidationCodeActionType.LOGIN;
          }
        },
      });
    this.resend$.next();
  }

}
