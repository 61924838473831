<div class="flex items-center gap-3">
    <pxc-user-admin-state-badge
        *ngIf="userCountByAdminState[UserState.ENABLED] > 0"
        [state]="UserState.ENABLED"
        [count]="userCountByAdminState[UserState.ENABLED]"
    ></pxc-user-admin-state-badge>
    <pxc-user-admin-state-badge
        *ngIf="userCountByAdminState[UserState.DISABLED] > 0"
        [state]="UserState.DISABLED"
        [count]="userCountByAdminState[UserState.DISABLED]"
    ></pxc-user-admin-state-badge>
    <pxc-user-admin-state-badge
        *ngIf="userCountByAdminState[UserState.INVITED] > 0"
        [state]="UserState.INVITED"
        [count]="userCountByAdminState[UserState.INVITED]"
    ></pxc-user-admin-state-badge>
</div>