import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AvatarStyleService } from 'src/app/services/avatar-style.service';
import { AvatarStyle, Patient } from '@pixacare/pxc-ts-core';
import { Size } from 'src/app/shared/models/enums/size.enum';

@Component({
  selector: 'pxc-patient-avatar',
  templateUrl: './patient-avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientAvatarComponent implements OnInit {

  @Input() patient: Patient;
  @Input() size = Size.MEDIUM;

  Size = Size;

  avatarColors: AvatarStyle;

  constructor(
    private avatarStyleService: AvatarStyleService,
  ) { }

  ngOnInit(): void {
    const patientName = this.patient ? `${this.patient?.firstName}${this.patient?.lastName}` : '';
    this.avatarColors = this.avatarStyleService.getAvatarColors(patientName);
  }

}
