import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatGoDataUnit',
  standalone: true,
})
export class FormatGoDataUnitPipe implements PipeTransform {

  transform(value: number, args?: any): string {

    if (value < 1) {
      return value * 1000 + ' Mo';
    } else {
      return value + ' Go';
    }

  }

}
