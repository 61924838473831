import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TuiButtonModule, TuiLinkModule } from '@taiga-ui/core';
import { ChannelAvatarComponent } from '../channel-avatar/channel-avatar.component';
import { Channel } from '@pixacare/pxc-ts-core';
import { ChannelTypePipe } from '../pipes/channel-type.pipe';
import { ChatEntityLinkDirective } from '../chat-entity-link/chat-entity-link.directive';
import { ChannelNotificationsComponent } from './channel-notifications/channel-notifications.component';
import { ChannelParticipantsComponent } from '../channel-participants/channel-participants.component';
import { ChannelNamePipe } from '../pipes/channel-name.pipe';

@Component({
  selector: 'pxc-channel-settings',
  standalone: true,
  imports: [
    CommonModule,
    TuiLinkModule,
    TuiButtonModule,
    ChannelAvatarComponent,
    ChannelTypePipe,
    ChatEntityLinkDirective,
    ChannelParticipantsComponent,
    ChannelNamePipe,
    ChannelNotificationsComponent,
  ],
  templateUrl: './channel-settings.component.html',
  styles: [ '' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelSettingsComponent {

  @Input() channel: Channel;
  @Output() closeClick = new EventEmitter<void>();

}
