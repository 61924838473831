import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FlattenedTelemonitoring, PaginationQuery, PaginationDirection } from '@pixacare/pxc-ts-core';
import { BasePagination } from '@shared/base-pagination';
import { PagedCollection } from '@shared/models/pagination/paged-collection';
import { defaultPaginationContext } from '@shared/models/pagination/pagination-context.config';
import { telemonitoringsActions } from '@shared/store/telemonitorings/telemonitorings.actions';
import { selectTelemonitorings } from '@shared/store/telemonitorings/telemonitorings.selectors';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TelemonitoringStorePaginationService extends BasePagination<FlattenedTelemonitoring> {

  constructor(
    private readonly store: Store,
  ) {
    super();
  }

  load(clientCode: string, {
    query = new PaginationQuery({}),
    direction = PaginationDirection.NEXT,
    reset = false,
  } = {}): void {

    const clientCodeFilter = query.filter?.find((f) => f.prop === 'client_code')
    || (clientCode ? {
      prop: 'client_code',
      op: 'eq',
      val: clientCode,
    } : null);

    const finalQuery = new PaginationQuery({
      orderBy: ['last_activity|desc'],
      size: defaultPaginationContext.countPerPage,
      ...query,
      filter: [
        ...(query.filter || []),
        ...(clientCodeFilter ? [clientCodeFilter] : []),
      ],
    });

    if (direction === PaginationDirection.NEXT) {
      this.store.dispatch(telemonitoringsActions.getNextUserTelemonitorings({
        options: { query: finalQuery, reset },
      }));
    } else {
      this.store.dispatch(telemonitoringsActions.getPreviousUserTelemonitorings({
        options: { query: finalQuery, reset },
      }));
    }
  }

  select(isArchived = null): Observable<PagedCollection<FlattenedTelemonitoring[]>> {
    this.collection$ = this.store.select(selectTelemonitorings(isArchived));
    return this.collection$;
  }

}
