import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrawerComponent } from './drawer/drawer.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TuiButtonModule, TuiSvgModule, TuiTooltipModule } from '@taiga-ui/core';

@NgModule({
  declarations: [
    DrawerComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    SharedModule,
    TuiTooltipModule,
    TuiSvgModule,
    TuiButtonModule,
  ],
  exports: [DrawerComponent],
})
export class DrawerModule { }
