import { Observable, first } from 'rxjs';
import { PagedCollection } from './models/pagination/paged-collection';
import { PaginationDirection, PaginationQuery } from '@pixacare/pxc-ts-core';

export abstract class BasePagination<T> {

  collection$: Observable<PagedCollection<T[]>>;

  loadPreviousPage(clientCode: string): void {
    this.collection$
      .pipe(first())
      .subscribe((collection) => {
        if (collection.context.hasPreviousPage) {

          this.load(clientCode, {
            query: collection.navigation.previous[
              Math.max(collection.context.lastPage - collection.context.currentPages + 1, 0)
            ],
            direction: PaginationDirection.PREVIOUS,
          });
        }
      });
  }

  loadNextPage(clientCode: string): void {
    this.collection$
      .pipe(first())
      .subscribe((collection) => {
        if (collection.context.hasNextPage) {
          this.load(clientCode, {
            query: collection.navigation.next[collection.context.lastPage],
          });
        }
      });
  }

  abstract load(clientCode: string, options: {
    query?: PaginationQuery;
    direction?: PaginationDirection;
    reset?: boolean;
  }): void;

  abstract select(options: {
    userId?: number;
    clientCode?: string;
  }): Observable<PagedCollection<T[]>>;

}
