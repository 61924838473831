import { EncounterStatus } from '@pixacare/pxc-ts-core';
import { Status } from '../enums/status.enum';

export const encounterStatusConfig: { [encounter in EncounterStatus]: {
  status: Status;
  label: string;
} } = {
  [EncounterStatus.FINISHED]: {
    status: Status.WARNING,
    label: 'Terminé',
  },
  [EncounterStatus.IN_PROGRESS]: {
    status: Status.SUCCESS,
    label: 'En cours',
  },
  [EncounterStatus.PLANNED]: {
    status: Status.INFO,
    label: 'Planifié',
  },
};

export const unknownEncouterStatusConfig: {
  status: Status;
  label: string;
} = {
  status: Status.WARNING,
  label: 'Statut inconnu',
};
