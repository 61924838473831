<tui-elastic-container *tuiLet="areLabelsValid() as areLabelsValid">

  <pxc-paywall-alert>
  </pxc-paywall-alert>

  <div *ngIf="sequence$ | async as sequence" class="flex flex-col gap-6">

    <div class="grid lg:grid-cols-2 gap-6">
  
      <div class="flex flex-col gap-2">
        <pxc-title icon="tuiIconCalendarLarge" title="Date" titleSize="small"></pxc-title>
        <tui-input-date-time [(ngModel)]="updatedDate" data-testid="input-date" class="flex-1">
          Entrez une date et une heure
          <input
              tuiTextfield
              placeholder="18/04/2019 14:22"
          />
        </tui-input-date-time>
      </div>
  
      <div class="flex flex-col gap-2">
        <pxc-title icon="tuiIconTagLarge" title="Mots clés" titleSize="small"></pxc-title>
        <pxc-select-label class="select-label"
          [(selectedLabels)]="updatedLabels"
          data-testid="labels"
        ></pxc-select-label>
        <p *ngIf="!areLabelsValid" class="text-red-600">Vous devez entrer au moins un mot clé.</p>
      </div>
  
    </div>
  
    <div class="flex flex-col shrink-0 gap-2">
      <pxc-title icon="tuiIconCameraLarge" title="Photographies" titleSize="small"></pxc-title>

      <pxc-image-input
        [medias]="medias"
        (mediaDelete)="deleteMedia($event)"
        [showDelete]="(medias.length + newMedias.length) > 0"
        [(ngModel)]="newMedias"
        data-testid="medias"
        (mediaClickedIdx)="openGallery($event)"
        ngDefaultControl
      ></pxc-image-input>
      <p *ngIf="pictureCount === 0" class="text-red-600 font-medium">Veuillez ajouter au moins une photo.</p>
  
    </div>

    <div class="flex flex-col gap-2" *ngIf="!!basePatient && (displayAnalysis$ | async)">
      <pxc-title title="Questionnaire" titleSize="tiny" icon="heartfulForm"></pxc-title>
      
      <ng-container *ngIf="(isSadmFormLoading$ | async) === false; else analysisLoading">
        <pxc-sadm-form-input
          [analysisFormAnswers]="analysisFormAnswers$ | async"
          (analysisFormAnswersChange)="analysisFormAnswers$.next($event)"
          [protocolFormAnswers]="protocolFormAnswers$ | async"
          (protocolFormAnswersChange)="protocolFormAnswers$.next($event)"
          [(sadmEntity)]="sadmEntity"
          [(sadmEntityId)]="sadmEntityId"
          [clientCode]="clientCode"
          [patientId]="basePatient.id"
        ></pxc-sadm-form-input>

      </ng-container>
      <ng-template #analysisLoading>
        <div class="flex gap-2 items-center justify-center h-14">
          <pxc-spinner></pxc-spinner>
          <p class="text-slate-500">Chargement du questionnaire ...</p>
        </div>
      </ng-template>
    </div>
    
    <div class="flex flex-col gap-2">
      <pxc-title icon="tuiIconUserLarge" title="Patient" titleSize="small"></pxc-title>
      <pxc-patient-edit
        [patient]="updatedPatient"
        [clientCode]="clientCode"
        [displayActionButtons]="false"
        (patientChange)="patientChange($event)"
        (formValidityUpdate)="isPatientEditFormValid$.next($event)"
        (isCheckingFormValidityChange)="isPatientEditFormLoading$.next($event)"
        data-testid="patient-edit"
      ></pxc-patient-edit>
    </div>
    
    <div class="flex flex-col gap-2">
      <pxc-title icon="tuiIconFileTextLarge" title="Description" titleSize="small"></pxc-title>
      <tui-textarea
        [(ngModel)]="updatedDescription"
        [expandable]="true"
        maxlength="65535"
        data-testid="description"
      >
        Ajoutez une description
      </tui-textarea>
    </div>

    <div class="flex gap-2 items-center justify-between mt-2 flex-wrap">
      <button tuiButton type="button" appearance="outline" (click)="close()" class="max-md:w-full">Annuler</button>
      <button tuiButton type="button" 
        class="max-md:w-full max-md:order-first"
        (click)="save()" 
        [showLoader]="isPatientEditFormLoading$ | async"
        [disabled]="(isPatientEditFormValid$ | async) === false || (isPatientEditFormLoading$ | async) || !areLabelsValid || pictureCount === 0" 
        data-testid="confirm" 
      >
        Enregistrer
      </button>
  
    </div>
  
  </div>    

</tui-elastic-container>