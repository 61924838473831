<!-- Drawer Drop List -->
<ng-container *ngIf="(drawer$ | async) as drawer">
  <div class="droplist-ctrl-container hidden lg:block absolute overflow-hidden select-none"
    [ngClass]="[drawer.state,
    drawer.medias.length > 0 ? 'full' : 'empty']"
  >
    <div id="drawer-list"
      class="droplist-ctrl hidden lg:flex flex-col !pt-1 !px-3 !pb-0"
      [ngClass]="[drawer.state,
                  drawer.medias.length > 0 ? 'full' : 'empty']"
      [class.dragging]="drawer.isUserDragging"
      cdkDropList
      [cdkDropListData]="drawer.medias"
      [cdkDropListEnterPredicate]="alreadyPresentPredicate"
      (cdkDropListDropped)="drop($event)"
      (cdkDropListEntered)="isUserSorting = true"
      (cdkDropListExited)="isUserSorting = false"
      data-testid="droplist"
    >
    <!-- Image List -->
      <div class="thumbnail group relative my-1 mx-0 cursor-grab first-of-type:mt-0"
        *ngFor="let media of drawer.medias; let idx = index"
        cdkDrag
      >
        <tui-svg *ngIf="!drawer.isUserDragging"
          class="opacity-0 group-hover:opacity-100 absolute !h-6 !w-6 !top-1 !right-1 rounded-xl text-white bg-pixared-2 cursor-pointer p-px transition-opacity"
          src="tuiIconClose"
          (click)="removeThumbnail(idx)"
          [attr.data-testid]="'remove-media-'+idx"
        ></tui-svg>
        <img class="w-full rounded" [src]="media.thumbnailUri" alt="thumbnail">
        <div *cdkDragPlaceholder
          class="aspect-square w-full border-2 border-dashed border-slate-400 rounded-xl bg-slate-100"
        ></div>
      </div>
    </div>
  </div>
  

  <!-- Drawer  -->
  <div class="drawer-container hidden lg:flex shadow flex-col bg-white absolute rounded-xl items-center p-4"
    [ngClass]="drawer.state"
    [class.pb-2]="drawer.state !== DrawerState.TINY"
    [class.dragging]="drawer.isUserDragging"
  >
    <!-- Handle -->
    <pxc-handle
      class="drawer-handle absolute -translate-y-full z-[1001] shadow-md shadow-slate-300/20 rounded-full"
      [class.dragging]="!drawer.isUserDragging && [DrawerState.TINY, DrawerState.COMPACT].includes(drawer.state)"
      (handleClicked)="updateDrawerState()"
      data-testid="handle"
    ></pxc-handle>

    <!-- Informations -->
    <div class="flex flex-col items-center w-full flex-1 relative gap-2">
      <div class="flex gap-2 justify-center items-center">
        <p *ngIf="drawer.medias.length>0" class="bg-slate-300 py-px px-2 rounded-full" data-testid="media-count">
          {{drawer.medias.length > 99 ? '99+' : drawer.medias.length}}
        </p>
        <tui-tooltip
          class="z-[1001]"
          content="Le tiroir de comparaison vous permet de glisser vos photos puis de les comparer."
          direction="bottom"
          data-testid="tooltip-icon"
        ></tui-tooltip>
      </div>
      <div class="flex flex-col justify-center items-center text-center w-full gap-2 empty-drawer-arrow" data-testid="expanded-title"
        *ngIf="!isUserSorting && ( (drawer.state === DrawerState.OPEN || drawer.isUserDragging) && drawer.medias.length <= 0)"
      >
      <tui-svg src="tuiIconCornerRightDownLarge"></tui-svg>
        <p class="text-sm">Glisser ici pour comparer.</p>
      </div>
      <p *ngIf="drawer.state === DrawerState.TINY && !drawer.isUserDragging" 
        class="text-xs text-center text-slate-500" data-testid="tiny-title"
      >
        Comparez vos photos
      </p>
    </div>

    <!-- Bottom Buttons -->
    <div class="flex-col w-full items-center mt-1" *ngIf="drawer.medias.length > 0">
      <button
        class="w-full mb-1 self-end"
        tuiButton
        type="button"
        icon="tuiIconLayers"
        size="s"
        (click)="loadImageInGallery()"
        data-testid="compare-button"
      >
        <span class="hidden 2xl:block">Comparer</span>
      </button>
      <button
        class="w-full mb-1 self-end"
        tuiButton
        type="button"
        icon="tuiIconTrash2"
        appearance="secondary-destructive"
        size="s"
        (click)="clearItems()"
        data-testid="clear-button"
      >
        <span class="hidden 2xl:block">Vider</span>
      </button>
    </div>
  </div>
</ng-container>