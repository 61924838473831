<form class="flex flex-col gap-4" (submit)="confirm()" [formGroup]="formGroup">

  <ng-container *ngIf="(encounters$ | async) as encounters; else loading">
    
    <p *ngIf="encounters.length > 0">
      Sélectionnez le séjour dans lequel vous souhaitez exporter le rapport du patient.
    </p>
    
    <div class="flex flex-col gap-2 overflow-y-auto min-h-[300px] max-h-[50vh]"
      scroll-tracker 
      [threshold]="{bottom:100}" 
      (bottomThreshold)="loadNextPage()"
      data-testid="scroll-viewport"
    >

      <tui-radio-block
        *ngFor="let encounter of encounters" 
        contentAlign="left"
        formControlName="encounterId"
        [item]="encounter.id"
        [attr.data-testid]="encounter.id"
      >

        <div class="flex items-center gap-2" *tuiLet="(encounterStatusConfig[encounter.status] ?? unknownEncouterStatusConfig) as config">

          <ng-container *ngIf="encounter.ufCode !== null || encounter.identifier !== null; then tagsTemplate; else dateTemplate"></ng-container>

          <ng-template #tagsTemplate>
            <div class="flex flex-col items-start my-2 gap-1 flex-1">
              <div class="flex gap-2">
                <ng-container *ngIf="encounter.ufCode !== null">
                  <p class="bg-slate-200 rounded p-1" data-testid="encounter-uf-code">
                    UF : <strong>{{encounter.ufCode}}</strong>
                  </p>
                </ng-container>
                <ng-container *ngIf="encounter.identifier !== null">
                  <p class="bg-slate-200 rounded p-1" data-testid="encounter-ehr-identifier">
                    ID : <strong>{{encounter.identifier}}</strong>
                  </p>
                </ng-container>
              </div>
              <ng-container [ngTemplateOutlet]="dateTemplate"></ng-container>
            </div>
          </ng-template>

          <ng-template #dateTemplate>
            <p [ngSwitch]="encounter.status" class="flex-1" data-testid="encounter-date">
              <ng-container *ngSwitchCase="EncounterStatus.PLANNED">
                Séjour à partir du <strong>{{encounter.fromDate | date}}</strong>
              </ng-container>
              <ng-container *ngSwitchCase="EncounterStatus.IN_PROGRESS">
                Séjour depuis le <strong>{{encounter.fromDate | date}}</strong>
              </ng-container>
              <ng-container *ngSwitchCase="EncounterStatus.FINISHED">
                Séjour du <strong>{{encounter.fromDate | date}}</strong>
                au <strong>{{encounter.toDate | date}}</strong>
              </ng-container>
            </p>
          </ng-template>
        
          <tui-badge
            [status]="config.status"
            data-testid="encounter-state"
            [value]="config.label"
          ></tui-badge>
        </div>
        
      </tui-radio-block>

      <pxc-status [state]="Status.BASIC"
        *ngIf="encounters.length === 0"
        class="my-auto"
        icon="tuiIconSearchLarge"
        title="Aucun séjour"
        description="Ce patient n'a aucun séjour enregistré dans son DPI."
        data-testid="no-encounter-message"
      ></pxc-status>

    </div>

  </ng-container>
  
  <div class="flex justify-between gap-2">
    <button tuiButton type="button" appearance="outline" (click)="close()" data-testid="cancel">Annuler</button>
    <button tuiButton
      type="submit"
      [disabled]="!formGroup.valid"
      data-testid="confirm"
    >
      Confirmer
    </button>
  </div>

</form>

<ng-template #loading>
  <pxc-loader class="my-2" data-testid="loading">
    Chargement des séjours du patient ...
  </pxc-loader>
</ng-template>