import { RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterState } from './router.state';

export const ROUTER_FEATURE_KEY = 'Router';

const selectRouterState = createFeatureSelector<RouterReducerState<RouterState>>(ROUTER_FEATURE_KEY);

const getRouteParams = (key: string) =>
  createSelector(selectRouterState, ({ state }) => +(state?.params?.[key]) || null);

export const selectClientCode = createSelector(selectRouterState, ({ state }) => state.queryParams?.cc?.toString());

export const selectDepartmentId = getRouteParams('departmentId');

export const selectPatientId = getRouteParams('patientId');

export const selectTelemonitoringId =  getRouteParams('tmId');

export const selectQueryParams = createSelector(selectRouterState, ({ state }) => state.queryParams);
