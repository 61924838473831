<div class="flex gap-2 items-start" [ngClass]="{
  'ml-auto': !isIncoming,
  'mt-2': showHeader
}">

  <pxc-avatar 
    *ngIf="isIncoming" 
    [name]="message.senderName" 
    [ngClass]="{
      'opacity-0 h-0': !showHeader
    }"
  ></pxc-avatar>

  <div
    class="flex flex-col cursor-pointer"
    [ngClass]="{'items-start': isIncoming,'items-end': !isIncoming}"
    (click)="messageClicked.emit()"
  >
    <div class="w-fit rounded-b-xl !shadow p-3 flex flex-col gap-1" [ngClass]="{
      'bg-white rounded-tr-xl': isIncoming,
      'bg-blue-200 rounded-tl-xl': !isIncoming 
    }">
  
      <ng-container *ngIf="showHeader">
        <div 
          *ngIf="isIncoming; else messageDate;" 
          class="flex gap-3 justify-between"
        >
          <p class="text-sm line-clamp-1 font-medium" [ngStyle]="{
            'color': avatarColors.textColor
          }">
            {{message.senderName}}  
          </p>
          <ng-container *ngTemplateOutlet="messageDate"></ng-container>
        </div>
      </ng-container>
  
      <pxc-message-special
        *ngIf="message.special?.shares"
        [special]="message.special"
        [userId]="authenticationService.currentUser.id"
      ></pxc-message-special>
      <p class="max-w-prose break-words">{{message.text}}</p>
    </div>
    
    <tui-elastic-container>
      <p class="mt-1 text-slate-600" *ngIf="shouldShowReaders">
        {{readers | listMessageReaders: channelUsersCount:authenticationService.currentUser.id}}
      </p>
    </tui-elastic-container>
  </div>

  <ng-template #messageDate>
    <p class="text-xs text-slate-400 text-right">
      {{date | date:'shortTime'}}
    </p>
  </ng-template>

</div>
