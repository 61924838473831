<button 
  tuiButton
  type="button"
  [appearance]="appearance"
  data-testid="button"
  [icon]="icon"
  class="w-full"
  [disabled]="disabled"
>
  {{label}}
</button>