<pxc-tile
  [vertical]="vertical"
>

  <pxc-channel-avatar 
    leading
    [channelType]="channel?.type" 
    [channelUsers]="channel?.users"
    [size]="size"
    data-testid="channel-avatar"
  ></pxc-channel-avatar>

  <tui-line-clamp title
    [content]="channelName"
    [linesLimit]="1"
    [lineHeight]="20"
    [ngClass]="{'max-w-24': vertical}"
  ></tui-line-clamp>

  <ng-template #channelName>
    <span class="font-medium">
      {{channel | channelName}}
    </span>
  </ng-template>

  <span *ngIf="showDetails" date data-testid="date" [ngClass]="{
    'font-bold': unreadsCount,
    'text-slate-400': !unreadsCount
  }">{{date | date:'shortDate'}}</span>

  <div *ngIf="showDetails" subtitle class="flex">                                                                                                                                                                                                                                              
    <span 
      data-testid="subtitle"
      class="w-full line-clamp-1 mt-1" 
      [ngClass]="{
        'font-bold': unreadsCount,
        'text-slate-500': !unreadsCount
      }"
    >{{channel | channelSubtitle}}</span>
    <tui-badge *ngIf="unreadsCount" class="shrink-0 bg-red-400" [value]="unreadsCount" status="custom" data-testid="counter" size="s"></tui-badge>
  </div>

</pxc-tile>