export const sequencesCountPluralMapping: { [amount: string]: string } = {
  '=1': '# séquence',
  other: '# séquences',
};

export const sequencesPluralMapping: { [amount: string]: string } = {
  '=1': 'séquence',
  other: 'séquences',
};

export const personCountPluralMapping: { [k: string]: string } = {
  '=1': '# utilisateur',
  other: '# utilisateurs',
};

export const collaboratorsPluralMapping: { [amount: string]: string } = {
  '=1': 'collaborateur',
  other: 'collaborateurs',
};

export const departmentsPluralMapping: { [amount: string]: string } = {
  '=0': 'Aucun groupe sélectionné',
  '=1': '# groupe sélectionné',
  other: '# groupes sélectionnés',
};

export const sequenceCountPluralMapping: { [k: string]: string } = {
  '=0': 'Aucune séquence sélectionnée',
  '=1': '# séquence sélectionnée',
  other: '# séquences sélectionnées',
};

export const participantsPluralMapping: { [amount: string]: string } = {
  '=1': '# participant',
  other: '# participants',
};

export const usagePluralMapping: { [k: string]: string } = {
  '=0': 'Non utilisé',
  '=1': '# utilisation',
  other: '# utilisations',
};
