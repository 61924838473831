<tui-hosted-dropdown #dropdown [tuiDropdownMaxHeight]="500" [content]="content" tuiDropdownAlign="right">

  <button 
    tuiButton 
    [appearance]="isActive ? 'primary' : 'flat'" 
    class="tui-group__inherit-item"
    [icon]="isActive ? icon : ''"
    [iconRight]="'tuiIconChevronDown'" 
    [pseudoHover]="dropdown.open || null"
    size="s"
    data-testid="dropdown-button"
  >
    {{buttonText}}
  </button>

</tui-hosted-dropdown>

<ng-template #content>
  <div class="max-w-md w-full h-full overflow-hidden">
    <ng-content></ng-content>
  </div>
</ng-template>