<div class="flex flex-col gap-3">

  <div class="flex md:items-center gap-5 my-2 max-md:flex-col">
    <p class="truncate max-w-[12rem]">{{ fromClientName$ | async }}</p>

    <tui-svg src="tuiIconChevronsRightLarge"></tui-svg>

    <pxc-client-picker
      [(selectedClient)]="toClientCode"
      [clients]="movableClients$ | async"
      data-testid="client-picker"
      class="flex-1 bg-white"
    ></pxc-client-picker>
  </div>

  <div class="flex gap-2 justify-between max-md:flex-col-reverse">
    <button tuiButton appearance="outline" (click)="cancel()" data-testid="cancel">
      Annuler
    </button>
    <button tuiButton (click)="move()" data-testid="confirm" [disabled]="!toClientCode">
      Déplacer
    </button>
  </div>

</div>
