import {
  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpServerUnavailableInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      // tap(null, (error: HttpErrorResponse) => {
      //   if (error.status === 0) {
      //     // server-side error
      //     this.router.navigateByUrl('/server-unavailable');
      //     return;
      //   }
      //   return throwError(error);
      // }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          // server-side error
          this.router.navigateByUrl('/server-unavailable');
        }
        return throwError(error);
      }),
    );
  }

}
