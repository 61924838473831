<pxc-image-overlay [caption]="file?.name" [showDelete]="showDelete" (delete)="delete.emit()">
    <ng-container
        *ngIf="(src$ | async) as src; else loadingBlock"
    >
        <img
            *ngIf="(error$ | async) === null; else errorBlock"
            class="w-full h-full object-cover"
            [src]="src"
            (error)="error$.next(true)"
            data-testid="image"
        />
    </ng-container>

    <ng-template #loadingBlock>
        <pxc-image-loading data-testid="loading"></pxc-image-loading>
    </ng-template>
</pxc-image-overlay>

<ng-template #errorBlock>
    <pxc-image-broken data-testid="error"></pxc-image-broken>
</ng-template>