<div class="flex items-center gap-3">
    <pxc-filter-bar 
        searchPlaceholder="Rechercher un groupe par nom, nombre de participants ..."
        data-testid="filter-bar"
    ></pxc-filter-bar>
    <button
        slot="actions"
        tuiButton
        icon="tuiIconUsers"
        (click)="createGroup()"
    >
        Ajouter
    </button>
</div>

<table
    *ngIf="(clientDepartments$ | async) as clientDepartments; else loading"
    class="w-full mt-3 rounded-xl overflow-hidde bg-white !shadow"
    tuiTable
    [columns]="columns"
>
    <ng-container *ngIf="clientDepartments.length > 0; else emptyState">
        <thead>
            <tr tuiThGroup>
                <th
                    *tuiHead="'name'"
                    tuiTh
                >
                    Nom du groupe
                </th>
                <th
                    *tuiHead="'membersCount'"
                    tuiTh
                    [sorter]="clientDepartmentsAdminService.sortByMembersCount"
                >
                    Membres
                </th>
                <th
                    *tuiHead="'actions'"
                    tuiTh
                    [sorter]="null"
                >
                    Actions
                </th>
            </tr>
        </thead>
        <tbody tuiTbody>
            <tr
                *ngFor="let department of (clientDepartments | tuiTableSort)"
                tuiTr
            >
                <td class="min-w-72 max-w-72" tuiTd *tuiCell="'name'">
                    <p>{{department.name}}</p>
                </td>
                <td class="min-w-48 max-w-48" tuiTd *tuiCell="'membersCount'">
                    <p>{{department.membersCount}}</p>
                </td>
                <td class="min-w-48 max-w-48" tuiTd *tuiCell="'actions'">
                    <button
                        tuiIconButton
                        icon="tuiIconFolderMinus"
                        appearance="flat-destructive"
                        [disabled]="true"
                        tuiHint="La suppression de groupe est désactivée. Veuillez contacter le support."
                        tuiHintAppearance="error"
                    ></button>
                </td>
            </tr>
        </tbody>
    </ng-container>
</table>

<ng-template #loading>
    <pxc-loader data-testid="loading" class="my-5">
        Chargement des groupes ...
    </pxc-loader>
</ng-template>

<ng-template #emptyState>
    <p class="text-slate-500 text-center my-16 w-full">Aucun groupe trouvé.</p>
</ng-template>