import { statisticsActions } from './statistics.actions';
import { lifeCycleActions } from '../life-cycle/life-cycle.actions';
import { createReducer, on } from '@ngrx/store';
import { initialStatisticsState } from './statistics.state';

export const statisticsReducer = createReducer(
  initialStatisticsState,

  on(lifeCycleActions.resetState, () => initialStatisticsState),

  on(statisticsActions.loadSuccess,
    (state, { userStatistics }) => ({
      ...state,
      statistics: userStatistics,
    }),
  ),
);
