<div
  class="handle content-[''] bg-pixablue-3 text-pixablue-1 
  w-8 h-8 cursor-pointer 
  flex justify-center items-center rounded-full 
  transition-transform"
  [class.rotate-0]="handleState"
  [class.rotate-180]="!handleState"
  (click)="toggleHandleState()"
  data-testid="handle"
  >
  <tui-svg
    class="icon"
    [src]="direction === HandleDirection.VERTICAL 
      ? 'tuiIconChevronUp'
      : 'tuiIconChevronRight'"
  ></tui-svg>
</div>