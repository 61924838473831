<div #zoomContainer
  class="h-full relative group"
  (wheel)="wheelEvent($event)"
  [ngClass]="{
    'overflow-hidden': !enableOverflow, 
    'cursor-grabbing': isUserMoving(), 
    'cursor-grab': isMovingAllowed()
  }"
>
  <div #slider class="h-full origin-top-left"
    [style.transform]="getTransformStyle()"
    [style.transition]="!this.isUserClicking ? 'transform .3s ease-out' : ''"
    (mousedown)="enableMoving()"
    (mousemove)="mouseMove($event)"
    (mouseup)="disableMoving()"
    (mouseout)="disableMoving()"
  >
    <ng-content></ng-content>
  </div>
  
  <div *ngIf="zoomButtons" class="
    hidden rounded-xl group-hover:flex
    bg-action-dark
    absolute bottom-4 left-1/2 -translate-x-1/2
    transition-all duration-300
    gap-2 shadow-2xl px-2
  " tuiMode="onDark">
    <button tuiIconButton
      icon="tuiIconZoomInLarge"
      (click)="zoomIn()"
      appearance="flat"
      [disabled]="isContainerZoomMax()"
    ></button>
    <button tuiIconButton 
      icon="tuiIconZoomOutLarge"
      (click)="zoomOut()"
      appearance="flat"
      [disabled]="isContainerZoomMin()"
    ></button>
  </div>

</div>
