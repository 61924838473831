import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { TuiTableModule } from '@taiga-ui/addon-table';
import { Observable, combineLatest, map, of } from 'rxjs';
import { Department } from 'src/app/shared/models/base/department';
import { ClientDepartmentsAdminService } from './client-departments-admin.service';
import { TuiButtonModule, TuiHintModule } from '@taiga-ui/core';
import { FILTER_TEMPLATES, FilterBarService } from '../../filters/filter-bar.service';
import { match } from 'src/app/shared/utils/utils';
import { SharedModule } from '../../shared/shared.module';
import { FilterBarComponent } from '../../filters/filter-bar/filter-bar.component';
import { getDepartmentMembershipsCount } from 'src/app/shared/utils/department-utils';

@Component({
  selector: 'pxc-client-departments-admin',
  standalone: true,
  imports: [
    CommonModule,
    FilterBarComponent,
    TuiTableModule,
    TuiButtonModule,
    TuiHintModule,
    SharedModule,
  ],
  providers: [
    FilterBarService,
    {
      provide: FILTER_TEMPLATES,
      useValue: of([]),
    },
    ClientDepartmentsAdminService,
  ],
  templateUrl: './client-departments-admin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientDepartmentsAdminComponent implements OnInit {

  clientDepartments$: Observable<Department[]>;
  columns = ['name', 'membersCount', 'actions'];

  readonly clientDepartmentsAdminService = inject(ClientDepartmentsAdminService);
  private readonly filterBarService = inject(FilterBarService);

  ngOnInit(): void {
    this.clientDepartments$ = combineLatest([
      this.filterBarService.filteredSearch$,
      this.clientDepartmentsAdminService.loadClientDepartments$(),
    ]).pipe(
      map(([{ search }, departments]) =>
        departments
          .filter((department: Department) =>
            match(search, [department.name, getDepartmentMembershipsCount(department).toString()]),
          ).sort(this.clientDepartmentsAdminService.sortByMembersCount),
      ),
    );
  }

  createGroup(): void {
    this.clientDepartmentsAdminService.openCreateDepartment();
  }

}
