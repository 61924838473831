import { SortOrderStrategy } from 'src/app/shared/models/enums/sort-order-strategy.enum';

const SORT_DIVIDER = '|';

enum SortOrderPatientProperty {
  LAST_NAME = 'last_name',
  FIRST_NAME = 'first_name',
  LAST_ACTIVITY = 'last_activity',
}

enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export const patientOrderings: { [key: string]: string[] } = {
  [SortOrderStrategy.ALPHABETICAL]: [
    `${SortOrderPatientProperty.LAST_NAME}${SORT_DIVIDER}${SortOrder.ASC}`,
    `${SortOrderPatientProperty.FIRST_NAME}${SORT_DIVIDER}${SortOrder.ASC}`,
  ],
  [SortOrderStrategy.LAST_ACTIVITY]: [
    `${SortOrderPatientProperty.LAST_ACTIVITY}${SORT_DIVIDER}${SortOrder.DESC}`,
  ],
};
