import {
  Component, OnInit, ChangeDetectionStrategy, Inject, Input, Optional,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { selectClient, selectClients } from 'src/app/shared/store/clients/clients.selectors';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { ClientData } from 'src/app/shared/models/base/client-data';
import { FlattenedSequence } from '@pixacare/pxc-ts-core';

@Component({
  templateUrl: './sequence-move.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SequenceMoveComponent implements OnInit {

  @Input() flattenedSequence: FlattenedSequence = this.context.data.flattenedSequence;

  fromClientName$: Observable<string>;
  movableClients$: Observable<{ [clientCode: string]: ClientData }>;
  toClientCode: string;

  constructor(
    protected store: Store,
    @Optional() @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<string, { flattenedSequence: FlattenedSequence }>,
  ) {}

  ngOnInit(): void {
    this.fromClientName$ = this.store
      .select(selectClient(this.flattenedSequence.clientCode))
      .pipe(
        map((client) => client.fullName),
      );

    this.movableClients$ = this.store.select(selectClients).pipe(
      map((clients) => {
        const movableClients = { ...clients };
        delete movableClients[this.flattenedSequence.clientCode];
        return movableClients;
      }),
    );

    this.movableClients$.pipe(first()).subscribe((clients) => {
      this.toClientCode = Object.keys(clients)[0];
    });

  }

  cancel(): void {
    this.context.completeWith(null);
  }

  move(): void {
    if (!this.toClientCode) {
      return;
    }

    this.context.completeWith(this.toClientCode);
  }

}
