import { getGlobalColorHex } from 'src/app/shared/utils/tailwind-utils';

export enum SectionActivity {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum SectionValidity {
  VALID = 'valid',
  INVALID = 'invalid',
}

type SectionColors = { [key in SectionValidity]: { [validity in SectionActivity]: string } };

export const sectionColors: SectionColors = {
  [SectionValidity.VALID]: {
    [SectionActivity.ACTIVE]: getGlobalColorHex('pixablue-1'),
    [SectionActivity.INACTIVE]: getGlobalColorHex('pixablue-3'),
  },
  [SectionValidity.INVALID]: {
    [SectionActivity.ACTIVE]: getGlobalColorHex('pixared-1'),
    [SectionActivity.INACTIVE]: getGlobalColorHex('pixared-2'),
  },
};
