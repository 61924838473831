<div class="flex flex-col gap-2">
  <div 
    class="mt-2 flex gap-2" 
    *ngIf="showFavoriteLabels && (favoriteLabels$ | async) as favoriteLabels" data-testid="favorite-labels">
    <pxc-label class="label" *ngFor="let favoriteLabel of favoriteLabels"
      [label]="favoriteLabel.value" 
      [clickable]="true"
      (click)="addLabel(favoriteLabel.display)"
      [attr.data-testid]="'label-'+favoriteLabel.display"
    >
    </pxc-label>
  </div>
  
  <tui-input-tag
    [tuiTextfieldLabelOutside]="true"
    [ngModel]="value"
    (ngModelChange)="onLabelChange($event)"
    (searchChange)="onSearchChange($event)"
    [maxLength]="255"
    uniqueTags="true"
    data-testid="label-input"
    ngDefaultControl
  >
    Ajoutez des mots clés
    <ng-container *ngIf="autocomplete">
      <tui-data-list-wrapper
        *tuiDataList
        [items]="autoCompleteLabels$ | async"
        [emptyContent]="emptyState"
      ></tui-data-list-wrapper>
    </ng-container>
  </tui-input-tag>

  <ng-template #emptyState>
    <p class="font-bold text-slate-600">
      Aucun résultat. <span class="text-slate-400 font-normal">Appuyez sur entrée pour ajouter le mot clé.</span>
    </p>      
  </ng-template>
</div>

