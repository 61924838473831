<div class="flex justify-start align-center">
  <ng-container *ngFor="let button of buttons$ | async; let index = index">
    <button 
      tuiButton 
      appearance="custom" 
      size="s" 
      class="!min-w-20 tui-space_right-2"
      [ngClass]="index === selectedIndex ? '!bg-blue-100' : '!bg-black/[.04]'"
      (click)="onButtonClick(button, index)"
    >
      {{ button.display }}
    </button>
  </ng-container>
</div>
