import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable, switchMap } from 'rxjs';
import { StorageSpace } from '../shared/models/paywall/storage-space.enum';
import { selectClientDataConsumptionInGb } from '../shared/store/clients/clients.selectors';
import { AppConfigHttpService } from './http/app-config.http.service';
import { selectClientCode } from '../shared/store/router/router.selectors';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  MAX_THRESHOLD = 100;

  constructor(
    private readonly store: Store,
    private readonly configurationService: AppConfigHttpService,
  ) { }

  getActiveClientStorageSpace(): Observable<StorageSpace> {
    return this.getClientStorageSpace(this.MAX_THRESHOLD);
  }

  private getClientStorageSpace(threshold: number): Observable<StorageSpace> {

    return this.store.select(selectClientCode).pipe(
      switchMap((clientCode) => this.store.select(selectClientDataConsumptionInGb(clientCode))),
      map(({ total, used }) => {

        const quotient = (used * 100) / total;

        if (quotient >= this.configurationService.configuration.paywall.warningQuotient) {
          if (quotient >= threshold) {
            return StorageSpace.FULL;
          } else {
            return StorageSpace.LOW;
          }
        } else {
          return StorageSpace.AVAILABLE;
        }
      }),
    );

  }

}
