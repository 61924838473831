import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Filter } from '@pixacare/pxc-ts-core';
import { Observable, BehaviorSubject, map, combineLatest, distinctUntilChanged, debounceTime, first } from 'rxjs';
import { FilterTemplate } from 'src/app/shared/models/filters/filter-template';
import { FilteredSearch } from 'src/app/shared/models/filters/filtered-search';
import { FlattenedFilterTemplate } from 'src/app/shared/models/filters/flattened-filter-template';

export const FILTER_TEMPLATES = new InjectionToken<Observable<FilterTemplate[]>>('filterTemplates');

@Injectable()
export class FilterBarService {

  filteredSearch$: Observable<FilteredSearch>;
  templates$: Observable<FlattenedFilterTemplate[]>;
  search$: Observable<string>;

  private persistentFilters$: Observable<Filter[]>;
  private persistentFilters: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
  private templates: BehaviorSubject<FlattenedFilterTemplate[]> = new BehaviorSubject<FlattenedFilterTemplate[]>(null);
  private search: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    @Inject(FILTER_TEMPLATES) private readonly filterTemplates$: Observable<FilterTemplate[]>,
  ) {

    this.resetFilters();

    this.templates$ = this.templates.asObservable();
    this.search$ = this.search.asObservable().pipe(
      distinctUntilChanged(),
    );
    this.persistentFilters$ = this.persistentFilters.asObservable();

    this.filteredSearch$ = combineLatest([
      this.templates$,
      this.search$.pipe(debounceTime(300)),
      this.persistentFilters$,
    ]).pipe(
      map(([templates, search, persistentFilters]) => ({
        filters: [
          ...templates.map((template) => template.filters).flat(1),
          ...persistentFilters,
        ],
        search,
      })),
    );
  }

  setPersistentFilters(filters: Filter[]): void {
    this.persistentFilters.next(filters);
  }

  updateTemplate(targetTemplate: FlattenedFilterTemplate): void {
    this.templates.next([
      ...this.templates.getValue()
        .filter((template) => template.type !== targetTemplate.type || template.property !== targetTemplate.property),
      targetTemplate,
    ].sort((a, b) => a.name.localeCompare(b.name)),
    );
  }

  updateSearch(value: string): void {
    this.search.next(value);
  }

  resetFilters(): void {
    this.filterTemplates$.pipe(first()).subscribe((templates) => {
      this.templates.next(templates.map((template) => ({ ...template, filters: [] })));
    });
  }

  clear(): void {
    this.updateSearch(null);
    this.resetFilters();
  }

}
